<template>
    <!--   Raffle prizes  -->
    <div class="c-components_body unselectable" style="backdrop-filter: blur(5px);background: linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.6));">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <!--   Prizes bg static   -->
            <div class="c-prizes_2"
                 :style="{'width': realWidth-80*ss*xMult+'px','height': realHeight-80*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
            >
                <!--   vertical title  -->
                <div v-if="orient==='V'" class="c-prizes_column_1"
                     :style="{'width': realWidth-80*ss*xMult+'px','height': 280*ss*xMult+'px','gap': 30*ss*xMult+'px',}">

                    <!--   Raffle Prizes   -->
                    <div class="c-prizes_text"
                         :style="{'width': '100%','height': 80*ss*xMult+'px',
                             'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px','margin-top': 40*ss*xMult+'px',}"
                    >
                        {{messages.m030_1}}
                    </div>

                    <!--   Row with Daily/Weekly/Monthly switch   -->
                    <div class="c-prizes_row_3"
                         :style="{'width': 900*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 30*ss*xMult+'px',
                            'border-radius': 20*ss*xMult+'px',}"
                    >
                        <!--   Daily   -->
                        <div class="c-prizes_row_4"
                             @click="periodClick('daily')"
                             :style="{'width': 'auto','height': 120*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
                        >
                            <div class="c-prizes_ring_info"
                                 :style="{'min-width': 30*ss*xMult+'px',
                                    'box-shadow': prizes.period==='daily'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'background':prizes.period==='daily'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m030_2}}
                            </div>
                        </div>
                        <!--   Weekly   -->
                        <div class="c-prizes_row_4"
                             @click="periodClick('weekly')"
                             :style="{'width': 'auto','height': 120*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
                        >
                            <div class="c-prizes_ring_info"
                                 :style="{'min-width': 30*ss*xMult+'px',
                                    'box-shadow': prizes.period==='weekly'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'background':prizes.period==='weekly'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m030_3}}
                            </div>
                        </div>
                        <!--   Monthly   -->
                        <div class="c-prizes_row_4"
                             @click="periodClick('monthly')"
                             :style="{'width': 'auto','height': 120*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
                        >
                            <div class="c-prizes_ring_info"
                                 :style="{'min-width': 30*ss*xMult+'px',
                                    'box-shadow': prizes.period==='monthly'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'background':prizes.period==='monthly'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m030_4}}
                            </div>
                        </div>
                    </div>
                </div>

                <!--   vertical scroll static   -->
                <div v-if="orient==='V'" class="c-prizes_scroll_static"
                     :style="{'width': realWidth-80*ss*xMult+'px','gap': 30*ss*xMult+'px',
                        'height': orient==='V'?realHeight-530*ss*xMult+'px':realHeight-320*ss*xMult+'px',}"
                >
                    <!--   scroll content dynamic height PRIZES   -->
                    <div v-if="prizes.content==='prizes'" class="c-prizes_3"
                         :style="{'width': realWidth-80*ss*xMult+'px','height': 'auto','gap': 30*ss*xMult+'px',
                         'padding-top': 0*ss*xMult+'px','padding-bottom': 10*ss*xMult+'px',}"
                    >
                        <!--   Countdown   -->
                        <CountDown />
                        <Entries />
                        <!--   Prizes text  -->
                        <div class="c-prizes_text"
                             :style="{'width': '100%','height': 80*ss*xMult+'px',
                             'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                        >
                            {{messages.m030_15}}
                        </div>

                        <PrizeCard
                                v-for="(prizeItem, index) in getPrizes"
                                :key="prizes.period+index"
                                :prize-item="prizeItem"
                        />

                    </div>

                    <!--   scroll content dynamic height WINNERS   -->
                    <div v-if="prizes.content==='winners'" class="c-prizes_3"
                         :style="{'width': realWidth-80*ss*xMult+'px','height': 'auto','gap': 30*ss*xMult+'px',
                         'padding-top': 0*ss*xMult+'px','padding-bottom': 10*ss*xMult+'px',}"
                    >
                        <!--   Countdown   -->
                        <CountDown />
                        <Entries />

                        <!--   Winners text  -->
                        <div class="c-prizes_row_2"
                             :style="{'width': 900*ss*xMult+'px','height': 'auto','gap': 30*ss*xMult+'px',}"
                        >
                            <div class="c-prizes_text_win c-prizes_row_2"
                                 :style="{'width': 310*ss*xMult+'px','height': 80*ss*xMult+'px',
                                 'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                            >
                                {{messages.m030_16}}
                            </div>
                            <div class="c-prizes_text_win_2 c-prizes_row_2"
                                 :style="{'width': 560*ss*xMult+'px','height': 'auto','padding-top': 20*ss*xMult+'px','padding-bottom': 20*ss*xMult+'px',
                                 'font-size': 32*ss*xMult+'px','line-height': 36*ss*xMult+'px',}"
                            >
                                {{messages.m030_17}}
                            </div>
                        </div>

                        <WinnerElement
                                v-for="(winnerItem, index) in getWinners"
                                :key="prizes.period+index"
                                :item="winnerItem"
                        />
                    </div>
                </div>

                <!--   vertical Winners/Prizes + Claim buttons   -->
                <div v-if="orient==='V'" class="c-prizes_row_1"
                     :style="{'width': realWidth-80*ss*xMult+'px','height': 100*ss*xMult+'px','gap': 50*ss*xMult+'px','margin-top': 10*ss*xMult+'px',}">
                    <div class="c-prizes_row_3"
                         :style="{'width': 600*ss*xMult+'px','height': 100*ss*xMult+'px','gap': 15*ss*xMult+'px','border-radius': 60*ss*xMult+'px',}"
                    >
                        <!--   Prizes   -->
                        <div class="c-prizes_row_4"
                             @click="clickPrizes"
                             :style="{'width': 300*ss*xMult+'px','height': 100*ss*xMult+'px','gap': 20*ss*xMult+'px',
                             }"
                        >
                            <div class="c-prizes_ring_info"
                                 :style="{'width': '100%',
                                    'box-shadow': '0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'background':getBgPrizes,
                                    'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+0*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                            >
                                <img :src="prizesSvg" alt=""
                                     :style="{'width': 45*ss*xMult+'px','height': 45*ss*xMult+'px',}"
                                />
                                {{messages.m030_15}}
                            </div>
                        </div>
                        <!--   Winners   -->
                        <div class="c-prizes_row_4"
                             @click="clickWinners"
                             :style="{'width': 300*ss*xMult+'px','height': 100*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
                        >
                            <div class="c-prizes_ring_info"
                                 :style="{'width': '100%',
                                    'box-shadow': '0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'background':getBgWinners,
                                    'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+0*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                            >
                                <img :src="winnersSvg"
                                     :style="{'width': 45*ss*xMult+'px','height': 45*ss*xMult+'px',}"
                                />
                                {{messages.m030_16}}
                            </div>
                        </div>
                    </div>

                    <ButtonSvgOval
                            :callback="clickClaim" :callback-wait="true"
                            :btn-text="messages.m030_14"
                            :bg-width="250*ss*xMult" :bg-height="90*ss*xMult"
                            :font-size="45*ss*xMult"
                            :svg-width="60*ss*xMult" :svg-height="60*ss*xMult"
                            :click-scale="0.95"
                    />
                </div>

                <!--   Horizontal title   -->
                <div v-if="orient==='H'" class="c-prizes_row_horiz"
                     :style="{'width': realWidth-80*ss*xMult+'px','height': realHeight-80*ss*xMult+'px',}">
                    <!--   Raffle prizes / Daily-weekly-monthly / Countdown / Entries  -->
                    <div class="c-prizes_column_1"
                         :style="{'width': 1000*ss*xMult+'px','height': realHeight-80*ss*xMult+'px','gap': 30*ss*xMult+'px',}">

                        <!--   Raffle Prizes   -->
                        <div class="c-prizes_text"
                             :style="{'width': '100%','height': 80*ss*xMult+'px',
                             'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px','margin-top': 40*ss*xMult+'px',}"
                        >
                            {{messages.m030_1}}
                        </div>

                        <!--   Row with Daily/Weekly/Monthly switch   -->
                        <div class="c-prizes_row_3"
                             :style="{'width': 900*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 30*ss*xMult+'px',
                            'border-radius': 20*ss*xMult+'px',}"
                        >
                            <!--   Daily   -->
                            <div class="c-prizes_row_4"
                                 @click="periodClick('daily')"
                                 :style="{'width': 'auto','height': 120*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
                            >
                                <div class="c-prizes_ring_info"
                                     :style="{'min-width': 30*ss*xMult+'px',
                                    'box-shadow': prizes.period==='daily'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'background':prizes.period==='daily'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                                >
                                    {{messages.m030_2}}
                                </div>
                            </div>
                            <!--   Weekly   -->
                            <div class="c-prizes_row_4"
                                 @click="periodClick('weekly')"
                                 :style="{'width': 'auto','height': 120*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
                            >
                                <div class="c-prizes_ring_info"
                                     :style="{'min-width': 30*ss*xMult+'px',
                                    'box-shadow': prizes.period==='weekly'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'background':prizes.period==='weekly'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                                >
                                    {{messages.m030_3}}
                                </div>
                            </div>
                            <!--   Monthly   -->
                            <div class="c-prizes_row_4"
                                 @click="periodClick('monthly')"
                                 :style="{'width': 'auto','height': 120*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
                            >
                                <div class="c-prizes_ring_info"
                                     :style="{'min-width': 30*ss*xMult+'px',
                                    'box-shadow': prizes.period==='monthly'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'background':prizes.period==='monthly'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                                >
                                    {{messages.m030_4}}
                                </div>
                            </div>
                        </div>

                        <!--   Countdown   -->
                        <CountDown />
                        <Entries />

                        <!--   Horizontal Winners/Prizes + Claim buttons   -->
                        <div class="c-prizes_row_1" style="position: absolute"
                             :style="{'width': realWidth-80*ss*xMult+'px','height': 100*ss*xMult+'px','gap': 50*ss*xMult+'px',
                                'bottom': 40*ss*xMult+'px',}">
                            <div class="c-prizes_row_3"
                                 :style="{'width': 600*ss*xMult+'px','height': 100*ss*xMult+'px','gap': 15*ss*xMult+'px','border-radius': 60*ss*xMult+'px',}"
                            >
                                <!--   Prizes   -->
                                <div class="c-prizes_row_4"
                                     @click="clickPrizes"
                                     :style="{'width': 300*ss*xMult+'px','height': 100*ss*xMult+'px','gap': 20*ss*xMult+'px',
                             }"
                                >
                                    <div class="c-prizes_ring_info"
                                         :style="{'width': '100%',
                                    'box-shadow': '0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'background':getBgPrizes,
                                    'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+0*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                                    >
                                        <img :src="prizesSvg"
                                             :style="{'width': 45*ss*xMult+'px','height': 45*ss*xMult+'px',}"
                                        />
                                        {{messages.m030_15}}
                                    </div>
                                </div>
                                <!--   Winners   -->
                                <div class="c-prizes_row_4"
                                     @click="clickWinners"
                                     :style="{'width': 300*ss*xMult+'px','height': 100*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
                                >
                                    <div class="c-prizes_ring_info"
                                         :style="{'width': '100%',
                                    'box-shadow': '0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'background':getBgWinners,
                                    'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+0*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                                    >
                                        <img :src="winnersSvg"
                                             :style="{'width': 45*ss*xMult+'px','height': 45*ss*xMult+'px',}"
                                        />
                                        {{messages.m030_16}}
                                    </div>
                                </div>
                            </div>


                            <ButtonSvgOval
                                    :callback="clickClaim" :callback-wait="true"
                                    :btn-text="messages.m030_14"
                                    :bg-width="250*ss*xMult" :bg-height="90*ss*xMult"
                                    :font-size="45*ss*xMult"
                                    :svg-width="60*ss*xMult" :svg-height="60*ss*xMult"
                                    :click-scale="0.95"
                            />
                        </div>
                    </div>

                    <div class="c-prizes_column_1"
                         :style="{'width': realWidth-1080*ss*xMult+'px','gap': 0*ss*xMult+'px',
                            'height': realHeight-100*ss*xMult+'px',}"
                    >
                        <!--   Prizes text  -->
                        <div v-if="prizes.content==='prizes'" class="c-prizes_text"
                             :style="{'width': '100%','height': 80*ss*xMult+'px','margin-top': 40*ss*xMult+'px',
                                 'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                        >
                            {{messages.m030_15}}
                        </div>
                        <!--   Winners text  -->
                        <div v-if="prizes.content==='winners'" class="c-prizes_row_2"
                             :style="{'width': realWidth-1080*ss*xMult+'px','height': 'auto','gap': 30*ss*xMult+'px','margin-top': 40*ss*xMult+'px',}"
                        >
                            <div class="c-prizes_text_win c-prizes_row_2"
                                 :style="{'width': 310*ss*xMult+'px','height': 80*ss*xMult+'px',
                                            'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                            >
                                {{messages.m030_16}}
                            </div>
                            <div class="c-prizes_text_win_2 c-prizes_row_2"
                                 :style="{'width': 600*ss*xMult+'px','height': 'auto','padding-top': 20*ss*xMult+'px','padding-bottom': 20*ss*xMult+'px',
                                            'font-size': 35*ss*xMult+'px','line-height': 36*ss*xMult+'px',}"
                            >
                                {{messages.m030_17}}
                            </div>
                        </div>

                        <!--   Horizontal scroll static   -->
                        <div class="c-prizes_scroll_static"
                             :style="{'width': realWidth-1080*ss*xMult+'px','gap': 0*ss*xMult+'px','margin-top': 40*ss*xMult+'px',
                            'height': realHeight-200*ss*xMult+'px',}"
                        >
                            <!--   scroll content dynamic height PRIZES   -->
                            <div v-if="prizes.content==='prizes'" class="c-prizes_3"
                                 :style="{'width': realWidth-1080*ss*xMult+'px','height': 'auto','gap': 30*ss*xMult+'px',
                             'padding-top': 0*ss*xMult+'px','padding-bottom': 0*ss*xMult+'px',}"
                            >
                                <PrizeCard
                                        v-for="(prizeItem, index) in getPrizes"
                                        :key="prizes.period+index"
                                        :prize-item="prizeItem"
                                />

                            </div>

                            <!--   scroll content dynamic height WINNERS   -->
                            <div v-if="prizes.content==='winners'" class="c-prizes_3" style="justify-content: flex-start;"
                                 :style="{'width': realWidth-1080*ss*xMult+'px','height': 'auto','gap': 30*ss*xMult+'px',
                                    'padding-top': 0*ss*xMult+'px','padding-bottom': 10*ss*xMult+'px',}"
                            >

                                <WinnerElement
                                        v-for="(winnerItem, index) in getWinners"
                                        :key="prizes.period+index"
                                        :item="winnerItem"
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import TicketElement from "@/vue/tickets/TicketElement";
    import CountDown from "@/vue/tickets/CountDown";
    import Entries from "@/vue/tickets/Entries";
    import PrizeCard from "@/vue/tickets/PrizeCard";
    import WinnerElement from "@/vue/tickets/WinnerElement";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import prizesSvg from "@/assets/svg/tickets/prizes.svg";
    import winnersSvg from "@/assets/svg/tickets/winners.svg";
    import confetti from "@/assets/images/confetti.webp";
    import iconGemSvg from "@/assets/svg/icon_gem2.svg";
    import strokesSvg from "@/assets/svg/strokes.svg";
    import ticketBronze from "@/assets/images/tickets_bronze.webp";
    import ticketSilver from "@/assets/images/tickets_silver.webp";
    import ticketGold from "@/assets/images/tickets_gold.webp";

    export default defineComponent({
        name: 'Prizes',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","userData","messages","btnRad","orient","prizes","blockspinState"]),
            getBgPrizes(){
                const state=this.$store.state;
                let param='linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%';
                if (state.prizes.content!=='prizes')
                    param='none'
                return param;
            },
            getBgWinners(){
                const state=this.$store.state;
                let param='linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%';
                if (state.prizes.content!=='winners')
                    param='none';
                return param;
            },
            getPrizes(){
                const state=this.$store.state;
                let prizes=[];
                if (state.blockspinState.prizes)
                    prizes=state.blockspinState.prizes[state.prizes.period];
                return prizes;
            },
            getWinners(){
                const state=this.$store.state;
                let elems;
                if (state.blockspinState.winners)
                    elems=state.blockspinState.winners[state.prizes.period];
                elems=elems?elems:[];
                Utils.addDigitalPrizeData(elems);
                return elems;
            },
        },
        components: {ButtonSvg,ButtonSvgOval,TicketElement,CountDown,Entries,PrizeCard,WinnerElement},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),iconGemSvg:Utils.getGlobalLink(iconGemSvg), ticketBronze:Utils.getGlobalLink(ticketBronze),
                ticketSilver:Utils.getGlobalLink(ticketSilver),ticketGold:Utils.getGlobalLink(ticketGold),confetti:Utils.getGlobalLink(confetti),
                strokesSvg:Utils.getGlobalLink(strokesSvg),winnersSvg:Utils.getGlobalLink(winnersSvg),prizesSvg:Utils.getGlobalLink(prizesSvg),

            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            periodClick(period){
                const state=this.$store.state;
                if (period==='daily' || period==='weekly' || period==='monthly'){
                    state.prizes.period=period;
                }
            },
            btn_xClick(){
                this.$store.state.prizes.visible=false;
            },
            clickClaim(){
                this.$store.dispatch('getUser');
                this.$store.state.popupVisible.prizesClaim=true;
            },
            clickPrizes(){
                const state=this.$store.state;
                state.prizes.content='prizes';
            },
            clickWinners(){
                const state=this.$store.state;
                state.prizes.content='winners';
            },
        },
    });
</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-prizes_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;

        border-radius: 20px;
        background: radial-gradient(100.84% 132.88% at -11.6% -9.79%,#ca1d91 0, #6d14ce 100%);
        box-shadow: 0px 0px 60px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 70px 0px rgba(109, 20, 206, 0.70);
        /*background: rgba(0, 0, 0, 0.3);/**/
    }
    .c-prizes_3{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;

    }

    .c-prizes_scroll_static {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-prizes_column_1{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        /*background: linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg, #d0e5ea .27%, #000000 105.74%) 0/200%;/**/

        /*background: rgba(0, 0, 0, 0.3);/**/
    }
    .c-prizes_column_2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-prizes_row_1{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-prizes_row_horiz{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-prizes_row_2{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-prizes_row_3{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        background: rgba(255, 255, 255, 0.20);
    }
    .c-prizes_row_4{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-prizes_ring_info{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        border: 1px solid rgba(255, 255, 255, 0.10);
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.03em;
        color: #FFFFFF;
    }

    .c-prizes_img1{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-prizes_text{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;

        /*word-wrap: break-word;/**/
        white-space: nowrap;        /*Нет переноса не переносит*/
    }

    .c-prizes_text_win{
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        background: linear-gradient(95.19deg,#0cff7c -16.14%,#00af90 137.96%);
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;

        /*word-wrap: break-word;/**/
        white-space: nowrap;        /*Нет переноса не переносит*/
    }

    .c-prizes_text_win_2{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        color: #FFFFFF;

        /*word-wrap: break-word;/**/
        /*white-space: nowrap;        /*Нет переноса не переносит*/
    }


</style>

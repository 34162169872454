<template>
    <!--   Raffle Tickets    -->
    <div class="c-raffle"
         :style="{'width': 1000*ss*xMult+'px','height': 500*ss*xMult+'px','border-radius': 40*ss*xMult+'px','gap': 20*ss*xMult+'px',}"
    >
        <div class="c-user_info_title_text"
             :style="{'width': 485*ss*xMult+'px','height': 80*ss*xMult+'px','border-radius': 40*ss*xMult+'px',
                'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-top': 20*ss*xMult+'px',
                'text-shadow': '0 0 '+15*ss*xMult+'px rgba(57,231,156,.6)',}"
        >
            {{messages.m022}}
        </div>

        <div class="c-raffle_line"
             :style="{'width': 1000*ss*xMult+'px','height': 240*ss*xMult+'px','margin-top': 0*ss*xMult+'px','gap': 25*ss*xMult+'px',}"
        >
            <!--     Bronze       -->
            <div class="c-raffle_col"
                 :style="{'width': 300*ss*xMult+'px','height': 240*ss*xMult+'px','margin-top': 0*ss*xMult+'px','gap': 0*ss*xMult+'px',}"
            >
                <div class="c-raffle_text"
                     :style="{'width': 300*ss*xMult+'px','height': 70*ss*xMult+'px',
                     'font-size': 50*ss*xMult+'px','line-height': 70*ss*xMult+'px',}"
                >
                    {{userData.bronzeTickets?userData.bronzeTickets:0}}
                </div>
                <img class="c-raffle_img" :src="ticketBronze" alt=""
                     :style="{'width': 'auto','height': 105*ss*xMult+'px',}"
                />
                <div class="c-raffle_text"
                     :style="{'width': 300*ss*xMult+'px','height': 60*ss*xMult+'px','margin-top': 5*ss*xMult+'px',
                     'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                >
                    {{messages.m025_1}}
                </div>
            </div>

            <!--     Silver       -->
            <div class="c-raffle_col"
                 :style="{'width': 300*ss*xMult+'px','height': 240*ss*xMult+'px','margin-top': 0*ss*xMult+'px','gap': 0*ss*xMult+'px',}"
            >
                <div class="c-raffle_text"
                     :style="{'width': 300*ss*xMult+'px','height': 70*ss*xMult+'px',
                     'font-size': 50*ss*xMult+'px','line-height': 70*ss*xMult+'px',}"
                >
                    {{userData.silverTickets?userData.silverTickets:0}}
                </div>
                <img class="c-raffle_img" :src="ticketSilver" alt=""
                     :style="{'width': 'auto','height': 105*ss*xMult+'px',}"
                />
                <div class="c-raffle_text"
                     :style="{'width': 300*ss*xMult+'px','height': 60*ss*xMult+'px','margin-top': 5*ss*xMult+'px',
                     'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                >
                    {{messages.m025_2}}
                </div>
            </div>

            <!--     Gold       -->
            <div class="c-raffle_col"
                 :style="{'width': 300*ss*xMult+'px','height': 240*ss*xMult+'px','margin-top': 0*ss*xMult+'px','gap': 0*ss*xMult+'px',}"
            >
                <div class="c-raffle_text"
                     :style="{'width': 300*ss*xMult+'px','height': 70*ss*xMult+'px',
                     'font-size': 50*ss*xMult+'px','line-height': 70*ss*xMult+'px',}"
                >
                    {{userData.goldTickets?userData.goldTickets:0}}
                </div>
                <img class="c-raffle_img" :src="ticketGold" alt=""
                     :style="{'width': 'auto','height': 105*ss*xMult+'px',}"
                />
                <div class="c-raffle_text"
                     :style="{'width': 300*ss*xMult+'px','height': 60*ss*xMult+'px','margin-top': 5*ss*xMult+'px',
                     'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                >
                    {{messages.m025_3}}
                </div>
            </div>

        </div>

        <!--       Get more         -->
        <ButtonSvgOval2 :svg-normal="iconPlusSvg"
                        :btn-text="messages.m023"
                        :bg-width="300*ss*xMult" :bg-height="btnRad*0.6*xMult"
                        :font-size="btnRad*0.6*0.45*xMult"
                        :max-width="(500*ss-btnRad/2-70*ss)*xMult"
                        :svg-width="btnRad*0.6/2*xMult" :svg-height="btnRad*0.6/2*xMult"
                        :background-val="'linear-gradient(95.19deg,rgba(12,255,124,0) -16.14%,#00af90 237.96%) var(--x,0)/300%'"
                        :border-val="'2px solid'"
                        :border-color="'#0cff7c'"
                        :callback="btnGetClick" :callback-wait="true"
                        :click-scale="0.95"
        />


    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import PopupInfo from "@/vue/elements/PopupInfo";
    import ButtonSvgOval2 from "@/vue/elements/ButtonSvgOval2";
    import Utils from "@/scripts/Utils";
    import iconPlusSvg from "@/assets/svg/icon_plus_green";
    import ticketBronze from "@/assets/images/tickets_bronze.webp";
    import ticketSilver from "@/assets/images/tickets_silver.webp";
    import ticketGold from "@/assets/images/tickets_gold.webp";

    export default defineComponent({
        name: 'Raffle',
        components: {ButtonSvgOval, PopupInfo,ButtonSvgOval2},
        computed: {
            ...mapGetters(["ss","xMult","messages","btnRad","userData"]),

        },
        data(){
            return {
                iconPlusSvg:Utils.getGlobalLink(iconPlusSvg),ticketBronze:Utils.getGlobalLink(ticketBronze),
                ticketSilver:Utils.getGlobalLink(ticketSilver),ticketGold:Utils.getGlobalLink(ticketGold)
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btnGetClick(){
                this.$store.dispatch('clickGetTickets');
            },

        },

    });
</script>

<style lang="css" scoped>
    .c-raffle{
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: flex-start;
        align-items: center;

        background: hsla(0,0%,100%,.1);
    }

    .c-raffle_text{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: visible;
        color: #FFFFFF;
        text-align: -webkit-center;
    }

    .c-raffle_img{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .c-raffle_line{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

    }

    .c-raffle_col{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

</style>

import GameSignals from "./GameSignals";
import Config from "./Config";
import GameList from "./GameList";
import Backend from "../classes/backend";
import DigitalPrizes from "@/classes/DigitalPrizes";
import * as Constants from "@/classes/Constants";
//import {initMessaging} from "@/classes/firebase";

export default new class Utils {
    constructor() {
        this.getUserTries=0;
        this.logLeaderboardNum=0;
        this.logBetsNum=0;
        this.resizeTimer=null;
        this.correctBetsTimeout=null;
        this.digitalPrizes=DigitalPrizes;
        this.betsToAdd=[];                  //Add new bets from blockspinstate to add in transition group
        this.betsToAddInterval=null;
        this.deltaTime=(Config.betsUpdateTime-1)*1000;      //Time to add new bet in transition, change after every getBlockspinState

    }

    addDigitalPrizeData(prizes) {
        if (!prizes) return;
        for (let prize of prizes) {
            if (prize.type === "digital") {
                if (this.digitalPrizes[prize.digitalType]) {
                    prize.name = this.digitalPrizes[prize.digitalType].name;
                    prize.codeInstructions = this.digitalPrizes[prize.digitalType].codeInstructions;
                    prize.collection = this.digitalPrizes[prize.digitalType].collection;
                    if (this.digitalPrizes[prize.digitalType].img)
                        prize.img = this.getGlobalLink(this.digitalPrizes[prize.digitalType].img);
                    if (this.digitalPrizes[prize.digitalType].video)
                        prize.video = this.getGlobalLink(this.digitalPrizes[prize.digitalType].video);

                }
            }
        }
    }

    animationStopper(){
        document.body.classList.add("resize-animation-stopper");
        if (this.resizeTimer){
            clearTimeout(this.resizeTimer);
            this.resizeTimer=null;
        }
        this.resizeTimer = setTimeout(() => {
            document.body.classList.remove("resize-animation-stopper");
        }, 400);
    }

    addPayment(wall,defC){
        if (Config.walletLoad===true)
            return;
        try{
            Config.walletLoad=true;
            const src = document.createElement('script');
            //let srcUrl=this.getGlobalLink('payment/pay.js')
            let srcUrl=this.getGlobalLink('pay.js');
            src.setAttribute('src', srcUrl);
            document.head.appendChild(src);

            src.onload = ()=>{

                wall.value=appCreateWallet(defC);          //Don't import Wallet class. Connects in the script 'pay.js'
                MyLog("Payments loaded and ready");
            };
        }
        catch (e) {
            Config.walletLoad=false;
            console.log('Payments not available! Utils.addPayment catch: ',e.message,'\nstack: ',e.stack)
        }
    }

    clearList(lst){
        lst.forEach(cur=>{
            cur.splice(0,cur.length);
        });
        lst.splice(0,lst.length);
    }

    async connectTelegram(secretCode){
        const state=appVue.$store.state;
        const messages=appVue.$store.state.messages;
        try{
            const obj={env:this.getEnv(), loginData:state.loginData, telegramMergeCode:secretCode};
            MyLog('Waiting for Backend.connectTelegram...');
            MyLog(JSON.stringify(obj));
            const objRet=await Backend.connectTelegram(obj);
            MyLog('connectTelegram return:');
            MyLog(JSON.stringify(objRet));
        }
        catch (e) {
            let msg='Utils.connectTelegram catch: '+e.message+'\nstack: '+e.stack;
            console.log(msg);
            appVue.$store.state.showMessagePopup(msg, messages.m029_5,true);
        }
    }

    correctBetsSize(){
        if (this.betsActive==='all') {
            const state = appVue.$store.state;
            try {
                if (state.betsListCurrent.length > Config.betsMaxCount) {
                    state.betsListCurrent.splice(Config.betsMaxCount, state.betsListCurrent.length - Config.betsMaxCount);
                }
                MyLog('state.betsListCurrent.length=' + state.betsListCurrent.length + ' | ' + Date.now());
            } catch (e) {}
        }
    }

    createStateParam(route) {
        const queryParams = route.query;
        const paramsArray = [];

        for (const key in queryParams) {
            paramsArray.push(`${key}:${queryParams[key]}`);
        }

        return paramsArray.join(',');
    }

    //get secret code for merge account with discord account
    async discordGetSecret(){
        try {
            const messages=appVue.$store.state.messages;
            const objRet = await Backend.discordGetSecret({tgInitData:appVue.$store.state.tgInitData});
            MyLog('discordGetSecret objRet:')
            MyLog(JSON.stringify(objRet));
            if (objRet.telegramMergeCode){
                appVue.$store.state.showMessagePopup(objRet.telegramMergeCode, messages.m054_01,false);
            }
            else{
                appVue.$store.state.showMessagePopup(objRet.error, messages.m029_5,true);
            }
        }
        catch (e) {
            let msg='Utils.discordGetSecret catch: '+e.message+'\nstack: '+e.stack;
            console.log(msg);
            appVue.$store.state.showMessagePopup(msg, messages.m029_5,true);
        }
    }

    FormatTimeString=function(sec,short=false){          //sec => time in seconds. Return 00h 00m 00s
        let s=Math.round(sec);
        let h=Math.trunc(s/3600);
        s=s % 3600;
        let m=Math.trunc(s/60);
        s=s % 60;
        h=h.toString();
        m=m.toString().padStart(2,'0');
        s=s.toString().padStart(2,'0');

        if (short===false)
            return `${h}h ${m}m ${s}s`
        if (short===true){
            if (h==='0')
                return `${m}m ${s}s`
            else
                return `${h}h ${m}m ${s}s`
        }
    }

    init(){
        let resizeTimer;
        window.addEventListener("resize", () => {           //Stop Vue transitions until resize
            document.body.classList.add("resize-animation-stopper");
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                document.body.classList.remove("resize-animation-stopper");
            }, 400);

            this.resize();
        });
        this.resize();
    }

    getEnv() {
        //return "prod";
        let url = window.location.hostname;
        if(url.includes("test.") || url.includes("localhost") || (url.includes('127.0.0.1'))) {
            return "test";
        } else {
            return "prod";
        }
    }

    getGlobalLink(link){
        let globalLink=link;
        if (globalLink) {
            if (document.location.hostname === 'localhost')
                globalLink = 'http://localhost:8080/' + link;
            if (window.location.hostname.includes("mobiletest."))
                globalLink = 'https://mobiletest.blockspingaming.com/' + link;
            if (window.location.hostname.includes("mobile."))
                globalLink = 'https://mobile.blockspingaming.com/' + link;
        }
        else{
            globalLink='';
        }
        return globalLink
    }

    async getLeaderboard() {            //Start update leaderboard every 10 seconds
        this.stopLeaderboard();
        this.getLeaderboardAction()
        this.getLeaderboardTimeout = setInterval(async()=> {
            // In case get user has not returned yet, getters.getLoginData would be null
            this.getLeaderboardAction();
        }, Config.leaderboardUpdateTime * 1000);
    };
    async getLeaderboardAction() {
        const state=appVue.$store.state;
        const leaderboard = await Backend.getLeaderboard(state.loginData);
        if (leaderboard) {
            state.leaderboard=leaderboard;

            if (this.logLeaderboardNum===0 && state.loginData){
                this.logLeaderboardNum=1;
                MyLog('Leaderboard:')
                MyLog(JSON.stringify(leaderboard));
            }
        }
    }

    //betsGameType {slot/scratch/crash/instant},betsActive {all/big/mine}
    async getBets(betsGameType,betsActive) {            //Start update bets every 10 seconds
        try {
            this.stopBets();
            const state = appVue.$store.state;
            state.betsListCurrent.splice(0,state.betsListCurrent.length);
            this.betsToAdd.splice(0,this.betsToAdd.length);
            this.betsGameType = betsGameType;
            this.betsActive = betsActive;
            await this.getBetsFirstAction();
            if (this.betsActive==='all') {
                await this.getBetsNextAction();
                this.getBetsTimeout = setInterval(async () => {
                    // In case get user has not returned yet, getters.getLoginData would be null
                    await this.getBetsNextAction();
                }, Config.betsUpdateTime * 1000);
            }
        }catch (e) {
            console.log('Utils.getBets catch: ',e.message,'\nstack: ',e.stack)
        }
    }

    async getBetsFirstAction() {
        try {
            const state = appVue.$store.state;
            const bets = await Backend.getBets(state.loginData);

            if (bets) {
                state.bets = bets;
                bets[this.betsGameType][this.betsActive].forEach((bet,betIndex)=>{
                    if (betIndex>=5 || this.betsActive==='big'){       //Add first 5 bets in getBetsNextAction
                        state.betsListCurrent.push(bet);
                    }
                });

                if (this.logBetsNum === 0 && state.loginData) {
                    this.logBetsNum = 1;
                    MyLog('Recent bets:')
                    MyLog(JSON.stringify(bets));
                }
            }
        }
        catch (e) {
            console.log('Utils.getBets catch: ',e.message,'\nstack: ',e.stack)
        }
    }

    async getBetsNextAction() {
        try {
            const state = appVue.$store.state;
            const bets = await Backend.getBets(state.loginData);
            if (this.betsToAddInterval){
                clearInterval(this.betsToAddInterval);
                this.betsToAddInterval=null;
            }

            if (bets) {
                const betsCur=bets[this.betsGameType][this.betsActive];
                let timeLast=state.betsListCurrent[0]?state.betsListCurrent[0].timestamp:0;
                betsCur.slice().reverse().forEach((bet)=>{
                    if (bet.timestamp && bet.timestamp>timeLast){
                        this.betsToAdd.push(bet);
                    }
                });

                if (this.betsToAdd.length>0){
                    if (this.betsToAdd.length>Config.betsMaxCount){
                        this.betsToAdd.splice(0,this.betsToAdd.length-Config.betsMaxCount);
                    }

                    this.deltaTime=(Config.betsUpdateTime-0)*1000/this.betsToAdd.length;
                    state.betsAddDeltaTime=this.deltaTime/1000;
                    let bet=this.betsToAdd[0];
                    this.betsToAdd.splice(0,1);
                    state.betsListCurrent.splice(0,0,bet);
                    //setTimeout(()=>{this.correctBetsSize();},this.deltaTime/2)
                    this.correctBetsTimeout=setTimeout(()=>{this.correctBetsSize();},(Config.betsUpdateTime-0)*1000)

                    if (this.betsToAdd.length>0){
                        this.betsToAddInterval=setInterval(()=>{
                            if (this.betsToAdd.length>0){
                                bet=this.betsToAdd[0];
                                this.betsToAdd.splice(0,1);
                                state.betsListCurrent.splice(0,0,bet);
                                //setTimeout(()=>{this.correctBetsSize();},this.deltaTime/2)
                            }else{
                                clearInterval(this.betsToAddInterval);
                                this.betsToAddInterval=null;
                            }
                        },this.deltaTime);
                    }
                }
            }
        }
        catch (e) {
            console.log('Utils.getBets catch: ',e.message,'\nstack: ',e.stack)
        }
    }

    getClaimText(notificationSelected){
        const state=appVue.$store.state;
        const messages=state.messages;
        let msg='';
        try{
            switch(notificationSelected.prizeType) {
                case "chips": {
                    msg=notificationSelected.prizeAmount+' '+messages.m022_1;
                    break;
                }
                case "gems": {
                    msg=notificationSelected.prizeAmount+' '+messages.m022_2;
                    break;
                }
                case "bronzeTickets": {
                    msg=notificationSelected.prizeAmount+' '+state.messages.m027_4+(notificationSelected.prizeAmount>1?'s':'');
                    break;
                }
                case "silverTickets": {
                    msg=notificationSelected.prizeAmount+' '+state.messages.m027_5+(notificationSelected.prizeAmount>1?'s':'');
                    break;
                }
                case "goldTickets": {
                    msg=notificationSelected.prizeAmount+' '+state.messages.m027_6+(notificationSelected.prizeAmount>1?'s':'');
                    break;
                }
                case "monthlyCard": {
                    msg=notificationSelected.monthlyCardType+' ' +state.messages.m038_08;
                    break;
                }
                case "scratch": {
                    msg=notificationSelected.prizeAmount+' '+notificationSelected.prizeType+' '+notificationSelected.game;
                    break;
                }
            }
            msg=msg?msg:notificationSelected.prizeAmount+' '+notificationSelected.prizeType;
        }catch (e) {

        }
        return msg;
    }

    getDate(timestamp) {
        let date = new Date(timestamp);
        const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
        return months[date.getMonth()] + " " + (date.getDate()) + ", " + date.getFullYear();
    }

    getDateOrNow(timestamp) {
        let date;
        if (!timestamp)
            date = new Date.now();
        else
            date = new Date(timestamp);
        const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
        return months[date.getMonth()] + " " + (date.getDate()) + ", " + date.getFullYear();
    }

    getMeta = (url) =>                                  //Wait for image loading, for get image size
        new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = (err) => reject(err);
            img.src = url;
        });

    getRandomNumber (min, max) {
        return Math.random() * (max - min) + min
    }

    getRandomInt(min, max) {
        return Math.round(this.getRandomNumber(min, max))
    }

    getResetIn(period='hourly'){
        let s='';
        if (period==='weekly'){
            let week = Math.trunc((Date.now() / 1000 / 60 / 60 / 24 - 4) / 7);
            //let newWeek = ((week + 1) * 7 + 4) * 1000 * 60 * 60 * 24;
            let newWeek_sec = ((week + 1) * 7 + 4) * 60 * 60 * 24;
            s=this.FormatTimeString(newWeek_sec-Date.now()/1000);
        }

        if (period==='daily'){
            let day = Math.trunc(Date.now() / 1000 / 60 / 60 / 24);
            let newDay_sec=(day+1)*60*60*24;
            s=this.FormatTimeString(newDay_sec-Date.now()/1000);
        }

        if (period==='hourly'){
            let hour = Math.trunc(Date.now() / 1000 / 60 / 60);
            let newHour_sec=(hour+1)*60*60;
            s=this.FormatTimeString(newHour_sec-Date.now()/1000,true);
        }

        return s;
    };

    async getState(){
        const state=appVue.$store.state;
        const bs=await Backend.getState();
        state.blockspinState = bs;
        this.unlockGames(state,bs.gamesUnlockedForAll);
        this.updateTime(state);
        this.updatePrice();

        try{
            let pool = bs.prizes;
            this.addDigitalPrizeData(pool.daily);
            this.addDigitalPrizeData(pool.weekly);
            this.addDigitalPrizeData(pool.monthly);
        }catch (e) {
            console.log('Utils.getState catch: ',e.message,'\nstack: ',e.stack)
        }

        MyLog('blockspinState: ');
        MyLog(JSON.stringify(bs));
    }

    async getUser(loginData, untilUpdated = '') {
        try {
            setTimeout(()=>{
                //MyLog("calling firebase");
                //initMessaging();
            }, 5000)
            const state = appVue.$store.state;
            const getters = appVue.$store.getters;
            let user = await Backend.getUser(loginData);
            if (!user.error) {               //Не знаю для чего это пока что, сохранил на сколько смог
                if (state.userId && untilUpdated) {
                    if ((untilUpdated === 'chips' && user.chips === getters.getChipsBalance) ||
                        (untilUpdated === 'monthlyCard' && (!user.monthlyCard.expiry || (getters.getUserMonthlyCard && user.monthlyCard.expiry === getters.getUserMonthlyCard.expiry))) ||
                        (untilUpdated === 'boosts' && user.boosts.daysStreakInsuranceRemaining === getters.getUserBoosts.daysStreakInsuranceRemaining && user.boosts.xpFreeze === getters.getUserBoosts.xpFreeze && user.boosts.xpBoost == getters.getUserBoosts.xpBoost && user.boosts.megaXpBoost === getters.getUserBoosts.megaXpBoost && user.recoveryExperience === getters.getRecoveryExperience)
                    ) {
                        this.getUserTries++;

                        if (this.getUserTries < Config.getUserMaxTries) {
                            setTimeout(function () {
                                appVue.$store.dispatch('getUser',untilUpdated);
                                MyLog('appVue.$store.dispatch("getUser",untilUpdated);');
                            }, Config.getUserTimeoutTime)
                        }
                    }
                }

                //appVue.$store.commit("setLoginData", loginData);
                //state.userId=loginData.userId;
                this.loginSetUserData(state, user);
                this.addDigitalPrizeData(user.prizes);
            } else if (user.error === 'User not found' || user.error === "Auth Token Expired" || user.error === 'Authentication Error' || user.error === '') {
                localStorage.removeItem("loginData");
                appVue.$store.commit("setLoginData", null);
                state.userId = null;
            }
        }
        catch (e) {
            let msg='Utils.getUser catch: '+e.message+'\nstack: '+e.stack;
            console.log(msg);
            appVue.$store.state.showMessagePopup(msg, 'Utils.getUser catch',true);
        }
    }

    isMobile () {
        this.mobileCached = null;
        this.mobileCached = navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i);
        return this.mobileCached;
    }

    invoiceClosed(invoiceStatus){
        //'cancelled'/'paid'
        const state=appVue.$store.state;
        let count=0;                    //max 10 times for try purchaseInfoGet with txId
        if (invoiceStatus==='paid') {
            let interval=setInterval(()=>{
                count+=1;
                Backend.purchaseInfoGet({tgInitData:state.tgInitData}).then((obj)=>{
                    MyLog('purchaseInfoGet:');
                    MyLog(JSON.stringify(obj));
                    if (obj.txId) {
                        clearInterval(interval);
                        //let s=state.messages.m035_01+'\n'+obj.purchaseObj.description+'\ntxId: '+obj.txId;
                        let s=state.messages.m035_01+'\n'+obj.purchaseObj.description;
                        state.showMessagePopup(s,state.messages.m035_11,false);
                    }
                    else{
                        if (count>=12){
                            clearInterval(interval);
                            state.showMessagePopup(state.messages.m035_12,state.messages.m035_11,false);
                        }
                    }
                })
            },400)
        }
        else {
            state.showMessagePopup(state.messages.m035_10,state.messages.m029_5,true);
            Backend.purchaseInfoCancel({tgInitData:state.tgInitData});
        }
        MyLog('invoiceClosed invoiceStatus: ' + invoiceStatus);
    }

    //async login(loginMethod, accessToken, tokenType, code, redirectUri, referralCode, jumpTask, loginData) {
    async loginBackend(login){        //Return userData and loginData from backend
        this.loginTrap();

        const loginResponse = await Backend.login(
            login.loginMethod,
            login.accessToken,
            login.tokenType,
            login.code,
            login.redirectUri,
            login.referralCode,
            login.jumpTask,
            login.loginData
        );

        MyLog("loginResponse: "+JSON.stringify(loginResponse));
        if(!loginResponse.error) {
            let s=JSON.stringify(loginResponse.loginData);
            localStorage.setItem("loginData", s);
            let loginData=JSON.parse(s);
            let user=JSON.parse(JSON.stringify(loginResponse.user));

            appVue.$store.commit("setLoginData", loginData);            //Use global appVue (not this.$store)
            appVue.$store.state.userId=loginData?loginData.userId:null;
            MyLog("userId: "+appVue.$store.state.userId);
            this.loginSetUserData(appVue.$store.state,user);

            return loginResponse;
        } else {
            return loginResponse;
        }
    }

    loginTrap(){            //Waiting for 5 sec, if no loginResponse from backend, then show error message
        let count=0;
        const state=appVue.$store.state;
        const dt=100;
        let interval=setInterval(()=>{
            count+=dt;
            if (state.userId){
                clearInterval(interval);
            }
            if (count>5000){
                clearInterval(interval);
                state.showMessagePopup(state.messages.m029_13,state.messages.m029_5,true);
            }
        },dt);
    }

    loginGoogle(route){
        let link = "https://accounts.google.com/o/oauth2/auth?client_id=670546952853-cb3ear67ou5compu6l4ok5vm426lmh83.apps.googleusercontent.com&redirect_uri=" + encodeURIComponent(window.location.protocol + "//" + window.location.host) + "&response_type=token&scope=email";
        let state = this.createStateParam(route);
        if(state) {
            link += "&state=" + state;
        }
        console.log('link: ',link);
        setTimeout(()=>{
            localStorage.setItem("auth", "google");
            localStorage.setItem("path", window.location.pathname);
            window.location.href = link;
        },1);
    }

    async loginGuest() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        let ref;
        if (urlParams.get("ref")) {
            ref = urlParams.get("ref");
        }
        let loginObj={
            loginMethod: "guest",
            referralCode: ref
        };
        await this.loginBackend(loginObj);

    }

    loginDiscord(route){
        let link = "https://discord.com/api/oauth2/authorize?client_id=1033789772612771972&redirect_uri=" + encodeURIComponent(window.location.protocol + "//" + window.location.host) + "&response_type=token&scope=identify%20email";
        let state = this.createStateParam(route);
        if(state) {
            link += "&state=" + state;
        }
        localStorage.setItem("auth", "discord");
        localStorage.setItem("path", window.location.pathname);
        window.location.href = link;
    }

    loginFacebook(route){
        let link = "https://www.facebook.com/v15.0/dialog/oauth?client_id=895412358094670&redirect_uri=" + encodeURIComponent(window.location.protocol + "//" + window.location.host);
        let state = this.createStateParam(route);
        if(state) {
            link += "&state=" + state;
        }
        localStorage.setItem("auth", "facebook");
        localStorage.setItem("path", window.location.pathname);
        window.location.href = link;
    }

    logout(state){
        const loginData=state.loginData;
        Backend.logout(loginData);

        localStorage.removeItem("loginData");

        state.loginData=null;
        state.userData=null;

        this.unlockGames(state,state.blockspinState.gamesUnlockedForAll,true);      //Force lock games

        this.logoutWallet();

        GameSignals.goHome.dispatch();
    }

    logoutWallet(){
        try{
            const wallet=appVue.$store.state.wallet;
            if (wallet){
                if (wallet.wax.getUsername())
                    wallet.wax.logout();
                if (wallet.sol.getUsername())
                    wallet.sol.logout();
                if (wallet.ronin.getUsername())
                    wallet.ronin.logout();
            }
        }catch (e) {
            let msg='Utils.logoutWallet catch: '+e.message+'\nstack: '+e.stack;
            console.log(msg);
        }
    }

    MyLog(log){
        try{
            let url = window.location.hostname;
            if(url.includes("test.") || url.includes("localhost") || (url.includes('127.0.0.1'))) {
                console.log(log);
            }
        }
        catch (e) {

        }
    }

    processStateParam(stateParamsString) {
        let deserializedParams = {};

        if(stateParamsString) {
            let stateParams = stateParamsString.split(",");

            for(let stateParam of stateParams) {
                let stateParamSplit = stateParam.split(":");
                if(stateParamSplit.length === 2)
                {
                    if(stateParamSplit[0] === "ref") {
                        deserializedParams.referralCode = stateParamSplit[1];
                    } else if(stateParamSplit[0] === "transaction_id" || stateParamSplit[0] === "adv_sub") {
                        if(!deserializedParams.hasOwnProperty("jumpTask")) {
                            deserializedParams.jumpTask = {};
                        }
                        deserializedParams.jumpTask[stateParamSplit[0]] = stateParamSplit[1];
                    }
                }
            }
        }
        return deserializedParams;
    }

    resize() {
        this.resizeAction();
        setTimeout(()=>{this.resizeAction()},50)        //Double resize for iPhone chrome devices (else not see window size change)
    }
    resizeAction() {
        //I use in index.html minimum-scale=1.0 (not initial-scale=1.0) for correct calculate device screen size
        //<meta name="viewport" content="width=device-width,minimum-scale=1.0">

        let appWidth =  window.innerWidth;
        let appHeight = window.innerHeight;
        appVue.$store.state.deviceWidth=appWidth;
        appVue.$store.state.deviceHeight=appHeight;
        let orient=appWidth<appHeight?'V':'H';

        if (orient==='V'){                      //Calculate design size and aspect ratio only for Vertical orientation,
            //else redirect page to blockspingame web page, if not playing game now.
            let asp=appHeight/appWidth;
            if (asp>Config.aspRatioMax){
                appHeight=appWidth*Config.aspRatioMax;
            }
            if (asp<Config.aspRatioMin){
                appWidth=appHeight/Config.aspRatioMin;
            }
        }
        //appVue.$store.state.realWidth=appWidth;
        //appVue.$store.state.realHeight=appHeight;

        let perW = appWidth / Config.baseWidth;
        let perH = appHeight / Config.baseHeight;
        let ss = Math.min(perW, perH);
        //appVue.$store.state.ss=ss;

        let obj={realWidth:appWidth,realHeight:appHeight,ss:ss,orient:orient};
        let orientOld=appVue.$store.state.orient;
        appVue.$store.commit('resize',obj);

        if (orientOld!==orient){
            //appVue.$store.state.orient=orient;
            GameSignals.orientationChanged.dispatch();
        }
    }

    setRouterCorrPath(){        //Set corrected path names for router
        //I do it by hand because GameList structure isn't mine. For example correct name for 'allGames' is 'slot'
        let keys=Object.keys(GameList.allGames);
        keys.forEach(key=>{
           Config.corrPath.push('/slot/'+key);
        });

        keys=Object.keys(GameList.scratchCards);
        keys.forEach(key=>{
            Config.corrPath.push('/scratch/'+key);
        });

        keys=Object.keys(GameList.crashGames);
        keys.forEach(key=>{
            Config.corrPath.push('/crash/'+key);
        });

        keys=Object.keys(GameList.instantGames);
        keys.forEach(key=>{
            Config.corrPath.push('/instant/'+key);
        });

        keys=Object.keys(GameList.spincity);
        keys.forEach(key=>{
            Config.corrPath.push('/spincity/'+key);
        });

    }
    setActiveGames(){       //Set active slot games to state.achievSlotGames {} (isSoon!==true, testing!==true)
        const state=appVue.$store.state;
        state.achievSlotGames={};
        Object.keys(GameList.allGames).forEach((gameKey)=>{
            const game=GameList.allGames[gameKey];
            if (game.isSoon!==true && game.testing!==true){
                state.achievSlotGames[gameKey]=game;
            }
        });
    }

    setEnvOptions(state){            //Default game settings for Production. Change game env for test
        if (this.getEnv()==='test'){
            Config.gameTypesSoon=['poker','casino'];
            Config.gameTypesNew=['crashGames','instantGames','spincity'];
            GameList.spincity.tycoon.isSoon=false;
            GameList.allGames.parisparis.isSoon=false;
        }
        state.gameTypesNew=Config.gameTypesNew;
        state.gameTypesSoon=Config.gameTypesSoon;

        let sound=localStorage.getItem('sound');
        if (sound===false || sound===true) state.sound=sound;
    }

    setIcons(gameType, force=false){            //Set current icons in PageGameList
        const state=appVue.$store.state;
        let curList=[];
        let oldList=state.gameList;

        MyLog('setIcons state.gameType: '+state.gameType);
        MyLog('setIcons gameType: '+gameType);

        if (force) state.gameType=null;

        if (state.gameType!==gameType){
            state.gameList=[[]];
            this.clearList(oldList);
            setTimeout(()=>{
                state.gameType=gameType;
            },10);
        }

        let configList=GameList[gameType];
        let keys=configList?Object.keys(configList):[];
        if (!configList) return;
        let len=keys.length;

        let maxI=3, maxJ=2;
        //If first load and no user action before, add only 6 'slots' images
        if (gameType==='allGames' && state.firstUserAction===false && state.orient==='V'){
            maxI=3; maxJ=2;
            len=6;
        }
        else{
            if (gameType==='allGames' || gameType==='scratchCards')
                maxJ=state.orient==='V'?2:4;
            if (gameType==='crashGames')
                maxJ=state.orient==='V'?1:2;
            if (gameType==='instantGames')
                maxJ=state.orient==='V'?2:4;
            if (gameType==='spincity')
                maxJ=1;
            maxI=Math.trunc(len/maxJ);
            maxI+=len % maxJ===0?0:1;
        }

        MyLog('state.orient: '+state.orient);
        MyLog('appVue.state.orient: '+appVue.$store.state.orient);
        MyLog('state.realWidth: '+state.realWidth);


        let num=0;
        for (let i=0;i<maxI;i++){
            curList.push([]);
            let cur=curList[i];
            for (let j=0;j<maxJ;j++){
                if (num<len){
                    cur.push(configList[keys[num]]);
                }
                num+=1;
            }
        }

        state.gameList=curList;
        if (state.gameType===gameType){
            this.clearList(oldList);
            state.gameType=gameType;
        }
    }

    setIconsPath(){         //Associate filenames with file-loader hash names
        let images;

               //require.context not working with variables
        images = require.context("../assets/images/allGames", false, /\.webp$/);
        this.setIconsPathContext(images,GameList.allGames)

        images = require.context("../assets/images/crashGames", false, /\.webp$/);
        this.setIconsPathContext(images,GameList.crashGames)

        images = require.context("../assets/images/scratchCards", false, /\.webp$/);
        this.setIconsPathContext(images,GameList.scratchCards)

        images = require.context("../assets/images/instantGames", false, /\.webp$/);
        this.setIconsPathContext(images,GameList.instantGames)

        images = require.context("../assets/images/spincity", false, /\.webp$/);
        this.setIconsPathContext(images,GameList.spincity)

    }

    setIconsPathContext(images,curList){
        if (!images){ console.log('Error in PageOut.loadIcons (allGames)'); }
        else {
            let keys=Object.keys(curList);
            keys.forEach((key) => {
                let cur=curList[key];
                let img = images('./'+cur.image);
                cur.imageLink = img.default;
            });
        }
    }

    stopLeaderboard() {
        if (this.getLeaderboardTimeout) {
            clearInterval(this.getLeaderboardTimeout);
            this.getLeaderboardTimeout=null;
        }
    }
    stopBets() {
        if (this.getBetsTimeout) {
            clearInterval(this.getBetsTimeout);
            this.getBetsTimeout=null;
        }
        if (this.betsToAddInterval){
            clearInterval(this.betsToAddInterval);
            this.betsToAddInterval=null;
        }
        if (this.correctBetsTimeout){
            clearTimeout(this.correctBetsTimeout);
            this.correctBetsTimeout=null;
        }

    }

    loginSetUserData(state,user){
        user=user?user:{};

        state.userName=user.username;
        state.userData=user;

        if(state.loginData.loginMethod === "discord") {     //If login method not discord then "discordUsername" avaliable if discord connected to account
            state.userData.discordUsername=user.username;
        }

        if (this.getEnv()==='test' && user.unlockedGames) user.unlockedGames.tycoon=0;

        this.unlockGames(state,Object.keys(user.unlockedGames));

        MyLog("userData:");
        MyLog(JSON.stringify(user));

        /*commit('setIsInitialLoginData', true);
        commit('setAvatar', user.avatar);
        commit('setNumInvited', user.numReferred);
        commit('setReferralCode', user.referralCode);
        commit('setChips', user.chips);
        commit('setGems', user.gems);
        commit('setRecoveryExperience', user.recoveryExperience);
        commit('setExperience', user.experience);
        commit('setDailyStreak', user.dailyStreak);
        commit('setBronzeTickets', user.bronzeTickets);
        commit('setSilverTickets', user.silverTickets);
        commit('setGoldTickets', user.goldTickets);
        commit('setNumEntries', user.numEntries);
        commit('setUserPrizes', user.prizes);
        commit('setAchievements', user.achievements);
        commit('setUnlockedGames', user.unlockedGames);
        commit('setClaimedFreeChipsToday', user.claimedFreeChipsToday);
        commit('setNumScratchCards', user.numScratchCards);
        commit('setUserMonthlyCard', user.monthlyCard);
        commit('setUserBoosts', user.boosts);
        commit('setUserTokenBalances', user.tokenBalances);
        commit('setUserTokenWithdrawals', user.tokenWithdrawals);
        commit('setNumBets', user.numBets);
        commit('setTotalWon', user.totalWon);
        commit('setNotifications', user.notifications);

        if(getters.getLoginData.loginMethod == "discord") {
            commit('setDiscordUsername', user.username);
        } else if(user.discordUsername) {
            commit('setDiscordUsername', user.discordUsername);
        }
        //*/
    }

    unlockGames(state,unlockListParam,force=false){         //If force===true then set 'isUnlock' values from unlockListParam (true/false)
                                                                    //else only append true values from unlockListParam
        try {
            let unlockList = unlockListParam ? unlockListParam : [];
            let keysTypes = Object.keys(GameList);
            keysTypes.forEach(keyType => {
                let keysGames = Object.keys(GameList[keyType]);
                keysGames.forEach(keyGame => {
                    let game = GameList[keyType][keyGame];
                    if (force===true){
                        game.isUnlock = unlockList.includes(game.name);
                    }
                    else{
                        game.isUnlock = unlockList.includes(game.name) ? true : game.isUnlock;
                    }

                })
            });

            this.setIcons(state.gameType);

        }catch (e) {
            console.log('Utils.unlockGames catch: ',e.message,'\nstack: ',e.stack)
        }
    }

    updatePrice(){      //Update chips/boost/vip(monthlyCards) price, discount, blockchain price
        try {
            const state=appVue.$store.state;
            const chips=state.shop.items.chips;
            const boost=state.shop.items.boost;
            const vip=state.shop.items.vip;
            const bs=state.blockspinState;

            bs.chipsPrice.forEach((item,index)=>{
                chips[index].price.usd=item;
                //{roninUsdPrice,waxUsdPrice,maticUsdPrice,bnbUsdPrice,solUsdPrice}
                chips[index].price.sol=bs.solUsdPrice?(item/bs.solUsdPrice).toFixed(Constants.priceFraction.sol):0;
                chips[index].price.wax=bs.waxUsdPrice?(item/bs.waxUsdPrice).toFixed(Constants.priceFraction.wax):0;
                chips[index].price.ronin=bs.roninUsdPrice?(item/bs.roninUsdPrice).toFixed(Constants.priceFraction.ronin):0;
                chips[index].price.xtr=Math.round(item/Constants.xtrUsdPrice);

                chips[index].name=bs.chipsAmount[index].toLocaleString()+' '+state.messages.m022_1;
                chips[index].discount=bs.chipsDiscount[index];
            });

            Object.keys(boost).forEach((item,index)=>{
                let usd=bs.boosts[item].price?bs.boosts[item].price:0;
                let discount=bs.boosts[item].discount?bs.boosts[item].discount:0;
                boost[item].discount=discount;
                boost[item].price.usd=usd;
                boost[item].price.sol=bs.solUsdPrice?(usd/bs.solUsdPrice).toFixed(Constants.priceFraction.sol):0;
                boost[item].price.wax=bs.waxUsdPrice?(usd/bs.waxUsdPrice).toFixed(Constants.priceFraction.wax):0;
                boost[item].price.ronin=bs.roninUsdPrice?(usd/bs.roninUsdPrice).toFixed(Constants.priceFraction.ronin):0;
                boost[item].price.xtr=Math.round(usd/Constants.xtrUsdPrice);
            });

            //monthlyCards
            Object.keys(vip).forEach((item,index)=>{
                let mc=bs.monthlyCards[item];
                mc=mc?mc:{};
                let usd=mc.price?mc.price:0;
                let discount=mc.discount?mc.discount:0;
                vip[item].discount=discount;
                vip[item].price.usd=usd;
                vip[item].price.sol=bs.solUsdPrice?(usd/bs.solUsdPrice).toFixed(Constants.priceFraction.sol):0;
                vip[item].price.wax=bs.waxUsdPrice?(usd/bs.waxUsdPrice).toFixed(Constants.priceFraction.wax):0;
                vip[item].price.ronin=bs.roninUsdPrice?(usd/bs.roninUsdPrice).toFixed(Constants.priceFraction.ronin):0;
                vip[item].price.xtr=Math.round(usd/Constants.xtrUsdPrice);

                vip[item].dailyChipsBonusPercentage=mc.dailyChipsBonusPercentage;
                vip[item].daysStreakInsurance=mc.daysStreakInsurance;
                vip[item].maxBet=mc.maxBet;
                vip[item].chipsDiscountPercentage=mc.chipsDiscountPercentage;
                vip[item].unlockAllGames=mc.unlockAllGames;
                vip[item].unlockStats=mc.unlockStats;
                vip[item].entriesBonusPercentage=mc.entriesBonusPercentage;
                vip[item].turboMode=mc.turboMode;
                vip[item].xPBonusPercentage=mc.xPBonusPercentage;
                vip[item].gemsBonusPercentage=mc.gemsBonusPercentage;
            });
        }
        catch (e) {
            console.log('Utils.updatePrice catch: ',e.message,'\nstack: ',e.stack)
        }
    }

    updateTime(state){
        if(this.updateTimeInterval) {
            clearInterval(this.updateTimeInterval);
        }

        let initialFrontendTimestamp = Date.now();
        let initialBackendTimestamp = state.blockspinState.timestamp;
        let initialMillisecondsRemainingToday = state.blockspinState.millisecondsRemainingToday;
        let initialMillisecondsRemainingThisWeek = state.blockspinState.millisecondsRemainingThisWeek;
        let initialMillisecondsRemainingThisMonth = state.blockspinState.millisecondsRemainingThisMonth;

        this.updateTimeInterval=setInterval(()=>{
            const timeChange = Date.now() - initialFrontendTimestamp;
            state.blockspinState.timestamp=initialBackendTimestamp+timeChange;
            state.blockspinState.millisecondsRemainingToday=initialMillisecondsRemainingToday-timeChange;
            state.blockspinState.millisecondsRemainingThisWeek=initialMillisecondsRemainingThisWeek-timeChange;
            state.blockspinState.millisecondsRemainingThisMonth=initialMillisecondsRemainingThisMonth-timeChange;

            const oneHour = 1000 * 60 * 60;
            const mrt=state.blockspinState.millisecondsRemainingToday;
            const millisecondsRemainingUntilNextHour = mrt % oneHour;
            if(mrt < 0 ||
                (millisecondsRemainingUntilNextHour > (oneHour-7000) && millisecondsRemainingUntilNextHour < (oneHour-5000) )) {
                this.getState(state);
                this.getUser(state.loginData);
                clearInterval(this.updateTimeInterval);
                //dispatch('getLeaderboard');
            }
        }, 1000);
    }

    async wait(waitTime){
        await new Promise(resolve => setTimeout(resolve,waitTime));
    }

}

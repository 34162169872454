<template>
    <div class="c-components_body unselectable" style="backdrop-filter: blur(5px);background: linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.6));">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <!--   Ticket bg static   -->
            <div class="c-tickets_2"
                 :style="{'width': realWidth-80*ss*xMult+'px','height': realHeight-80*ss*xMult+'px','gap': 30*ss*xMult+'px',}"
            >
                <!--   Gems and tickets count vertical   -->
                <div v-if="orient==='V'" class="c-tickets_column_1"
                     :style="{'width': realWidth-80*ss*xMult+'px','height': 320*ss*xMult+'px','gap': 30*ss*xMult+'px',}">
                    <!--img :src="confetti" alt="" style="position: absolute"
                         :style="{'width': realWidth-80*ss*xMult+'px','height': 'auto','top': 420*ss*xMult+'px',}"
                    /-->

                    <!--   Row with gems   -->
                    <div class="c-tickets_row_1"
                         :style="{'width': realWidth-80*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 30*ss*xMult+'px','margin-top': 50*ss*xMult+'px',}"
                    >
                        <div class="c-tickets_ring_info"
                             :style="{'min-width': 30*ss*xMult+'px',
                                'box-shadow': '0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                'border-radius': 50*ss*xMult+'px',
                                'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                        >
                            {{userData.gems?userData.gems.toLocaleString():'0'}}
                        </div>
                        <img :src="iconGemSvg" alt=""
                             :style="{'width': 'auto','height': 100*ss*xMult+'px',}"
                        />
                    </div>

                    <!--   Row with tickets   -->
                    <div class="c-tickets_row_1"
                         :style="{'width': realWidth-80*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 30*ss*xMult+'px',}"
                    >
                        <!--   ticketBronze   -->
                        <div class="c-tickets_row_1"
                             :style="{'width': 'auto','height': 120*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
                        >
                            <img :src="ticketBronze" alt=""
                                 :style="{'width': 'auto','height': 100*ss*xMult+'px',}"
                            />
                            <div class="c-tickets_ring_info"
                                 :style="{'min-width': 30*ss*xMult+'px',
                                    'box-shadow': '0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{userData.bronzeTickets?userData.bronzeTickets:0}}
                            </div>
                        </div>
                        <!--   ticketSilver   -->
                        <div class="c-tickets_row_1"
                             :style="{'width': 'auto','height': 120*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
                        >
                            <img :src="ticketSilver" alt=""
                                 :style="{'width': 'auto','height': 100*ss*xMult+'px',}"
                            />
                            <div class="c-tickets_ring_info"
                                 :style="{'min-width': 30*ss*xMult+'px',
                                    'box-shadow': '0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{userData.silverTickets?userData.silverTickets:0}}
                            </div>
                        </div>
                        <!--   ticketGold   -->
                        <div class="c-tickets_row_1"
                             :style="{'width': 'auto','height': 120*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
                        >
                            <img :src="ticketGold" alt=""
                                 :style="{'width': 'auto','height': 100*ss*xMult+'px',}"
                            />
                            <div class="c-tickets_ring_info"
                                 :style="{'min-width': 30*ss*xMult+'px',
                                    'box-shadow': '0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{userData.goldTickets?userData.goldTickets:0}}
                            </div>
                        </div>
                    </div>
                </div>

                <!--   Gems and tickets count horizontal   -->
                <div v-if="orient==='H'" class="c-tickets_row_1"
                     :style="{'width': realWidth-80*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 100*ss*xMult+'px','margin-top': 50*ss*xMult+'px',}">
                    <!--img :src="confetti" alt="" style="position: absolute"
                         :style="{'width': realWidth-80*ss*xMult+'px','height': 'auto','top': 420*ss*xMult+'px',}"
                    /-->

                    <!--   Row with gems   -->
                    <div class="c-tickets_row_1"
                         :style="{'width': 'auto','height': 120*ss*xMult+'px','gap': 30*ss*xMult+'px',}"
                    >
                        <div class="c-tickets_ring_info"
                             :style="{'min-width': 30*ss*xMult+'px',
                                'box-shadow': '0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                'border-radius': 50*ss*xMult+'px',
                                'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                        >
                            {{userData.gems?userData.gems.toLocaleString():'0'}}
                        </div>
                        <img :src="iconGemSvg" alt=""
                             :style="{'width': 'auto','height': 100*ss*xMult+'px',}"
                        />
                    </div>

                    <!--   Row with tickets   -->
                    <div class="c-tickets_row_1"
                         :style="{'width': 'auto','height': 120*ss*xMult+'px','gap': 30*ss*xMult+'px',}"
                    >
                        <!--   ticketBronze   -->
                        <div class="c-tickets_row_1"
                             :style="{'width': 'auto','height': 120*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
                        >
                            <img :src="ticketBronze" alt=""
                                 :style="{'width': 'auto','height': 100*ss*xMult+'px',}"
                            />
                            <div class="c-tickets_ring_info"
                                 :style="{'min-width': 30*ss*xMult+'px',
                                    'box-shadow': '0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{userData.bronzeTickets?userData.bronzeTickets:0}}
                            </div>
                        </div>
                        <!--   ticketSilver   -->
                        <div class="c-tickets_row_1"
                             :style="{'width': 'auto','height': 120*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
                        >
                            <img :src="ticketSilver" alt=""
                                 :style="{'width': 'auto','height': 100*ss*xMult+'px',}"
                            />
                            <div class="c-tickets_ring_info"
                                 :style="{'min-width': 30*ss*xMult+'px',
                                    'box-shadow': '0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{userData.silverTickets?userData.silverTickets:0}}
                            </div>
                        </div>
                        <!--   ticketGold   -->
                        <div class="c-tickets_row_1"
                             :style="{'width': 'auto','height': 120*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
                        >
                            <img :src="ticketGold" alt=""
                                 :style="{'width': 'auto','height': 100*ss*xMult+'px',}"
                            />
                            <div class="c-tickets_ring_info"
                                 :style="{'min-width': 30*ss*xMult+'px',
                                    'box-shadow': '0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{userData.goldTickets?userData.goldTickets:0}}
                            </div>
                        </div>
                    </div>
                </div>

                <!--   Ticket scroll static   -->
                <div class="c-tickets_scroll_static"
                     :style="{'width': realWidth-80*ss*xMult+'px','gap': 30*ss*xMult+'px',
                        'height': orient==='V'?realHeight-460*ss*xMult+'px':realHeight-320*ss*xMult+'px',}"
                >
                    <!--   scroll content dynamic height   -->
                    <div class="c-tickets_3"
                         :style="{'width': realWidth-80*ss*xMult+'px','height': 'auto','gap': 30*ss*xMult+'px',
                         'padding-top': 0*ss*xMult+'px','padding-bottom': 10*ss*xMult+'px',}"
                    >
                        <!--   Buy Raffle Tickets   -->
                        <div class="c-tickets_text"
                             :style="{'width': '100%','height': 80*ss*xMult+'px',
                             'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                        >
                            {{messages.m027_1}}
                        </div>

                        <!--   Tickets   -->
                        <div v-if="orient==='V'" class="c-tickets_column_2"
                             :style="{'width': '100%','height': 2460*ss*xMult+'px','gap': 30*ss*xMult+'px',}"
                        >
                            <div class="c-tickets_row_2" :style="{'width': '100%','height': 800*ss*xMult+'px',}">
                                <TicketElement ticket-type="bronze"/>
                            </div>
                            <div class="c-tickets_row_2" :style="{'width': '100%','height': 800*ss*xMult+'px',}">
                                <TicketElement ticket-type="silver"/>
                            </div>
                            <div class="c-tickets_row_2" :style="{'width': '100%','height': 800*ss*xMult+'px',}">
                                <TicketElement ticket-type="gold"/>
                            </div>
                        </div>
                        <div v-if="orient==='H'" class="c-tickets_row_2"
                             :style="{'width': '100%','height': 800*ss*xMult+'px',}"
                        >
                            <div class="c-tickets_row_2" :style="{'width': '33%','height': 800*ss*xMult+'px',}">
                                <TicketElement ticket-type="bronze"/>
                            </div>
                            <div class="c-tickets_row_2" :style="{'width': '33%','height': 800*ss*xMult+'px',}">
                                <TicketElement ticket-type="silver"/>
                            </div>
                            <div class="c-tickets_row_2" :style="{'width': '33%','height': 800*ss*xMult+'px',}">
                                <TicketElement ticket-type="gold"/>
                            </div>
                        </div>


                        <!--   Strokes   -->
                        <img class="c-tickets_img1"
                             :src="strokesSvg" alt=""
                             :style="{'width': realWidth-120*ss*xMult+'px','height': 'auto','margin-top': 30*ss*xMult+'px',}"
                        />

                        <!--   Enter a raffle now!   -->
                        <div class="c-tickets_text"
                             :style="{'width': '100%','height': 80*ss*xMult+'px',
                             'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                        >
                            {{messages.m027_2}}
                        </div>

                        <!--   Lotto   -->
                        <div v-if="orient==='V'" class="c-tickets_column_2"
                             :style="{'width': '100%','height': 2460*ss*xMult+'px','gap': 30*ss*xMult+'px',}"
                        >
                            <div class="c-tickets_row_2" :style="{'width': '100%','height': 800*ss*xMult+'px',}">
                                <TicketElement ticket-type="lottoBronze"/>
                            </div>
                            <div class="c-tickets_row_2" :style="{'width': '100%','height': 800*ss*xMult+'px',}">
                                <TicketElement ticket-type="lottoSilver"/>
                            </div>
                            <div class="c-tickets_row_2" :style="{'width': '100%','height': 800*ss*xMult+'px',}">
                                <TicketElement ticket-type="lottoGold"/>
                            </div>
                        </div>
                        <div v-if="orient==='H'" class="c-tickets_row_2"
                             :style="{'width': '100%','height': 800*ss*xMult+'px',}"
                        >
                            <div class="c-tickets_row_2" :style="{'width': '33%','height': 800*ss*xMult+'px',}">
                                <TicketElement ticket-type="lottoBronze"/>
                            </div>
                            <div class="c-tickets_row_2" :style="{'width': '33%','height': 800*ss*xMult+'px',}">
                                <TicketElement ticket-type="lottoSilver"/>
                            </div>
                            <div class="c-tickets_row_2" :style="{'width': '33%','height': 800*ss*xMult+'px',}">
                                <TicketElement ticket-type="lottoGold"/>
                            </div>
                        </div>
                    </div>
                </div>

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import TicketElement from "@/vue/tickets/TicketElement";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import confetti from "@/assets/images/confetti.webp";
    import iconGemSvg from "@/assets/svg/icon_gem2.svg";
    import strokesSvg from "@/assets/svg/strokes.svg";
    import ticketBronze from "@/assets/images/tickets_bronze.webp";
    import ticketSilver from "@/assets/images/tickets_silver.webp";
    import ticketGold from "@/assets/images/tickets_gold.webp";

    export default defineComponent({
        name: 'Tickets',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","userData","messages","btnRad","orient"]),

        },
        components: {ButtonSvg,ButtonSvgOval,TicketElement,},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),iconGemSvg:Utils.getGlobalLink(iconGemSvg), ticketBronze:Utils.getGlobalLink(ticketBronze),
                ticketSilver:Utils.getGlobalLink(ticketSilver),ticketGold:Utils.getGlobalLink(ticketGold),confetti:Utils.getGlobalLink(confetti),
                strokesSvg:Utils.getGlobalLink(strokesSvg),

            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btn_xClick(){
                this.$store.state.popupVisible.tickets=false;
            },
        },
    });
</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-tickets{
        display: flex;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0;
        margin:0;
        width: 100vw;
        height: 100vh;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;

        backdrop-filter: blur(5px);
        background: linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,0.6));
    }

    .c-tickets_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;

        border-radius: 20px;
        background: radial-gradient(233.23% 93.25% at 92.1% -14.57%, #F30B25 0%, #E41186 100%), #FFF;
        box-shadow: 0px 0px 60px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 70px 0px rgba(255, 0, 125, 0.70);
        /*background: rgba(0, 0, 0, 0.3);/**/
    }
    .c-tickets_3{
        display: grid;
        position: relative;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: visible;
    }

    .c-tickets_scroll_static {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
    }

    .c-tickets_column_1{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }
    .c-tickets_column_2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-tickets_row_1{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }


    .c-tickets_row_2{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-tickets_ring_info{
        display: flex;
        justify-content: center;
        align-items: center;

        background: rgba(255, 255, 255, 0.20);
        border: 1px solid rgba(255, 255, 255, 0.10);
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.03em;
        color: #FFFFFF;
    }

    .c-tickets_img1{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-tickets_text{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;

        /*word-wrap: break-word;/**/
        white-space: nowrap;        /*Нет переноса не переносит*/
    }


</style>

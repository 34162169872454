<template>
    <!--   Achievements    -->
    <div class="c-achievements"
         :style="{'width': 1000*ss*xMult+'px','height': (190+boostsCount*boostSize)*ss*xMult+'px',
         'border-radius': 40*ss*xMult+'px','gap': 0*ss*xMult+'px',
            'margin-bottom': 30*ss*xMult+'px'}"
    >

        <div class="c-achievements_row_2"
             :style="{'width': 1000*ss*xMult+'px','height': 100*ss*xMult+'px','margin-top': 20*ss*xMult+'px','gap': 20*ss*xMult+'px',}"
        >
            <div class="c-user_info_title_text"
                 :style="{'width':'auto','max-width': 485*ss*xMult+'px','height': 100*ss*xMult+'px','border-radius': 40*ss*xMult+'px',
                    'font-size': 46*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                    'text-shadow': '0 0 '+15*ss*xMult+'px rgba(57,231,156,.6)',}"
            >
                {{messages.m022_3}}
            </div>

            <ButtonSvgOval2 :svg-normal="iconPlusSvg"
                            :btn-text="achievementsStatsUnlocked?messages.m023_2:messages.m023_1"
                            :bg-width="420*ss*xMult" :bg-height="btnRad*0.6*xMult"
                            :font-size="btnRad*0.6*0.45*xMult"
                            :max-width="(500*ss-btnRad/2-70*ss)*xMult"
                            :svg-width="btnRad*0.6/2*xMult" :svg-height="btnRad*0.6/2*xMult"
                            :background-val="'linear-gradient(95.19deg,rgba(12,255,124,0) -16.14%,#00af90 237.96%) var(--x,0)/300%'"
                            :border-val="'2px solid'"
                            :border-color="'#0cff7c'"
                            :callback="btnGetClick" :callback-wait="true"
                            :click-scale="0.95"
            />
        </div>

        <div v-if="boostsCount" class="c-achievements_col"
             :style="{'width': 1000*ss*xMult+'px','height': boostsCount*boostSize*ss*xMult+'px',}"
        >
            <div v-for="(achievKey, boostIndex) in boostsKeys"
                 class="c-achievements_row_main" style="border-bottom: 2px solid #19c9f3;"
                 :style="{'width': 980*ss*xMult+'px','height': (boostSize-35)*ss*xMult+'px','margin-top': 35*ss*xMult+'px',
                    'gap': 20*ss*xMult+'px',}"
            >
                <img class="c-achievements_row" alt=""
                     :src="Utils.getGlobalLink(achievObj[userData.achievements[achievKey].name].image)"
                     :style="{'width': 175*ss*xMult+'px','height': (boostSize-55)*ss*xMult+'px',}"
                />

                <!--      Boost name          -->
                <div class="c-achievements_col2"
                     :style="{'width': 760*ss*xMult+'px','height': (boostSize-55)*ss*xMult+'px',}"
                >
                    <div class="c-achievements_text"
                         :style="{'width': 760*ss*xMult+'px','height': (boostSize-55)*0.35*ss*xMult+'px',
                            'font-size': (boostSize-55)*0.25*ss*xMult+'px','line-height': (boostSize-55)*0.35*ss*xMult+'px',}"
                    >
                        {{getDate(userData.achievements[achievKey].timestamp)}}
                    </div>

                    <div class="c-achievements_text"
                         :style="{'width': 760*ss*xMult+'px','height': (boostSize-55)*0.35*ss*xMult+'px',
                            'font-size': (boostSize-55)*0.25*ss*xMult+'px','line-height': (boostSize-55)*0.35*ss*xMult+'px',}"
                    >
                        {{achievObj[userData.achievements[achievKey].name].title}}
                    </div>

                    <div class="c-achievements_text_2" style="opacity: 0.6;"
                         :style="{'width': 760*ss*xMult+'px','height': (boostSize-55)*0.3*ss*xMult+'px',
                            'font-size': (boostSize-55)*0.2*ss*xMult+'px','line-height': (boostSize-55)*0.3*ss*xMult+'px',}"
                    >
                        {{achievObj[userData.achievements[achievKey].name].description}}
                    </div>
                </div>

            </div>
        </div>

    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import ButtonSvgOval2 from "@/vue/elements/ButtonSvgOval2";
    import PopupInfo from "@/vue/elements/PopupInfo";
    import Utils from "@/scripts/Utils";
    import iconPlusSvg from "@/assets/svg/icon_plus_green";

    export default defineComponent({
        name: 'Achievements',
        components: {ButtonSvgOval2, PopupInfo,},
        computed: {
            ...mapGetters(["ss","xMult","messages","btnRad","userData","achievObj","achievementsStatsUnlocked"]),
            boostsCount(){
                const ud=this.$store.state.userData;
                let cnt=0;
                if (ud && ud.achievements)
                    cnt=Object.keys(ud.achievements).length;
                return cnt;
            },
            boostsKeys(){
                const ud=this.$store.state.userData;
                let keys=[];
                if (ud && ud.achievements)
                    keys=Object.keys(ud.achievements);
                return keys;
            },
            boostSize(){
                return 220;
            },

        },
        data(){
            return {
                Utils:Utils,iconPlusSvg:Utils.getGlobalLink(iconPlusSvg),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btnGetClick(){
                if (this.$store.getters.achievementsStatsUnlocked){
                    this.$store.state.popupVisible.achievStats=true;
                }
                else {
                    const shop = this.$store.state.shop;
                    shop.shopType = 'boost';
                    shop.visible = true;
                }
            },
            getDate(timestamp) {
                let date;
                if (!timestamp)
                    date = new Date.now();
                else
                    date = new Date(timestamp);
                const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
                return months[date.getMonth()] + " " + (date.getDate()) + ", " + date.getFullYear();
            },

        },

    });
</script>

<style lang="css" scoped>
    .c-achievements{
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: flex-start;
        align-items: center;

        background: hsla(0,0%,100%,.1);
    }

    .c-achievements_text{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        color: #FFFFFF;
        white-space: nowrap;
    }

    .c-achievements_text_2{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        color: #FFFFFF;
        white-space: nowrap;
    }

    .c-achievements_row{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .c-achievements_row_2{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .c-achievements_col{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-achievements_col2{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-achievements_row_main{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }


</style>

<template>
    <!-- bgWidth string value with px (can be auto) bgHeight-->
    <div class="c-button_svg_oval_bg unselectable"
         :style="{'width': bgWidth+'px','height': bgHeight+'px'}"
    >

        <!-- Button content  -->
        <div class="c-button_svg_oval_fix" @click="onClick"
             :style="{'width': bgWidth+'px','height': bgHeight+'px','top': 0*ss+'px','left': 0*ss+'px'}"
        >
            <div class="c-button_svg_oval"
                 :style="{'width': (bgWidth-12*ss)*scale+'px','height': (bgHeight-8*ss)*scale+'px','gap':20*ss+'px',
                    'background':backgroundVal,'border-radius':borderRadius*scale+'px',
                    'border':borderVal,'border-color':borderColor,}"
            >
                <div v-if="svgNormal" class="c-button_svg_oval_cont"
                     :style="{'width': svgWidth*scale+'px','height': svgHeight*scale+'px'}"
                >
                    <img style="display: flex; align-items: center; justify-content: center;" alt=""
                         :style="{'width': svgWidth*scale+'px','height': svgHeight*scale+'px'}"
                         :src="svgNormal"
                    />
                </div>

                <div v-if="btnText" class="c-button_svg_oval_text"
                     :style="{'width': 'auto','max-width':maxWidth+'px','font-size': fontSize?fontSize*scale+'px':bgHeight/3*scale+'px'}"
                >
                    {{btnText}}
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';


    export default defineComponent({
        name: 'ButtonSvgOval2',
        components: {},
        computed: {
            ...mapGetters(["ss"]),

        },
        data(){
            return {
                clicked:false,
                scale:1,
            };
        },
        created() {
            //console.log(this.clickScale)
        },
        mounted() {
        },
        methods: {
            onClick() {
                if (!this.callback) return;
                this.clicked=true;
                if (!this.callbackWait) this.callback();

                TweenMax.to(this,{scale:this.clickScale,duration:0.1,onComplete:()=>{
                        TweenMax.to(this,{scale:1,duration:0.1,onComplete:()=>{
                                this.clicked=false;
                                if (this.callbackWait) this.callback();
                            }
                        });
                    }
                });
            },
        },

        props:{
            svgNormal: {
                type: String,
                default: ""
            },
            btnText: {
                type: String,
                default: null
            },
            bgWidth: {
                type: Number,
                default: 0         // if 0 than 'auto'
            },
            bgHeight: {
                type: Number,
                default: 0          // if 0 than 'auto'
            },
            fontSize: {
                type: Number,
                default: 0          // if 0 than bgHeight/3
            },
            maxWidth: {                 //Max text field width
                type: Number,
                default: 100         //
            },
            svgWidth: {
                type: Number,
                default: 44
            },
            svgHeight: {
                type: Number,
                default: 44
            },
            callback: {
                type: Function,
                default: () => {}
            },
            callbackWait: {                 //If true, then call callback after delay
                type: Boolean,
                default: false
            },
            opacityVal: {
                type: Number,
                default: 1
            },
            borderRadius: {
                type: Number,
                default: 44
            },
            backgroundVal: {
                type: String,
                default: 'linear-gradient(180deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.00) 58.54%), linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)'
            },
            borderColor: {
                type: String,
                default: '#FFFFFF'
            },
            borderVal: {
                type: String,
                default: 'linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%)'
            },
            clickScale: {                   //default sacle when click
                type: Number,
                default: 0.9
            },
        }
    });
</script>

<style lang="css" scoped>

    .c-button_svg_oval_bg{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 0px 0px;
    }

    .c-button_svg_oval_fix{
        display: flex;
        position: absolute;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /*transform: translate(50%, -50%);/**/
        transform: translate(0, 0);

        /*top: 0;
        left: 0;/**/
    }

    .c-button_svg_oval{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 0px 0px;
    }

    /*.c-button_svg_oval::after {
        display: flex;
        background: linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%);
        content: '';
        height: 100%;
        width: 100%;
        padding: 2px;
        top: 0;
        right: 0;
        -webkit-mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        border-radius: 50px;
        position: absolute;
    }/**/

    .c-button_svg_oval_border{
        display: flex;
        /*background: linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%);/**/
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }

    .c-button_svg_oval_cont{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .c-button_svg_oval_text{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        color: #FFFFFF;

        font-family: Rubik;
        white-space: nowrap;
        font-style: normal;
        font-weight: 600;
        overflow: hidden;
    }

</style>

<template>
    <div class="c-top_panel_bg"
         :style="{'top': 0*ss*xMult+'px','left':0*ss*xMult+'px','width': realWidth-40*ss*xMult+'px','height': (orient==='V'?260:140)*ss*xMult+'px',
            'margin': 20*ss*xMult+'px','flex-direction':orient==='V'?'column':'row'}">

        <!--        Blockspin logo  -->
        <div class="c-top_logo"
             @click="blockspinClick"
             :style="{'width': (orient==='V'?realWidth-40*ss*xMult:733*ss*xMult)+'px','height': 110*ss*xMult+'px',}">
            <img class="c-top_logo"
                 :style="{'width': 533*ss*xMult+'px','height': 110*ss*xMult+'px',}"
                 :src="blockspinLogoIcon"
                 alt=""
            />
            <div class="c-top_logo c-top_btn_text2"
                 :style="{'width': 'auto','height': 'auto','padding-right': 10*ss*xMult+'px',
                    'font-size': 50*ss*xMult+'px',}"
            >
                {{messages.m002_1+' '+getLevel}}
            </div>
        </div>

        <!--        left arrow / GAMES / right arrow  -->
        <div class="c-top_btns"
             :style="{'width': 600*ss*xMult+'px','height': 140*ss*xMult+'px',}"
        >
            <ButtonSvg :svg-normal="menuArrowLeft" :svg-clicked="menuArrowLeft"
                       :bg-width="85*ss*xMult"
                       :bg-height="85*ss*xMult"
                       :callback="btnLeftClick" :callback-wait="false"
            />

            <div class="c-top_text_bg"
                 :style="{'max-width': 410*ss*xMult+'px','width': 410*ss*xMult+'px','height':120*ss*xMult+'px'}">
                <div class="c-top_btn_text1"
                     :style="{'width': 410*ss*xMult+'px','max-width': 410*ss*xMult+'px','height':120*ss*xMult+'px','line-height':(titleFontSize+10)*ss*xMult+'px','font-size':titleFontSize*ss*xMult+'px',}">
                    {{messages.topPanel[pageCurrent]}}
                </div>
            </div>

            <ButtonSvg :svg-normal="menuArrowRight" :svg-clicked="menuArrowRight"
                       :bg-width="85*ss*xMult"
                       :bg-height="85*ss*xMult"
                       :callback="btnRightClick" :callback-wait="false"
            />
        </div>
        <!--   to center the arrow area in horizontal orientation     -->
        <div v-if="orient==='H'" :style="{'width': 450*ss*xMult+'px','height': 110*ss*xMult+'px',}"/>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    import icon1 from "../assets/images/blockspin-logo.webp";
    import menuArrowLeft from "../assets/svg/menu_arrow_left.svg";
    import menuArrowRight from "../assets/svg/menu_arrow_right.svg";
    import ButtonSvg from "./elements/ButtonSvg";
    import NotificationsNumber from "@/vue/elements/NotificationsNumber";

    export default {
        name: "TopPanel",
        components: {ButtonSvg, NotificationsNumber},
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","pageVisibleUserInfo","btnRad","xMult","notificationsCountUnread","messages","orient","pageCurrent",
                "getLevel"]),
            titleFontSize(){
                const pageCurrent=this.$store.state.pageCurrent;
                let fs=100;
                if (pageCurrent===2 || pageCurrent===3)
                    fs=55;
                return fs;
            },
        },
        created() {


        },
        data: function () {
            return {
                blockspinLogoIcon:icon1,
                menuArrowLeft:menuArrowLeft,
                menuArrowRight:menuArrowRight,
            }
        },
        methods:{
            btnLeftClick(){
                this.$store.commit("btnLeftClick");
            },
            btnRightClick(){
                this.$store.commit("btnRightClick");
            },
            blockspinClick(){
                this.$store.state.pageCurrent=0;
            },
        },
    }

</script>

<style lang="css" scoped>
    .c-top_panel_bg{
        display: flex;
        overflow: visible;
        position: relative;
        justify-content: space-between;
        align-items: center;
        padding:0;
        margin:0;

        /*background: rgba(0, 0, 0, 0.2);/**/

    }
    .c-top_logo{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .c-top_btns{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-top_text_bg{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-top_btn_text1{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF90;
        overflow: hidden;

        font-family: Rubik;
        white-space: nowrap;
        font-style: normal;
        font-weight: 700;

        background: linear-gradient(281.53deg,#7a00ff 5.6%,#00fff0 111.24%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-box-flex: 0;
    }

    .c-top_btn_text2{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF90;
        overflow: hidden;

        font-family: Rubik;
        white-space: nowrap;
        font-style: italic;
        font-weight: 700;

        background: linear-gradient(102.76deg, #ffd4a6 3.6%, #ff893a 53.89%,#cc181a 99.19%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-box-flex: 0;
    }



</style>

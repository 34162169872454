<template>
    <div class="c-redeem_popup unselectable"
         :style="{'top': 0*ss+'px','left':0*ss+'px',}"
    >
        <!--   Popup bg  "Reffer a friend" popup  -->
        <div class="c-redeem_popup_2"
             :style="{'width': 1000*ss*xMult+'px','height': 600*ss*xMult+'px','gap': 30*ss*xMult+'px','border-radius':60*ss*xMult+'px',
                'border':6*ss*xMult+'px solid #76069A',
                'background':getBg,'box-shadow':getShadow}"
        >

            <!--     decoration images -->
            <div class="c-redeem_popup_bg_abs"
                 :style="{'width': '100%','height': '100%','border-radius':60*ss*xMult+'px',}"
            >
                <img class="c-redeem_popup_img_1" alt=""
                     :style="{'width': '100%','height': '100%',}"
                     :src="img_redeem"
                />
            </div>

            <!--     Info region       -->
            <div class="c-redeem_popup_column" style="justify-content: space-evenly;"
                 :style="{'width': 1000*ss*xMult+'px','height': 600*ss*xMult+'px',}"
            >
                <!--     Redeem Code       -->
                <div class="c-redeem_popup_text c-redeem_popup_column"
                     :style="{'width': 1000*ss*xMult+'px','height': 100*ss*xMult+'px',
                        'font-size': 70*ss*xMult+'px','line-height': 100*ss*xMult+'px',}"
                >
                    {{messages.m047_01}}
                </div>

                <!--     Enter your code... + Code input      -->
                <div v-if="!redeemMessage" class="c-redeem_popup_column"
                     :style="{'width': 1000*ss*xMult+'px','height': 250*ss*xMult+'px','gap': 20*ss*xMult+'px',}"
                >
                    <!--     Enter your code...       -->
                    <div class="c-redeem_popup_text c-redeem_popup_column"
                         :style="{'width': 920*ss*xMult+'px','height': 60*ss*xMult+'px',}"
                    >
                        <div class="c-redeem_popup_text c-redeem_popup_column"
                             :style="{'width': 920*ss*xMult+'px','height': 60*ss*xMult+'px',
                            'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                        >
                            {{messages.m047_02}}
                        </div>

                    </div>

                    <!--     Code input  -->
                    <div class="c-redeem_popup_row"
                         :style="{'width': 945*ss*xMult+'px','height': 130*ss*xMult+'px',
                            'border-radius': 100*ss*xMult+'px',}"
                    >
                        <input class="c-redeem_popup_input" v-model="redeemCode" :placeholder="messages.m047_04"
                               :style="{'width': 600*ss*xMult+'px','height': 96*ss*xMult+'px','margin-right': -75*ss*xMult+'px',
                                'padding-left': 90*ss*xMult+'px','font-size': 50*ss*xMult+'px',
                                'border-top-left-radius': 60*ss*xMult+'px','border-bottom-left-radius': 60*ss*xMult+'px',}"
                        />

                        <ButtonSvgOval
                                :callback="clickSend" :callback-wait="true"
                                :btn-text="messages.m047_03"
                                :bg-width="300*ss*xMult" :bg-height="100*ss*xMult"
                                :font-size="45*ss*xMult"
                                :svg-width="60*ss*xMult" :svg-height="60*ss*xMult"
                                :click-scale="0.95"
                        />

                        <img v-if="!redeemCode" class="c-redeem_popup_img_1" alt="" @click="clickPaste"
                             :style="{'width': 'auto','height': 65*ss*xMult+'px','left': 30*ss*xMult+'px',}"
                             :src="img_paste"
                        />
                        <img v-else class="c-redeem_popup_img_1" alt="" @click="clickClear"
                             :style="{'width': 'auto','height': 50*ss*xMult+'px','left': 38*ss*xMult+'px',}"
                             :src="img_btn_x_yellow"
                        />
                        <div v-if="popupQuickHelp.redeemPopup===true" class="c-redeem_popup_popup"
                             :style="{'left': 50*ss*xMult+'px','top': -90*ss*xMult+'px',}"
                        >
                            <PopupInfo     :bg-width="250*ss*xMult" :bg-height="90*ss*xMult"
                                           :max-width="50*ss*xMult"
                                           :border-radius="30*ss*xMult"
                                           :font-size="45*ss*xMult"
                                           :btn-text="messages.m047_05"
                                           :background-val="'rgba(0, 0, 0, 0.4)'"
                                           :border-val="'1px solid rgba(255, 255, 255, 0.5)'"
                                           :callback="popupInfoClick" :callback-wait="true"

                            />
                        </div>
                    </div>
                </div>

                <!--     Result of enter redeem      -->
                <div v-else class="c-redeem_popup_column"
                     :style="{'width': 1000*ss*xMult+'px','height': 300*ss*xMult+'px','gap': 20*ss*xMult+'px',}"
                >
                    <!--     Text with redeem result      -->
                    <div class="c-redeem_popup_text c-redeem_popup_column"
                         :style="{'width': 'auto','max-width': 920*ss*xMult+'px','height': 180*ss*xMult+'px',
                        'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                    >
                        {{redeemMessage}}
                    </div>
                    <ButtonSvgOval
                            :callback="clickOk" :callback-wait="true"
                            :btn-text="messages.m_ok"
                            :bg-width="300*ss*xMult" :bg-height="100*ss*xMult"
                            :font-size="45*ss*xMult"
                            :svg-width="60*ss*xMult" :svg-height="60*ss*xMult"
                            :click-scale="0.95"
                    />
                </div>

            </div>

            <!--   X button   -->
            <div style="position: absolute"
                 :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
            >
                <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                           :bg-width="72*ss*xMult"
                           :bg-height="72*ss*xMult"
                           :callback="btn_xClick" :callback-wait="true"
                />
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import PopupInfo from "@/vue/elements/PopupInfo";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import img_btn_x_yellow from "@/assets/svg/btn_x_yellow.svg";
    import img_paste from "@/assets/svg/paste.svg";
    import img_redeem from "@/assets/images/info/redeem_bg.webp";


    export default defineComponent({
        name: 'RedeemPopup',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","messages","popupQuickHelp","userData","getReferralLink"]),


            getBg(){
                let txt='radial-gradient(100.84% 132.88% at -11.6% -9.79%,#ca1d91 0,#6d14ce 100%)';
                return txt;
            },
            getShadow(){
                let txt='0 0 100px rgba(181,6,220,.5),inset 0 0 100px hsla(0,0%,100%,.5)';
                return txt;
            },
        },
        components: {ButtonSvg,ButtonSvgOval,PopupInfo},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),img_redeem:Utils.getGlobalLink(img_redeem),img_paste:Utils.getGlobalLink(img_paste),
                img_btn_x_yellow:Utils.getGlobalLink(img_btn_x_yellow),
                redeemCode:'',redeemMessage:'',
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btn_xClick(){
                this.$store.state.popupVisible.redeemPopup=false;
            },

            clickClear(){
                this.redeemCode='';
            },

            async clickPaste(){
                const state=this.$store.state;
                state.popupQuickHelp.redeemPopup=true;
                let clipboardData = window.clipboardData || navigator.clipboard;
                const text = await clipboardData.readText();
                this.redeemCode=text;
                setTimeout(() => {
                    state.popupQuickHelp.redeemPopup=false;
                }, 1000);
            },

            clickOk(){
                this.redeemMessage='';
                this.redeemCode='';
                this.$store.state.popupVisible.redeemPopup=false;
            },
            async clickSend(){
                let result = await this.$store.dispatch("redeemCode", this.redeemCode);
                if (!result || result.error) {
                    this.redeemMessage = result.error;
                    return;
                }
                if(result.type === "monthlyCard") {
                    this.redeemMessage = " Congratulations you redeemed a " + result.monthlyCardType + " VIP Membership!";
                } else {
                    this.redeemMessage = " Congratulations you redeemed " + (result.numChips ? result.numChips.toLocaleString() : result.game) + " " + result.type;
                }
            },

            popupInfoClick(){
                const state=this.$store.state;
                state.popupQuickHelp.redeemPopup=false;

            },

        },
    });
</script>

<style lang="css" scoped>

    .c-redeem_popup{
        display: flex;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0;
        margin:0;
        width: 100vw;
        height: 100vh;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;
        backdrop-filter: blur(5px);
        background: linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8));

    }

    .c-redeem_popup_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-redeem_popup_bg_abs{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-redeem_popup_column{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-redeem_popup_img_1{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-redeem_popup_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;

        /*word-wrap: break-word;/**/
        /*white-space: nowrap;        /*Нет переноса не переносит*/
    }


    .c-redeem_popup_time_bg{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        box-shadow: 0 7px 15px rgb(0 0 0 / 59%), inset 0 5px 6px hsl(0deg 0% 100% / 42%);
        background: hsl(0deg 0% 4.3% / 26%);
    }

    .c-redeem_popup_row{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: visible;
    }

    .c-redeem_popup_popup{
        position: absolute;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }

    .c-redeem_popup_input{
        font-style: normal;
        font-weight: 400;
        font-family: 'Rubik', sans-serif;

        letter-spacing: -0.03em;

        /*background: rgba(255, 255, 255, 0.20);/**/
    }

</style>

<template>
    <!-- Notifications count -->
    <div class="c-notif_number"
         :style="{'width': bgWidth?bgWidth+'px':'auto','height': bgHeight?bgHeight+'px':'auto','min-width': bgMinWidth,
            'left':bgLeft, 'right':bgRight,'top':bgTop, 'bottom':bgBottom,
            'padding':bgPadding,'background':backgroundVal,'border-radius':borderRadius+'px'}"
    >

        <div class="c-notif_number_text"
             :style="{'font-size':fontSize+'px','line-height':lineHeight+'px',}"
        >
            {{notificationsCount}}
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';


    export default defineComponent({
        name: 'NotificationsNumber',
        components: {},
        data(){
            return {
                clicked:false,
                scale:1,
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {

        },

        computed: {
            ...mapGetters(["ss"]),
        },

        props: {
            bgWidth: {
                type: Number,
                default: 0      //auto
            },
            bgHeight: {
                type: Number,
                default: 0      //auto
            },
            bgLeft: {
                type: String,
                default: 'unset'      //auto
            },
            bgRight: {
                type: String,
                default: 'unset'      //auto
            },
            bgTop: {
                type: String,
                default: 'unset'      //auto
            },
            bgMinWidth: {
                type: String,
                default: 'unset'      //auto
            },
            bgBottom: {
                type: String,
                default: 'unset'      //auto
            },
            bgPadding: {
                type: String,
                default: '0px'      //auto
            },
            borderRadius: {
                type: Number,
                default: 100
            },
            backgroundVal: {
                type: String,
                default: 'red'
            },
            notificationsCount: {
                type: Number,
                default: 0
            },

            fontSize: {
                type: Number,
                default: 10
            },
            lineHeight: {
                type: Number,
                default: 10
            },

        },

    });
</script>

<style lang="css" scoped>
    .c-notif_number{
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;



    }
    .c-notif_number_text{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        letter-spacing: -0.03em;
        color: #FFFFFF;

    }

</style>

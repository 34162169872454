<template>
                <!--         Svg button with source in string               -->
    <div class="c-button_svg_in" @click="onClick"
         :style="{'width': bgWidth+'px','height': bgHeight+'px', }"
    >
        <div class="c-button_svg_in"
             :style="{'width': bgWidth*scale+'px','height': bgHeight*scale+'px'}"
        >
            <div v-if="!clicked" class="c-button_svg_in_img"
                 v-html="svgNormal"
                 :style="{'opacity':opacityVal?opacityVal:1}"
            />
            <div v-else class="c-button_svg_in_img"
                 v-html="svgClicked?svgClicked:svgNormal"
                 :style="{'opacity':opacityVal?opacityVal:1}"
            />
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';


    export default defineComponent({
        name: 'ButtonSvgIn',
        components: {},
        data(){
            return {
                clicked:false,
                scale:1,
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            onClick() {
                if (!this.callback) return;
                this.clicked=true;
                if (!this.callbackWait) this.callback();

                TweenMax.to(this,{scale:0.9,duration:0.1,onComplete:()=>{
                        TweenMax.to(this,{scale:1,duration:0.1,onComplete:()=>{
                                this.clicked=false;
                                if (this.callbackWait) this.callback();
                            }
                        });
                    }
                });
            }
        },

        computed: {
            ...mapGetters(["ss"]),
        },

        props: {
            svgNormal: {
                type: String,
                default: ""
            },
            svgClicked: {
                type: String,
                default: ""
            },
            bgWidth: {
                type: Number,
                default: 44
            },
            bgHeight: {
                type: Number,
                default: 44
            },
            callback: {
                type: Function,
                default: () => {}
            },
            callbackWait: {                 //If true, then call callback after delay
                type: Boolean,
                default: false
            },
            opacityVal: {
                type: Number,
                default: 1
            },
        },

    });
</script>

<style lang="css" scoped>
    .c-button_svg_in{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 0px 0px;
    }

    .c-button_svg_in_img{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

</style>

export default {
    isLocal: false,     //Определяю по 127.0.0.1 в адресной строке
    baseWidth:1080,
    baseHeight:1920,
    aspRatioMin:1.7,        //if aspect ratio Height/Width out from this range than crop body size
    aspRatioMax:2.23,

    corrPath:["/","/home"],           //correct path names
    defaultPath:"/home",

    getUserTimeoutTime:2000,        // How often to ping getUser to check for new balance
    getUserMaxTries:30,             // How many times to try pining getUser until giving up
    maxPages:1,

    gameTypesNew:['crashGames','instantGames'],
    gameTypesSoon:['spincity','poker','casino'],

    leaderboardUpdateTime:10,           //in seconds
    betsUpdateTime:10,           //in seconds
    betsMaxCount:30,            //Maximum count of Recent bets array

    links:{
        zelay:'https://zealy.io/c/blockspingaming/questboard',
        follow:'https://linktr.ee/blockspingaming',
    },
    slotAchievementsPrize:100,

    walletLoad:false,           //If true then pay.js from /payment/pay.js added to index.html
    walletLink:{
        phantom:'https://phantom.app/',
        solflare:'https://solflare.com/',
        wax:'https://all-access.wax.io/',
        anchor:'https://greymass.com/en/anchor/',   //linkAnchor
        ronin:'https://wallet.roninchain.com/',
    },



}

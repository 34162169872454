<template>
    <div v-if="showItem" class="c-my_progress"
         @click="openGame"
         :style="{'width': 450*ss*xMult+'px','height': 450*ss*xMult+'px','border-radius': 0*ss*xMult+'px'}"
    >
        <img :src="getImg" alt=""
             :style="{'width': '100%','height': '100%',}"
        />

        <div v-if="!gameCompleted" class="c-my_progress_inactive"
             :style="{'width': '100%','height': '100%','border-radius': 60*ss*xMult+'px'}"
        >
            <img :src="img_x2" alt="" style="position: absolute;"
                 :style="{'width': 100*ss*xMult+'px','height': 100*ss*xMult+'px','top': 15*ss*xMult+'px','left': 15*ss*xMult+'px',}"
            />
        </div>
        <div v-else class="c-my_progress_active"
             :style="{'width': 450*ss*xMult-6+'px','height': 450*ss*xMult-6+'px','border-radius': 60*ss*xMult+'px'}"
        >
            <img :src="img_check" alt="" style="position: absolute;"
                 :style="{'width': 100*ss*xMult+'px','height': 100*ss*xMult+'px','top': 15*ss*xMult+'px','left': 15*ss*xMult+'px',}"
            />
        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Utils from "@/scripts/Utils";
    import GameList from "@/scripts/GameList";

    import img_x2 from "@/assets/svg/x2.svg";
    import img_check from "@/assets/svg/check.svg";


    export default {
        name: 'MyProgressGame',
        components: {},
          //One player element of left players panel
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","xMult","blockspinState"]),
            getImg() {
                //debugger
                return Utils.getGlobalLink(this.gameObj.thumb);
            },
            showItem(){         //if env==prod and game.testing ==true then not show game
                if ((this.gameObj.testing===true && Utils.getEnv()==='prod') || this.gameObj.isSoon===true)
                    return false;
                return true;
            },
            gameCompleted(){
                try{
                    const bc=this.$store.state.blockspinState.gamesLeaderboardBetsPerGame;
                    let gameCount=this.myProgressObj[this.gameName];
                    if (gameCount && gameCount>=bc)
                        return true;
                }catch (e) {}
                return false;
            },
            //blockspinState.gamesLeaderboardBetsPerGame

        },
        data(){
            return {
                gameObj:{},img_x2:Utils.getGlobalLink(img_x2),img_check:Utils.getGlobalLink(img_check),
            };
        },
        created() {
            this.gameObj=this.gameObjThis();
        },
        methods: {
            gameObjThis(){
                const obj=GameList.allGames[this.gameName];
                return obj;
            },
            openGame(){
                this.$store.dispatch("openGame",  this.gameName);
            },
        },
        props: {
            gameName:{
                type: String,
                default: ''
            },
            myProgressObj:{
                type: Object,
                default: {}
            },

        },
    }
</script>

<style lang="css">
    .c-my_progress{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        background: none;
        /*border: 2px solid rgba(25,201,243,.4);/**/
    }
    .c-my_progress_inactive{
        display: flex;
        position: absolute;
        align-items: center;
        background: rgba(125, 133, 141, 0.5);
    }
    .c-my_progress_active{
        display: flex;
        position: absolute;
        align-items: center;
        border: 3px solid rgb(12, 255, 124);
    }

</style>

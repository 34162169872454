<template>
    <div class="c-shop_blur unselectable"
         :style="{'top': 0*ss+'px','left':0*ss+'px',}">
        <div class="c-shop"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <div class="c-shop_2" :style="{'width': realWidth-80*ss*xMult+'px','height': realHeight-80*ss*xMult+'px','border-radius': 40*ss*xMult+'px',}">

                <!--   Game Shop VERTICAL  -->
                <div v-if="orient==='V'" class="c-shop_column_1"
                     :style="{'width': realWidth-80*ss*xMult+'px','height': realHeight-80*ss*xMult+'px','gap': 30*ss*xMult+'px',}"
                >
                    <!--   ShopType vertical   -->
                    <div class="c-shop_column_1"
                         :style="{'width': realWidth-80*ss*xMult+'px','height': 260*ss*xMult+'px','gap': 20*ss*xMult+'px','margin-top': 20*ss*xMult+'px',}">
                        <img :src="starImg" alt="" style="position: absolute"
                             :style="{'width': 90*ss*xMult+'px','height': 'auto','top': 10*ss*xMult+'px','left': 20*ss*xMult+'px',}"
                        />

                        <!--   Shop text  -->
                        <div class="c-shop_row_1 c-shop_text"
                             :style="{'width': realWidth-80*ss*xMult+'px','height': 120*ss*xMult+'px','margin-top': 0*ss*xMult+'px',
                                'font-size': 80*ss*xMult+'px','line-height': 120*ss*xMult+'px',}"
                        >
                            {{messages.m032_01}}
                        </div>

                        <!--   Row shop type   -->
                        <div class="c-shop_row_1" style="background: rgba(3, 18, 35, 0.15);"
                             :style="{'width': realWidth-80*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 20*ss*xMult+'px',}"
                        >
                            <!--   chips    -->
                            <div class="c-shop_row_2" @click="shopTypeClick('chips')"
                                 :style="{'width': 'auto','min-width': 210*ss*xMult+'px',
                                    'height': 60*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 15*ss*xMult+'px '+35*ss*xMult+'px',
                                    'border-radius': 40*ss*xMult+'px',
                                    'background': shop.shopType==='chips'?'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%':'none',
                                    'box-shadow': shop.shopType==='chips'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border': shop.shopType==='chips'?'none':'1px solid rgba(255, 255, 255, 0.10)',
                                    }"
                            >
                                <img :src="iconChipsSvg" alt=""
                                     :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                />
                                <div class="c-shop_ring_info"
                                     :style="{'max-width': 150*ss*xMult+'px',
                                        'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                                >
                                    {{messages.m022_1}}
                                </div>
                            </div>
                            <!--   boost   -->
                            <div class="c-shop_row_2" @click="shopTypeClick('boost')"
                                 :style="{'width': 'auto','min-width': 210*ss*xMult+'px',
                                    'height': 90*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+35*ss*xMult+'px',
                                    'border-radius': 40*ss*xMult+'px',
                                    'background': shop.shopType==='boost'?'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%':'none',
                                    'box-shadow': shop.shopType==='boost'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border': shop.shopType==='boost'?'none':'1px solid rgba(255, 255, 255, 0.10)',
                                    }"
                            >
                                <img :src="boostSvg" alt=""
                                     :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                />
                                <div class="c-shop_ring_info"
                                     :style="{'max-width': 150*ss*xMult+'px',
                                        'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                                >
                                    {{messages.m032_03}}
                                </div>
                            </div>
                            <!--   VIP   -->
                            <div class="c-shop_row_2" @click="shopTypeClick('vip')"
                                 :style="{'width': 'auto','min-width': 210*ss*xMult+'px',
                                    'height': 90*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+35*ss*xMult+'px',
                                    'border-radius': 40*ss*xMult+'px',
                                    'background': shop.shopType==='vip'?'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%':'none',
                                    'box-shadow': shop.shopType==='vip'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border': shop.shopType==='vip'?'none':'1px solid rgba(255, 255, 255, 0.10)',
                                    }"
                            >
                                <img :src="vipSvg" alt=""
                                     :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                />
                                <div class="c-shop_ring_info"
                                     :style="{'max-width': 150*ss*xMult+'px',
                                        'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                                >
                                    {{messages.m032_04}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--   Shop scroll static   -->
                    <div class="c-shop_scroll_static"
                         :style="{'width': realWidth-80*ss*xMult+'px','gap': 30*ss*xMult+'px',
                            'height': orient==='V'?realHeight-640*ss*xMult+'px':realHeight-320*ss*xMult+'px',}"
                    >
                        <!--   decoration images   -->
                        <img :src="starImg" alt="" style="position: absolute; transform: rotate(110.0deg);"
                             :style="{'width': 110*ss*xMult+'px','height': 'auto','top': 200*ss*xMult+'px','right': 40*ss*xMult+'px',}"
                        />
                        <img :src="bracketImg" alt="" style="position: absolute; "
                             :style="{'width': 110*ss*xMult+'px','height': 'auto','top': 700*ss*xMult+'px','left': 20*ss*xMult+'px',}"
                        />

                        <!--   scroll content dynamic height   -->
                        <div class="c-shop_3" style="justify-content: center;"
                             :style="{'width': realWidth-80*ss*xMult+'px','height': 'auto','gap': 0*ss*xMult+'px',
                             'padding-top': 0*ss*xMult+'px','padding-bottom': 0*ss*xMult+'px',}"
                        >
                            <ShopCard v-for="(itemKey,itemIndex) in Object.keys(shop.items[shop.shopType])"
                                      :item-type="shop.shopType"
                                      :item-key="itemKey"
                                      :currency="shop.currency"
                                      :key="'shop'+itemIndex"
                            />
                        </div>
                    </div>

                    <!--   Select your currency   -->
                    <div class="c-shop_column_1" style="background: #031223;position: absolute;"
                         :style="{'left': '-1px','bottom': '-1px','width': realWidth-80*ss*xMult+2+'px','height': 220*ss*xMult+1+'px','gap': 20*ss*xMult+'px',}">
                        <!--   Select text  -->
                        <div class="c-shop_row_1 c-shop_text"
                             :style="{'width': realWidth-80*ss*xMult+'px','height': 70*ss*xMult+'px','margin-top': 10*ss*xMult+'px',
                                'font-size': 60*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                        >
                            {{messages.m032_05}}
                        </div>

                        <!--   Currency type   -->
                        <div class="c-shop_row_1" style="background: rgba(255, 255, 255, 0.15);"
                             :style="{'width': realWidth-80*ss*xMult+'px','height': 100*ss*xMult+'px','gap': 0*ss*xMult+'px',}"
                        >
                            <!--   Telegram stars only in tgMode  vertical  -->
                            <div v-if="tgMode" style="display: contents;">
                                <!--   Telegram Stars    -->
                                <div class="c-shop_row_2" @click="currencyClick('xtr')"
                                     :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                    'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                    'border-radius': 20*ss*xMult+'px',
                                    'background': shop.currency==='xtr'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'box-shadow': shop.currency==='xtr'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',
                                    }"
                                >
                                    <img :src="imgStarTg" alt=""
                                         :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                    />
                                    <div class="c-shop_ring_info" style="white-space: nowrap;"
                                         :style="{'max-width': 300*ss*xMult+'px',
                                        'font-size': 40*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                                    >
                                        {{messages.m031_10}}
                                    </div>
                                </div>
                            </div>
                            <div v-else style="display: contents;">
                                <!--   WAX    -->
                                <div class="c-shop_row_2" @click="currencyClick('wax')"
                                     :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                    'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                    'border-radius': 20*ss*xMult+'px',
                                    'background': shop.currency==='wax'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'box-shadow': shop.currency==='wax'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',
                                    }"
                                >
                                    <img :src="waxSvg" alt=""
                                         :style="{'width': 120*ss*xMult+'px','height': 'auto',}"
                                    />
                                </div>
                                <!--   Solana    -->
                                <div class="c-shop_row_2" @click="currencyClick('sol')"
                                     :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                    'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                    'border-radius': 20*ss*xMult+'px',
                                    'background': shop.currency==='sol'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'box-shadow': shop.currency==='sol'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',
                                    }"
                                >
                                    <img :src="solSvg" alt=""
                                         :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                    />
                                    <div class="c-shop_ring_info"
                                         :style="{'max-width': 150*ss*xMult+'px',
                                        'font-size': 40*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                                    >
                                        {{messages.m031_03}}
                                    </div>
                                </div>
                                <!--   Ronin    -->
                                <div class="c-shop_row_2" @click="currencyClick('ronin')"
                                     :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                    'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                    'border-radius': 20*ss*xMult+'px',
                                    'background': shop.currency==='ronin'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'box-shadow': shop.currency==='ronin'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',
                                    }"
                                >
                                    <img :src="roninSvg" alt=""
                                         :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                    />
                                    <div class="c-shop_ring_info"
                                         :style="{'max-width': 150*ss*xMult+'px','color':getEnv==='test'?'white':'gray',
                                        'font-size': 40*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                                    >
                                        {{messages.m031_04}}
                                    </div>
                                </div>
                                <!--   USD    -->
                                <div class="c-shop_row_2" @click="currencyClick('usd')"
                                     :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                    'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                    'border-radius': 20*ss*xMult+'px',
                                    'background': shop.currency==='usd'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'box-shadow': shop.currency==='usd'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',
                                    }"
                                >
                                    <div class="c-shop_ring_info"
                                         :style="{'max-width': 150*ss*xMult+'px',
                                        'font-size': 40*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                                    >
                                        {{messages.m031_09}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--   Game Shop HORIZONTAL  -->
                <div v-if="orient==='H'" class="c-shop_column_1"
                     :style="{'width': realWidth-80*ss*xMult+'px','height': realHeight-80*ss*xMult+'px','gap': 30*ss*xMult+'px',}"
                >
                    <img :src="starImg" alt="" style="position: absolute"
                         :style="{'width': 90*ss*xMult+'px','height': 'auto','top': 30*ss*xMult+'px','left': 20*ss*xMult+'px',}"
                    />

                    <!--   Shop text + type row horizontal-->
                    <div class="c-shop_row_2"
                         :style="{'width': realWidth-80*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 20*ss*xMult+'px','margin-top': 20*ss*xMult+'px',}"
                    >
                        <!--   Shop text  -->
                        <div class="c-shop_row_1 c-shop_text"
                             :style="{'max-width': 600*ss*xMult+'px','width': 'auto','height': 120*ss*xMult+'px','margin-top': 0*ss*xMult+'px',
                                'font-size': 80*ss*xMult+'px','line-height': 120*ss*xMult+'px','padding-left': 30*ss*xMult+'px','padding-right': 30*ss*xMult+'px',}"
                        >
                            {{messages.m032_01}}
                        </div>

                        <!--   Row shop type   -->
                        <div class="c-shop_row_1" style="background: rgba(3, 18, 35, 0.15);"
                             :style="{'width': 1000*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 20*ss*xMult+'px','border-radius': 40*ss*xMult+'px',}"
                        >
                            <!--   chips    -->
                            <div class="c-shop_row_2" @click="shopTypeClick('chips')"
                                 :style="{'width': 'auto','min-width': 210*ss*xMult+'px',
                                    'height': 60*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 15*ss*xMult+'px '+35*ss*xMult+'px',
                                    'border-radius': 40*ss*xMult+'px',
                                    'background': shop.shopType==='chips'?'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%':'none',
                                    'box-shadow': shop.shopType==='chips'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border': shop.shopType==='chips'?'none':'1px solid rgba(255, 255, 255, 0.10)',
                                    }"
                            >
                                <img :src="iconChipsSvg" alt=""
                                     :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                />
                                <div class="c-shop_ring_info"
                                     :style="{'max-width': 150*ss*xMult+'px',
                                        'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                                >
                                    {{messages.m022_1}}
                                </div>
                            </div>
                            <!--   boost   -->
                            <div class="c-shop_row_2" @click="shopTypeClick('boost')"
                                 :style="{'width': 'auto','min-width': 210*ss*xMult+'px',
                                    'height': 90*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+35*ss*xMult+'px',
                                    'border-radius': 40*ss*xMult+'px',
                                    'background': shop.shopType==='boost'?'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%':'none',
                                    'box-shadow': shop.shopType==='boost'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border': shop.shopType==='boost'?'none':'1px solid rgba(255, 255, 255, 0.10)',
                                    }"
                            >
                                <img :src="boostSvg" alt=""
                                     :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                />
                                <div class="c-shop_ring_info"
                                     :style="{'max-width': 150*ss*xMult+'px',
                                        'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                                >
                                    {{messages.m032_03}}
                                </div>
                            </div>
                            <!--   VIP   -->
                            <div class="c-shop_row_2" @click="shopTypeClick('vip')"
                                 :style="{'width': 'auto','min-width': 210*ss*xMult+'px',
                                    'height': 90*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+35*ss*xMult+'px',
                                    'border-radius': 40*ss*xMult+'px',
                                    'background': shop.shopType==='vip'?'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%':'none',
                                    'box-shadow': shop.shopType==='vip'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border': shop.shopType==='vip'?'none':'1px solid rgba(255, 255, 255, 0.10)',
                                    }"
                            >
                                <img :src="vipSvg" alt=""
                                     :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                />
                                <div class="c-shop_ring_info"
                                     :style="{'max-width': 150*ss*xMult+'px',
                                        'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                                >
                                    {{messages.m032_04}}
                                </div>
                            </div>
                        </div>

                    </div>

                    <!--   Shop scroll static  horizontal -->
                    <div class="c-shop_scroll_static"
                         :style="{'width': realWidth-80*ss*xMult+'px','gap': 30*ss*xMult+'px',
                            'height': realHeight-410*ss*xMult+'px',}"
                    >
                        <!--   decoration images   -->
                        <img :src="starImg" alt="" style="position: absolute; transform: rotate(110.0deg);"
                             :style="{'width': 110*ss*xMult+'px','height': 'auto','top': 200*ss*xMult+'px','right': 40*ss*xMult+'px',}"
                        />
                        <img :src="bracketImg" alt="" style="position: absolute; "
                             :style="{'width': 110*ss*xMult+'px','height': 'auto','top': 700*ss*xMult+'px','left': 20*ss*xMult+'px',}"
                        />

                        <!--   scroll content dynamic height horizontal   -->
                        <div class="c-shop_3" style="justify-content: space-evenly;"
                             :style="{'width': realWidth-80*ss*xMult+'px','height': 'auto','gap': 0*ss*xMult+'px',
                             'padding-top': 0*ss*xMult+'px','padding-bottom': 0*ss*xMult+'px',}"
                        >
                            <ShopCard v-for="(itemKey,itemIndex) in Object.keys(shop.items[shop.shopType])"
                                      :item-type="shop.shopType"
                                      :item-key="itemKey"
                                      :currency="shop.currency"
                                      :key="'shop'+itemIndex"
                            />
                        </div>
                    </div>

                    <!--   Select your currency horizontal  -->
                    <div class="c-shop_row_2" style="background: #031223;position: absolute;"
                         :style="{'left': '-1px','bottom': '-1px','width': realWidth-80*ss*xMult+2+'px','height': 130*ss*xMult+1+'px','gap': 20*ss*xMult+'px',}">
                        <!--   Select text  -->
                        <div class="c-shop_row_1 c-shop_text"
                             :style="{'max-width': 900*ss*xMult+'px','width':'auto','height': 70*ss*xMult+'px','padding-right': 20*ss*xMult+'px',
                                'font-size': 60*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                        >
                            {{messages.m032_05}}
                        </div>

                        <!--   Currency type   -->
                        <div class="c-shop_row_1" style="background: rgba(255, 255, 255, 0.15);"
                             :style="{'width': 1040*ss*xMult+'px','height': 100*ss*xMult+'px','border-radius': 30*ss*xMult+'px',}"
                        >
                            <!--   Telegram stars only in tgMode  horizontal  -->
                            <div v-if="tgMode" style="display: contents;">
                                <!--   Telegram Stars    -->
                                <div class="c-shop_row_2" @click="currencyClick('xtr')"
                                     :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                    'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                    'border-radius': 20*ss*xMult+'px',
                                    'background': shop.currency==='xtr'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'box-shadow': shop.currency==='xtr'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',
                                    }"
                                >
                                    <img :src="imgStarTg" alt=""
                                         :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                    />
                                    <div class="c-shop_ring_info" style="white-space: nowrap;"
                                         :style="{'max-width': 300*ss*xMult+'px',
                                        'font-size': 40*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                                    >
                                        {{messages.m031_10}}
                                    </div>
                                </div>
                            </div>
                            <div v-else style="display: contents;">
                                <!--   WAX    -->
                                <div class="c-shop_row_2" @click="currencyClick('wax')"
                                     :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                        'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                        'border-radius': 20*ss*xMult+'px',
                                        'background': shop.currency==='wax'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'box-shadow': shop.currency==='wax'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',
                                        }"
                                >
                                    <img :src="waxSvg" alt=""
                                         :style="{'width': 120*ss*xMult+'px','height': 'auto',}"
                                    />
                                </div>
                                <!--   Solana    -->
                                <div class="c-shop_row_2" @click="currencyClick('sol')"
                                     :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                        'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                        'border-radius': 20*ss*xMult+'px',
                                        'background': shop.currency==='sol'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'box-shadow': shop.currency==='sol'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',
                                        }"
                                >
                                    <img :src="solSvg" alt=""
                                         :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                    />
                                    <div class="c-shop_ring_info"
                                         :style="{'max-width': 150*ss*xMult+'px',
                                            'font-size': 40*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                                    >
                                        {{messages.m031_03}}
                                    </div>
                                </div>
                                <!--   Ronin    -->
                                <div class="c-shop_row_2" @click="currencyClick('ronin')"
                                     :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                        'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                        'border-radius': 20*ss*xMult+'px',
                                        'background': shop.currency==='ronin'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'box-shadow': shop.currency==='ronin'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',
                                        }"
                                >
                                    <img :src="roninSvg" alt=""
                                         :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                    />
                                    <div class="c-shop_ring_info"
                                         :style="{'max-width': 150*ss*xMult+'px','color':getEnv==='test'?'white':'gray',
                                            'font-size': 40*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                                    >
                                        {{messages.m031_04}}
                                    </div>
                                </div>
                                <!--   USD    -->
                                <div class="c-shop_row_2" @click="currencyClick('usd')"
                                     :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                        'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                        'border-radius': 20*ss*xMult+'px',
                                        'background': shop.currency==='usd'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'box-shadow': shop.currency==='usd'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',
                                        }"
                                >
                                    <div class="c-shop_ring_info"
                                         :style="{'max-width': 150*ss*xMult+'px',
                                            'font-size': 40*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                                    >
                                        {{messages.m031_09}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--
                <ShopBuyConfirm v-if="popupBuyShopConfirm.visible===true" />
                -->

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
            <PopupVip v-if="popupVip.visible===true"/>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import ShopCard from "@/vue/shop/ShopCard";
    import ShopBuyConfirm from "@/vue/shop/ShopBuyConfirm";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import bracketImg from "@/assets/images/bracket.webp";
    import iconGemSvg from "@/assets/svg/icon_gem2.svg";
    import strokesSvg from "@/assets/svg/strokes.svg";
    import iconChipsSvg from "@/assets/svg/icon_chips";
    import boostSvg from "@/assets/svg/shop/boost.svg";
    import vipSvg from "@/assets/svg/shop/vip.svg";
    import starImg from "@/assets/images/star.webp";
    import waxSvg from "@/assets/svg/chains/icon_wax_white.svg";
    import solSvg from "@/assets/svg/chains/icon_sol.svg";
    import roninSvg from "@/assets/svg/chains/icon_ron.svg";
    import PopupVip from "@/vue/elements/PopupVip";
    import imgStarTg from "@/assets/images/star_tg.webp";

    export default defineComponent({
        name: 'Shop',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","getLeft","xMult","userData","messages","btnRad","orient","shop","popupVip",
                "popupBuyShopConfirm","getEnv","tgMode"]),

        },
        components: {ButtonSvg,ButtonSvgOval,ShopCard,PopupVip,ShopBuyConfirm},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),iconGemSvg:Utils.getGlobalLink(iconGemSvg), bracketImg:Utils.getGlobalLink(bracketImg),
                strokesSvg:Utils.getGlobalLink(strokesSvg),starImg:Utils.getGlobalLink(starImg),
                iconChipsSvg:Utils.getGlobalLink(iconChipsSvg),boostSvg:Utils.getGlobalLink(boostSvg),vipSvg:Utils.getGlobalLink(vipSvg),
                waxSvg:Utils.getGlobalLink(waxSvg),solSvg:Utils.getGlobalLink(solSvg),roninSvg:Utils.getGlobalLink(roninSvg),
                imgStarTg:Utils.getGlobalLink(imgStarTg)
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);

        },
        async beforeMount() {
            await Utils.getState();
        },
        mounted() {
        },
        methods: {
            btn_xClick(){
                this.$store.state.shop.visible=false;
                this.$store.dispatch('getUser');
            },
            async shopTypeClick(param){
                await Utils.getState();
                const state=this.$store.state;
                state.shop.shopType=param;
            },
            currencyClick(param){
                if (Utils.getEnv()!=='test' && param==='ronin'){
                    return;
                }
                const state=this.$store.state;
                state.shop.currency=param;
            }
        },
    });
</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }


    .c-shop_blur{
        display: flex;
        overflow: visible;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0;
        margin:0;

        width: 100vw;
        height: 100vh;
        transform: translate(0%, 0%);

        backdrop-filter: blur(5px);
        background: linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8));/**/
    }

    .c-shop{
        display: flex;
        overflow: hidden;
        position: absolute;
        /*transform: translate(50%, -50%);/**/
        transform: translate(0%, 0%);
        padding:0;
        margin:0;

        flex-direction: column;
        justify-content: center;
        align-items: center;

        /*background: rgba(255, 255, 255, 0.1);/**/
        /*border: 4px solid rgba(0, 255, 0, 0.5);/**/
    }

    .c-shop_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;

        background: radial-gradient(233.23% 93.25% at 92.1% -14.57%, #F30B25 0%, #E41186 100%), #FFF;
        box-shadow: 0px 0px 60px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 70px 0px rgba(255, 0, 125, 0.70);
        /*background: rgba(0, 0, 0, 0.3);/**/
    }
    .c-shop_3{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        align-items: center;
        overflow-x: hidden;
        overflow-y: visible;
    }

    .c-shop_scroll_static {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-shop_column_1{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }
    .c-shop_column_2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-shop_row_1{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }


    .c-shop_row_2{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-shop_ring_info{
        display: flex;
        justify-content: center;
        align-items: center;

        /*background: rgba(255, 255, 255, 0.20);
        border: 1px solid rgba(255, 255, 255, 0.10);/**/
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.03em;
        color: #FFFFFF;
    }

    .c-shop_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;

        /*word-wrap: break-word;/**/
        white-space: nowrap;        /*Нет переноса не переносит*/
    }


</style>

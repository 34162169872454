<template>
    <!--   If wallet not found then show this popup with "create wallet link"   -->
    <!--   props walletType: wax/anchor/phantom/solflare/ronin   -->
    <div class="c-components_body unselectable" style="backdrop-filter: blur(5px);background: linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8));">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <!--   Popup bg static   -->
            <div class="c-no_wallet_2"
                 :style="{'width': 1000*ss*xMult+'px','height': 600*ss*xMult+'px','gap': 30*ss*xMult+'px','border-radius':60*ss*xMult+'px',
                    'border':6*ss*xMult+'px solid #00c6fb',
                    'background':getBg,'box-shadow':getShadow}"
            >

                <!--     Information       -->
                <div class="c-no_wallet_column"
                     :style="{'width': 1000*ss*xMult+'px','height': 500*ss*xMult+'px',}"
                >
                    <!--     Title       -->
                    <div class="c-no_wallet_row"
                         :style="{'width': 800*ss*xMult+'px','height': 110*ss*xMult+'px','gap': 30*ss*xMult+'px',}"
                    >
                        <img :src="getImg" alt=""
                             :style="{'width': 'auto','height': 110*ss*xMult+'px','margin-left': 20*ss*xMult+'px',}"
                        />
                        <div class="c-no_wallet_text c-no_wallet_2" style="color: #ffffff;"
                             :style="{'width': 'auto','height': 110*ss*xMult+'px',
                                'font-size': 70*ss*xMult+'px','line-height': 110*ss*xMult+'px',}"
                        >
                            {{messages.m029_5}}
                        </div>
                    </div>
                    <!--     Row with WAX/Phantom       -->
                    <div class="c-no_wallet_2"
                         :style="{'width': 900*ss*xMult+'px','height': 400*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                    >
                        <div class="c-no_wallet_text c-no_wallet_2" style="color: #FFFFFF;"
                             :style="{'width': 900*ss*xMult+'px','height': 65*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 65*ss*xMult+'px',}"
                             @click="click"
                        >
                            {{getText1}}
                        </div>
                        <div class="c-no_wallet_text c-no_wallet_2" style="color: #FFE500; text-decoration: underline;"
                             :style="{'width': 900*ss*xMult+'px','height': 'auto',
                            'font-size': 50*ss*xMult+'px','line-height': 65*ss*xMult+'px',}"
                             @click="click"
                        >
                            {{getText2}}
                        </div>
                    </div>

                </div>

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import waxSvg from "@/assets/svg/chains/icon_wax.svg";
    import anchorImg from "@/assets/images/logo_anchor.webp";
    import phantomImg from "@/assets/images/logo_phantom.webp";
    import solflareImg from "@/assets/images/logo_solflare.webp";
    import roninSvg from "@/assets/svg/chains/icon_ron.svg";
    import Config from "@/scripts/Config";

    export default defineComponent({
        name: 'PopupNoWallet',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","messages",]),
            getImg(){
                let txt;
                const param=this.$store.state.popupNoWallet.walletType;
                if (param==='wax')
                    txt=Utils.getGlobalLink(waxSvg);
                if (param==='solflare')
                    txt=Utils.getGlobalLink(solflareImg);
                if (param==='phantom')
                    txt=Utils.getGlobalLink(phantomImg);
                if (param==='anchor')
                    txt=Utils.getGlobalLink(anchorImg);
                if (param==='ronin')
                    txt=Utils.getGlobalLink(roninSvg);
                txt=txt?txt:'';
                return txt;
            },

            getText1(){
                let txt;
                const messages=this.$store.state.messages;
                const param=this.$store.state.popupNoWallet.walletType;
                if (param==='wax')
                    txt=messages.m034_01;
                if (param==='anchor')
                    txt=messages.m034_02;
                if (param==='phantom')
                    txt=messages.m034_03;
                if (param==='solflare')
                    txt=messages.m034_04;
                if (param==='ronin')
                    txt=messages.m034_05;

                txt=txt?txt:'';
                return txt;
            },

            getText2(){
                let txt;
                const messages=this.$store.state.messages;
                const param=this.$store.state.popupNoWallet.walletType;
                if (param==='wax')
                    txt=messages.m034_06;
                if (param==='anchor')
                    txt=messages.m034_07;
                if (param==='phantom')
                    txt=messages.m034_08;
                if (param==='solflare')
                    txt=messages.m034_09;
                if (param==='ronin')
                    txt=messages.m034_10;

                txt=txt?txt:'';
                return txt;
            },

            getBg(){
                let txt='linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%)';
                return txt;
            },
            getShadow(){
                let txt='0 0 100px rgba(25,201,243,.6),inset 0 0 100px hsla(0,0%,100%,.5)';
                return txt;
            },

        },
        components: {ButtonSvg,ButtonSvgOval,},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btn_xClick(){
                const param=this.$store.state.popupNoWallet;
                param.visible=false;

            },
            click(){
                let txt;
                const param=this.$store.state.popupNoWallet.walletType;
                txt=Config.walletLink[param];

                if (txt)
                    window.open(txt, '_blank').focus();
            },

        },
    });
</script>

<style lang="css" scoped>

    .c-no_wallet_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-no_wallet_column{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-no_wallet_row{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;

    }

    .c-no_wallet_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        text-align: -webkit-center;

        /*word-wrap: break-word;/**/
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

</style>

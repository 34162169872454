<template>
    <!--Info how to play games, shop, etc + About us-->
    <!--VERTICAL-->
    <div v-if="orient==='V'" class="c-info_about_bg"
         :style="{'width': '100%','height': 'auto','margin-top': 30*ss*xMult+'px','padding-bottom': 20*ss*xMult+'px',}">
        <img  class="c-info_about_img"
              :src="img_blockspin" alt=""
              :style="{'width': 600*ss*xMult+'px','height': 'auto','margin-top': 30*ss*xMult+'px',}"
        />

        <!--Twitter + discord + Contact Us-->
        <div class="c-info_about_rcc"
             :style="{'width': 950*ss*xMult+'px','height': 190*ss*xMult+'px','margin-top': 30*ss*xMult+'px','gap': 70*ss*xMult+'px'}">
            <img  class="c-info_about_img" @click="clickDiscord"
                  :src="img_discord" alt=""
                  :style="{'width': 80*ss*xMult+'px','height': 80*ss*xMult+'px',}"
            />
            <img  class="c-info_about_img" @click="clickTwitter"
                  :src="img_twitter" alt=""
                  :style="{'width': 83*ss*xMult+'px','height': 80*ss*xMult+'px',}"
            />
            <img  class="c-info_about_img" @click="clickTelegram"
                  :src="img_telegram" alt=""
                  :style="{'width': 120*ss*xMult+'px','height': 120*ss*xMult+'px',}"
            />
            <div class="c-info_about_ccs" :style="{'width': 380*ss*xMult+'px','height': 130*ss*xMult+'px',}">
                <ButtonSvgOval :svg-normal="img_email"
                               :btn-text="messages.m046_01"
                               :bg-width="378*ss*xMult" :bg-height="btnRad*0.8"
                               :max-width="380*ss*xMult-btnRad/2-70*ss*xMult"
                               :font-size="45*ss*xMult"
                               :svg-width="btnRad*0.4" :svg-height="btnRad*0.4"
                               :background-val="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%'"
                               :border-val="'linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%)'"
                               :callback="clickContactUs" :callback-wait="true"
                               :click-scale="0.95"
                />
            </div>

        </div>

        <!--Blog + Contact Us + Discord Bot + About Us (2 column)-->
        <div class="c-info_about_rcb"
             :style="{'width': 950*ss*xMult+'px','height': 240*ss*xMult+'px','margin-top': 30*ss*xMult+'px','gap': 0*ss*xMult+'px'}">
            <div class="c-info_about_css"
                 :style="{'width': 400*ss*xMult+'px','height': 240*ss*xMult+'px','gap': 0*ss*xMult+'px'}">
                        <!-- Blog -->
                <div class="c-info_about_rcs c-info_about_text"
                     @click="clickBlog"
                     :style="{'width': 400*ss*xMult+'px','height': 80*ss*xMult+'px','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                    {{messages.m046_03}}
                </div>
                        <!-- Discord Bot -->
                <div class="c-info_about_rcs c-info_about_text"
                     @click="clickDiscord"
                     :style="{'width': 400*ss*xMult+'px','height': 80*ss*xMult+'px','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                    {{messages.m046_02}}
                </div>
                        <!-- About Us -->
                <div class="c-info_about_rcs c-info_about_text"
                     @click="clickAboutUs"
                     :style="{'width': 400*ss*xMult+'px','height': 80*ss*xMult+'px','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                    {{messages.m046_04}}
                </div>
            </div>

            <div class="c-info_about_css"
                 :style="{'width': 540*ss*xMult+'px','height': 240*ss*xMult+'px','gap': 0*ss*xMult+'px'}">
                <!-- Privacy Policy -->
                <div class="c-info_about_rcs c-info_about_text"
                     @click="clickPrivacy"
                     :style="{'width': 540*ss*xMult+'px','height': 80*ss*xMult+'px','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                    {{messages.m046_08}}
                </div>
                <!-- Terms and Conditions -->
                <div class="c-info_about_rcs c-info_about_text"
                     @click="clickTerms"
                     :style="{'width': 540*ss*xMult+'px','height': 80*ss*xMult+'px','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                    {{messages.m016_1}}
                </div>
            </div>
        </div>

        <!--Join our discord community-->
        <div class="c-info_about_discord"
             :style="{'width': 950*ss*xMult+'px','height': 400*ss*xMult+'px','border-radius': 50*ss*xMult+'px','margin-top': 50*ss*xMult+'px',}">
            <img  style="position: absolute;"
                  :src="img_discord_decor" alt=""
                  :style="{'width': '100%','height': '100%','top': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
            />

            <div class="c-info_about_ccc"
                 :style="{'width': 800*ss*xMult+'px','height': 180*ss*xMult+'px',}">
                <div class="c-info_about_ccc c-info_about_text" style="font-weight: 700;text-align: -webkit-center;"
                     :style="{'width': 800*ss*xMult+'px','height': 160*ss*xMult+'px','font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px',}">
                    {{messages.m046_09}}
                </div>
            </div>
            <div class="c-info_about_ccc" :style="{'width': 800*ss*xMult+'px','height': 130*ss*xMult+'px',}">
                <ButtonSvgOval :svg-normal="img_discord"
                               :btn-text="messages.m032_02"
                               :bg-width="378*ss*xMult" :bg-height="btnRad*0.8"
                               :max-width="594*ss*xMult-btnRad/2-70*ss*xMult"
                               :font-size="45*ss*xMult"
                               :svg-width="btnRad*0.4" :svg-height="btnRad*0.4"
                               :background-val="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                               :border-val="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                               :callback="clickDiscord" :callback-wait="true"
                               :click-scale="0.95"
                />
            </div>

        </div>

        <!--Copyright info-->
        <div class="c-info_about_ccc"
             :style="{'width': 950*ss*xMult+'px','height': 'auto','margin-top': 70*ss*xMult+'px','gap': 20*ss*xMult+'px'}">
            <!-- © 2023 BlockSpinGaming Ltd All rights reserved -->
            <div class="c-info_about_ccc c-info_about_text" style="text-align: -webkit-center;opacity: 0.6;font-weight: 400;"
                 :style="{'width': 950*ss*xMult+'px','height': 'auto','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                {{messages.m046_05}}
            </div>
            <!-- Peiraios, 30, 1st floor, Office 1, Strovolos, 2023, Nicosia, Cyprus -->
            <div class="c-info_about_ccc c-info_about_text" style="text-align: -webkit-center;opacity: 0.6;font-weight: 400;"
                 :style="{'width': 950*ss*xMult+'px','height': 'auto','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                {{messages.m046_06}}
            </div>
        </div>

        <!--Powered by + DRAGON logo-->
        <div class="c-info_about_rcc"
             :style="{'width': 950*ss*xMult+'px','height': 240*ss*xMult+'px','margin-top': 0*ss*xMult+'px','gap': 30*ss*xMult+'px'}">
            <div class="c-info_about_ccc"
                 :style="{'width': 'auto','height': 240*ss*xMult+'px','gap': 0*ss*xMult+'px'}">
                <!-- Powered by -->
                <div class="c-info_about_rcc c-info_about_text"
                     :style="{'width': 'auto','height': 80*ss*xMult+'px','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                    {{messages.m046_07}}
                </div>
            </div>
            <div class="c-info_about_ccc"
                 :style="{'width': 600*ss*xMult+'px','height': 'auto','gap': 0*ss*xMult+'px'}">
                <!-- DRAGON logo -->
                <img  class="c-info_about_img"
                      :src="img_dragon" alt=""
                      :style="{'width': 600*ss*xMult+'px','height': 'auto',}"
                />
            </div>
        </div>
    </div>

    <!--HORIZONTAL-->
    <div v-else class="c-info_about_bg"
         :style="{'width': '100%','height': 'auto','margin-top': 30*ss*xMult+'px','padding-bottom': 0*ss*xMult+'px',}">
        <div class="c-info_about_rcs" style="align-items: flex-start;"
             :style="{'width': realWidth-80*ss*xMult+'px','height': 'auto','margin-top': 30*ss*xMult+'px','gap': 0*ss*xMult+'px'}">
            <!--Left column-->
            <div class="c-info_about_css" style="border-bottom: 2px solid rgba(255, 255, 255, 0.20);"
                 :style="{'width': (realWidth-80*ss*xMult)/2+'px','height': 700*ss*xMult+'px','gap': 0*ss*xMult+'px'}">
                <!--Blockspin logo-->
                <img  class="c-info_about_img"
                      :src="img_blockspin" alt=""
                      :style="{'width': 'auto','height': 190*ss*xMult+'px','margin-top': 30*ss*xMult+'px',}"
                />

                <!--Blog + Contact Us + Discord Bot + About Us (2 column)-->
                <div class="c-info_about_rcb"
                     :style="{'width': '100%','height': 400*ss*xMult+'px','margin-top': 30*ss*xMult+'px','gap': 0*ss*xMult+'px'}">
                    <div class="c-info_about_css"
                         :style="{'width': '100%','height': 400*ss*xMult+'px','gap': 0*ss*xMult+'px'}">
                        <!-- Blog -->
                        <div class="c-info_about_rcs c-info_about_text"
                             @click="clickBlog"
                             :style="{'width': 400*ss*xMult+'px','height': 80*ss*xMult+'px','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                            {{messages.m046_03}}
                        </div>
                        <!-- Discord Bot -->
                        <div class="c-info_about_rcs c-info_about_text"
                             @click="clickDiscord"
                             :style="{'width': 400*ss*xMult+'px','height': 80*ss*xMult+'px','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                            {{messages.m046_02}}
                        </div>
                        <!-- About Us -->
                        <div class="c-info_about_rcs c-info_about_text"
                             @click="clickAboutUs"
                             :style="{'width': 400*ss*xMult+'px','height': 80*ss*xMult+'px','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                            {{messages.m046_04}}
                        </div>

                        <!-- Privacy Policy -->
                        <div class="c-info_about_rcs c-info_about_text"
                             @click="clickPrivacy"
                             :style="{'width': 540*ss*xMult+'px','height': 80*ss*xMult+'px','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                            {{messages.m046_08}}
                        </div>
                        <!-- Terms and Conditions -->
                        <div class="c-info_about_rcs c-info_about_text"
                             @click="clickTerms"
                             :style="{'width': 540*ss*xMult+'px','height': 80*ss*xMult+'px','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                            {{messages.m016_1}}
                        </div>
                    </div>

                </div>
            </div>
            <!--Right column-->
            <div class="c-info_about_css" style="border-bottom: 2px solid rgba(255, 255, 255, 0.20); align-items: center;"
                 :style="{'width': (realWidth-80*ss*xMult)/2+'px','height': 700*ss*xMult+'px','gap': 0*ss*xMult+'px'}">
                <!--Twitter + discord + Contact Us-->
                <div class="c-info_about_rcc"
                     :style="{'width': '100%','height': 190*ss*xMult+'px','margin-top': 30*ss*xMult+'px','gap': 70*ss*xMult+'px'}">
                    <img  class="c-info_about_img" @click="clickDiscord"
                          :src="img_discord" alt=""
                          :style="{'width': 80*ss*xMult+'px','height': 80*ss*xMult+'px',}"
                    />
                    <img  class="c-info_about_img" @click="clickTwitter"
                          :src="img_twitter" alt=""
                          :style="{'width': 83*ss*xMult+'px','height': 80*ss*xMult+'px',}"
                    />
                    <img  class="c-info_about_img" @click="clickTelegram"
                          :src="img_telegram" alt=""
                          :style="{'width': 120*ss*xMult+'px','height': 120*ss*xMult+'px',}"
                    />
                    <div class="c-info_about_ccs" :style="{'width': 380*ss*xMult+'px','height': 130*ss*xMult+'px',}">
                        <ButtonSvgOval :svg-normal="img_email"
                                       :btn-text="messages.m046_01"
                                       :bg-width="378*ss*xMult" :bg-height="btnRad*0.8"
                                       :max-width="380*ss*xMult-btnRad/2-70*ss*xMult"
                                       :font-size="45*ss*xMult"
                                       :svg-width="btnRad*0.4" :svg-height="btnRad*0.4"
                                       :background-val="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%'"
                                       :border-val="'linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%)'"
                                       :callback="clickContactUs" :callback-wait="true"
                                       :click-scale="0.95"
                        />
                    </div>

                </div>

                <!--Join our discord community-->
                <div class="c-info_about_discord"
                     :style="{'width': 950*ss*xMult+'px','height': 400*ss*xMult+'px','border-radius': 50*ss*xMult+'px','margin-top': 20*ss*xMult+'px',}">
                    <img  style="position: absolute;"
                          :src="img_discord_decor" alt=""
                          :style="{'width': '100%','height': '100%','top': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
                    />

                    <div class="c-info_about_ccc"
                         :style="{'width': 800*ss*xMult+'px','height': 180*ss*xMult+'px',}">
                        <div class="c-info_about_ccc c-info_about_text" style="font-weight: 700;text-align: -webkit-center;"
                             :style="{'width': 800*ss*xMult+'px','height': 160*ss*xMult+'px','font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px',}">
                            {{messages.m046_09}}
                        </div>
                    </div>
                    <div class="c-info_about_ccc" :style="{'width': 800*ss*xMult+'px','height': 130*ss*xMult+'px',}">
                        <ButtonSvgOval :svg-normal="img_discord"
                                       :btn-text="messages.m032_02"
                                       :bg-width="378*ss*xMult" :bg-height="btnRad*0.8"
                                       :max-width="594*ss*xMult-btnRad/2-70*ss*xMult"
                                       :font-size="45*ss*xMult"
                                       :svg-width="btnRad*0.4" :svg-height="btnRad*0.4"
                                       :background-val="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                                       :border-val="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                                       :callback="clickDiscord" :callback-wait="true"
                                       :click-scale="0.95"
                        />
                    </div>

                </div>
            </div>
        </div>

        <!--Copyright info-->
        <div class="c-info_about_ccc"
             :style="{'width': '100%','height': 'auto','margin-top': 50*ss*xMult+'px','gap': 20*ss*xMult+'px'}">
            <!-- © 2023 BlockSpinGaming Ltd All rights reserved -->
            <div class="c-info_about_ccc c-info_about_text" style="text-align: -webkit-center;opacity: 0.6;font-weight: 400;"
                 :style="{'width': '100%','height': 'auto','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                {{messages.m046_05}}
            </div>
            <!-- Peiraios, 30, 1st floor, Office 1, Strovolos, 2023, Nicosia, Cyprus -->
            <div class="c-info_about_ccc c-info_about_text" style="text-align: -webkit-center;opacity: 0.6;font-weight: 400;"
                 :style="{'width': '100%','height': 'auto','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                {{messages.m046_06}}
            </div>
        </div>

        <!--Powered by + DRAGON logo-->
        <div class="c-info_about_rcc"
             :style="{'width': '100%','height': 240*ss*xMult+'px','margin-top': 0*ss*xMult+'px','gap': 30*ss*xMult+'px'}">
            <div class="c-info_about_ccc"
                 :style="{'width': 'auto','height': 240*ss*xMult+'px','gap': 0*ss*xMult+'px'}">
                <!-- Powered by -->
                <div class="c-info_about_rcc c-info_about_text"
                     :style="{'width': 'auto','height': 80*ss*xMult+'px','line-height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px'}">
                    {{messages.m046_07}}
                </div>
            </div>
            <div class="c-info_about_ccc"
                 :style="{'width': 600*ss*xMult+'px','height': 'auto','gap': 0*ss*xMult+'px'}">
                <!-- DRAGON logo -->
                <img  class="c-info_about_img"
                      :src="img_dragon" alt=""
                      :style="{'width': 600*ss*xMult+'px','height': 'auto',}"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";

    import img_blockspin from "@/assets/images/info/blockspin_white.webp";
    import img_discord_decor from "@/assets/images/info/discord_decor.webp";
    import img_email from "@/assets/svg/e_mail.svg";
    import img_discord from "@/assets/svg/discord.svg";
    import img_dragon from "@/assets/svg/info/dragon.svg";
    import img_twitter from "@/assets/svg/info/twitter.svg";
    import img_telegram from "@/assets/svg/info/icon_telegram.svg";

    export default defineComponent({
    //export default {
        name: "PageInfoAbout",
        components: {ButtonSvgOval},
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","messages","orient","xMult"]),
            btnRad(){
                return 135*this.$store.state.ss*this.$store.getters.xMult
            },
        },
        data: function () {
            return {
                img_blockspin:Utils.getGlobalLink(img_blockspin),img_email:Utils.getGlobalLink(img_email),img_discord:Utils.getGlobalLink(img_discord),
                img_twitter:Utils.getGlobalLink(img_twitter),img_discord_decor:Utils.getGlobalLink(img_discord_decor),img_dragon:Utils.getGlobalLink(img_dragon),
                img_telegram:Utils.getGlobalLink(img_telegram),
            }
        },
        created() {

        },
        mounted() {

        },
        beforeUnmount() {

        },
        methods:{
            clickAboutUs(){
                window.open("https://dragondreamstudio.com/");
            },
            clickBlog(){
                window.open("https://blog.blockspingaming.com/");
            },
            clickContactUs(){
                window.open("mailto:info@blockspingaming.com");
            },
            clickDiscord(){
                window.open("https://discord.gg/uxHqcVdKZV");
            },
            clickPrivacy(){
                this.$store.state.popupVisible.privacy=true;
            },
            clickTerms(){
                this.$store.state.popupVisible.terms=true;
            },
            clickTwitter(){
                window.open("https://twitter.com/intent/follow?screen_name=blockspingaming");
            },
            clickTelegram(){
                window.open("https://t.me/+rACILAlzW7I1Mzk0");
            },
        },
    });

</script>

<style lang="css" scoped>
    .c-info_about_bg{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        overflow: -moz-scrollbars-none;
        justify-content: center;
        align-items: center;

        background: #031223;
    }

    .c-info_about_img{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-info_about_rcb{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .c-info_about_rcc{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .c-info_about_rcs{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        /*background: rgba(255, 255, 255, 0.2);/**/
    }

    .c-info_about_ccs{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-info_about_css{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: hidden;

        /*background: rgba(255, 255, 255, 0.2);/**/
    }

    .c-info_about_discord{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        overflow: hidden;
        background: linear-gradient(95deg, #DC06FF -6.49%, #8606B2 108.32%);
    }

    .c-info_about_ccc{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-info_about_text{
        font-family: Rubik, sans-serif;
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        color: #FFFFFF;

        word-wrap: break-word;/**/
        /*white-space: nowrap;        /*Нет переноса не переносит*/
    }

</style>

<template>
    <div class="c-components_body unselectable" style="backdrop-filter: blur(5px);background: linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8));">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
        <!--   Shop buy confirm bg static   -->
            <div class="c-shop_buy_confirm_2"
                 :style="{'width': 1000*ss*xMult+'px','height': 800*ss*xMult+'px','gap': 30*ss*xMult+'px','border-radius':60*ss*xMult+'px',
                    'border':6*ss*xMult+'px solid #06A071',
                    'background':getBg,'box-shadow':getShadow}"
            >

                <!--     confetti  for buy confirm     -->
                <div class="c-shop_buy_confirm_bg_abs"
                     :style="{'width': '100%','height': '100%',}"
                >
                    <img class="c-shop_buy_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 800*ss*xMult+'px','bottom': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
                         :src="confetti_2"
                    />
                    <img class="c-shop_buy_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 800*ss*xMult+'px','bottom': 0*ss*xMult+'px','right': 0*ss*xMult+'px',}"
                         :src="confetti_3"
                    />

                    <img class="c-shop_buy_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 150*ss*xMult+'px','top': 80*ss*xMult+'px','right': 150*ss*xMult+'px',}"
                         :src="coin_1"
                    />
                    <img class="c-shop_buy_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 150*ss*xMult+'px','bottom': 80*ss*xMult+'px','right': 50*ss*xMult+'px',}"
                         :src="coin_2"
                    />
                    <img class="c-shop_buy_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 150*ss*xMult+'px','top': 150*ss*xMult+'px','left': 0*ss*xMult+'px',}"
                         :src="coin_3"
                    />
                </div>

                <!--     Confirm information       -->
                <div class="c-shop_buy_confirm_column"
                     :style="{'width': 1000*ss*xMult+'px','height': 500*ss*xMult+'px','margin-bottom': 80*ss*xMult+'px',}"
                >
                    <div class="c-shop_buy_confirm_text"
                         :style="{'width': 1000*ss*xMult+'px','height': 100*ss*xMult+'px',
                            'font-size': 70*ss*xMult+'px','line-height': 100*ss*xMult+'px','margin-bottom': 30*ss*xMult+'px',}"
                    >
                        {{messages.m028}}
                    </div>
                    <div class="c-shop_buy_confirm_text"
                         :style="{'width': 1000*ss*xMult+'px','height': 60*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                    >
                        {{getText}}
                    </div>
                    <div class="c-shop_buy_confirm_text"
                         :style="{'width': 1000*ss*xMult+'px','height': 60*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                    >
                        =
                    </div>
                    <div class="c-shop_buy_confirm_text"
                         :style="{'width': 1000*ss*xMult+'px','height': 60*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-bottom': 50*ss*xMult+'px',}"
                    >
                        {{getText2}}
                    </div>

                    <!--     Confirm button       -->
                    <ButtonSvgOval :svg-normal="checkSvg"
                                   :callback="clickConfirm" :callback-wait="true"
                                   :btn-text="messages.m028_2"
                                   :bg-width="420*ss*xMult" :bg-height="100*ss*xMult"
                                   :max-width="420*ss*xMult"
                                   :font-size="45*ss*xMult"
                                   :svg-width="50*ss*xMult" :svg-height="50*ss*xMult"
                                   :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                                   :borderVal="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                                   :click-scale="0.95"
                    />
                </div>

                <img class="c-shop_buy_confirm_img_1" :src="getImage"
                     :style="{'top': -175*ss*xMult+'px', 'height': 350*ss*xMult+'px','width': 'auto',}"
                />
                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import {currencyNames} from "@/classes/Constants";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import checkSvg from "@/assets/svg/icon_check.svg";
    import confetti_2 from "@/assets/svg/tickets/confetti_2.svg";
    import confetti_3 from "@/assets/svg/tickets/confetti_3.svg";
    import confetti_4 from "@/assets/images/confetti_4.webp";
    import coin_1 from "@/assets/svg/tickets/coin_1.svg";
    import coin_2 from "@/assets/svg/tickets/coin_2.svg";
    import coin_3 from "@/assets/svg/tickets/coin_3.svg";
    import coin_left from "@/assets/svg/tickets/coins_left.svg";
    import coin_right from "@/assets/svg/tickets/coins_right.svg";
    import iconGemSvg from "@/assets/svg/icon_gem2.svg";
    import ticketBronze from "@/assets/images/tickets_bronze.webp";
    import ticketSilver from "@/assets/images/tickets_silver.webp";
    import ticketGold from "@/assets/images/tickets_gold.webp";

    export default defineComponent({
        name: 'ShopBuyConfirm',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","messages","popupBuyShopConfirm",]),
            getImage(){
                ////buyObj:{itemType,itemKey,currency:{wax,sol,ronin,usd},amount,price,walletType:{wax,anchor,phantom,solflare}}
                let img='';
                const tb=this.$store.state.popupBuyShopConfirm.buyObj;
                const items=this.$store.state.shop.items;
                const item=items[tb.itemType][tb.itemKey]
                img=Utils.getGlobalLink(item.img);
                img=img?img:'';
                return img;
            },
            getText(){
                const tb=this.$store.state.popupBuyShopConfirm.buyObj;
                const items=this.$store.state.shop.items;
                const item=items[tb.itemType][tb.itemKey]
                let txt;
                txt=item.amount+' x '+item.name;
                return txt;
            },
            getText2(){
                const state=this.$store.state;
                const tb=this.$store.state.popupBuyShopConfirm.buyObj;
                let txt;
                txt=(tb.amount*tb.price).toLocaleString()+' '+currencyNames[tb.currency];

                return txt;
            },
            getBg(){
                let txt='linear-gradient(95deg, #0CFF7C -16.14%, #00AF90 137.96%)';
                return txt;
            },
            getShadow(){
                let txt='0px 0px 100px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 100px 0px rgba(57, 231, 156, 0.60)';
                return txt;
            },

        },
        components: {ButtonSvg,ButtonSvgOval,},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),iconGemSvg:Utils.getGlobalLink(iconGemSvg), ticketBronze:Utils.getGlobalLink(ticketBronze),
                ticketSilver:Utils.getGlobalLink(ticketSilver),ticketGold:Utils.getGlobalLink(ticketGold),confetti_2:Utils.getGlobalLink(confetti_2),
                checkSvg:Utils.getGlobalLink(checkSvg),confetti_3:Utils.getGlobalLink(confetti_3),coin_1:Utils.getGlobalLink(coin_1),
                coin_2:Utils.getGlobalLink(coin_2),coin_3:Utils.getGlobalLink(coin_3),confetti_4:Utils.getGlobalLink(confetti_4),
                coin_left:Utils.getGlobalLink(coin_left),coin_right:Utils.getGlobalLink(coin_right),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {

            btn_xClick(){
                this.$store.state.popupBuyShopConfirm.visible=false;
            },
            async clickConfirm(){
                const state=this.$store.state;
                const tb=state.popupBuyShopConfirm;
                const obj=JSON.parse(JSON.stringify(tb.buyObj));

                const items=this.$store.state.shop.items;
                const item=items[tb.buyObj.itemType][tb.buyObj.itemKey]
                let txt;
                txt=(tb.buyObj.itemType==='vip'?'':(item.amount+' x '))+item.name;
                obj.title=txt;
                obj.description=txt;
                if (item.photo) {
                    obj.photo_url = Utils.getGlobalLink(item.photo);
                    const img = await Utils.getMeta(obj.photo_url);
                    obj.photo_width = img.naturalWidth;
                    obj.photo_height = img.naturalHeight;
                }

                this.$store.dispatch(state.tgMode?'buyFinishTelegram':'buyFinish',obj);

                tb.buyObj=null;
                state.popupBuyShopConfirm.visible=false;
            },
        },
    });
</script>

<style lang="css" scoped>
    .c-shop_buy_confirm_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-shop_buy_confirm_bg_abs{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-shop_buy_confirm_column{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-shop_buy_confirm_img_1{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-shop_buy_confirm_text{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;

        /*word-wrap: break-word;/**/
        white-space: nowrap;        /*Нет переноса не переносит*/
    }


</style>

<template>
    <!--
            Panel with Login buttons
                    or
            User name
            Gems
            Chips
            Tickets
            Entries
    -->

    <div class="c-user_info"
         :style="{'top': 0*ss*xMult+'px','left':0*ss*xMult+'px',}">

        <div class="c-user_info_bg"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">

            <div class="c-user_info_bg_rel"
                 :style="{'width': realWidth+'px','height': realHeight+'px',}">

                <div class="c-user_info_blur"
                     :style="{'top': 0*ss*xMult+'px','left':0*ss*xMult+'px',
                        'width': 860*ss*xMult+'px','height': realHeight+'px',}">
                </div>

                <div class="c-user_info_main"
                    :style="{'top': 0*ss*xMult+'px','left':0*ss*xMult+'px',
                    'width': 860*ss*xMult+'px','height': realHeight+'px',}">

                    <div v-if="!loginData" class="c-user_info_not_login"
                    >
                        <LoginButtons/>
                    </div>
                    <div v-else>
                        <div class="c-user_info_login"
                             :style="{'top': 54*ss*xMult+'px','left':54*ss*xMult+'px',
                                'width': 756*ss*xMult+'px','height': 1800*ss*xMult-btnRad*2+'px',
                                'padding-top': 8*ss*xMult+'px','gap':40*ss*xMult+'px',}"
                        >
                            <!--     User name       -->
                            <div class="c-user_info_user_btn"
                                :style="{'width': 702*ss*xMult+'px','height': btnRad*0.9+'px','max-width': 702*ss*xMult+'px'}">
                                <ButtonSvgOval :svg-normal="iconPersonSvg" :btn-text="userName"
                                               :callback="btnUserClick" :callback-wait="true"
                                               :bg-width="702*ss*xMult" :bg-height="btnRad*0.9"
                                               :max-width="702*ss*xMult-btnRad/2-70*ss*xMult"
                                               :svg-width="btnRad/2" :svg-height="btnRad/2"
                                               :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%'"
                                               :borderVal="'linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%)'"
                                               :clickScale="0.97"
                                />
                                <NotificationsNumber v-if="notificationsCountUnread>0"
                                                     :bg-left="0*ss*xMult+'px'"
                                                     :bg-top="0*ss*xMult+'px'"
                                                     :bg-min-width="30*ss*xMult+'px'"
                                                     :font-size="45*ss*xMult"
                                                     :line-height="45*ss*xMult"
                                                     :bg-padding="10*ss*xMult+'px '+20*ss*xMult+'px'"
                                                     :notifications-count="notificationsCountUnread"
                                />
                            </div>

                            <!--     Gems       -->
                            <div class="c-user_info_user_btn"
                                 :style="{'width': 594*ss*xMult+'px','height': btnRad*0.9+'px','max-width': 594*ss*xMult+'px'}">
                                <ButtonSvgOval :svg-normal="iconGemsSvg"
                                               :callback="btnGemsClick" :callback-wait="true"
                                               :btn-text="userData.gems?userData.gems.toLocaleString()+' GEMS':'0 GEMS'"
                                               :bg-width="594*ss*xMult" :bg-height="btnRad*0.9"
                                               :max-width="594*ss*xMult-btnRad/2-70*ss*xMult"
                                               :svg-width="btnRad/2" :svg-height="btnRad/2"
                                               :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(95.19deg,#0cff7c -16.14%,#00af90 137.96%) var(--x,0)/200%'"
                                               :borderVal="'linear-gradient(102.76deg,#06a071 3.6%,#bcffde 53.89%,#06a071 99.19%)'"
                                               :click-scale="0.95"
                                />
                                <div v-if="popupQuickHelp.userInfoGemsInfo===true" class="c-user_info_gems_info_popup"
                                     :style="{'right': -420*ss*xMult+'px',}"
                                >
                                    <PopupInfo     :bg-width="400*ss*xMult" :bg-height="150*ss*xMult"
                                                   :max-width="50*ss*xMult"
                                                   :border-radius="30*ss*xMult"
                                                   :font-size="35*ss*xMult"
                                                   :btn-text="messages.m024"
                                                   :background-val="'rgba(255, 255, 255, 0.1)'"
                                                   :border-val="'1px solid rgba(255, 255, 255, 0.5)'"
                                                   :callback="popupInfoClick" :callback-wait="true"

                                    />
                                </div>
                            </div>

                            <!--     Chips       -->
                            <ButtonSvgOval :svg-normal="iconChipsSvg" :callback="btnChipsClick"
                                           :btn-text="userData.chips?userData.chips.toLocaleString()+' CHIPS':'0 CHIPS'"
                                           :bg-width="594*ss*xMult" :bg-height="btnRad*0.9"
                                           :max-width="594*ss*xMult-btnRad/2-70*ss*xMult"
                                           :svg-width="btnRad/2" :svg-height="btnRad/2"
                                           :background-val="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                                           :border-val="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                                           :click-scale="0.95"
                            />

                            <!--     Tickets       -->
                            <ButtonSvgOval :svg-normal="iconTicketsSvg" :btn-text="ticketsStr" :callback="btnTicketsClick"
                                           :callback-wait="true"
                                           :bg-width="486*ss*xMult" :bg-height="btnRad*0.9"
                                           :max-width="486*ss*xMult-btnRad/2-70*ss*xMult"
                                           :svg-width="btnRad/2" :svg-height="btnRad/2"
                                           :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(95.11deg,#ffc83c -9.98%,#dc8400 118.99%) var(--x,0)/200%'"
                                           :borderVal="'linear-gradient(102.76deg, #bb7605 3.6%, #ffde91 53.89%, #CC181A 99.19%)'"
                                           :click-scale="0.93"
                            />

                            <!--     Entires       -->
                            <ButtonSvgOval :svg-normal="iconEntiresSvg" :btn-text="entriesStr"
                                           :callback="btnEntriesClick"
                                           :callback-wait="true"
                                           :bg-width="486*ss*xMult" :bg-height="btnRad*0.9"
                                           :max-width="486*ss*xMult-btnRad/2-70*ss*xMult"
                                           :svg-width="btnRad/2" :svg-height="btnRad/2"
                                           :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(94.81deg,#dc06ff -6.49%,#8606b3 108.32%) var(--x,0)/200%'"
                                           :borderVal="'linear-gradient(102.76deg,#76069a 3.6%,#e698f3 53.89%,#76069a 99.19%)'"
                                           :click-scale="0.93"
                            />

                            <!--     Claim Prize       -->
                            <ButtonSvgOval v-if="orient==='V'"
                                           :svg-normal="prizesSvg" :btn-text="messages.m030_15_1"
                                           :callback="btnClaimClick"
                                           :callback-wait="true"
                                           :bg-width="486*ss*xMult" :bg-height="btnRad*0.9"
                                           :max-width="486*ss*xMult-btnRad/2-70*ss*xMult"
                                           :svg-width="btnRad/2" :svg-height="btnRad/2"
                                           :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(94.81deg,#dc06ff -6.49%,#8606b3 108.32%) var(--x,0)/200%'"
                                           :borderVal="'linear-gradient(102.76deg,#76069a 3.6%,#e698f3 53.89%,#76069a 99.19%)'"
                                           :click-scale="0.93"
                            />
                        </div>

                        <div class="c-user_info_login" style="color:#ff37df;"
                             :style="{'bottom': 54*ss*xMult+'px','left':54*ss*xMult+'px',
                                'width': 756*ss*xMult+'px','height': (orient==='V'?3:2)*btnRad+'px',
                                'padding-top': 8*ss*xMult+'px','gap':30*ss*xMult+'px',}"
                        >
                            <div style="display: flex; justify-content: space-between;"
                                 :style="{'width': 756*ss*xMult+'px','height': btnRad+'px',
                                    'flex-direction':orient==='V'?'column':'row'}"
                            >
                                <!--     Join Us       -->
                                <ButtonSvgOval :svg-normal="joinSvg"
                                               :btn-text="messages.m032_02"
                                               :bg-width="378*ss*xMult" :bg-height="btnRad*0.8"
                                               :max-width="594*ss*xMult-btnRad/2-70*ss*xMult"
                                               :svg-width="tgMode?btnRad/2.2:btnRad/2" :svg-height="tgMode?btnRad/2.2:btnRad/2"
                                               :background-val="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                                               :border-val="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                                               :callback="btnJoinClick" :callback-wait="true"
                                               :click-scale="0.9"
                                />

                                <!--     go Home       -->
                                <ButtonSvgOval v-if="orient==='H'"
                                               :svg-normal="iconHomeSvg"
                                               :btn-text="'Home'"
                                               :font-size="50*ss*xMult"
                                               :bg-width="345*ss*xMult" :bg-height="btnRad*0.9"
                                               :max-width="354*ss*xMult-btnRad/2-70*ss*xMult"
                                               :svg-width="btnRad/2" :svg-height="btnRad/2"
                                               :backgroundVal="'none'"
                                               :borderVal="'none'"
                                               :callback="btnHomeClick" :callback-wait="true"
                                               :clickScale="1"
                                />
                            </div>
                            <div style="display: flex; justify-content: space-between;"
                                :style="{'width': 756*ss*xMult+'px','height': (orient==='V'?2:1)*btnRad+'px',
                                    'flex-direction':orient==='V'?'column':'row'}"
                            >
                                <!--     Shop       -->
                                <ButtonSvgOval :svg-normal="prizesSvg"
                                               :btn-text="messages.m032_01"
                                               :bg-width="378*ss*xMult" :bg-height="btnRad*0.8"
                                               :max-width="594*ss*xMult-btnRad/2-70*ss*xMult"
                                               :font-size="45*ss*xMult"
                                               :svg-width="btnRad*0.4" :svg-height="btnRad*0.4"
                                               :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(94.81deg,#dc06ff -6.49%,#8606b3 108.32%) var(--x,0)/200%'"
                                               :borderVal="'linear-gradient(102.76deg,#88028D 3.6%,#FF37DF 53.89%,#88028D 99.19%)'"
                                               :callback="btnShopClick" :callback-wait="true"
                                               :click-scale="0.9"
                                />

                                <!--     Logout       -->
                                <div v-if="tgMode!==true" style="display: contents;">
                                    <ButtonSvgOval
                                                   :svg-normal="iconLogoutSvg"
                                                   :btn-text="'Logout'"
                                                   :bg-width="328*ss*xMult" :bg-height="btnRad*0.9"
                                                   :max-width="354*ss*xMult-btnRad/2-70*ss*xMult"
                                                   :svg-width="btnRad/2" :svg-height="btnRad/2"
                                                   :backgroundVal="'none'"
                                                   :borderVal="'none'"
                                                   :callback="btnLogoutClick" :callback-wait="true"
                                                   :clickScale="1"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="c-user_info_btn_x"
                    :style="{'top': 20*ss*xMult+'px','right':20*ss*xMult+'px','width': btnRad+'px','height': btnRad+'px',}">
                    <ButtonSvg :svg-normal="menuBtnXSvg" :svg-clicked="menuBtnXSvg"
                               :bg-width="btnRad"
                               :bg-height="btnRad"
                               :callback="btnCloseClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import menuBtnXSvg from "../../assets/svg/menu_btn_x.svg";
    import iconPersonSvg from "../../assets/svg/icon_person.svg";
    import iconGemsSvg from "../../assets/svg/icon_gems.svg";
    import iconChipsSvg from "../../assets/svg/icon_chips.svg";
    import iconTicketsSvg from "../../assets/svg/icon_tickets.svg";
    import iconEntiresSvg from "../../assets/svg/icon_lottery.svg";
    import iconDiscordSvg from "../../assets/svg/icon_discord.svg";
    import iconLogoutSvg from "../../assets/svg/icon_logout.svg";
    import iconHomeSvg from "@/assets/svg/icon_home.svg";
    import chipsSvg from "../../assets/svg/chips.svg";

    import ButtonSvg from "../elements/ButtonSvg";
    import ButtonSvgOval from "../elements/ButtonSvgOval";
    import LoginButtons from "./LoginButtons";
    import Utils from "../../scripts/Utils";
    import prizesSvg from "@/assets/svg/tickets/prizes.svg";
    import NotificationsNumber from "@/vue/elements/NotificationsNumber";
    import PopupInfo from "@/vue/elements/PopupInfo";
    import GameSignals from "@/scripts/GameSignals";
    import imgTg from "@/assets/images/icon_telegram.webp";

    //export default defineComponent({              //Not working debugger in chrome
    export default {
        name: "UserInfo",
        components: {ButtonSvg, ButtonSvgOval, LoginButtons, NotificationsNumber,PopupInfo},
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","getLeft","loginData","userName","userData","messages","xMult","orient","popupVisible",
                "notificationsCountUnread","popupQuickHelp", "tgMode"]),
            btnRad(){
                return 135*this.$store.state.ss*this.$store.getters.xMult
            },
            joinSvg(){
                const state=this.$store.state;
                if (state.tgMode)
                    return Utils.getGlobalLink(imgTg);

                return Utils.getGlobalLink(iconDiscordSvg)
            },
            ticketsStr(){
                const userData=this.$store.state.userData;
                let count=userData.bronzeTickets?userData.bronzeTickets:0;
                count+=userData.silverTickets?userData.silverTickets:0;
                count+=userData.goldTickets?userData.goldTickets:0;
                return count.toString()+' Tickets'
            },
            entriesStr(){
                const userData=this.$store.state.userData;
                let count=0;
                try{
                    count=userData.numEntries.daily+userData.numEntries.weekly+userData.numEntries.monthly;
                }catch (e) {}
                return count.toString()+' Entries'
            },
        },
        created() {

        },
        data: function () {
            return {
                menuBtnXSvg:Utils.getGlobalLink(menuBtnXSvg),chipsSvg:Utils.getGlobalLink(chipsSvg),iconPersonSvg:Utils.getGlobalLink(iconPersonSvg),
                iconGemsSvg:Utils.getGlobalLink(iconGemsSvg),iconChipsSvg:Utils.getGlobalLink(iconChipsSvg),
                iconTicketsSvg:Utils.getGlobalLink(iconTicketsSvg),iconEntiresSvg:Utils.getGlobalLink(iconEntiresSvg),
                iconLogoutSvg:Utils.getGlobalLink(iconLogoutSvg),
                prizesSvg:Utils.getGlobalLink(prizesSvg),iconHomeSvg:Utils.getGlobalLink(iconHomeSvg),
            }
        },
        methods:{
            btnCloseClick(){
                this.$store.state.pageVisibleUserInfo=false;
            },
            btnGemsClick(){
                const state=this.$store.state;
                state.popupQuickHelp.userInfoGemsInfo=true;
                setTimeout(()=>{
                    state.popupQuickHelp.userInfoGemsInfo=false;
                },6000)//*/
            },
            popupInfoClick(){
                const state=this.$store.state;
                state.popupQuickHelp.userInfoGemsInfo=false;
            },
            btnChipsClick(){
                this.$store.state.popupVisible.chipsClaim=true;
                /*const shop=this.$store.state.shop;
                shop.shopType='chips';
                shop.visible=true;//*/
            },
            btnEntriesClick(){
                this.$store.state.prizes.visible=true;
            },
            btnClaimClick(){
                this.$store.state.popupVisible.prizesClaim=true;
            },
            btnJoinClick(){
                const state=this.$store.state;
                if (state.tgMode){
                    window.open("https://t.me/+rACILAlzW7I1Mzk0");
                }
                else{
                    window.open("https://discord.gg/uxHqcVdKZV");
                }

            },
            btnHomeClick(){
                GameSignals.goHome.dispatch();
            },
            btnLogoutClick(){
                Utils.logout(this.$store.state);
            },
            btnShopClick(){
                this.$store.state.shop.visible=true;
            },
            btnTicketsClick(){
                this.$store.dispatch('clickGetTickets');
            },
            btnUserClick(){
                if (this.$store.getters.isGuest){
                    this.$store.state.popupVisible.learnNewAccount=true;
                }
                else{
                    this.$store.state.popupVisible.userAccount=true;
                }

            },
        },
    }
    //);

</script>

<style lang="css" scoped>
    .c-user_info{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: absolute;
        justify-content: center;
        align-items: center;

        /*transform: translate(50%, -50%);/**/
        padding:0;
        margin:0;
        background: rgba(0, 0, 0, 0.67);

        width: 100vw;
        height: 100vh;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;

    }
    .c-user_info_bg{
        display: flex;
        overflow: hidden;
        position: absolute;
        transform: translate(0%, 0%);
        padding:0;
        margin:0;

        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-user_info_bg_rel{
        display: flex;
        position: relative;
    }

    .c-user_info_blur{
        display: flex;
        overflow: hidden;
        position: absolute;
        padding:0;
        margin:0;
        /*background: rgba(0, 0, 0, 0.2);/**/

        backdrop-filter: blur(10px);

    }

    .c-user_info_main{
        display: flex;
        overflow: visible;
        position: relative;
        padding:0;
        margin:0;
        background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-user_info_btn_x{
        display: flex;
        overflow: hidden;
        position: absolute;
        padding:0;
        margin:0;
        /*background: rgba(255, 255, 255, 0.2);/**/
    }

    .c-user_info_not_login{
        display: flex;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0;
        margin:0;
        /*background: rgba(255, 0, 0, 0.2);/**/
    }

    .c-user_info_login{
        display: flex;
        overflow: visible;
        position: absolute;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding:0;
        margin:0;
        /*background: rgba(255, 0, 0, 0.2);/**/
    }

    .c-user_info_user_btn{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.4);/**/
    }

    .c-user_info_gems_info_popup{
        position: absolute;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }

</style>

import lajam_thumb from "@/assets/images/allGamesThumb/lajam_thumb.webp";
import king_arthur_thumb from "@/assets/images/allGamesThumb/king_arthur_thumb.webp";
import tigerland_thumb from "@/assets/images/allGamesThumb/tigerland_thumb.webp";
import cartel_thumb from "@/assets/images/allGamesThumb/cartel_thumb.webp";
import night_of_fear_thumb from "@/assets/images/allGamesThumb/night_of_fear_thumb.webp";
import legends_thumb from "@/assets/images/allGamesThumb/legends_thumb.webp";
import wildwest_thumb from "@/assets/images/allGamesThumb/wildwest_thumb.webp";
import cleopatra_thumb from "@/assets/images/allGamesThumb/cleopatra_thumb.webp";
import spymaster_thumb from "@/assets/images/allGamesThumb/spymaster_thumb.webp";
import samurai_thumb from "@/assets/images/allGamesThumb/samurai_thumb.webp";
import surfin_thumb from "@/assets/images/allGamesThumb/surfin_thumb.webp";
import pirates_thumb from "@/assets/images/allGamesThumb/pirates_thumb.webp";
import thebigfive_thumb from "@/assets/images/allGamesThumb/thebigfive_thumb.webp";
import aladdin_thumb from "@/assets/images/allGamesThumb/aladdin_thumb.webp";
import vegas_thumb from "@/assets/images/allGamesThumb/vegas_thumb.webp";
import route66_thumb from "@/assets/images/allGamesThumb/route66_thumb.webp";
import muertos_thumb from "@/assets/images/allGamesThumb/muertos_thumb.webp";
import tokyorun_thumb from "@/assets/images/allGamesThumb/tokyorun_thumb.webp";
import sherlock_thumb from "@/assets/images/allGamesThumb/sherlock_thumb.webp";
import apachetrail_thumb from "@/assets/images/allGamesThumb/apachetrail_thumb.webp";
import rocket_thumb from "@/assets/images/allGamesThumb/rocket_thumb.webp";
import monument_thumb from "@/assets/images/allGamesThumb/monument_thumb.webp";
import plinko_thumb from "@/assets/images/allGamesThumb/plinko_thumb.webp";
import penalty_thumb from "@/assets/images/allGamesThumb/penalty_thumb.webp";
import mayan_thumb from "@/assets/images/allGamesThumb/mayan_thumb.webp";
import hulaspin_thumb from "@/assets/images/allGamesThumb/hulaspin_thumb.webp";
import copa_thumb from "@/assets/images/allGamesThumb/copacabana_thumb.webp";
import imperialrome_thumb from "@/assets/images/allGamesThumb/imperialrome_thumb.webp";
import ninja_thumb from "@/assets/images/allGamesThumb/ninja_thumb.webp";
import parisparis_thumb from "@/assets/images/allGamesThumb/parisparis_thumb.webp";
import bollywood_thumb from "@/assets/images/allGamesThumb/bollywood_thumb.webp";



export default {
    allGames: {
        parisparis: {
            title: 'Paris',
            name: 'parisparis',
            uri: 'parisparis',
            description:
                'paris_desc',
            image: 'paris.webp',
            achievement: 'sacrebleuplayer',
            thumb: parisparis_thumb,                  //Change thumb when production
            isNew: true,
            order: 25,
        },
        kingarthur: {
            title: 'King Arthur',
            name: 'kingarthur',
            uri: 'king-arthur',
            description:
                'kingarthur_desc',
            image: 'kingarthur.webp',
            thumb: king_arthur_thumb,
            achievement: 'excalibur',
            isNew: false,
            order: 1,
        },

        tigerland: {
            title: 'Tiger Land',
            name: 'tigerland',
            uri: 'tigerland',
            description:
                'tigerland_desc',
            image: 'tigerland.webp',
            thumb: tigerland_thumb,
            achievement: 'zhuhou',
            isNew: false,
            order: 2
        },

        cartel: {
            title: 'Cartel',
            name: 'cartel',
            uri: 'cartel',
            description:
                'cartel_desc',
            image: 'cartel.webp',
            thumb: cartel_thumb,
            achievement: 'kingpin',
            isNew: false,
            order: 3
        },

        nightoffear: {
            title: 'Night of Fear',
            name: 'nightoffear',
            uri: 'night-of-fear',
            description:
                'nightoffear_desc',
            image: 'night.webp',
            thumb: night_of_fear_thumb,
            achievement: 'ghost',
            isNew: false,
            order: 4
        },

        legends: {
            title: 'Northern Legends',
            name: 'legends',
            uri: 'legends',
            description:
                'cartel_desc',
            image: 'legends.webp',
            thumb: legends_thumb,
            achievement: 'slotlegend',
            order: 16,
        },

        wildwest: {
            title: 'Wild West',
            name: 'wildwest',
            uri: 'wild-west',
            description:
                'wildwest_desc',
            image: 'wildwest.webp',
            thumb: wildwest_thumb,
            achievement: 'outlaw',
            isNew: false,
            order: 5
        },

        cleopatra: {
            title: 'Cleopatra',
            name: 'cleopatra',
            uri: 'cleopatra',
            description:
                'cleopatra_desc',
            image: 'cleopatra.webp',
            thumb: cleopatra_thumb,
            achievement: 'scarabs',
            isNew: false,
            order: 6
        },
        spymaster: {
            title: 'Spymaster',
            name: 'spymaster',
            uri: 'spymaster',
            description:
                'spymaster_desc',
            image: 'spymaster.webp',
            thumb: spymaster_thumb,
            achievement: 'topsecret',
            isNew: false,
            order: 7
        },
        samurai: {
            title: 'Samurai Spirit',
            name: 'samurai',
            uri: 'samurai-spirit',
            description:
                'samurai_desc',
            image: 'samurai.webp',
            thumb: samurai_thumb,
            achievement: 'shogun',
            isNew: false,
            order: 8
        },
        surfin: {
            title: 'Surfin\' California',
            name: 'surfin',
            uri: 'surfin',
            description:
                'surfin_desc',
            image: 'surfin.webp',
            thumb: surfin_thumb,
            achievement: 'surfer',
            isNew: false,
            order: 9
        },
        pirates: {
            title: 'Treasure Island',
            name: 'pirates',
            uri: 'treasure-island',
            description:
                'pirates_desc',
            image: 'pirates.webp',
            thumb: pirates_thumb,
            achievement: 'pirate',
            isNew: false,
            order: 10
        },
        thebigfive: {
            title: 'The Big Five',
            name: 'thebigfive',
            uri: 'thebig5',
            description:
                'thebigfive_desc',
            image: 'thebigfive.webp',
            thumb: thebigfive_thumb,
            achievement: 'jungleking',
            isNew: false,
            order: 11
        },
        aladdin: {
            title: 'Aladdin',
            name: 'aladdin',
            uri: 'aladdin',
            description:
                'aladdin_desc',
            image: 'aladdin.webp',
            thumb: aladdin_thumb,
            achievement: 'genie',
            isNew: false,
            order: 12
        },
        vegasclassic: {
            title: 'Vegas Classic',
            name: 'vegasclassic',
            uri: 'vegasclassic',
            description:
                'vegas_desc',
            image: 'vegas.webp',
            thumb: vegas_thumb,
            achievement: 'progambler',
            isNew: false,
            order: 13,
        },
        route66: {
            title: 'Route 66',
            name: 'route66',
            uri: 'route66',
            description:
                'route66_desc',
            image: 'route66.webp',
            thumb: route66_thumb,
            achievement: 'goldenbiker',
            isNew: false,
            order: 14,
        },
        muertos: {
            title: 'Dia De Muertos',
            name: 'muertos',
            uri: 'muertos',
            description:
                'muertos_desc',
            image: 'muertos.webp',
            thumb: muertos_thumb,
            achievement: 'gravegambler',
            order: 15,
        },
        tokyorun: {
            title: 'Tokyo Run',
            name: 'tokyorun',
            uri: 'tokyorun',
            description:
                'tokio_desc',
            image: 'tokyorun.webp',
            thumb: tokyorun_thumb,
            achievement: 'heistclown',
            order: 17
        },
        lajam: {
            title: 'L.A. Jam',
            name: 'lajam',
            uri: 'lajam',
            description:
                'lajam_desc',
            image: 'lajam.webp',
            thumb: lajam_thumb,
            achievement: 'mcslotter',
            isNew: false,
            order: 18,
        },
        apachetrail: {
            title: 'Apache Trail',
            name: 'apachetrail',
            uri: 'apachetrail',
            description:
                'apache_desc',
            image: 'apachetrail.webp',
            achievement: 'grandchief',
            thumb: apachetrail_thumb,
            isSoon: false,
            isNew: false,
            order: 19,
        },
        hulaspin: {
            title: 'Hulaspin',
            name: 'hulaspin',
            uri: 'hulaspin',
            description:
                'hulaspin_desc',
            image: 'hulaspin.webp',
            achievement: 'hulaace',
            thumb: hulaspin_thumb,
            isSoon: false,
            isNew: false,
            order: 20,
        },
        copacabana: {
            title: 'Copacabana',
            name: 'copacabana',
            uri: 'copacabana',
            description:
                'copa_desc',
            image: 'copacabana.webp',
            achievement: 'carioca',
            thumb: copa_thumb,
            isSoon: false,
            isNew: false,
            order: 21,
        },
        imperialrome: {
            title: 'Imperialrome',
            name: 'imperialrome',
            uri: 'imperialrome',
            description:
                'imperialrome_desc',
            image: 'imperialrome.webp',
            achievement: 'caesar',
            thumb: imperialrome_thumb,
            isNew: false,
            order: 23,
        },
        ninjastrike: {
            title: 'Ninja Strike',
            name: 'ninjastrike',
            uri: 'ninjastrike',
            description: 'ninjastrike_desc',
            image: 'ninja_strike.webp',
            achievement: 'shadowwarrior',
            thumb: ninja_thumb,
            isCascading: false,
            order: 24,
            isNew: false,
        },
        sherlock: {
            title: 'Sherlock',
            name: 'sherlock',
            uri: 'sherlock',
            description:
                'sherlock_desc',
            image: 'sherlock.webp',
            achievement: '',
            thumb: sherlock_thumb,
            isSoon: true,
            isCascading: true,
            order: 22,
            testing: true
        },
        bollywood: {
            title: 'Bollywood',
            name: 'bollywood',
            uri: 'bollywood',
            description:
                'bollywood_desc',
            image: 'bollywood.webp',
            achievement: 'sacrebleuplayer',
            thumb: bollywood_thumb,                  //Change thumb when production
            isNew: false,
            testing: true,
            isSoon: true,
            order: 26,
        },
    },

    scratchCards: {
        panda: {
            title: 'Panda Party',
            name: 'panda',
            uri: 'panda',
            description:
                'scratch_high_vol_desc',
            image: 'panda.webp',
            thumb: 'panda.webp',
            cardPrice: 10,
            diamondWin: 10000,
            isNew: false,
            order: 1,
        },
        buffalo: {
            title: 'Buffalo Mania',
            name: 'buffalo',
            uri: 'buffalo',
            description:
                'scratch_high_vol_desc',
            image: 'buffalo.webp',
            thumb: 'buffalo.webp',
            cardPrice: 20,
            diamondWin: 20000,
            isNew: false,
            order: 2
        },
        hippo: {
            title: 'Hippo Rama',
            name: 'hippo',
            uri: 'hippo',
            description:
                'scratch_high_vol_desc',
            image: 'hippo.webp',
            thumb: 'hippo.webp',
            cardPrice: 50,
            diamondWin: 50000,
            isNew: false,
            order: 3
        },
        cheetah: {
            title: 'Cheetah Chasers',
            name: 'cheetah',
            uri: 'cheetah',
            description:
                'scratch_high_vol_desc',
            image: 'cheetah.webp',
            thumb: 'cheetah.webp',
            cardPrice: 100,
            diamondWin: 100000,
            isNew: false,
            order: 4
        },
        rhino: {
            title: 'Rhino Rampage',
            name: 'rhino',
            uri: 'rhino',
            description:
                'scratch_very_high_vol_desc',
            image: 'rhino.webp',
            thumb: 'rhino.webp',
            cardPrice: 200,
            diamondWin: 250000,
            isNew: false,
            order: 5
        },
        elephant: {
            title: 'Elephant Frenzy',
            name: 'elephant',
            uri: 'elephant',
            description:
                'scratch_very_high_vol_desc',
            image: 'elephant.webp',
            thumb: 'elephant.webp',
            cardPrice: 500,
            diamondWin: 500000,
            isNew: false,
            order: 6
        },
        lion: {
            title: 'Lion\'s Roar',
            name: 'lion',
            uri: 'lion',
            description:
                'scratch_very_high_vol_desc',
            image: 'lion.webp',
            thumb: 'lion.webp',
            cardPrice: 1000,
            diamondWin: 1000000,
            isNew: false,
            order: 7
        },
        tiger: {
            title: 'Tiger Strike',
            name: 'tiger',
            uri: 'tiger',
            description:
                'scratch_very_high_vol_desc',
            image: 'tiger.webp',
            thumb: 'tiger.webp',
            cardPrice: 5000,
            diamondWin: 2000000,
            isNew: false,
            order: 8
        },
        velociraptor: {
            title: 'Veloci-Win',
            name: 'velociraptor',
            uri: 'velociraptor',
            description:
                'scratch_extreme_vol_desc',
            image: 'velociraptor.webp',
            thumb: 'velociraptor.webp',
            cardPrice: 7000,
            diamondWin: 3000000,
            isNew: false,
            order: 9
        },
        spinosaurus: {
            title: 'Spino-Scratch',
            name: 'spinosaurus',
            uri: 'spinosaurus',
            description:
                'scratch_extreme_vol_desc',
            image: 'spinosaurus.webp',
            thumb: 'spinosaurus.webp',
            cardPrice: 10000,
            diamondWin: 4000000,
            isNew: false,
            order: 10
        },
        triceratops: {
            title: 'Tri-Win-Tops',
            name: 'triceratops',
            uri: 'triceratops',
            description:
                'scratch_extreme_vol_desc',
            image: 'triceratops.webp',
            thumb: 'triceratops.webp',
            cardPrice: 15000,
            diamondWin: 6000000,
            isNew: false,
            order: 11
        },
        stegosaurus: {
            title: 'Stego-Smash',
            name: 'stegosaurus',
            uri: 'stegosaurus',
            description:
                'scratch_extreme_vol_desc',
            image: 'stegosaurus.webp',
            thumb: 'stegosaurus.webp',
            cardPrice: 20000,
            diamondWin: 9000000,
            isNew: false,
            order: 12
        },
        ankylosaurus: {
            title: 'Ankylo-Gold',
            name: 'ankylosaurus',
            uri: 'ankylosaurus',
            description:
                'scratch_extreme_vol_desc',
            image: 'ankylosaurus.webp',
            thumb: 'ankylosaurus.webp',
            cardPrice: 25000,
            diamondWin: 11000000,
            isNew: false,
            order: 13
        },
        brontosaurus: {
            title: 'Bronto-Bucks',
            name: 'brontosaurus',
            uri: 'brontosaurus',
            description:
                'scratch_extreme_vol_desc',
            image: 'brontosaurus.webp',
            thumb: 'brontosaurus.webp',
            cardPrice: 30000,
            diamondWin: 15000000,
            isNew: false,
            order: 14
        },
        diplodocus: {
            title: 'Diplo-Diamond',
            name: 'diplodocus',
            uri: 'diplodocus',
            description:
                'scratch_extreme_vol_desc',
            image: 'diplodocus.webp',
            thumb: 'diplodocus.webp',
            cardPrice: 35000,
            diamondWin: 17000000,
            isNew: false,
            order: 15
        },
        trex: {
            title: 'T-Rex Riches',
            name: 'trex',
            uri: 'trex',
            description:
                'scratch_extreme_vol_desc',
            image: 'trex.webp',
            thumb: 'trex.webp',
            cardPrice: 50000,
            diamondWin: 20000000,
            isNew: false,
            order: 16
        },
    },

    crashGames: {
        rocket: {
            title: 'Rocket Blast',
            name: 'rocket',
            uri: 'rocket',
            description:
                'variable_vol_desc',
            image: 'rocket.webp',
            thumb: rocket_thumb,
            isNew: false,
            order: 1,
        },
        monument: {
            title: 'Monument Valley - PVP',
            name: 'monument',
            uri: 'monument',
            description:
                'variable_vol_desc',
            image: 'monument.webp',
            thumb: monument_thumb,
            isNew: true,
            order: 2
        },
    },

    instantGames: {
        mayan: {
            title: 'Mayan Treasure',
            name: 'mayan',
            uri: 'mayan',
            description:
                'variable_vol_desc',
            image: 'mayan.webp',
            thumb: mayan_thumb,
            isNew: false,
            order: 3,
        },
        plinko: {
            title: 'Plinko',
            name: 'plinko',
            uri: 'plinko',
            description:
                'variable_vol_desc',
            image: 'plinko.webp',
            thumb: plinko_thumb,
            order: 1,

        },
        penalty: {
            title: 'Penalty Worldcup',
            name: 'penalty',
            uri: 'penalty',
            description:
                'variable_vol_desc',
            image: 'penalty.webp',
            thumb: penalty_thumb,
            isNew: false,
            order: 2,
        },
    },

    spincity: {
        tycoon: {
            title: 'Spincity Tycoon',
            name: 'tycoon',
            uri: 'spincitytycoon',
            description:
                'variable_vol_desc',
            image: 'spincity-banner.webp',
            thumb: plinko_thumb,
            isNew: true,
            isSoon: true,
            order: 1,
            testing: true,
        },
    },
}

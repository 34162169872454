<template>
    <!--   Popup with Bronze/Silver/Gold card description   -->
    <div class="c-popup_vip"
         :style="{'top': 0*ss+'px','left':0*ss+'px',}"
    >
        <!--   Popup bg static VERTICAL  -->
        <div v-if="orient==='V'" class="c-popup_vip_2"
             :style="{'width': 1000*ss*xMult+'px','height': 'auto','gap': 20*ss*xMult+'px','border-radius':60*ss*xMult+'px',
                'border':6*ss*xMult+'px solid #DC8400','padding-top': 30*ss*xMult+'px','padding-bottom': 30*ss*xMult+'px',
                'background':getBg,'box-shadow':getShadow}"
        >
            <!--     Title      -->
            <div class="c-popup_vip_col"
                 :style="{'width': 700*ss*xMult+'px','height': 120*ss*xMult+'px',}"
            >
                <div class="c-popup_vip_col c-popup_vip_text" style="font-weight: 700;"
                     :style="{'max-width': 700*ss*xMult+'px','width': 'auto','height': 120*ss*xMult+'px',
                        'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                >
                    {{getTitle}}
                </div>
            </div>
            <!--     Perks list      -->
            <div v-for="item in getPerks"
                 class="c-popup_vip_row"
                 :style="{'width': 900*ss*xMult+'px','height': 90*ss*xMult+'px','gap': 20*ss*xMult+'px',
                    'border-radius': 50*ss*xMult+'px',}"
            >
                <img class="c-popup_vip_col"
                     :src="doneSvg" alt=""
                     :style="{'width': 70*ss*xMult+'px','height': 70*ss*xMult+'px','margin-left': 30*ss*xMult+'px',}"
                />
                <div class="c-popup_vip_col c-popup_vip_text" style="font-weight: 600;"
                     :style="{'max-width': 770*ss*xMult+'px','width': 'auto','height': 90*ss*xMult+'px',
                        'font-size': 40*ss*xMult+'px','line-height': 55*ss*xMult+'px',}"
                >
                    {{item}}
                </div>
            </div>

            <!--     Image of card with 2 gems      -->
            <div class="c-popup_vip_col" style="position: relative;"
                 :style="{'width': 1000*ss*xMult+'px','height': 410*ss*xMult+'px','margin-bottom': 10*ss*xMult+'px','margin-top': 20*ss*xMult+'px',}"
            >
                <img class="c-popup_vip_col" style="position: absolute; transform: rotate(-30deg);"
                     :src="gemSvg" alt=""
                     :style="{'width': 200*ss*xMult+'px','height': 'auto','top': 170*ss*xMult+'px','left': 40*ss*xMult+'px',}"
                />
                <img class="c-popup_vip_col" style="position: absolute; transform: rotate(30deg);"
                     :src="gemSvg" alt=""
                     :style="{'width': 200*ss*xMult+'px','height': 'auto','top': 170*ss*xMult+'px','right': 40*ss*xMult+'px',}"
                />
                    <!--     Image of card       -->
                <img class="c-popup_vip_col" style="position: relative;"
                     :src="getImg" alt=""
                     :style="{'width': 700*ss*xMult+'px','height': 410*ss*xMult+'px',}"
                />
            </div>

            <!--   X button   -->
            <div style="position: absolute"
                 :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
            >
                <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                           :bg-width="72*ss*xMult"
                           :bg-height="72*ss*xMult"
                           :callback="btn_xClick" :callback-wait="true"
                />
            </div>
        </div>

        <!--   Popup bg static HORIZONTAL  -->
        <div v-if="orient==='H'" class="c-popup_vip_2" style="flex-wrap: wrap;"
             :style="{'width': 1700*ss*xMult+'px','height': 'auto','gap': 20*ss*xMult+'px','border-radius':60*ss*xMult+'px',
                'border':6*ss*xMult+'px solid #DC8400','padding-top': 30*ss*xMult+'px','padding-bottom': 30*ss*xMult+'px',
                'background':getBg,'box-shadow':getShadow}"
        >
            <!--     Title      -->
            <div class="c-popup_vip_col"
                 :style="{'width': 1700*ss*xMult+'px','height': 80*ss*xMult+'px',}"
            >
                <div class="c-popup_vip_col c-popup_vip_text" style="font-weight: 700;"
                     :style="{'max-width': 1600*ss*xMult+'px','width': 'auto','height': 80*ss*xMult+'px',
                        'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                >
                    {{getTitle}}
                </div>
            </div>

            <div class="c-popup_vip_3"
                 :style="{'width': 1700*ss*xMult+'px','height': 'auto','gap': 20*ss*xMult+'px',
                         'padding-top': 0*ss*xMult+'px','padding-bottom': 0*ss*xMult+'px',}"
            >
                <!--     Perks list      -->
                <div v-for="item in getPerks"
                     class="c-popup_vip_row"
                     :style="{'width': 700*ss*xMult+'px','height': 90*ss*xMult+'px','gap': 20*ss*xMult+'px',
                        'border-radius': 50*ss*xMult+'px',}"
                >
                    <img class="c-popup_vip_col"
                         :src="doneSvg" alt=""
                         :style="{'width': 70*ss*xMult+'px','height': 70*ss*xMult+'px','margin-left': 30*ss*xMult+'px',}"
                    />
                    <div class="c-popup_vip_col c-popup_vip_text" style="font-weight: 600;"
                         :style="{'max-width': 770*ss*xMult+'px','width': 'auto','height': 90*ss*xMult+'px',
                            'font-size': 40*ss*xMult+'px','line-height': 55*ss*xMult+'px',}"
                    >
                        {{item}}
                    </div>
                </div>
            </div>

            <!--     Image of card with 2 gems      -->
            <div class="c-popup_vip_col" style="position: relative;"
                 :style="{'width': 1000*ss*xMult+'px','height': 350*ss*xMult+'px','margin-bottom': 10*ss*xMult+'px','margin-top': 20*ss*xMult+'px',}"
            >
                <img class="c-popup_vip_col" style="position: absolute; transform: rotate(-30deg);"
                     :src="gemSvg" alt=""
                     :style="{'width': 170*ss*xMult+'px','height': 'auto','top': 150*ss*xMult+'px','left': 30*ss*xMult+'px',}"
                />
                <img class="c-popup_vip_col" style="position: absolute; transform: rotate(30deg);"
                     :src="gemSvg" alt=""
                     :style="{'width': 170*ss*xMult+'px','height': 'auto','top': 150*ss*xMult+'px','right': 30*ss*xMult+'px',}"
                />
                <!--     Image of card       -->
                <img class="c-popup_vip_col" style="position: relative;"
                     :src="getImg" alt=""
                     :style="{'width': 600*ss*xMult+'px','height': 350*ss*xMult+'px',}"
                />
            </div>

            <!--   X button   -->
            <div style="position: absolute"
                 :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
            >
                <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                           :bg-width="72*ss*xMult"
                           :bg-height="72*ss*xMult"
                           :callback="btn_xClick" :callback-wait="true"
                />
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import gemSvg from "@/assets/svg/icon_gem2.svg";
    import doneSvg from "@/assets/svg/shop/done.svg";
    import {monthlyCards} from "@/classes/Constants";

    export default defineComponent({
        name: 'PopupVip',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","messages","popupMessage","orient"]),

            getBg(){
                let txt='linear-gradient(95deg, rgb(255, 200, 60) -9.98%, rgb(220, 132, 0) 118.99%)';
                return txt;
            },
            getImg(){
                let txt;
                const param=this.$store.state.popupVip;
                txt=Utils.getGlobalLink(monthlyCards[param.vipType].img);
                txt=txt?txt:'';
                return txt;
            },
            getPerks(){
                const param=this.$store.state.popupVip;
                const perks=this.$store.state.messages.perks[param.vipType];
                return perks;
            },
            getTitle(){
                const param=this.$store.state.popupVip;
                let txt=monthlyCards[param.vipType].name;
                txt=txt?txt:'';
                return txt;
            },
            getShadow(){
                let txt='rgba(255, 229, 0, 0.4) 0px 0px 60px 0px, rgba(255, 255, 255, 0.3) 0px 0px 100px 0px inset';

                return txt;
            },

        },
        components: {ButtonSvg,ButtonSvgOval,},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),gemSvg:Utils.getGlobalLink(gemSvg),doneSvg:Utils.getGlobalLink(doneSvg),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btn_xClick(){
                const param=this.$store.state.popupVip;
                param.visible=false;
            },
            clickConfirm(){
                const param=this.$store.state.popupVip;
                param.visible=false;
            }
        },
    });
</script>

<style lang="css" scoped>

    .c-popup_vip{
        display: flex;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0;
        margin:0;
        width: 100vw;
        height: 100vh;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;
        backdrop-filter: blur(5px);
        background: linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8));

    }

    .c-popup_vip_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-popup_vip_3{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: visible;
    }

    .c-popup_vip_row{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-popup_vip_col{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-popup_vip_text{
        font-family: Rubik;
        font-style: normal;
        overflow: hidden;
        color: #FFFFFF;
        text-align: -webkit-center;

        /*word-wrap: break-word;/**/
        /*background: rgba(0, 0, 0, 0.3);/**/
    }


</style>

export default {
    //width="80%" height="80%" style="position: absolute;"
    menuBtn: `
<svg width="100%" height="100%" fill="none" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="url(#a)"/>
<path d="m28 25.5c0.3852 2e-4 0.7556 0.1486 1.0344 0.4144s0.4446 0.6287 0.4631 1.0135-0.1117 0.7619-0.3637 1.0533-0.6064 0.4746-0.9898 0.5118l-0.144 7e-3h-16c-0.3852-2e-4 -0.7556-0.1486-1.0344-0.4144s-0.4446-0.6287-0.4631-1.0135 0.1117-0.7619 0.3637-1.0533 0.6064-0.4746 0.9898-0.5118l0.144-7e-3h16zm0-7c0.3978 0 0.7794 0.158 1.0607 0.4393s0.4393 0.6629 0.4393 1.0607-0.158 0.7794-0.4393 1.0607-0.6629 0.4393-1.0607 0.4393h-16c-0.3978 0-0.7794-0.158-1.0607-0.4393s-0.4393-0.6629-0.4393-1.0607 0.158-0.7794 0.4393-1.0607 0.6629-0.4393 1.0607-0.4393h16zm0-7c0.3978 0 0.7794 0.158 1.0607 0.4393s0.4393 0.6629 0.4393 1.0607-0.158 0.7794-0.4393 1.0607-0.6629 0.4393-1.0607 0.4393h-16c-0.3978 0-0.7794-0.158-1.0607-0.4393s-0.4393-0.6629-0.4393-1.0607 0.158-0.7794 0.4393-1.0607 0.6629-0.4393 1.0607-0.4393h16z" fill="#fff"/>
<defs>
<linearGradient id="a" x1=".11561" x2="44.985" y1="1.6289e-5" y2="3.0747" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C6FB" offset="0"/>
<stop stop-color="#005BEA" offset="1"/>
</linearGradient>
</defs>
</svg>
`,
}

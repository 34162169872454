<template>
    <!--Info how to play games, shop, etc + About us-->
    <div class="c-info_bg"
         :style="{'width': realWidth-0*ss*xMult+'px','height': realHeight-(orient==='V'?300:190)*ss*xMult+'px',}">
        <!--static container of scroll-->
        <div class="c-info_static"
             :style="{'width': realWidth-0*ss*xMult+'px','height': realHeight-(orient==='V'?300:190)*ss*xMult+'px',}">

            <!--main scroll with dynamic height -->
            <div class="c-info_scroll"
                 :style="{'width': realWidth-0*ss*xMult+'px','height': 'auto','gap': 0*ss*xMult+'px',
                                'padding-top': 0*ss*xMult+'px','padding-bottom': 0*ss*xMult+'px',}"
            >
                <PageInfoInfo/>
                <PageInfoAbout/>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { defineComponent } from 'vue';
    import PageInfoInfo from "@/vue/pages/components/PageInfoInfo";
    import PageInfoAbout from "@/vue/pages/components/PageInfoAbout";

    export default defineComponent({
    //export default {
        name: "PageInfo",
        components: {PageInfoInfo,PageInfoAbout},
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","messages","orient","xMult"]),

        },
        data: function () {
            return {

            }
        },
        created() {

        },
        mounted() {

        },
        beforeUnmount() {

        },

        methods:{

        },
    });

</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-info_bg{
        display: flex;
        overflow: -moz-scrollbars-none;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding:0;
        margin:0;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }
    .c-info_static{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0px;
        flex: none;
        flex-grow: 0;
        overflow: hidden;

        /*background: rgba(255, 255, 255, 0.2);/**/
    }

    .c-info_scroll{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;
    }


</style>

<template>
    <!--   Element for Achievements Statistics, props: img, title, msg, prizeMsg, prizeVal, prizeActive     -->
    <div class="c-app_ccs" style="background: hsla(0,0%,100%,.1);"
         :style="{'width': 1000*ss*xMult-16+'px','height': 240*ss*xMult+'px','gap': 0*ss*xMult+'px','border-radius': 50*ss*xMult+'px',}"
    >

        <!-- Image, title description   -->
        <div class="c-app_rsc"
             :style="{'width': 'auto','height': 120*ss*xMult+'px','margin-left': 40*ss*xMult+'px','gap': 30*ss*xMult+'px',}">
            <img :src="img" alt=""
                 :style="{'width': 100*ss*xMult+'px','height': 100*ss*xMult+'px',}"/>

            <div class="c-app_ccs"
                 :style="{'width': 'auto','height': 120*ss*xMult+'px',}">
                <!--  All time CHIPS earned text -->
                <div class="c-achiev_elem_text c-app_ccc"
                     :style="{'width': 'auto','height': 60*ss*xMult+'px','font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',}">
                    {{title}}
                </div>
                <div class="c-achiev_elem_text c-app_ccc" style="opacity: 0.8;"
                     :style="{'width': 'auto','height': 60*ss*xMult+'px','font-size': 40*ss*xMult+'px','line-height': 60*ss*xMult+'px',}">
                    {{msg}}
                </div>
            </div>
        </div>

        <!-- Date / progress, progressbar, chips prize   -->
        <div class="c-app_rsc"
             :style="{'width': 940*ss*xMult-16+'px','height': 100*ss*xMult+'px','margin-left': 40*ss*xMult+'px','gap': 30*ss*xMult+'px',}">
            <div class="c-app_ccs"
                 :style="{'width': 455*ss*xMult-8+'px','height': 100*ss*xMult+'px',}">
                <div v-if="percentVisible" class="c-achiev_elem_text c-app_ccc"
                     :style="{'width': 'auto','height': 60*ss*xMult+'px','font-size': 40*ss*xMult+'px','line-height': 60*ss*xMult+'px',}">
                    {{prizeMsg}}
                </div>
                <!-- progress bar-->
                <div v-if="percentVisible" class="c-app_ccs"
                     :style="{'width': '100%','height': 30*ss*xMult+'px',}">
                    <svg xmlns="http://www.w3.org/2000/svg" :width="'235'" height="4" :viewBox="'0 0 235 4'" fill="none"
                         style="position: absolute;"
                    >
                        <rect :width="'235'" height="4" rx="2" fill="white" fill-opacity="0.1"/>
                    </svg>

                    <!-- Progress Fill -->
                    <svg xmlns="http://www.w3.org/2000/svg" :width="prizePercent+'%'" height="4" :viewBox="'0 0 235 4'" fill="none"
                         style="position: absolute;"
                    >
                        <rect :width="'100%'" height="4" rx="2" fill="url(#paint0_linear_6338_5814)"/>
                        <defs>
                            <linearGradient id="paint0_linear_6338_5814" x1="-27.4038" y1="-1.4" x2="-11.1079" y2="68.8761" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#0CFF7C"/>
                                <stop offset="1" stop-color="#00AF90"/>
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>

            <!--  chips prize -->
            <div class="c-app_rbc" style="background: linear-gradient(94deg, rgb(0, 198, 251) 0.27%, rgb(0, 91, 234) 105.74%);"
                 :style="{'width': 445*ss*xMult-8+'px','height': 70*ss*xMult+'px','border-radius': 40*ss*xMult+'px',
                    'opacity': prizeActive?'1':'0.2',}">
                <div class="c-achiev_elem_text c-app_ccc"
                     :style="{'width': 'auto','height': 60*ss*xMult+'px','font-size': 40*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-left': 20*ss*xMult+'px',}">
                    {{messages.m050_03}}
                </div>
                <div class="c-app_rbc"
                     :style="{'width': 'auto','height': 60*ss*xMult+'px','margin-right': 20*ss*xMult+'px','gap': 10*ss*xMult+'px',}">
                    <div class="c-achiev_elem_text c-app_ccc"
                         :style="{'width': 'auto','height': 60*ss*xMult+'px','font-size': 40*ss*xMult+'px','line-height': 60*ss*xMult+'px',}">
                        {{prizeVal}}
                    </div>
                    <img :src="img_chips" alt=""
                         :style="{'width': 50*ss*xMult+'px','height': 50*ss*xMult+'px',}"/>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import img_chips from "@/assets/svg/chips";

    export default defineComponent({
        name: 'AchievElem',
        components: {},
        computed: {
            ...mapGetters(["ss","xMult","messages"]),
        },
        data(){
            return {
                img_chips:Utils.getGlobalLink(img_chips),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {

        },

        props: {
            img: {
                type: String,
                default: ""
            },
            title: {
                type: String,
                default: ""
            },
            msg: {
                type: String,
                default: ""
            },
            prizeMsg: {
                type: String,
                default: ""
            },
            prizeVal: {
                type: String,
                default: ''
            },
            prizePercent: {
                type: Number,
                default: 0
            },
            percentVisible: {
                type: Boolean,
                default: true
            },
            prizeActive: {
                type: Boolean,
                default: true
            },
        },

    });
</script>

<style lang="css" scoped>
    .c-achiev_elem_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        color: #FFFFFF;
        text-align: -webkit-center;
        white-space: nowrap;
    }

</style>

<template>
    <!--     Winner elem in Raffle prizes (player icon, nickname, claim/unclaimed )        -->
    <div class="c-winner_elem unselectable" style="border-bottom: 2px solid #19c9f3;"
         :style="{'width': 930*ss*xMult+'px','height': 150*ss*xMult+'px','gap': 10*ss*xMult+'px','margin-left': 20*ss*xMult+'px'}"
    >
        <!--    Avatar      -->
        <div class="c-winner_elem_col"
             :style="{'width': 150*ss*xMult+'px','height': 150*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
        >
            <img v-if="item.avatar" :src="/\/\d$/.test(item.avatar)?item.avatar+'.webp':item.avatar"
                 alt=""
                 class="c-winner_elem_img"
                 :style="{'width': 130*ss*xMult+'px','height': 130*ss*xMult+'px','margin-top': 0*ss*xMult+'px','border-radius': 100*ss*xMult+'px',}"
            />
        </div>

        <!--    Nickname      -->
        <div class="c-winner_elem_col"
             :style="{'width': 460*ss*xMult+'px','height': 150*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
        >
            <div class="c-winner_elem_row c-winner_elem_text" style="color: white"
                 :style="{'width': 460*ss*xMult+'px','height': 60*ss*xMult+'px',
                    'font-size': 40*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
            >
                {{item.username}}
            </div>
            <div class="c-winner_elem_row c-winner_elem_text" style="color: white; opacity: 0.7;"
                 :style="{'width': 460*ss*xMult+'px','height': 60*ss*xMult+'px',
                    'font-size': 35*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
            >
                {{item.name+' | '+item.collection}}
            </div>
        </div>

        <!--    Claimed/unclaimed      -->
        <div class="c-winner_elem_col"
             :style="{'width': 300*ss*xMult+'px','height': 150*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
        >
            <div v-if="item.claimed===true" class="c-winner_elem_row"
                 :style="{'width': 300*ss*xMult+'px','height': 60*ss*xMult+'px',}"
                 @click="openTxLink(item)"
            >
                <div class="c-winner_elem_row c-winner_elem_text" style="color: white"
                     :style="{'width': 250*ss*xMult+'px','height': 60*ss*xMult+'px',
                    'font-size': 40*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                >
                    {{messages.m030_18}}
                </div>
                <div v-if="item.type==='nft'" class="c-winner_elem_img" style="justify-content: flex-start;"
                     :style="{'width': 50*ss*xMult+'px','height': 60*ss*xMult+'px',}">
                    <img class="c-winner_elem_img"
                         :src="linkSvg" alt=""
                         :style="{'width': 45*ss*xMult+'px','height': 45*ss*xMult+'px',}"
                    />
                </div>

            </div>
            <div v-else class="c-winner_elem_row c-winner_elem_text" style="color: white"
                 :style="{'width': 300*ss*xMult+'px','height': 60*ss*xMult+'px',
                    'font-size': 40*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
            >
                {{messages.m030_19}}
            </div>

            <div class="c-winner_elem_row c-winner_elem_text" style="color: white; opacity: 0.7;"
                 :style="{'width': 300*ss*xMult+'px','height': 60*ss*xMult+'px',
                    'font-size': 35*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
            >
                {{getDate(item.timestamp)}}
            </div>

        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import linkSvg from "@/assets/svg/tickets/out_link.svg";
    import Utils from "@/scripts/Utils";



    export default defineComponent({
        name: 'WinnerElement',
        components: {},
        computed: {
            ...mapGetters(["ss","xMult","messages",]),

        },
        data(){
            return {
                linkSvg:Utils.getGlobalLink(linkSvg)
            };
        },
        created() {

            //console.log(this.clickScale)
        },
        mounted() {
        },
        methods: {
            openTxLink(item){
                this.$store.dispatch('openTxLink',item);
            },
            getDate(timestamp) {
                let date = new Date(timestamp);
                const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
                return months[date.getMonth()] + " " + (date.getDate()) + ", " + date.getFullYear();
            },

        },
        props:{
            item:{
                type: Object,
                default:{},
            }
            /*
            {
                "timestamp":1703376000000,
                "type":"nft",
                "winner":"648737691fe3f1f41a2d8dcc",
                "claimed":true,
                "name":"Cocochoco",
                "chain":"RON",
                "contract":"0x737b80335a9396a8658405d7adcbc57343ff0558",
                "nftId":"1",
                "collection":"ACI",
                "tokenStandard":"ERC1155",
                "img":"https://cdn.axieinfinity.com/marketplace-website/consumables/1.png",
                "avatar":"https://cdn.discordapp.com/avatars/483890519924277261/4d7368636beaa5127eead5f386b6ba56",
                "username":"in3briate",
                "txId":"0x8cf354c6c44b38e99f5f8467442b12b3b92fe36001df73ab663fd7b1db7a02ec"
             }
             */
        }
    });

</script>

<style lang="css" scoped>

    .c-winner_elem{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }


    .c-winner_elem_col{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }


    .c-winner_elem_row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: visible;

        /*background: rgba(255, 255, 255, 0.3);/**/
    }

    .c-winner_elem_img{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-winner_elem_text{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        text-align: -webkit-left;
        white-space: nowrap;

        /*background: rgba(255, 255, 255, 0.3);/**/
    }

</style>

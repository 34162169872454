<template>
    <!--   Chips    -->
    <div class="c-chips"
         :style="{'width': 485*ss*xMult+'px','height': 400*ss*xMult+'px','border-radius': 40*ss*xMult+'px','gap': 35*ss*xMult+'px',}"
    >
        <img class="c-chips_img" alt=""
             :style="{'width': 67*3*ss*xMult+'px','height': 82*3*ss*xMult+'px','top': 0*ss*xMult+'px','right': 0*ss*xMult+'px',}"
             :src="chips1Svg"
        />
        <img class="c-chips_img" alt=""
             :style="{'width': 307*0.8*ss*xMult+'px','height': 400*0.8*ss*xMult+'px','bottom': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
             :src="chips2Svg"
        />

        <div class="c-user_info_title_text"
             :style="{'width': 485*ss*xMult+'px','height': 80*ss*xMult+'px','border-radius': 40*ss*xMult+'px',
                'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-top': 20*ss*xMult+'px',
                'text-shadow': '0 0 '+15*ss*xMult+'px rgba(57,231,156,.6)',}"
        >
            {{messages.m022_1}}
        </div>

        <div class="c-chips_line"
             :style="{'width': 485*ss*xMult+'px','height': 80*ss*xMult+'px','margin-top': 30*ss*xMult+'px',}"
        >
            <div class="c-chips_text"
                 :style="{'width': 'auto','height': 80*ss*xMult+'px','border-radius': 40*ss*xMult+'px',
                    'font-size': 60*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-right': 20*ss*xMult+'px',
                    'text-shadow': '0 0 '+45*ss*xMult+'px hsla(0,0%,100%,0.3)',}"
            >
                {{userData.chips?userData.chips.toLocaleString():'0'}}
            </div>
        </div>

        <!--       Get more         -->
        <div class="c-chips_line"
             :style="{'width': 465*ss*xMult+'px','height': btnRad*0.6*xMult+'px','margin-top': 0*ss*xMult+'px','margin-right': 20*ss*xMult+'px',}"
        >
            <ButtonSvgOval2 :svg-normal="iconPlusSvg"
                           :btn-text="messages.m023"
                           :bg-width="300*ss*xMult" :bg-height="btnRad*0.6*xMult"
                           :font-size="btnRad*0.6*0.45*xMult"
                           :max-width="(500*ss-btnRad/2-70*ss)*xMult"
                           :svg-width="btnRad*0.6/2*xMult" :svg-height="btnRad*0.6/2*xMult"
                           :background-val="'linear-gradient(95.19deg,rgba(12,255,124,0) -16.14%,#00af90 237.96%) var(--x,0)/300%'"
                           :border-val="'2px solid'"
                           :border-color="'#0cff7c'"
                           :callback="btnGetClick" :callback-wait="true"
                           :click-scale="0.95"
            />
        </div>

    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import ButtonSvgOval2 from "@/vue/elements/ButtonSvgOval2";
    import PopupInfo from "@/vue/elements/PopupInfo";
    import chips1Svg from "@/assets/svg/icon_chips_1.svg";
    import chips2Svg from "@/assets/svg/icon_chips_2.svg";
    import Utils from "@/scripts/Utils";
    import iconPlusSvg from "@/assets/svg/icon_plus_green.svg";

    export default defineComponent({
        name: 'Chips',
        components: {ButtonSvgOval2, PopupInfo,},
        computed: {
            ...mapGetters(["ss","xMult","messages","btnRad","userData"]),

        },
        data(){
            return {
                chips1Svg:Utils.getGlobalLink(chips1Svg),chips2Svg:Utils.getGlobalLink(chips2Svg),iconPlusSvg:Utils.getGlobalLink(iconPlusSvg),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btnGetClick(){
                this.$store.state.popupVisible.chipsClaim=true;
            },

        },

    });
</script>

<style lang="css" scoped>
    .c-chips{
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: flex-start;
        align-items: center;

        background: hsla(0,0%,100%,.1);
    }

    .c-chips_text{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        color: #FFFFFF;
        text-align: -webkit-center;
        white-space: nowrap;
    }

    .c-chips_line{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

    }

    .c-chips_img{
        display: flex;
        position: absolute;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

</style>

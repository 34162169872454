<template>
    <div class="c-iframe"
         :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
        <iframe allow="clipboard-read; clipboard-write"
                v-if="crashGames[gameName]"
                id="mainframe"
                class="c-iframe_bg"
                :style="{'width': realWidth+'px','height': realHeight+'px',}"
                :src="gameUri"
        ></iframe>

    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import GameList from "@/scripts/GameList";
    import Backend from '@/classes/backend.js';
    import Config from "@/scripts/Config";
    import Utils from "@/scripts/Utils";
    import ButtonSvgIn from "@/vue/elements/ButtonSvgIn";
    import SvgImports from "@/classes/SvgImports";

    export default {
        name: "PageCrash",
        components: {ButtonSvgIn},
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","getLeft","btnRad",]),
            gameUri(){
                const gameUri = (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? 'https://test.blockspingaming.com/' : 'https://blockspingaming.com/';
                let uri='';
                uri=gameUri+GameList.crashGames[this.gameName].uri;
                return uri;
            },
            gameName() {
                return this.$route.params.gameId.split("-").join("");
            },
        },
        created() {
            MyLog('game page created');
            window.addEventListener("message", this.receiveMessage, false);

            let path='/crash/'+this.gameName;
            localStorage.setItem("path",path);
        },
        beforeUnmount() {
            window.removeEventListener("message", this.receiveMessage);
        },

        data: function () {
            return {
                crashGames: GameList.crashGames,
                nextUser: {},
                isMobile: false,
                SvgImports:SvgImports,
            }
        },
        methods:{
            async receiveMessage(message) {
                if (typeof message.data !== "string") {
                    return;
                }
                const data = JSON.parse(message.data);
                switch (data.action) {
                    case "initcrash": {
                        this.gameWindow = message.source;
                        if (this.$store.state.loginData) {
                            let initCrashData = {
                                action: 'initcrash',
                                loginData: this.$store.state.loginData,
                                env: Utils.getEnv(),
                                sound: this.$store.getters.getSound,
                                chat: true,
                                betsPossible: this.$store.getters.getBetsPossible
                            }
                            try {
                                let tokenPrizes = this.$store.getters.blockspinState.leaderboardTokenPrizes;
                                if (tokenPrizes.hourly.crash || tokenPrizes.daily.crash || tokenPrizes.weekly.crash) {
                                    let maxBet = 1000;
                                    initCrashData.betsPossible = initCrashData.betsPossible.filter(bet => bet <= maxBet);
                                }
                            }
                            catch (e) {
                                console.log('PageCrash.receiveMessage tokenPrizes catch: ',e.message,'\nstack: ',e.stack)
                            }
                            if (data.game === 'monument') {
                                initCrashData.betsPossible = [500];
                            }
                            message.source.postMessage(JSON.stringify(initCrashData), "*");
                        } else {
                            this.initOnLogin = true;
                        }
                        break;
                    }
                    case "makebet": {
                        let bet = data.bet;
                        let chips = data.chips;
                        this.$store.state.userData.chips=chips;
                        this.$store.state.updateUserLevel();
                        break;
                    }
                    case "cancelbet": {
                        let bet = data.bet;
                        this.$store.state.userData.chips+=bet;
                        this.$store.state.updateUserLevel();
                        break;
                    }
                    case "finishbet": {
                        let user = data.user;
                        let newBet = data.newBet;

                        this.$store.commit("setPostSpinUserData", user);
                        this.$store.commit("setNewBet", newBet);

                        if (this.$store.getters.getIsGuest) {
                            this.$store.state.userData.numBets+=1;
                        }
                        this.$store.state.updateUserLevel();
                        break;
                    }
                    case "withdrawtoken": {
                        let tokenBalance = data.tokenBalance;
                        let address = data.address;
                        let withdraw = await this.$store.dispatch("withdrawToken", {
                            chain: tokenBalance.chain,
                            nativeToken: tokenBalance.nativeToken,
                            tokenAddress: tokenBalance.tokenAddress,
                            tokenSymbol: tokenBalance.tokenSymbol,
                            address,
                        });

                        let withdrawData = {
                            action: "withdrawtoken",
                            success: withdraw.success,
                            error: withdraw.error,
                            txId: withdraw.txId
                        }
                        message.source.postMessage(JSON.stringify(withdrawData), "*");
                        break;
                    }
                    case "home": {
                        this.$router.push({path:Config.defaultPath,query:this.$route.query});
                        break;
                    }
                    case "soundOn": {
                        this.$store.commit("setSound", true);
                        break;
                    }
                    case "soundOff": {
                        this.$store.commit("setSound", false);
                        break;
                    }
                }

            },
        },
    }

</script>

<style lang="css" scoped>


</style>

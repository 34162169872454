<template>
    <div class="c-iframe"
         :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
        <iframe
                v-if="instantGames[gameName]"
                id="mainframe"
                class="c-iframe_bg"
                :style="{'width': realWidth+'px','height': realHeight+'px',}"
                :src="gameUri"
        ></iframe>

    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import GameList from "../../scripts/GameList";
    import Backend from '../../classes/backend.js';
    import Config from "../../scripts/Config";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import menuBtnSvg from "@/assets/svg/menu_btn.svg";
    import Utils from "@/scripts/Utils";

    export default {
        name: "PageInstant",
        components: {ButtonSvg},
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","getLeft","btnRad"]),
            gameUri(){
                const gameUri = (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? 'https://test.blockspingaming.com/' : 'https://blockspingaming.com/';
                let uri='';
                uri=gameUri+GameList.instantGames[this.gameName].uri;
                return uri;
            },
            gameName() {
                return this.$route.params.gameId.split("-").join("");
            },
            chips() {
                return this.$store.state.userData.chips;
            },
        },
        created() {
            MyLog('game page created');
            window.addEventListener("message", this.receiveMessage, false);

            let path='/instant/'+this.gameName;
            localStorage.setItem("path",path);
        },
        beforeUnmount() {
            window.removeEventListener("message", this.receiveMessage);
        },

        data: function () {
            return {
                instantGames: GameList.instantGames,
                nextUser: {},
                isMobile: true,
                nextChips: [],
                menuBtnSvg:Utils.getGlobalLink(menuBtnSvg),
            }
        },
        methods:{
            async receiveMessage(message) {
                if (typeof message.data !== "string") {
                    return;
                }
                const data = JSON.parse(message.data);
                console.log(data);
                switch (data.action) {
                    case "init": {
                        this.gameWindow = message.source;
                        if (this.$store.state.loginData) {
                            let initData = await Backend.initInstant(
                                this.instantGames[this.$route.params.gameId].name,
                                this.$store.state.loginData
                            );
                            this.myTeam = initData.myTeam;
                            if (initData.error) {
                                alert(initData.error);
                            } else {
                                initData["sound"] = this.$store.getters.getSound;
                                message.source.postMessage(JSON.stringify(initData), "*");
                                if (initData.balance) {
                                    this.$store.state.userData.chips=initData.balance;
                                }
                                if (initData.jackpot) {
                                    this.$store.state.blockspinState.jackpot=initData.jackpot;
                                }
                            }
                        } else {
                            this.initOnLogin = true;
                        }
                        break;
                    }
                    case "bet": {
                        if(!this.$store.getters.getIsGuestAndOutOfBets) {
                            this.gameWindow = message.source;
                            let bet = data.bet;
                            let lines = data.lines;
                            let difficulty = data.difficulty;
                            let choice = data.choice;

                            if(this.gameName !== 'mayan' || data.minesweeper === null) {
                                this.$store.state.userData.chips -= bet;
                            }

                            let betData = await Backend.betInstant(
                                this.instantGames[this.$route.params.gameId].name,
                                bet,
                                lines,
                                difficulty,
                                choice,
                                this.$store.state.loginData
                            );
                            this.myTeam = betData.myTeam;
                            betData.action = "bet";
                            message.source.postMessage(JSON.stringify(betData), "*");

                            if(this.gameName === 'mayan' && betData.win > 0) {
                                let collectData = {
                                    action: 'collect',
                                    win: betData.win,
                                    multiplier: betData.multiplier,
                                    balance: betData.balance,
                                    game: this.gameName,
                                    success: true
                                }
                                message.source.postMessage(JSON.stringify(collectData), "*");
                            }

                            this.$store.state.userData.gems=betData.gems;
                            this.$store.commit("setExperience", betData.experience);
                            this.nextChips.unshift(betData.win);

                            if(this.$store.getters.getIsGuest) {
                                this.$store.state.userData.numBets+=1;
                            }

                        } else {
                            this.$store.state.userData.numBets+=1;
                        }
                        this.$store.state.updateUserLevel();
                        break;
                    }
                    case "joinTeam": {
                        if(!this.$store.getters.getIsGuestAndOutOfBets) {
                            this.gameWindow = message.source;
                            let team = data.team;

                            let joinTeamData = await Backend.joinTeam(
                                this.instantGames[this.$route.params.gameId].name,
                                team,
                                this.$store.state.loginData
                            );

                            if(joinTeamData.success) {
                                this.$store.state.userData.chips=joinTeamData.balance;
                            }

                            joinTeamData.action = "joinTeam";
                            message.source.postMessage(JSON.stringify(joinTeamData), "*");
                        }

                        break;
                    }
                    case "shoot": {
                        if(!this.$store.getters.getIsGuestAndOutOfBets) {
                            this.gameWindow = message.source;
                            let bet = data.bet;

                            let numGoals = data.numGoals;
                            if(!numGoals) {
                                this.$store.state.userData.chips-=bet;
                            }

                            let betData = await Backend.betInstant(
                                this.instantGames[this.$route.params.gameId].name,
                                bet,
                                null,
                                null,
                                null,
                                this.$store.state.loginData
                            );

                            if(betData.win) {
                                this.$store.state.userData.chips=betData.balance;
                            }

                            betData.action = "shoot";
                            message.source.postMessage(JSON.stringify(betData), "*");
                            this.$store.state.userData.gems=betData.gems;
                            this.$store.commit("setExperience", betData.experience);
                        }
                        this.$store.state.updateUserLevel();
                        break;
                    }
                    case "collect": {
                        if(!this.$store.getters.getIsGuestAndOutOfBets) {
                            this.gameWindow = message.source;

                            let collectData = await Backend.collect(
                                this.instantGames[this.$route.params.gameId].name,
                                this.$store.state.loginData
                            );

                            if(collectData.success) {
                                this.$store.state.userData.chips=collectData.balance;
                            }

                            collectData.action = "collect";
                            message.source.postMessage(JSON.stringify(collectData), "*");
                        }
                        this.$store.state.updateUserLevel();
                        break;
                    }
                    case 'getLeaderboard': {
                        let leaderboard = this.$store.state.leaderboard.penalty;

                        // has refreshed since last time getLeaderboard was called
                        if(leaderboard.current.hourly) {

                            delete leaderboard.current.hourly;
                            delete leaderboard.previous.hourly;
                            leaderboard.current.daily.prizePool = this.$store.state.blockspinState.leaderboardPrizeDaily.penalty;
                            leaderboard.previous.daily.prizePool = this.$store.state.blockspinState.leaderboardPrizeDaily.penalty;
                            leaderboard.current.weekly.prizePool = this.$store.state.blockspinState.leaderboardPrizeWeekly.penalty;
                            leaderboard.previous.weekly.prizePool = this.$store.state.blockspinState.leaderboardPrizeWeekly.penalty;
                            let teamsCurrent = [], teamsPrevious = [];
                            for(let user of leaderboard.current.weekly.users) {
                                if(!teamsCurrent.length || (teamsCurrent[teamsCurrent.length-1].team !== user.team)) {
                                    teamsCurrent.push({team: user.team, points: 0, prize: 0});
                                }
                                teamsCurrent[teamsCurrent.length-1].points += user.points;
                                teamsCurrent[teamsCurrent.length-1].prize += user.prize;
                            }
                            for(let user of leaderboard.previous.weekly.users) {
                                if(!teamsPrevious.length || (teamsPrevious[teamsPrevious.length-1].team !== user.team)) {
                                    teamsPrevious.push({team: user.team, points: 0, prize: 0});
                                }
                                teamsPrevious[teamsPrevious.length-1].points += user.points;
                                teamsPrevious[teamsPrevious.length-1].prize += user.prize;
                            }

                            leaderboard.current.weekly.teams = teamsCurrent;
                            leaderboard.previous.weekly.teams = teamsPrevious;

                            for(let i=0; i<3; i++) {
                                if(leaderboard.current.weekly.teams.length > i) {
                                    leaderboard.current.weekly.teams[i].users = leaderboard.current.weekly.users.filter(user => user.team === leaderboard.current.weekly.teams[i].team);
                                }
                                if(leaderboard.previous.weekly.teams.length > i) {
                                    leaderboard.previous.weekly.teams[i].users = leaderboard.previous.weekly.users.filter(user => user.team === leaderboard.previous.weekly.teams[i].team);
                                }
                            }

                            delete leaderboard.current.weekly.users;
                            delete leaderboard.previous.weekly.users;

                            leaderboard.current.weekly.millisecondsRemaining = this.$store.state.blockspinState.getMillisecondsRemainingThisWeek;
                            leaderboard.current.daily.millisecondsRemaining = this.$store.state.blockspinState.getMillisecondsRemainingToday;
                        }

                        if(this.myTeam.length) {
                            leaderboard.current.weekly.myTeam = { team: this.myTeam[0].team, points: 0, prize: 0 };
                            leaderboard.current.weekly.myTeam.users = this.myTeam;
                            for(let user of this.myTeam) {
                                leaderboard.current.weekly.myTeam.points += user.points;
                            }
                        }

                        leaderboard.action = 'getLeaderboard';
                        message.source.postMessage(JSON.stringify(leaderboard), "*");
                        break;
                    }
                    case "buyChips": {
                        this.$store.state.isBuyChipsPopupOpen=true;
                        break;
                    }
                    case "buyVip": {
                        this.$store.state.isShopPopupOpen=true;
                        break;
                    }
                    case "home": {
                        this.$router.push({path:Config.defaultPath,query:this.$route.query});
                        break;
                    }
                    case "soundOn": {
                        this.$store.commit("setSound", true);
                        break;
                    }
                    case "soundOff": {
                        this.$store.commit("setSound", false);
                        break;
                    }
                }
                if(data === 'betComplete' || (data && data.action && data.action === 'betComplete')) {
                    this.$store.state.userData.chips+=this.nextChips.pop();
                }

            },
        },
        watch:{
            chips(value) {
                if (this.gameWindow) {
                    this.gameWindow.postMessage(
                        JSON.stringify({ action: "balance", balance: value }),
                        "*"
                    );
                }
            },
        },
    }

</script>

<style lang="css" scoped>

</style>

<template>
    <div class="c-components_body unselectable" style="backdrop-filter: blur(5px);background: linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8));">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <!--   Ticket bg static   -->
            <div class="c-chips_claim_2" style="justify-content: flex-start;"
                 :style="{'width': 1000*ss*xMult+'px','height': 800*ss*xMult+'px','gap': 30*ss*xMult+'px','border-radius':60*ss*xMult+'px',
                    'border':6*ss*xMult+'px solid #005bea',
                    'background':getBg,'box-shadow':getShadow}"
            >

                <!--     confetti  for buy confirm     -->
                <div class="c-chips_claim_bg_abs"
                     :style="{'width': '100%','height': '100%','border-radius':60*ss*xMult+'px',}"
                >
                    <img class="c-chips_claim_img_1" alt=""
                         :style="{'width': 'auto','height': 800*ss*xMult+'px','bottom': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
                         :src="confetti_2"
                    />

                    <img class="c-chips_claim_img_1" alt=""
                         :style="{'width': 'auto','height': 350*ss*xMult+'px','bottom': 150*ss*xMult+'px','right': 60*ss*xMult+'px',}"
                         :src="coin_3"
                    />
                    <img class="c-chips_claim_img_1" alt=""
                         :style="{'width': 'auto','height': 300*ss*xMult+'px','bottom': 0*ss*xMult+'px','right': 100*ss*xMult+'px',}"
                         :src="coin_2"
                    />
                    <img class="c-chips_claim_img_1" alt=""
                         :style="{'width': 'auto','height': 400*ss*xMult+'px','bottom': 0*ss*xMult+'px','right': 0*ss*xMult+'px',}"
                         :src="coin_4"
                    />
                    <img class="c-chips_claim_img_1" alt=""
                         :style="{'width': 'auto','height': 300*ss*xMult+'px','bottom': 0*ss*xMult+'px','left': 50*ss*xMult+'px',}"
                         :src="coin_1"
                    />
                    <img class="c-chips_claim_img_1" alt=""
                         :style="{'width': 'auto','height': 450*ss*xMult+'px','bottom': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
                         :src="img_bag"
                    />
                </div>

                <!--     Confirm claim in not claim today       -->
                <div v-if="userData.claimedFreeChipsToday===false"
                     class="c-chips_claim_column" style="justify-content: flex-start;"
                     :style="{'width': 1000*ss*xMult+'px','height': 700*ss*xMult+'px','margin-top': 80*ss*xMult+'px',}"
                >
                    <!--     24h reward       -->
                    <div class="c-chips_claim_text c-chips_claim_column" style="color: #ffe500;"
                         :style="{'width': 1000*ss*xMult+'px','height': 100*ss*xMult+'px',
                            'font-size': 70*ss*xMult+'px','line-height': 100*ss*xMult+'px','margin-bottom': 10*ss*xMult+'px',}"
                    >
                        {{messages.m037_03}}
                    </div>
                    <!--     Chips count       -->
                    <div class="c-chips_claim_text c-chips_claim_column"
                         :style="{'width': 1000*ss*xMult+'px','height': 100*ss*xMult+'px',
                            'font-size': 90*ss*xMult+'px','line-height': 110*ss*xMult+'px','margin-bottom': 10*ss*xMult+'px',}"
                    >
                        {{getDailyChipsReward.toLocaleString()}}
                    </div>
                    <!--     Chips        -->
                    <div class="c-chips_claim_text c-chips_claim_column"
                         :style="{'width': 1000*ss*xMult+'px','height': 100*ss*xMult+'px',
                            'font-size': 80*ss*xMult+'px','line-height': 100*ss*xMult+'px','margin-bottom': 30*ss*xMult+'px',}"
                    >
                        {{messages.m037_02}}
                    </div>

                    <!--     Confirm button       -->
                    <div class="c-chips_claim_column"
                         :style="{'width': 450*ss*xMult+'px','height': 120*ss*xMult+'px','margin-bottom': 20*ss*xMult+'px',}"
                    >
                        <ButtonSvgOval :svg-normal="checkSvg"
                                       :callback="clickConfirm" :callback-wait="true"
                                       :btn-text="messages.m037_01"
                                       :bg-width="420*ss*xMult" :bg-height="100*ss*xMult"
                                       :max-width="420*ss*xMult"
                                       :font-size="45*ss*xMult"
                                       :svg-width="50*ss*xMult" :svg-height="50*ss*xMult"
                                       :click-scale="0.95"
                        />
                    </div>
                </div>

                <!--     Next reward       -->
                <div v-if="userData.claimedFreeChipsToday===true"
                     class="c-chips_claim_column" style="justify-content: flex-start;"
                     :style="{'width': 1000*ss*xMult+'px','height': 700*ss*xMult+'px','margin-top': 40*ss*xMult+'px',}"
                >
                    <!--     You already received your       -->
                    <div class="c-chips_claim_text c-chips_claim_column"
                         :style="{'width': 1000*ss*xMult+'px','height': 100*ss*xMult+'px',
                            'font-size': 55*ss*xMult+'px','line-height': 100*ss*xMult+'px','margin-bottom': 0*ss*xMult+'px',}"
                    >
                        {{messages.m037_07}}
                    </div>
                    <!--     DAILY CHIPS AWARD!       -->
                    <div class="c-chips_claim_text c-chips_claim_column" style="color: #ffe500;"
                         :style="{'width': 1000*ss*xMult+'px','height': 90*ss*xMult+'px',
                            'font-size': 80*ss*xMult+'px','line-height': 90*ss*xMult+'px','margin-bottom': 10*ss*xMult+'px',}"
                    >
                        {{messages.m037_08}}
                    </div>

                    <!--     Time for next claim       -->
                    <div class="c-chips_claim_time_bg"
                         :style="{'width': 550*ss*xMult+'px','height': 200*ss*xMult+'px','margin-bottom': 20*ss*xMult+'px',
                            'border-radius': 100*ss*xMult+'px',}"
                    >
                        <div class="c-chips_claim_text c-chips_claim_column"
                             :style="{'width': 500*ss*xMult+'px','height': 50*ss*xMult+'px','font-size': 45*ss*xMult+'px','line-height': 50*ss*xMult+'px',}">
                            {{messages.m037_09}}
                        </div>

                        <div class="c-chips_claim_row"
                             :style="{'width': 500*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 10*ss*xMult+'px',}">
                            <div class="c-chips_claim_column"
                                 :style="{'width': 160*ss*xMult+'px','height': '100%','gap': 10*ss*xMult+'px',}">
                                <div class="c-chips_claim_text c-chips_claim_column" style="font-weight: 700"
                                     :style="{'width': '100%','height': 70*ss*xMult+'px','font-size': 65*ss*xMult+'px','line-height': 70*ss*xMult+'px',}">
                                    {{getHours}}
                                </div>
                                <div class="c-chips_claim_text c-chips_claim_column" style="opacity: 0.6;"
                                     :style="{'width': '100%','height': 30*ss*xMult+'px','font-size': 30*ss*xMult+'px','line-height': 30*ss*xMult+'px',}">
                                    {{messages.m030_7}}
                                </div>
                            </div>
                            <div class="c-chips_claim_column"
                                 :style="{'width': 160*ss*xMult+'px','height': '100%','gap': 10*ss*xMult+'px',}">
                                <div class="c-chips_claim_text c-chips_claim_column" style="font-weight: 700"
                                     :style="{'width': '100%','height': 70*ss*xMult+'px','font-size': 65*ss*xMult+'px','line-height': 70*ss*xMult+'px',}">
                                    {{getMin}}
                                </div>
                                <div class="c-chips_claim_text c-chips_claim_column" style="opacity: 0.6;"
                                     :style="{'width': '100%','height': 30*ss*xMult+'px','font-size': 30*ss*xMult+'px','line-height': 30*ss*xMult+'px',}">
                                    {{messages.m030_8}}
                                </div>
                            </div>

                            <div class="c-chips_claim_column"
                                 :style="{'width': 160*ss*xMult+'px','height': '100%','gap': 10*ss*xMult+'px',}">
                                <div class="c-chips_claim_text c-chips_claim_column" style="font-weight: 700"
                                     :style="{'width': '100%','height': 70*ss*xMult+'px','font-size': 65*ss*xMult+'px','line-height': 70*ss*xMult+'px',}">
                                    {{getSec}}
                                </div>
                                <div class="c-chips_claim_text c-chips_claim_column" style="opacity: 0.6;"
                                     :style="{'width': '100%','height': 30*ss*xMult+'px','font-size': 30*ss*xMult+'px','line-height': 30*ss*xMult+'px',}">
                                    {{messages.m030_9}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--     Shop button       -->
                    <div class="c-chips_claim_column"
                         :style="{'width': 450*ss*xMult+'px','height': 120*ss*xMult+'px','margin-bottom': 10*ss*xMult+'px',}"
                    >
                        <ButtonSvgOval :svg-normal="prizesSvg"
                                       :callback="clickShop" :callback-wait="true"
                                       :btn-text="messages.m032_01"
                                       :bg-width="420*ss*xMult" :bg-height="100*ss*xMult"
                                       :max-width="420*ss*xMult"
                                       :font-size="45*ss*xMult"
                                       :svg-width="50*ss*xMult" :svg-height="50*ss*xMult"
                                       :click-scale="0.95"
                        />
                    </div>
                </div>

                <!--     info        -->
                <div class="c-chips_claim_column" style="position: absolute; background: rgba(0, 0, 0, 0.5);"
                     :style="{'width': '100%','height': 'auto','bottom': '0',
                            'font-size': 48*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-bottom': 0*ss*xMult+'px',}"
                >
                    <div
                            class="c-chips_claim_text_info c-chips_claim_column"
                            :style="{'width': 950*ss*xMult+'px','height': 'auto',
                            'font-size': 48*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                            'margin-bottom': 10*ss*xMult+'px','margin-top': 10*ss*xMult+'px',}"
                    >
                        {{messages.m037_04}}
                    </div>
                </div>

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import checkSvg from "@/assets/svg/icon_check.svg";
    import confetti_2 from "@/assets/images/svg_confetti.webp";
    import coin_1 from "@/assets/images/svg_chips_green.webp";
    import coin_2 from "@/assets/images/svg_chips_red.webp";
    import coin_3 from "@/assets/images/svg_chips_violet.webp";
    import coin_4 from "@/assets/images/svg_chips_yellow.webp";
    import img_bag from "@/assets/images/svg_bag.webp";
    import prizesSvg from "@/assets/svg/tickets/prizes.svg";

    export default defineComponent({
        name: 'ChipsClaim',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","messages","popupBuyShopConfirm","userData","getDailyChipsReward"]),


            getBg(){
                let txt='linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%)';
                return txt;
            },
            getShadow(){
                let txt='0 0 100px rgba(25,201,243,.6)';
                return txt;
            },

            getHours() {
                let timeRemaining = 0;
                const state=this.$store.state;
                timeRemaining = state.blockspinState.millisecondsRemainingToday;
                timeRemaining /= (1000 * 60 * 60 );
                timeRemaining %= 24;
                timeRemaining = Math.floor(timeRemaining);
                return timeRemaining.toString();
            },
            getMin(){
                let timeRemaining = 0;
                const state=this.$store.state;
                timeRemaining = state.blockspinState.millisecondsRemainingToday;
                timeRemaining /= (1000 * 60  );
                timeRemaining %= 60;
                timeRemaining = Math.floor(timeRemaining);
                return timeRemaining.toString();
            },
            getSec(){
                const state=this.$store.state;
                let txt=0;

                if (state.blockspinState.millisecondsRemainingToday){
                    txt=(Math.floor(state.blockspinState.millisecondsRemainingToday/1000)%60).toString()
                }
                return txt;
            },

        },
        components: {ButtonSvg,ButtonSvgOval,},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),img_bag:Utils.getGlobalLink(img_bag),
                confetti_2:Utils.getGlobalLink(confetti_2), checkSvg:Utils.getGlobalLink(checkSvg),coin_1:Utils.getGlobalLink(coin_1),
                coin_2:Utils.getGlobalLink(coin_2),coin_3:Utils.getGlobalLink(coin_3),coin_4:Utils.getGlobalLink(coin_4),
                prizesSvg:Utils.getGlobalLink(prizesSvg),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {

            btn_xClick(){
                this.$store.state.popupVisible.chipsClaim=false;
            },
            async clickConfirm(){
                const state=this.$store.state;
                state.popupVisible.chipsClaim=false;

                if(this.$store.getters.getIsGuest) {
                    state.popupVisible.signup=true;
                } else {
                    const claim = await this.$store.dispatch("claimChips");
                    if (claim.error) {
                        state.showMessagePopup(claim.error,state.messages.m029_5,true);
                    } else {
                        let confirmationText = state.messages.m037_05+" " + claim.numChips.toString() + " "+state.messages.m037_06;
                        state.showMessagePopup(confirmationText,state.messages.m035_03,false);
                    }
                }
            },
            clickShop(){
                const state=this.$store.state;
                state.popupVisible.chipsClaim=false;
                state.shop.shopType='chips'
                state.shop.visible=true;
            }
        },
    });
</script>

<style lang="css" scoped>
    .c-chips_claim_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-chips_claim_bg_abs{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-chips_claim_column{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-chips_claim_img_1{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-chips_claim_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;

        /*word-wrap: break-word;/**/
        white-space: nowrap;        /*Нет переноса не переносит*/
    }

    .c-chips_claim_text_info{
        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        overflow: hidden;
        /*color: #211d1d;/**/
        color: white;

        justify-content: center;
        align-items: center;
        text-align: -webkit-center;
        /*word-wrap: break-word;/**/

        /*-webkit-text-stroke: 1px rgba(255, 255, 255, 1); /* Толщина и цвет обводки */
        /*text-stroke: 1px rgba(0, 0, 0, 0.3); /* Толщина и цвет обводки (стандартное свойство) */
    }

    .c-chips_claim_time_bg{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        box-shadow: 0 7px 15px rgba(0,0,0,.07),inset 0 5px 6px hsla(0,0%,100%,.3);
        background: hsla(0,0%,100%,.2);
    }

    .c-chips_claim_row{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

</style>

<template>
    <!--   Achievements statistic popup   -->
    <div class="c-components_body unselectable" style="background: radial-gradient(52.63% 87.8% at 50.03% 4.04%, #0D4455 0%, #030F20 100%), linear-gradient(94deg, #00C6FB 0.27%, #005BEA 105.74%);">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <!--   VERTICAL
            v-if="orient==='V'"
             -->
            <div class="c-app_csc"
                 :style="{'width': realWidth-80*ss*xMult+'px','height': realHeight-80*ss*xMult+'px','gap': 20*ss*xMult+'px',}"
            >
                <!--  Achievement statistics  -->
                <div class="c-app_rcc"
                     :style="{'width': 930*ss*xMult+'px','height': 80*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
                >
                    <div class="c-achiev_stats_text"
                         :style="{'width': 'auto','height': 80*ss*xMult+'px',
                            'font-size': 65*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                    >
                        {{messages.m050_01}}
                    </div>
                </div>

                <!--scroll static-->
                <div class="c-app_ccc"
                     :style="{'width': realWidth-80*ss*xMult+'px','height':'auto','max-height': realHeight-180*ss*xMult+'px',}">
                    <!--scroll dinamic-->
                    <div class="c-app_scroll"
                         :style="{'width': realWidth-80*ss*xMult+'px','height':'auto','gap': 30*ss*xMult+'px',
                        'padding-top': 10*ss*xMult+'px','padding-bottom': 10*ss*xMult+'px',}">

                        <!--  All time CHIPS earned  -->
                        <div class="c-app_ccs" style="background: linear-gradient(95deg, rgb(220, 6, 255) -6.49%, rgb(134, 6, 178) 108.32%);"
                             :style="{'width': (orient==='V'?1000:2030)*ss*xMult-(orient==='V'?16:32)+'px','height': 240*ss*xMult+'px',
                                'gap': 0*ss*xMult+'px','border-radius': 50*ss*xMult+'px',}"
                        >
                            <img :src="img_chips_4" alt="" style="position: absolute"
                                 :style="{'right': -20*ss*xMult+'px','bottom': -20*ss*xMult+'px','width': 'auto','height': 250*ss*xMult+'px',}"/>
                            <!--  All time CHIPS earned text -->
                            <div class="c-achiev_stats_text c-app_ccc"
                                 :style="{'width': 'auto','height': 80*ss*xMult+'px','font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                                    'margin-left': 60*ss*xMult+'px',}">
                                {{messages.m050_02}}
                            </div>
                            <div class="c-app_rsc"
                                 :style="{'width': 'auto','height': 120*ss*xMult+'px','margin-left': 60*ss*xMult+'px','gap': 30*ss*xMult+'px',}">
                                <img :src="img_chips" alt=""
                                     :style="{'width': 100*ss*xMult+'px','height': 100*ss*xMult+'px',}"/>

                                <div class="c-achiev_stats_text c-app_ccc"
                                     :style="{'width': 'auto','height': 80*ss*xMult+'px','font-size': 60*ss*xMult+'px','line-height': 80*ss*xMult+'px',}">
                                    {{achievementsStatsUnlocked?(userData.totalWon?userData.totalWon:0):'******'}}
                                </div>
                            </div>

                        </div>

                        <!-- userData Achievements -->
                        <AchievElem v-for="(achievKey, achievIndex) in achievKeys"
                                    :key="achievIndex"
                                    :img="getImg1(achievKey)"
                                    :prize-msg="getDate1(achievKey)"
                                    :title="getTitle(achievKey)"
                                    :msg="getDescription(achievKey)"
                                    :prize-val="getChips(achievKey)"
                                    :prize-percent="100"
                                    :prize-active="false"
                                    :percent-visible="true"
                        />

                        <!-- blue line -->
                        <div class="c-app_ccs" style="border-bottom: 2px solid rgb(25, 201, 243);"
                             :style="{'width': (orient==='V'?1000:2030)*ss*xMult-(orient==='V'?16:32)+'px','height': '2px',}"
                        />

                        <!-- Achievements for Slot games -->
                        <AchievElem v-for="(game, gameIndex) in allGames"
                                    :key="gameIndex"
                                    :img="getImg2(game.achievement)"
                                    :title="getTitle2(game.achievement)"
                                    :msg="getDescription2(game.achievement)"
                                    :prize-percent="achievementsStatsUnlocked?getSlotPrizePercent(game.name):0"
                                    :prize-msg="achievementsStatsUnlocked?getSlotPrizeText(game.name):''"
                                    :prize-active="true"
                                    :percent-visible="achievementsStatsUnlocked"
                                    :prize-val="getChips2(game.achievement)"
                        />

                        <!-- Other Achievements getOtherPrizePercent -->
                        <AchievElem v-for="(otherObj, otherIndex) in otherAchievements"
                                    :key="otherIndex"
                                    :img="getImg3(otherObj.name)"
                                    :title="getTitle2(otherObj.name)"
                                    :msg="getDescription2(otherObj.name)"
                                    :prize-percent="achievementsStatsUnlocked?getOtherPrizePercent(otherObj.name):0"
                                    :prize-msg="achievementsStatsUnlocked?getOtherPrizeText(otherObj.name):''"
                                    :prize-active="true"
                                    :percent-visible="achievementsStatsUnlocked"
                                    :prize-val="getChips2(otherObj.name)"
                        />
                    </div>
                </div>

            </div>

            <!--   HORIZONTAL   -->
            <!--div v-if="orient==='H'" class="c-app_csc"
                 :style="{'width': realWidth-80*ss*xMult+'px','height': realHeight-40*ss*xMult+'px','gap': 0*ss*xMult+'px',}"
            >

            </div-->

            <!--   X button   -->
            <div style="position: absolute"
                 :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
            >
                <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                           :bg-width="72*ss*xMult"
                           :bg-height="72*ss*xMult"
                           :callback="btn_xClick" :callback-wait="true"
                />
            </div>
        </div>
    </div>


</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import AchievElem from "@/vue/account/AchievElem";
    import GameList from "@/scripts/GameList";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import img_chips from "@/assets/svg/chips.svg";
    import img_chips_4  from "@/assets/images/digitalPrizes/chips_4.webp";
    import Config from "@/scripts/Config";


    export default defineComponent({
        name: 'PrizesClaim',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","messages","orient","prizes","getClaimPrizes","popupQuickHelp",
                "achievementsStatsUnlocked","userData",]),
            achievKeys(){
                const ud=this.$store.state.userData;
                let keys=[];
                if (ud && ud.achievements)
                    keys=Object.keys(ud.achievements);
                return keys;
            },
        },
        components: {ButtonSvg,ButtonSvgOval,AchievElem},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),img_chips:Utils.getGlobalLink(img_chips),img_chips_4:Utils.getGlobalLink(img_chips_4),
                allGames:[],allGamesAcievKeys:[],otherAchievements:[],        //
            };
        },
        created() {

        },
        mounted() {
        },
        async beforeMount() {
            await this.$store.dispatch("getState");

            this.allGames.splice(0,this.allGames.length);
            this.allGamesAcievKeys.splice(0,this.allGamesAcievKeys.length);
            this.otherAchievements.splice(0,this.otherAchievements.length);

            Object.keys(GameList.allGames).forEach((gameKey,index)=>{       //Add slots games which has achievements
                const game=GameList.allGames[gameKey];
                if(game.isSoon!==true && game.achievement){
                    this.allGames.push(game);
                    this.allGamesAcievKeys.push(game.achievement);
                }
            });

            //Add other achievements
            const ud=this.$store.state.userData;
            const achievObj = this.$store.state.blockspinState.achievementRewards;
            Object.keys(achievObj).forEach((achievKey,index)=>{       //Add slots games which has achievements
                let b=true;
                if (ud){
                    b=!ud.achievements.some(obj => obj.name === achievKey);
                }
                if (!this.allGamesAcievKeys.includes(achievKey) && b){
                    const achievObj = this.$store.getters.achievObj;
                    if (achievObj[achievKey]){
                        this.otherAchievements.push(achievObj[achievKey]);
                    }
                }
            });

            await this.$store.dispatch("getOtherGamesAmount",this.otherAchievements);
        },
        methods: {
            btn_xClick(){
                this.$store.state.popupVisible.achievStats=false;
            },
            getImg1(achievKey){
                let img='';
                try {
                    const achievObj = this.$store.getters.achievObj;
                    const userData = this.$store.getters.userData;
                    img=Utils.getGlobalLink(achievObj[userData.achievements[achievKey].name].image)
                }
                catch (e) {}
                return img;
            },
            getImg2(achievKey){
                let img='';
                try {
                    const achievObj = this.$store.getters.achievObj;
                    img=Utils.getGlobalLink(achievObj[achievKey].image)
                }
                catch (e) {}
                return img;
            },
            getImg3(achievKey){
                let img='';
                try {
                    const achievObj = this.$store.getters.achievObj;
                    img=Utils.getGlobalLink(achievObj[achievKey].image)

                }
                catch (e) {}
                return img;
            },
            getDate1(achievKey){
                let txt='';
                try {
                    const userData = this.$store.getters.userData;
                    txt=Utils.getDateOrNow(userData.achievements[achievKey].timestamp)
                }
                catch (e) {}
                return txt;
            },
            getTitle(achievKey){
                let txt='';
                try {
                    const achievObj = this.$store.getters.achievObj;
                    const userData = this.$store.getters.userData;
                    txt=achievObj[userData.achievements[achievKey].name].title;
                }
                catch (e) {}
                return txt;
            },
            getTitle2(achievKey){
                let txt='';
                try {
                    const achievObj = this.$store.getters.achievObj;
                    txt=achievObj[achievKey].title;
                }
                catch (e) {}
                return txt;
            },
            getDescription(achievKey){
                let txt='';
                try {
                    const achievObj = this.$store.getters.achievObj;
                    const userData = this.$store.getters.userData;
                    txt=achievObj[userData.achievements[achievKey].name].description;
                }
                catch (e) {}
                return txt;
            },
            getDescription2(achievKey){
                let txt='';
                try {
                    const achievObj = this.$store.getters.achievObj;
                    txt=achievObj[achievKey].description;
                }
                catch (e) {}
                return txt;
            },
            getSlotPrizeText(slotName){
                let txt=' / '+ Config.slotAchievementsPrize.toLocaleString();
                try {
                    let val=this.$store.state.gameBetsAmount(slotName);
                    val=val<Config.slotAchievementsPrize?val:Config.slotAchievementsPrize;
                    txt=val.toLocaleString()+txt;
                }
                catch (e) {}
                return txt;
            },
            getSlotPrizePercent(slotName){
                let p=0;
                try {
                    let val=this.$store.state.gameBetsAmount(slotName);
                    val=val<Config.slotAchievementsPrize?val:Config.slotAchievementsPrize;
                    p=Math.round(val/Config.slotAchievementsPrize*100);
                }
                catch (e) {}
                return p;
            },
            getOtherPrizeText(otherName){
                let txt='0 / 0';
                try {
                    let achievement={amount:0,total:0};
                    this.otherAchievements.forEach((obj)=>{
                        if (obj.name===otherName){
                            achievement=obj;
                        }
                    })
                    let amountTxt=achievement.amount===undefined || achievement.amount===null?'undefined':achievement.amount.toLocaleString();
                    txt=amountTxt+ ' / '+(achievement.total?achievement.total.toLocaleString():'0');
                }
                catch (e) {}
                return txt;
            },
            getOtherPrizePercent(otherName){
                let p=0;
                try {
                    let achievement={amount:0,total:0};
                    this.otherAchievements.forEach((obj)=>{
                        if (obj.name===otherName){
                            achievement=obj;
                        }
                    })
                    let amount=achievement.amount?achievement.amount:0;
                    let total=achievement.total?achievement.total:1;
                    p=Math.round(amount/total*100);
                }
                catch (e) {}
                return p;
            },
            getChips(achievKey){
                let txt='';
                try {
                    const achievObj = this.$store.state.blockspinState.achievementRewards;
                    const userData = this.$store.getters.userData;
                    txt=achievObj[userData.achievements[achievKey].name].chips;
                    txt=txt.toLocaleString();
                }
                catch (e) {}
                return txt;
            },
            getChips2(achievKey){
                let txt='';
                try {
                    const achievObj = this.$store.state.blockspinState.achievementRewards;
                    txt=achievObj[achievKey].chips;
                    txt=txt.toLocaleString();
                }
                catch (e) {}
                return txt;
            },
        },

    });
</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-achiev_stats_rec{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        overflow: visible;

        /*background: rgba(255, 255, 255, 0.20);/**/
    }

    .c-achiev_stats_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        color: #FFFFFF;
        text-align: -webkit-center;
    }

</style>

<template>
    <!--   Game messages popup for player "successful and error"   -->
    <div class="c-components_body unselectable" style="backdrop-filter: blur(5px);background: linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8));">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <!--   Popup bg static   -->
            <div class="c-popup_contg_2"
                 :style="{'width': 1000*ss*xMult+'px','height': 800*ss*xMult+'px','gap': 30*ss*xMult+'px','border-radius':60*ss*xMult+'px',
                    'border':isError?6*ss*xMult+'px solid #CC181A':6*ss*xMult+'px solid #06A071',
                    'background':getBg,'box-shadow':getShadow}"
            >

                <!--     Confirm information       -->
                <div class="c-popup_contg_column"
                     :style="{'width': 1000*ss*xMult+'px','height': 700*ss*xMult+'px',}"
                >
                    <div class="c-popup_contg_text unselectable"
                         :style="{'width': 800*ss*xMult+'px','height': 120*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                    >
                        {{messages.m054_02}}
                    </div>
                    <div class="c-popup_contg_static"
                         :style="{'width': 910*ss*xMult+'px','height': 450*ss*xMult+'px',}"
                    >
                        <div class="c-popup_contg_text_scroll"
                             :style="{'width': 910*ss*xMult+'px','height': 'auto',
                                'font-size': 50*ss*xMult+'px','line-height': 65*ss*xMult+'px',}"
                        >
                            {{messages.m054_03}}
                            <a style="color: #a0ffe3;" :href="Constants.webAppUri" target="_blank">{{messages.m054_06}}</a>
                        </div>
                        <div class="c-popup_contg_text_scroll"
                             :style="{'width': 910*ss*xMult+'px','height': 'auto',
                                'font-size': 50*ss*xMult+'px','line-height': 65*ss*xMult+'px',}"
                        >
                            {{messages.m054_05}}
                        </div>
                    </div>

                    <!--   Enter code  -->
                    <div class="c-popup_contg_row_2"
                         :style="{'width': 1000*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 30*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
                    >

                        <div class="c-popup_contg_row_2"
                             :style="{'width': 1000*ss*xMult+'px','height': 120*ss*xMult+'px',}"
                        >

                            <input class="c-popup_contg_input" v-model="secretCode" :placeholder="messages.m054_04"
                                   :style="{'width': 660*ss*xMult+'px','height': 85*ss*xMult+'px','margin-right': -50*ss*xMult+'px',
                            'padding-left': 80*ss*xMult+'px','font-size': 50*ss*xMult+'px',
                            'border-top-left-radius': 60*ss*xMult+'px','border-bottom-left-radius': 60*ss*xMult+'px',}"
                            />

                            <ButtonSvgOval
                                    :callback="clickClaim" :callback-wait="true"
                                    :btn-text="messages.m012"
                                    :bg-width="250*ss*xMult" :bg-height="90*ss*xMult"
                                    :font-size="45*ss*xMult"
                                    :svg-width="60*ss*xMult" :svg-height="60*ss*xMult"
                                    :click-scale="0.95"
                            />
                            <img v-if="!secretCode" class="c-popup_contg_popup_img_1" alt="" @click="clickPaste"
                                 :style="{'width': 'auto','height': 65*ss*xMult+'px','left': 40*ss*xMult+'px',}"
                                 :src="img_paste"
                            />
                            <img v-else class="c-popup_contg_popup_img_1" alt="" @click="clickClear"
                                 :style="{'width': 'auto','height': 50*ss*xMult+'px','left': 48*ss*xMult+'px',}"
                                 :src="img_btn_x_yellow"
                            />
                            <div v-if="popupQuickHelp.prizesClaimQuick===true" class="c-popup_contg_popup_quick"
                                 :style="{'left': 50*ss*xMult+'px','top': -90*ss*xMult+'px',}"
                            >
                                <PopupInfo     :bg-width="250*ss*xMult" :bg-height="90*ss*xMult"
                                               :max-width="50*ss*xMult"
                                               :border-radius="30*ss*xMult"
                                               :font-size="45*ss*xMult"
                                               :btn-text="messages.m047_05"
                                               :background-val="'rgba(0, 0, 0, 0.4)'"
                                               :border-val="'1px solid rgba(255, 255, 255, 0.5)'"
                                               :callback="popupInfoClick" :callback-wait="true"

                                />
                            </div>
                        </div>
                    </div>

                </div>

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import PopupInfo from "@/vue/elements/PopupInfo";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import img_copy from "@/assets/svg/copy_white.svg";
    import img_paste from "@/assets/svg/paste";
    import img_btn_x_yellow from "@/assets/svg/btn_x_yellow";
    import * as Constants from "@/classes/Constants";

    export default defineComponent({
        name: 'PopupConnectTelegram',
        components: {ButtonSvg,ButtonSvgOval,PopupInfo},
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","messages","popupQuickHelp"]),

            getBg(){
                let txt='linear-gradient(95deg, #0CFF7C -16.14%, #00AF90 137.96%)';
                return txt;
            },
            getShadow(){
                let txt='0px 0px 100px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 100px 0px rgba(57, 231, 156, 0.60)';
                return txt;
            },
            isError(){        //true - buy confirm, false - burn tickets confirm
                return false;
            },
        },
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),img_copy:Utils.getGlobalLink(img_copy),
                img_paste:Utils.getGlobalLink(img_paste), img_btn_x_yellow:Utils.getGlobalLink(img_btn_x_yellow),
                secretCode: "", Constants:Constants,
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btn_xClick(){
                const state=this.$store.state;
                state.popupVisible.connectTelegram=false;

            },
            clickClaim(){
                const state=this.$store.state;
                state.popupVisible.connectTelegram=false;
                Utils.connectTelegram(this.secretCode);
            },
            clickClear(){
                this.secretCode='';
            },

            async clickPaste(){
                const state=this.$store.state;
                state.popupQuickHelp.prizesClaimQuick=true;
                let clipboardData = window.clipboardData || navigator.clipboard;
                const text = await clipboardData.readText();
                this.secretCode=text;
                setTimeout(() => {
                    state.popupQuickHelp.prizesClaimQuick=false;
                }, 1000);
            },

            popupInfoClick(){
                const state=this.$store.state;
                state.popupQuickHelp.popupMessage=false;
            },
        },
    });

</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-popup_contg_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-popup_contg_static {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .c-popup_contg_column{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-popup_contg_text{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;
        text-align: -webkit-center;

        /*word-wrap: break-word;/**/
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-popup_contg_text_scroll{
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;
        text-align: -webkit-left;

        overflow-x: hidden;
        overflow-y: auto;

        word-wrap: break-word;/**/
        white-space: pre-wrap;
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-popup_contg_row_2{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(255, 255, 255, 0.20);/**/
    }

    .c-popup_contg_input{
        font-style: normal;
        font-weight: 400;
        font-family: 'Rubik', sans-serif;

        letter-spacing: -0.03em;

        /*background: rgba(255, 255, 255, 0.20);/**/
    }

    .c-popup_contg_popup_quick{
        position: absolute;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }

    .c-popup_contg_popup_img_1{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

</style>

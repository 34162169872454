<template>
    <div class="c-count_down unselectable"
         :style="{'width': 900*ss*xMult+'px','height': 250*ss*xMult+'px',}"
    >
        <div class="c-count_down_bg"
             :style="{'width': 900*ss*xMult+'px','height': 250*ss*xMult+'px','margin-top': 0*ss*xMult+'px',
            'border': 6*ss*xMult+'px solid #233C64','border-radius': 60*ss*xMult+'px','gap': 20*ss*xMult+'px',}"
        >
            <div class="c-count_down_text c-count_down_title"
                 :style="{'width': 900*ss*xMult+'px','height': 55*ss*xMult+'px','font-size': 55*ss*xMult+'px','line-height': 55*ss*xMult+'px',}">
                {{messages.m030_5}}
            </div>

            <div class="c-count_down_row"
                 :style="{'width': 900*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 10*ss*xMult+'px',}">
            >
                <div class="c-count_down_col"
                     :style="{'width': 160*ss*xMult+'px','height': '100%','gap': 10*ss*xMult+'px',}">
                    <div class="c-count_down_text" style="font-weight: 700"
                         :style="{'width': '100%','height': 70*ss*xMult+'px','font-size': 65*ss*xMult+'px','line-height': 70*ss*xMult+'px',}">
                        {{prizes.period==='daily'?getHours:getDays}}
                    </div>
                    <div class="c-count_down_text" style="opacity: 0.6;"
                         :style="{'width': '100%','height': 30*ss*xMult+'px','font-size': 30*ss*xMult+'px','line-height': 30*ss*xMult+'px',}">
                        {{getText1}}
                    </div>
                </div>
                <div class="c-count_down_col"
                     :style="{'width': 160*ss*xMult+'px','height': '100%','gap': 10*ss*xMult+'px',}">
                    <div class="c-count_down_text" style="font-weight: 700"
                         :style="{'width': '100%','height': 70*ss*xMult+'px','font-size': 65*ss*xMult+'px','line-height': 70*ss*xMult+'px',}">
                        {{prizes.period==='daily'?getMin:getHours}}
                    </div>
                    <div class="c-count_down_text" style="opacity: 0.6;"
                         :style="{'width': '100%','height': 30*ss*xMult+'px','font-size': 30*ss*xMult+'px','line-height': 30*ss*xMult+'px',}">
                        {{getText2}}
                    </div>
                </div>

                <div class="c-count_down_col"
                     :style="{'width': 160*ss*xMult+'px','height': '100%','gap': 10*ss*xMult+'px',}">
                    <div class="c-count_down_text" style="font-weight: 700"
                         :style="{'width': '100%','height': 70*ss*xMult+'px','font-size': 65*ss*xMult+'px','line-height': 70*ss*xMult+'px',}">
                        {{prizes.period==='daily'?getSec:getMin}}
                    </div>
                    <div class="c-count_down_text" style="opacity: 0.6;"
                         :style="{'width': '100%','height': 30*ss*xMult+'px','font-size': 30*ss*xMult+'px','line-height': 30*ss*xMult+'px',}">
                        {{getText3}}
                    </div>
                </div>
            </div>


            <img :src="getImage" class="c-count_down_img"
                 :style="{'width': 'auto','height': 230*ss*xMult+'px','bottom': 5*ss*xMult+'px','left': 0*ss*xMult+'px',}"
            />
        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import timerImg from "@/assets/images/timer.webp";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";


    export default defineComponent({
        name: 'CountDown',
        components: {ButtonSvgOval,},
        computed: {
            ...mapGetters(["ss","xMult","blockspinState","prizes","messages"]),
            getImage(){
                let img='';
                img=Utils.getGlobalLink(timerImg)

                return img;
            },

            getDays() {
                let timeRemaining = 0;
                const state=this.$store.state;
                if (state.prizes.period==='weekly') {
                    timeRemaining = state.blockspinState.millisecondsRemainingThisWeek;
                }
                if (state.prizes.period === "monthly") {
                    timeRemaining = state.blockspinState.millisecondsRemainingThisMonth;
                }
                timeRemaining /= (1000 * 60 * 60 * 24);
                timeRemaining = Math.floor(timeRemaining);
                return timeRemaining.toString();
            },

            getHours() {
                let timeRemaining = 0;
                const state=this.$store.state;
                if (state.prizes.period==='daily') {
                    timeRemaining = state.blockspinState.millisecondsRemainingToday;
                }
                if (state.prizes.period==='weekly') {
                    timeRemaining = state.blockspinState.millisecondsRemainingThisWeek;
                }
                if (state.prizes.period === "monthly") {
                    timeRemaining = state.blockspinState.millisecondsRemainingThisMonth;
                }
                timeRemaining /= (1000 * 60 * 60 );
                timeRemaining %= 24;
                timeRemaining = Math.floor(timeRemaining);
                return timeRemaining.toString();
            },

            getMin(){
                let timeRemaining = 0;
                const state=this.$store.state;
                if (state.prizes.period==='daily') {
                    timeRemaining = state.blockspinState.millisecondsRemainingToday;
                }
                if (state.prizes.period==='weekly') {
                    timeRemaining = state.blockspinState.millisecondsRemainingThisWeek;
                }
                if (state.prizes.period === "monthly") {
                    timeRemaining = state.blockspinState.millisecondsRemainingThisMonth;
                }
                timeRemaining /= (1000 * 60  );
                timeRemaining %= 60;
                timeRemaining = Math.floor(timeRemaining);
                return timeRemaining.toString();
            },

            getSec(){
                const state=this.$store.state;
                let txt=0;

                if (state.blockspinState.millisecondsRemainingToday){
                    txt=(Math.floor(state.blockspinState.millisecondsRemainingToday/1000)%60).toString()
                }

                return txt;
            },

            getText1(){
                const state=this.$store.state;
                let txt=state.messages.m030_7;
                if (state.prizes.period!=='daily')
                    txt=state.messages.m030_6;
                return txt;
            },
            getText2(){
                const state=this.$store.state;
                let txt=state.messages.m030_8;
                if (state.prizes.period!=='daily')
                    txt=state.messages.m030_7;
                return txt;
            },
            getText3(){
                const state=this.$store.state;
                let txt=state.messages.m030_9;
                if (state.prizes.period!=='daily')
                    txt=state.messages.m030_8;
                return txt;
            },

        },
        data(){
            return {

            };
        },
        created() {

            //console.log(this.clickScale)
        },
        mounted() {
        },
        methods: {

        },

        props:{

        }
    });
</script>

<style lang="css" scoped>

    .c-count_down{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-count_down_bg{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        background: radial-gradient(52.63% 87.8% at 50.03% 4.04%, #0D4455 0%, #030F20 100%);
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-count_down_col{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(255, 255, 255, 0.3);/**/
    }

    .c-count_down_row{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-count_down_img{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .c-count_down_text{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;

        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        overflow: visible;
        text-align: -webkit-center;
        white-space: nowrap;

    }

    .c-count_down_title{
        background: linear-gradient(95.19deg,#0cff7c -16.14%,#00af90 137.96%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 0px 0px 15px rgba(57, 231, 156, 0.60);
    }

</style>

<template>
    <!--   Gems    -->
    <div class="c-gems"
         :style="{'width': 485*ss*xMult+'px','height': 400*ss*xMult+'px','border-radius': 40*ss*xMult+'px','gap': 35*ss*xMult+'px',}"
    >
        <img class="c-gems_img" alt=""
             :style="{'width': 67*3*ss*xMult+'px','height': 82*3*ss*xMult+'px','top': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
             :src="gems1Svg"
        />
        <img class="c-gems_img" alt=""
             :style="{'width': 307*0.8*ss*xMult+'px','height': 400*0.8*ss*xMult+'px','top': -50*ss*xMult+'px','right': 0*ss*xMult+'px',}"
             :src="gems3Svg"
        />
        <img class="c-gems_img" alt=""
             :style="{'width': 307*0.8*ss*xMult+'px','height': 400*0.8*ss*xMult+'px','bottom': -40*ss*xMult+'px','right': -10*ss*xMult+'px',}"
             :src="gems2Svg"
        />


        <div class="c-user_info_title_text"
             :style="{'width': 485*ss*xMult+'px','height': 80*ss*xMult+'px','border-radius': 40*ss*xMult+'px',
                'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-top': 20*ss*xMult+'px',
                'text-shadow': '0 0 '+15*ss*xMult+'px rgba(57,231,156,.6)',}"
        >
            {{messages.m022_2}}
        </div>

        <div class="c-gems_line"
             :style="{'width': 485*ss*xMult+'px','height': 80*ss*xMult+'px','margin-top': 30*ss*xMult+'px',}"
        >
            <div class="c-gems_text"
                 :style="{'width': 'auto','height': 80*ss*xMult+'px','border-radius': 40*ss*xMult+'px',
                    'font-size': 60*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-right': 40*ss*xMult+'px',
                    'text-shadow': '0 0 '+45*ss*xMult+'px hsla(0,0%,100%,0.3)',}"
            >
                {{userData.gems?userData.gems.toLocaleString():'0'}}
            </div>
        </div>

        <!--   I button   -->
        <ButtonSvgOval :svg-normal="infoSvg"
                       :bg-width="85*ss*xMult" :bg-height="80*ss*xMult"
                       :max-width="50*ss*xMult"
                       :svg-width="40*ss*xMult" :svg-height="40*ss*xMult"
                       :background-val="'hsla(0,0%,100%,0.2)'"
                       :border-val="'none'"
                       :callback="btnInfoClick" :callback-wait="true"
                       :click-scale="0.9"
        />

        <div v-if="popupQuickHelp.gemsInfo===true" class="c-gems_info_popup"
             :style="{'top': 120*ss*xMult+'px','left': 25*ss*xMult+'px',}"
        >
            <PopupInfo     :bg-width="435*ss*xMult" :bg-height="150*ss*xMult"
                           :max-width="50*ss*xMult"
                           :border-radius="30*ss*xMult"
                           :font-size="35*ss*xMult"
                           :btn-text="messages.m024"
                           :background-val="'rgba(255, 255, 255, 0.1)'"
                           :border-val="'1px solid rgba(255, 255, 255, 0.5)'"
                           :callback="popupInfoClick" :callback-wait="true"

            />
        </div>

    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import Utils from "@/scripts/Utils";
    import PopupInfo from "@/vue/elements/PopupInfo";
    import gems1Svg from "@/assets/svg/icon_gem3.svg";
    import gems2Svg from "@/assets/svg/icon_gem4.svg";
    import gems3Svg from "@/assets/svg/icon_gem5.svg";
    import infoSvg from "@/assets/svg/icon_info";

    export default defineComponent({
        name: 'Gems',
        components: {ButtonSvgOval, PopupInfo,},
        computed: {
            ...mapGetters(["ss","xMult","messages","userData","btnRad","popupQuickHelp"]),

        },
        data(){
            return {
                gems1Svg:Utils.getGlobalLink(gems1Svg),gems2Svg:Utils.getGlobalLink(gems2Svg),gems3Svg:Utils.getGlobalLink(gems3Svg),
                infoSvg:Utils.getGlobalLink(infoSvg),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btnGetClick(){},
            btnInfoClick(){
                const state=this.$store.state;
                state.popupQuickHelp.gemsInfo=true;
                setTimeout(()=>{
                    state.popupQuickHelp.gemsInfo=false;
                },6000)//*/
            },
            popupInfoClick(){
                const state=this.$store.state;
                state.popupQuickHelp.gemsInfo=false;
            },
        },

    });
</script>

<style lang="css" scoped>
    .c-gems{
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: flex-start;
        align-items: center;

        background: hsla(0,0%,100%,.1);
    }

    .c-gems_text{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: visible;
        color: #FFFFFF;
        text-align: -webkit-center;
    }

    .c-gems_img{
        display: flex;
        position: absolute;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .c-gems_line{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .c-gems_info_popup{
        position: absolute;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }

</style>

export default {
    bets: {
        slot: {
            all: [],
            big: [],
            mine: []
        },
        scratch: {
            all: [],
            big: [],
            mine: []
        }
    },
    leaderboard: {
        slot: {
            current:
                {
                    hourly: {
                        users: [],
                        myPoints: 0,
                    },
                    daily: {
                        users: [],
                        myPoints: 0,
                    },
                    weekly: {
                        users: [],
                        myPoints: 0,
                    },
                    games: {
                        users: [],
                        myPoints: 0,
                        myPrize:0,
                        myProgress:{},
                    },
                },
            previous: {
                hourly: {
                    users: [],
                    myPoints: 0,
                },
                daily: {
                    users: [],
                    myPoints: 0,
                },
                weekly: {
                    users: [],
                    myPoints: 0,
                },
                games: {
                    users: [],
                    myPoints: 0,
                    myPrize:0,
                },
            }
        },
        scratch: {
            current:
                {
                    hourly: {
                        users: [],
                        myPoints: 0,
                    },
                    daily: {
                        users: [],
                        myPoints: 0,
                    },
                    weekly: {
                        users: [],
                        myPoints: 0,
                    },
                },
            previous: {
                hourly: {
                    users: [],
                    myPoints: 0,
                },
                daily: {
                    users: [],
                    myPoints: 0,
                },
                weekly: {
                    users: [],
                    myPoints: 0,
                },
            }
        },
        penalty: {
            current:
                {
                    hourly: {
                        users: [],
                        myPoints: 0,
                    },
                    daily: {
                        users: [],
                        myPoints: 0,
                    },
                    weekly: {
                        users: [],
                        myPoints: 0,
                    },
                },
            previous: {
                hourly: {
                    users: [],
                    myPoints: 0,
                },
                daily: {
                    users: [],
                    myPoints: 0,
                },
                weekly: {
                    users: [],
                    myPoints: 0,
                },
            }
        }
    },
}


export default {
    topPanel:["GAMES","INFO","LEADERBOARD","LEADERBOARD","BETS"],
    ver:"Beta 1.0",
    m002:"OR",
    m002_1:"lvl.",
    m003:"Sign Up and Get Free Chips",
    m004:"Login",
    m005:"Continue as Guest*",
    m006:"*You will lose your progress if you don’t create an account",
    m006_1:"*You will lose your progress if you don't login",
    m007:"No account?",
    m007_1:"Sign up",
    m008:"Connect with your preferred login method",
    m008_1:"Sign up with your favorite social account or continue as a Guest",
    m009:"Login with Facebook",
    m010:"Login with Google",
    m011:"Login with Discord",
    m012:"Connect",
    m013:"Connect your",
    m013_1:"Merge your",
    m013_2:"account",
    m013_3:"Merge",
    m013_4:"Connect Telegram",
    m014:"to receive",
    m015:"5000 Chips",
    m016:"No purchase necessary. By creating an account, you agree to the ",
    m016_1:"Terms and Conditions",
    m016_2:" and ",
    m016_3:"Privacy Policy.",
    m016_4:"By signing up, you also agree to receive emails from BlockspinGaming",
    m017:"ID:",
    m017_2:"Until",
    m018:"Referral",
    m019:"Notifications",
    m020:"Level",
    m021:"Daily Streak",
    daily_streak_popup: "Your xp resets to 0 if you lose your daily streak",
    m022:"Raffle Tickets",
    m022_1:"Chips",
    m022_2:"Gems",
    m022_5:"GEMS",
    m022_3:"Achievements",
    m022_4:"Boosters",
    m023:"Get More",
    m023_1:"Unlock my stats",
    m023_2:"Check my stats",
    m024:"Play our games to mine Gems!",
    m025_1:"Bronze",
    m025_2:"Silver",
    m025_3:"Gold",
    m026:"Active Boost",
    m026_2:"Expire Time",
    m026_3:"Never",
    m026_4:"Credits",
    m027_1:"Buy Raffle Tickets",
    m027_2:"Enter a raffle now!",
    m027_3:"Burn Ticket",
    m027_4:"Bronze Ticket",
    m027_41:"for Daily Raffle",
    m027_5:"Silver Ticket",
    m027_51:"for Weekly Raffle",
    m027_6:"Gold Ticket",
    m027_61:"for Monthly Raffle",
    m027_7:"Today's",
    m027_8:"This Week's",
    m027_9:"This Month's",
    m027_10:"Raffle Entry",
    m028:"BUYING",
    m028_1:"BURNING",
    m028_2:"Confirm",
    m028_3:"Open Bot",
    m028_4:"Invoice sent",
    m028_5:"We have sent you an invoice.\n Please open the Telegram Bot and complete your purchase.",
    m_ok:'OK',
    m029_1:"You successfully received",
    m029_2:"entries for the",
    m029_3:"raffle",
    m029_4:"Congratulations",
    m029_5:"Error",
    m029_6:"You successfully received",
    m029_7:"Tickets",
    m029_8:"You haven't",
    m029_9:"GEMS to buy Tickets",
    m029_10:"You haven't",
    m029_11:"Tickets for enter a Raffle. Buy Tickets first.",
    m029_12:"No prize to claim.",
    m029_13:"No answer from Backend Login.",
    m030_1:"Raffle Prizes",
    m030_2:"Daily",
    m030_3:"Weekly",
    m030_4:"Monthly",
    m030_5:"Countdown",
    m030_6:"Days",
    m030_7:"Hours",
    m030_8:"Minutes",
    m030_9:"Seconds",
    m030_10:"Entries",
    m030_11:"You",
    m030_12:"Total",
    m030_13:"Odds",
    m030_14:"Claim",
    m030_15:"Prizes",
    m030_15_1:"Claim Prize",
    m030_16:"Winners",
    m030_17:"Winners have 48 hours to claim rewards. Unclaimed rewards will be added to the next rewards pool.",
    m030_18:"Claimed",
    m030_19:"Unclaimed",
    m031_01:"All",
    m031_02:"WAX",
    m031_03:"Solana",
    m031_04:"Ronin",
    m031_05:"BNB",
    m031_06:"Polygon",
    m031_09:"$ USD",
    m031_07:"Digital",
    m031_08:"To withdraw your unclaimed NFTs, select a blockchain.",
    m031_10:"Telegram Stars",
    m032_01:"Shop",
    m032_02:"Join Us",
    m032_03:"Boost",
    m032_04:"VIP",
    m032_05:"Select your currency:",
    m032_06:"SALE:",
    m032_07:"The payment module is not loaded.",
    m032_08:"Purchase error",
    m032_09:"Ronin Wallet not found. You can create Ronin Wallet here.",
    perks:{
        bronze:[
            "Plus 50% daily chips",
            "2,000 max bet",
            "1 day streak insurance",
            "25% chips discount",
            "Unlock stats",
        ],
        silver:[
            "Plus 100% daily chips",
            "5,000 max bet",
            "7 day streak insurance",
            "50% chips discount",
            "50% more entries in raffles",
            "Turbo mode",
            "Unlock all games",
            "XP boost x2",
            "Unlock stats",
        ],
        gold:[
            "Plus 100% daily chips",
            "10,000 max bet",
            "You don't lose daily streak",
            "75% chips discount",
            "100% more entries in raffles",
            "Turbo mode",
            "Unlock all games",
            "XP boost x5",
            "Unlock stats",
        ],
    },
    m033_01:"Select your Wallet",
    m033_02:"Wax Cloud Wallet",
    m033_03:"Anchor",
    m033_04:"Phantom",
    m033_05:"Solflare",
    m033_06:"Create a Wax Cloud Wallet",
    m033_07:"Create an Anchor Wallet",
    m033_08:"here",
    m033_09:"Create a Phantom Wallet",
    m033_10:"Create a Solflare Wallet",
    m033_11:"* Enable popup windows on your browser",
    m034_01:"Wax Wallet not found.",
    m034_02:"Anchor Wallet not found.",
    m034_03:"Phantom Wallet not found.",
    m034_04:"Solflare Wallet not found.",
    m034_05:"Ronin Wallet not found.",
    m034_06:"You can create Wax Wallet here.",
    m034_07:"You can create Anchor Wallet here.",
    m034_08:"You can create Phantom Wallet here.",
    m034_09:"You can create Solflare Wallet here.",
    m034_10:"You can create Ronin Wallet here.",
    m035_01:"You successfully bought",
    m035_02:"Chips. Your balance will be updated after a few seconds when the transaction is confirmed in our system.",
    m035_03:"Congratulations",
    m035_04:"Purchase TX failed",
    m035_05:"You successfully bought the boost.",
    m035_06:"You successfully bought the boosts.",
    m035_07:"You successfully bought the",
    m035_08:"monthly card. Your monthly card will be applied after a few seconds when the transaction is confirmed in our system.",
    m035_09:"Credit card payment server down.",
    m035_10:"Your purchase has been cancelled.",
    m035_11:"Payment confirmed",
    m035_12:"Thank you for your purchase!",
    m036:"We use cookies to ensure that we give you the best experience on our website. To learn more, go to the",
    m036_02:"Accept",
    m036_03:"Privacy Page",
    m037_01:"Claim Now",
    m037_02:"CHIPS",
    m037_03:"24h Reward",
    m037_04:"The amount of free chips increases with your level. Your level goes back to 0 if you don't play for more than 1 day.",
    m037_05:"You just received",
    m037_06:"daily chips award!",
    m037_07:"You already received your",
    m037_08:"DAILY CHIPS AWARD!",
    m037_09:"NEXT REWARD",
    m038_01:"Contact us on Discord to claim your prize!",
    m038_02:"Bronze VIP Card",
    m038_03:"Silver VIP Card",
    m038_04:"Gold VIP Card",
    m038_05:"Claim digital prize:",
    m038_06:"Enter your wallet address",
    m038_07:"You have successfully withdrawn your prizes!",
    m038_08:"VIP Card",
    m039_01:"Notifications",
    m039_02:"Filter:",
    m039_03:"Read All",
    m040_01:"Refer a friend",
    m040_02:"Invite your friends for free chips!",
    m040_03:"If they sign up and play you both get",
    m040_04:"1000 extra chips",
    m040_06:"Share your referral link",
    m040_07:"You have invited",
    m040_08:"friends",
    m040_09:"Copied!",
    m040_10:"ID Copied!",
    m041_01:"UNLOCK",
    m041_02:"for",
    m041_03:"DISCOUNT",
    m041_04:"YOU DON'T HAVE ENOUGH GEMS!",
    m041_05:"Unlock game: backend return error.",
    m042_01:"LEADERBOARD BETS 🔥",
    m042_02:"resets in",
    m042_03:"💰 Prize pool",
    m042_04:"Hourly",
    m042_05:"Daily",
    m042_06:"Weekly",
    m042_07:"USER",
    m042_08:"POINTS",
    m042_09:"PRIZE",
    m043_01:"LEADERBOARD GAMES 🎲",
    m043_02:"Leaderboard",
    m043_03:"Completed games",
    m043_04:"You earn",
    m043_05:"1 point",
    m043_06:"for each different game that you spinned",
    m043_07:"times with a minimum bet of",
    m043_08:"chips.",
    m044_01:"Recent bets 💸",
    m044_02:"User",
    m044_03:"Games",
    m044_04:"Chips",
    m044_05:"All",
    m044_06:"Biggest Wins",
    m044_07:"My Bets",
    m044_09:"Jackpot",
    m044_10:"Bonus",
    m044_11:"Freespin",
    m045_01:"GAMERS SHOP",
    m045_02:"Open",
    m045_03:"HOW TO WIN NFTS?",
    m045_04:"Learn",
    m045_05:"REDEEM CODE",
    m045_06:"Send",
    m045_07:"HOW TO GET FREE CHIPS?",
    m045_08:"HOURLY LUCKY WHEEL",
    m045_09:"Soon",
    m045_10:"Play",
    m045_11:"JACKPOT",
    m045_12:"Available Soon\nto Spincity NFTs holders",
    m046_01:"Contact Us",
    m046_02:"Discord Bot",
    m046_03:"Blog",
    m046_04:"About Us",
    m046_05:"© 2024 Dragon Dream Digital Ltd All rights reserved",           //BlockSpinGaming Ltd
    m046_06:"Escazu San Rafael, Edificio Ebc Corporativo, 8, San Jose, Costa Rica",
    m046_07:"Powered by",
    m046_08:"Privacy Policy",
    m046_09:"JOIN OUR DISCORD COMMUNITY",
    m047_01:"Redeem Code",
    m047_02:"Enter your code to redeem your reward",
    m047_03:"Send code",
    m047_04:"Enter your code",
    m047_05:"Pasted",
    m048_01:"How to win free chips?",
    m048_021:"Connect everyday",
    m048_022:"to increase your daily free chips reward",
    m048_031:"Levelling up",
    m048_032:"also earns you more chips, so keep playing and advancing to unlock new rewards",
    m048_041:"Collect achievements",
    m048_042:"and win up to 1 million chips. Example playing same slot machine 10,000 times",
    m048_051:"Participate in daily quests",
    m048_052:"on Zealy.io to earn additional opportunities to win free chips and other rewards",
    m048_061:"Refer friends",
    m048_062:"to the game and earn even more chips to play with",
    m048_071:"Follow our social media",
    m048_072:"where we regularly drop redeem codes for free chips and do giveaways",
    m049_01:"How to win NFTs?",
    m049_021:"COLLECT GEMS",
    m049_022:"automatically by just playing for free any game",
    m049_031:"CONVERT GEMS",
    m049_032:"to Raffle tickets",
    m049_041:"BURN TICKETS",
    m049_042:"to enter daily, weekly or monthly NFTs Raffle",
    m050_01:"Achievement statistics",
    m050_02:"All time CHIPS earned",
    m050_03:"Prize",
    m051_01:"Create a free account and unlock all the features",
    m051_02:"GENERATE GEMS JUST BY PLAYING",
    m051_03:"Trade them for raffle tickets and win rewards",
    m051_04:"FREE CHIPS",
    m051_05:"Just visit the site every day and get them",
    m051_06:"POWER UP WITH EXPERIENCE POINTS",
    m051_07:"Level up for chip rewards and a surge in daily free chips",
    m051_08:"BATTLE FOR THE TOP",
    m051_09:"Join the leaderboard fray, where the top 10 warriors reap rewards regularly",
    m051_10:"SPIN LIMITLESS",
    m051_11:"Embark on an infinite spinning journey",
    m051_12:"REFER A FRIEND",
    m051_13:"Invite your friends and get free chips",
    m051_14:"Create an account now",
    m052_01:"Lucky Wheel",
    m052_02:"NEXT SPIN",
    m052_03:"You've already Spin this hour",
    m052_04:"You won",
    m052_05:"Scratch card",
    m052_06:"No wins.",
    m052_07:"Play now",
    m052_08:"Mining ratio",
    m052_09:"Please login with Discord and complete the quests",
    games_desc:{
        aladdin_desc: "13 Winning Lines\nMedium Volatility\nBonus Game",
        apache_desc: "243 Ways to win\nHigh volatility\nFree Spins\nChance of Multiplier on every spin",
        bollywood_desc:"Soon",
        cartel_desc: "10 Winning Lines\nWin Both Ways\nMedium Volatility\nExpanding Wilds\nBonus Game",
        cleopatra_desc: "15 Winning Lines\nHigh Volatility\nAccumulator\nExpanding Wilds",
        copa_desc:  "20 Winning Lines\nVery High Volatility\nExpanded Symbols\nBonus Game\nFree spins",
        hulaspin_desc: "20 Winning Lines\nMedium Volatility\nFree Spins",
        imperialrome_desc: "20 Winning Lines\nHigh volatility\nUp to 50 Free Spins",
        ninjastrike_desc: "20 Winning Lines\nHigh volatility\nFree Spins\nRetrigers",
        kingarthur_desc: "25 Winning Lines\nLow Volatility\nFree Spins",
        lajam_desc: "15 Winning Lines\nHigh Volatility\nAccumulator\nExpanding Wilds",
        muertos_desc: "20 Winning Lines\nHigh Volatility\nFree Spins\nGiant symbols",
        nightoffear_desc: "13 Winning Lines\nMedium Volatility\nBonus Game",
        paris_desc: "25 Winning Lines\nLow Volatility\nFree Spins",
        pirates_desc: "25 Winning Lines\nMedium Volatility\nAccumulator\nBonus Game",
        route66_desc: "30 Winning Lines\nHigh Volatility\nBonus Game\nSticky Wilds",
        samurai_desc: "25 Winning Lines\nMedium Volatility\nBonus Multiplier",
        sherlock_desc: "New type of cascading slots...",
        spymaster_desc: "30 Winning Lines\nHigh Volatility\nBonus Game\nBonus Multiplier",
        surfin_desc: "30 Winning Lines\nHigh Volatility\nBonus Game\nSticky Wilds",
        thebigfive_desc: "25 Winning Lines\nLow Volatility\nFree Spins",
        tigerland_desc: "20 Winning Lines\nHigh Volatility\nAccumulator\nBonus Multiplier",
        tokio_desc:  "20 Winning Lines\nVery High Volatility\nExpanded Symbols\nBonus Game\nFree spins",
        vegas_desc: "25 Winning Lines\nLow Volatility\nFree Spins",
        wildwest_desc: "20 Winning Lines\nMedium Volatility\nFree Spins",

        scratch_high_vol_desc: "High Volatility",
        variable_vol_desc: "Variable Volatility",
        scratch_very_high_vol_desc: "Very High Volatility",
        scratch_extreme_vol_desc: "Extreme\nVolatility",
    },
    m053_01:"Number of free gems per chips played,",
    m053_02:"-10 means",
    m053_03:"gem(s) per 10 chips played.",
    m054_01:"Your SECRET code:",
    m054_02:"Connect your Telegram account",
    m054_03:"1) Open game in",
    m054_04:"Enter secret code...",
    m054_05:"2) Generate SECRET code\n3) Paste SECRET code here",
    m054_06:"Telegram",
    //
}


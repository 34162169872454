<template>
    <router-view ref="wallet"/>
</template>

<script>
    import { defineComponent } from 'vue';
    import Config from "../scripts/Config";
    import Utils from "../scripts/Utils";
    import GameSignals from "../scripts/GameSignals";
    export default defineComponent({              //Not working debugger in chrome

    //export default {
        name: "AppVue",
        computed: {
        },
        created() {
            MyLog("Start AppVue.created");
            this.$store.state.router=this.$router;
            Utils.setEnvOptions(this.$store.state);     //Default game settings for Production. Change game env for test
            this.checkUserAction();                //Load images after user action
            Utils.setIconsPath();                   //comparison image names with hash names from 'file loader'
            Utils.setActiveGames();         //Set active slot games to state.achievSlotGames {} (isSoon!==true, testing!==true)

            GameSignals.goHome.add(this.goHome,this);
            GameSignals.orientationChanged.add(this.orientationChanged, this);
            setTimeout(()=>{
                Utils.setIcons(appVue.$store.state.gameType);
            },10)

            //let resizeTimer;
            window.addEventListener("resize", () => {
                Utils.animationStopper();
                /*
                document.body.classList.add("resize-animation-stopper");
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    document.body.classList.remove("resize-animation-stopper");
                }, 400);
                //*/
            });
            this.checkTelegram();
        },
        data: function () {
            return {

            }
        },
        watch: {
            $route(to, from) {
                appVue.$store.state.routeQuery='{}';
                try{
                    appVue.$store.state.routeQuery=this.$route.query?JSON.stringify(this.$route.query):'{}';
                }catch (e) {
                    console.log('AppVue.$route catch: ',e.message,'\nstack: ',e.stack)
                }

                if (!Config.corrPath.includes(to.path)){
                    MyLog("to.path "+to.path+" not found. Redirect to default path= "+Config.defaultPath);
                    //this.$router.push({ path: Config.defaultPath, fullPath: '/' })
                    this.$router.push({path:Config.defaultPath,query:this.$route.query});
                }
                else{

                }
                localStorage.removeItem("path");

            },
        },
        methods:{
            checkUserAction(){
                document.body.addEventListener('mousemove', this.firstUserAction);
                document.body.addEventListener('scroll', this.firstUserAction);
                document.body.addEventListener('keydown', this.firstUserAction);
                document.body.addEventListener('click', this.firstUserAction);
                document.body.addEventListener('touchstart', this.firstUserAction);
            },
            checkTelegram(){
                try {
                    const tg = window.Telegram.WebApp;
                    if (tg && tg.initData){
                        tg.expand();
                        tg.disableVerticalSwipes();
                        this.$store.state.tgMode=true;
                        MyLog('tg.initData:')
                        MyLog(JSON.stringify(tg.initData))
                    }
                } catch (e) {
                    console.log('AppVue.checkTelegram catch: ',e.message,'\nstack: ',e.stack)
                }
            },
            firstUserAction(event){
                MyLog('firstUserAction called');
                this.$store.state.firstUserAction=true;
                Utils.setIcons(this.$store.state.gameType);

                document.body.removeEventListener('mousemove', this.firstUserAction);
                document.body.removeEventListener('scroll', this.firstUserAction);
                document.body.removeEventListener('keydown', this.firstUserAction);
                document.body.removeEventListener('click', this.firstUserAction);
                document.body.removeEventListener('touchstart', this.firstUserAction);
            },
            goHome(){
                localStorage.removeItem("path");
                this.$router.push({path:Config.defaultPath,query:this.$route.query});
            },

            orientationChanged(){
                //not updated on signal with this.$store.state
                if(appVue.$store.state.pageCurrent===0){                   //if pageOur visible (page 0)
                    if (appVue.$store.state.firstUserAction===false)
                        appVue.$store.state.firstUserAction=true;
                    setTimeout(()=>{
                        Utils.setIcons(appVue.$store.state.gameType);
                    },10)
                }
            },
        },
    }
    );


</script>

<style lang="css">
    * {     /*  Disable double touch zoom*/
        touch-action: manipulation;
    }

    .resize-animation-stopper * {
        animation: none !important;
        transition: none !important;
    }/**/

    .unselectable {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none;   /* Chrome/Safari/Opera */
        -khtml-user-select: none;    /* Konqueror */
        -moz-user-select: none;      /* Firefox */
        -ms-user-select: none;       /* Internet Explorer/Edge */
        user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
    }

    .c-user_info_title_text{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        background: linear-gradient(95.19deg,#0cff7c -16.14%,#00af90 137.96%);
        text-align: -webkit-center;
        white-space: nowrap;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    }

    .c-iframe{
        display: flex;
        overflow: hidden;
        position: absolute;
        transform: translate(0%, 0%);
        padding:0;
        margin:0;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .c-iframe_bg{
        display: flex;
        overflow: hidden;
        position: relative;
        border: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .c-components_body{
        /*Body style for components in Zero level on device screen*/
        display: flex;
        overflow: hidden;
        position: absolute;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding:0;
        margin:0;
        width: 100vw;
        height: 100vh;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;
    }
    .c-components_abs{
        /*Second style into body for components in Zero level on device screen*/
        display: flex;
        overflow: hidden;
        position: absolute;
        transform: translate(0%, 0%);
        padding:0;
        margin:0;

        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-app_css{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: hidden;
    }
    .c-app_ccs{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
    }
    .c-app_ccc{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .c-app_csc{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
    }

    .c-app_rss{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: hidden;
    }
    .c-app_rcs{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
    }
    .c-app_rcc{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .c-app_rsc{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
    }
    .c-app_rbc{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
    }

    /*   scroll dinamic   */
    .c-app_scroll{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: visible;
    }

</style>

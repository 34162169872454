<template>
    <div class="c-components_body unselectable" style="background: linear-gradient(94deg, #00C6FB 0.27%, #005BEA 105.74%);">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <div class="c-pop_sig_rel"
                 :style="{'width': realWidth+'px','height': realHeight+'px',}">

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>

                <!--   title text   -->
                <div class="c-pop_sig_title"
                     :style="{'width': realWidth-190*ss*xMult+'px','max-width': realWidth-190*ss*xMult+'px','height': 'auto',
                        'margin-top':0*ss*xMult+'px',}"
                >
                    <div class="c-pop_sig_title_text"
                         :style="{'font-size': 60*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                    >
                        {{messages.m008_1}}
                    </div>
                </div>

                <!--   Center "sign up" and "connect discord" window    -->
                <div class="c-pop_sig_center"
                     :style="{'width': realWidth-60*ss+'px','max-width': realWidth-60*ss+'px','height': 'auto',
                        'flex-direction':orient==='V'?'column':'row','gap': 30*ss*xMult+'px','margin-top':30*ss*xMult+'px',}"
                >
                    <!--   Facebook Google Guest sign up    -->
                    <div class="c-pop_sig_net"
                         :style="{'width': 1000*ss*xMult+'px','height': 550*ss*xMult+'px','border-radius': 40*ss*xMult+'px',
                            'gap': 30*ss*xMult+'px',}"
                    >
                        <!--   Google   -->
                        <div class="c-pop_sig_google"
                             :style="{'width': 800*ss*xMult+'px','height': 110*ss*xMult+'px','border-radius': 10*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                             @click="btnGoogleClick"
                        >
                            <img class="c-pop_sig_img" alt=""
                                 :src="googleSvg"
                                 :style="{'width': 90*ss*xMult+'px','height': 60*ss*xMult+'px','margin-left': 40*ss*xMult+'px',}"
                            />

                            <div class="c-pop_sig_google_text"
                                 :style="{'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                            >
                                {{messages.m010}}
                            </div>
                        </div>

                        <!--   OR   -->
                        <div class="c-pop_sig_text" style="opacity: 0.6;"
                             :style="{'width': 'auto','height': 'auto','font-size': 35*ss*xMult+'px','line-height': 40*ss*xMult+'px',}"
                        >
                            {{messages.m002}}
                        </div>

                        <!--   Facebook   -->
                        <div class="c-pop_sig_facebook"
                             :style="{'width': 800*ss*xMult+'px','height': 110*ss*xMult+'px','border-radius': 10*ss*xMult+'px','gap': 30*ss*xMult+'px',}"
                             @click="btnFaceClick"
                        >
                            <img class="c-pop_sig_img" alt=""
                                 :src="facebookSvg"
                                 :style="{'width': 70*ss*xMult+'px','height': 70*ss*xMult+'px','margin-left': 40*ss*xMult+'px',}"
                            />

                            <div class="c-pop_sig_text"
                                 :style="{'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                            >
                                {{messages.m009}}
                            </div>
                        </div>

                    </div>

                    <!--   OR    -->
                    <div class="c-pop_sig_text"
                         :style="{'width': 'auto','height': 'auto','font-size': 40*ss*xMult+'px','line-height': 40*ss*xMult+'px',}"
                    >
                        {{messages.m002}}
                    </div>

                    <!--   Discord Connect    -->
                    <div class="c-pop_sig_discord"
                         :style="{'width': 1000*ss*xMult+'px','height': 550*ss*xMult+'px','border-radius': 40*ss*xMult+'px','gap': 45*ss*xMult+'px',}"
                    >
                        <div class="c-pop_sig_discord_2"
                             :style="{'width': 800*ss*xMult+'px','height': 250*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
                        >
                            <img class="c-pop_sig_img" alt="" style="transform: rotate(21.779deg);"
                                 :src="discord_3d"
                                 :style="{'width': 250*ss*xMult+'px','height': 250*ss*xMult+'px','margin-left': 0*ss*xMult+'px',}"
                            />

                            <div class="c-pop_sig_discord_3"
                                 :style="{'width': 535*ss*xMult+'px','height': 250*ss*xMult+'px','gap': 20*ss*xMult+'px',}"
                            >
                                <div class="c-pop_sig_text" :style="{'font-size': 50*ss*xMult+'px','line-height': 50*ss*xMult+'px',}">
                                    {{messages.m013}}
                                </div>
                                <img class="c-pop_sig_img" alt=""
                                     :src="discord_text"
                                     :style="{'width': 'auto','height': 56*ss*xMult+'px','margin-left': 0*ss*xMult+'px',}"
                                />
                                <div class="c-pop_sig_discord_2" style="overflow: hidden"
                                     :style="{'width': 535*ss*xMult+'px','height': 'auto','gap': 15*ss*xMult+'px',}"
                                >
                                    <div class="c-pop_sig_text" :style="{'font-size': 45*ss*xMult+'px','line-height': 50*ss*xMult+'px',}">
                                        {{messages.m014}}
                                    </div>
                                    <div class="c-pop_sig_text" style="color:#FFE500;"
                                         :style="{'font-size': 45*ss*xMult+'px','line-height': 50*ss*xMult+'px',}">
                                        {{messages.m015}}
                                    </div>

                                </div>
                            </div>
                        </div>


                        <ButtonSvgOval :svg-normal="discordSvg"
                                       :bg-width="800*ss*xMult"
                                       :max-width="760*ss*xMult-btnRad/2*xMult"
                                       :bg-height="btnRad*0.85*xMult"
                                       :font-size="btnRad/3*xMult"
                                       :svg-width="btnRad/2*xMult"
                                       :svg-height="btnRad/2*xMult"
                                       :btn-text="messages.m012"
                                       :callback="btnDiscordClick" :callback-wait="true"
                                       :background-val="'linear-gradient(180deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.00) 58.54%), linear-gradient(95deg, #DC06FF -6.49%, #8606B2 108.32%)'"
                                       :borderVal="'linear-gradient(102.76deg, #76069A 3.6%, #8606B2 53.89%, #DC06FF 99.19%)'"
                                       :clickScale="0.97"
                        />
                    </div>
                </div>

                <!--   Terms and conditions text    -->
                <div class="c-pop_sig_down"
                     :style="{'width': realWidth-60*ss+'px','max-width': realWidth-60*ss+'px','height': 'auto',
                        'gap': 20*ss*xMult+'px','margin-top':40*ss*xMult+'px',}"
                >
                    <div class="c-pop_sig_down_text"
                         :style="{'font-size': 40*ss*xMult+'px','line-height': 55*ss*xMult+'px',}"
                    >
                        {{messages.m016}}
                        <a @click="termsClick" style="color:#FFE500;">{{messages.m016_1}}</a>
                        {{messages.m016_2}}
                        <a @click="privacyClick" style="color:#FFE500;">{{messages.m016_3}}</a>
                    </div>
                    <div class="c-pop_sig_down_text"
                       :style="{'font-size': 40*ss*xMult+'px','line-height': 55*ss*xMult+'px',}"
                    >
                        {{messages.m016_4}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import discord_3d from '@/assets/images/discord_3d.webp';
    import discord_text from '@/assets/svg/discord_text.svg';
    import discordSvg from '@/assets/svg/discord.svg';
    import facebookSvg from "@/assets/svg/facebk.svg";
    import googleSvg from "@/assets/svg/google.svg";
    import Utils from "@/scripts/Utils";

    export default {
        name: "PopupSignup",
        components: {ButtonSvg, ButtonSvgOval,},
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","orient","messages","xMult","getLeft"]),
            btnRad(){
                return 135*this.$store.state.ss
            },
        },
        created() {

        },
        data: function () {
            return {
                btn_xSvg:btn_xSvg,discordSvg:discordSvg,facebookSvg:facebookSvg,googleSvg:googleSvg,discord_3d:discord_3d,discord_text:discord_text,
            }
        },
        methods:{
            btnDiscordClick(){
                Utils.loginDiscord(this.$route);
            },
            btnFaceClick(){
                Utils.loginFacebook(this.$route);
            },
            btnGoogleClick(){
                Utils.loginGoogle(this.$route);
            },
            btn_xClick(){
                this.$store.state.popupVisible.signup=false;
            },
            privacyClick(){
                this.$store.state.popupVisible.privacy=true;
            },
            termsClick(){
                this.$store.state.popupVisible.terms=true;
            },
        },
    }

</script>

<style lang="css" scoped>
    .c-pop_sig_rel{
        display: flex;
        position: relative;
        overflow: hidden;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        overflow-x: hidden;
        overflow-y: auto;

    }

    .c-pop_sig_title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-pop_sig_title_text{
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        overflow: visible;
        color: #FFFFFF;

        text-align: -webkit-center;
        /*word-wrap: break-word;/**/
        /*white-space: nowrap;        /*Нет переноса не переносит*/
    }

    .c-pop_sig_center{
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-pop_sig_net{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        align-items: center;

        background: radial-gradient(178.9% 78.61% at 47.02% 0%, #0D4455 0%, #030F20 100%);
    }

    .c-pop_sig_discord{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        align-items: center;

        background: radial-gradient(56.97% 50.51% at 92.9% 100%, rgba(83, 95, 225, 0.60) 0%, rgba(83, 95, 225, 0.00) 100%),
            radial-gradient(109.84% 101.93% at -17.02% -9.49%, #535FE1 0%, rgba(83, 95, 225, 0.00) 100%), #031223;
    }

    .c-pop_sig_text{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: visible;
        color: #FFFFFF;
        text-align: -webkit-center;
    }

    .c-pop_sig_google{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        background: white;
    }

    .c-pop_sig_img{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .c-pop_sig_google_text{
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 400;
        overflow: visible;
        color: #000000;
        opacity: 0.6;
        text-align: -webkit-center;
    }

    .c-pop_sig_facebook{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        background: linear-gradient(93.92deg,#3849aa .27%, #4356c2 105.74%);
    }

    .c-pop_sig_discord_2{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        /*background: rgba(255, 255, 255, 0.3);/**/
    }
    .c-pop_sig_discord_3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-pop_sig_down{
        display: flex;
        overflow: hidden;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-pop_sig_down_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        color: #FFFFFF;

        text-align: -webkit-center;
        /*word-wrap: break-word;/**/
        white-space: break-spaces;
    }

</style>

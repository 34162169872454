<template>
    <div class="c-components_body unselectable" style="background: white;"
         :style="{'top': 0*ss+'px','left':0*ss+'px',}">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <div class="c-pop_terms_bg"
                 :style="{'width': realWidth+'px','height': realHeight+'px',}"
            >
                <div v-if="getReady" class="c-pop_terms_text"
                     :style="{'width': realWidth-100*ss*xMult+'px','height': 140*ss*xMult+'px','margin-left': 40*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
                >
                    <h2>{{terms.h2[1]}}</h2>
                </div>

                <div class="c-pop_terms_bg"
                     :style="{'width': realWidth+'px','height': realHeight-150*ss*xMult+'px',}"
                >
                    <div v-if="getReady" class="c-pop_terms_scroll"
                         :style="{'width': realWidth-40*ss*xMult+'px','height': 'auto','margin-left': 40*ss*xMult+'px',}"
                    >
                        <p :style="{'margin-top': 0*ss*xMult+'px',}">
                            {{terms.p[1]}}
                        </p>
                        <p>
                            {{terms.p[2]}}
                        </p>
                        <p>
                            {{terms.p[3]}}
                        </p>

                        <p>
                            {{terms.p[4]}}
                        </p>

                        <p>
                            {{terms.p[5]}}
                        </p>
                                        <!--    1. Changes to the Terms and updates    -->
                        <ul>
                            <h3>
                                {{terms.h3[1]}}
                            </h3>
                        </ul>

                        <p>
                            {{terms.p[6]}}
                        </p>
                        <p>
                            {{terms.p[7]}}
                        </p>
                        <p>
                            {{terms.p[8]}}
                        </p>

                                            <!--    2. Account    -->
                        <ul>
                            <h3>
                                {{terms.h3[2]}}
                            </h3>
                        </ul>
                        <p>
                            {{terms.p[9]}}
                        </p>
                        <p>
                            {{terms.p[10]}}
                        </p>
                        <p>
                            {{terms.p[11]}}
                        </p>
                        <p>
                            {{terms.p[12]}}
                        </p>

                        <p>
                            {{terms.p[13]}}
                        </p>

                                        <!--    3. User obligations    -->
                        <ul>
                            <h3>
                                {{terms.h3[3]}}
                            </h3>
                        </ul>

                        <li>
                            {{terms.li[1]}}
                        </li>
                        <li>
                            {{terms.li[2]}}
                        </li>
                        <li>
                            {{terms.li[3]}}
                        </li>
                        <li>
                            {{terms.li[4]}}
                        </li>
                        <li>
                            {{terms.li[5]}}
                        </li>
                        <li>
                            {{terms.li[6]}}
                        </li>
                        <li>
                            {{terms.li[7]}}
                        </li>
                        <li>
                            {{terms.li[8]}}
                        </li>
                        <li>
                            {{terms.li[9]}}
                        </li>
                        <li>
                            {{terms.li[10]}}
                        </li>
                        <li>
                            {{terms.li[11]}}
                        </li>
                        <li>
                            {{terms.li[12]}}
                        </li>

                                <!--    4. Intellectual property    -->
                        <ul>
                            <h3>
                                {{terms.h3[4]}}
                            </h3>
                        </ul>
                        <p>
                            {{terms.p[14]}}
                        </p>
                        <p>
                            {{terms.p[15]}}
                        </p>
                        <p>
                            {{terms.p[16]}}
                        </p>
                        <p>
                            {{terms.p[17]}}
                        </p>
                        <p>
                            {{terms.p[18]}}
                        </p>
                                <!--    5 User content    -->
                        <ul>
                            <h3>
                                {{terms.h3[5]}}
                            </h3>
                        </ul>

                        <p>
                            {{terms.p[19]}}
                        </p>
                        <p>
                            {{terms.p[20]}}
                        </p>
                        <p>
                            {{terms.p[21]}}
                        </p>
                        <p>
                            {{terms.p[22]}}
                        </p>
                        <p>
                            {{terms.p[23]}}
                        </p>
                        <p>
                            {{terms.p[24]}}
                        </p>
                        <p>
                            {{terms.p[25]}}
                        </p>
                        <p>
                            {{terms.p[26]}}
                        </p>
                        <p>
                            {{terms.p[27]}}
                        </p>
                        <p>
                            {{terms.p[28]}}
                        </p>

                            <li>
                                {{terms.li[13]}}
                            </li>
                            <li>
                                {{terms.li[14]}}
                            </li>
                            <li>
                                {{terms.li[15]}}
                            </li>
                            <li>
                                {{terms.li[16]}}
                            </li>

                        <p>
                            {{terms.p[29]}}
                        </p>
                            <li>
                                {{terms.li[17]}}
                            </li>
                            <li>
                                {{terms.li[18]}}
                            </li>
                            <li>
                                {{terms.li[19]}}
                            </li>

                                <!--    6 Virtual    -->
                        <ul>
                            <h3>
                                {{terms.h3[6]}}
                            </h3>
                        </ul>
                        <p>
                            {{terms.p[30]}}
                        </p>
                        <p>
                            {{terms.p[31]}}
                        </p>
                        <p>
                            {{terms.p[32]}}
                        </p>

                            <li>
                                {{terms.li[20]}}

                            </li>
                            <li>
                                {{terms.li[21]}}
                            </li>
                            <li>
                                {{terms.li[22]}}
                            </li>
                            <li>
                                {{terms.li[23]}}
                            </li>
                            <li>
                                {{terms.li[24]}}
                            </li>
                            <li>
                                {{terms.li[25]}}
                            </li>
                            <li>
                                {{terms.li[26]}}
                            </li>
                            <li>
                                {{terms.li[27]}}
                            </li>
                            <li>
                                {{terms.li[28]}}
                            </li>

                        <p>
                            {{terms.p[33]}}
                        </p>

                            <li>
                                {{terms.li[29]}}
                            </li>
                            <li>
                                {{terms.li[30]}}
                            </li>
                            <li>
                                {{terms.li[31]}}
                            </li>
                            <li>
                                {{terms.li[32]}}
                            </li>

                                <!--    7 Privacy    -->
                        <ul>
                            <h3>
                                {{terms.h3[7]}}
                            </h3>
                        </ul>

                        <p>
                            {{terms.p[34]}}
                        </p>

                        <!--    8 Disclaimer    -->
                        <ul>
                            <h3>
                                {{terms.h3[8]}}
                            </h3>
                        </ul>

                        <p>
                            {{terms.p[35]}}
                        </p>
                        <p>
                            {{terms.p[36]}}
                        </p>
                        <p>
                            {{terms.p[37]}}
                        </p>

                        <li>
                            {{terms.li[33]}}
                        </li>
                        <li>
                            {{terms.li[34]}}
                        </li>
                        <li>
                            {{terms.li[35]}}
                        </li>
                        <li>
                            {{terms.li[36]}}
                        </li>
                        <li>
                            {{terms.li[37]}}
                        </li>
                        <li>
                            {{terms.li[38]}}
                        </li>
                        <li>
                            {{terms.li[39]}}
                        </li>
                        <li>
                            {{terms.li[40]}}
                        </li>


                        <p>
                            {{terms.p[38]}}
                        </p>
                        <p>
                            {{terms.p[39]}}
                        </p>
                        <p>
                            {{terms.p[40]}}
                        </p>
                        <p>
                            {{terms.p[41]}}
                        </p>
                        <p>
                            {{terms.p[42]}}
                        </p>

                            <li>
                                {{terms.li[41]}}
                            </li>
                            <li>
                                {{terms.li[42]}}
                            </li>
                            <li>
                                {{terms.li[43]}}
                            </li>

                        <p>
                            {{terms.p[43]}}
                        </p>
                        <p>
                            {{terms.p[44]}}
                        </p>

                        <!--    9 Termination    -->
                        <ul>
                            <h3>
                                {{terms.h3[9]}}
                            </h3>
                        </ul>

                        <p>
                            {{terms.p[45]}}
                        </p>
                        <p>
                            {{terms.p[46]}}
                        </p>
                        <p>
                            {{terms.p[47]}}
                        </p>
                        <p>
                            {{terms.p[48]}}
                        </p>
                        <p>
                            {{terms.p[49]}}
                        </p>
                        <p>
                            {{terms.p[50]}}
                        </p>
                        <p>
                            {{terms.p[51]}}
                        </p>
                        <p>
                            {{terms.p[52]}}
                        </p>
                        <p>
                            {{terms.p[53]}}
                        </p>
                        <p>
                            {{terms.p[54]}}
                        </p>
                        <p>
                            {{terms.p[55]}}
                        </p>

                        <ul>
                            <!--    10 Governing    -->
                            <h3>
                                {{terms.h3[10]}}
                            </h3>
                        </ul>

                        <p>
                            {{terms.p[56]}}
                        </p>
                        <p>
                            {{terms.p[57]}}
                        </p>
                        <p>
                            {{terms.p[58]}}
                        </p>
                        <ul>
                            <!--    11 Arbitration    -->
                            <h3>
                                {{terms.h3[11]}}
                            </h3>
                        </ul>
                        <p>
                            {{terms.p[59]}}
                        </p>
                        <p>
                            {{terms.p[60]}}
                        </p>
                        <p>
                            {{terms.p[61]}}
                        </p>
                        <p>
                            {{terms.p[62]}}
                        </p>
                        <p>
                            {{terms.p[63]}}
                        </p>
                        <p>
                            {{terms.p[64]}}
                        </p>
                        <p>
                            {{terms.p[65]}}
                        </p>
                        <p>
                            {{terms.p[66]}}
                        </p>
                        <ul>
                            <!--    12 General    -->
                            <h3>
                                {{terms.h3[12]}}
                            </h3>
                        </ul>
                        <p>
                            {{terms.p[67]}}
                        </p>
                        <p>
                            {{terms.p[68]}}
                        </p>
                        <p>
                            {{terms.p[69]}}
                        </p>
                        <p>
                            {{terms.p[70]}}
                        </p>
                        <p>
                            {{terms.p[71]}}
                        </p>
                    </div>
                </div>

                <!--   X button   -->
                <div style="position: absolute; display: flex"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // Size of Terms and Conditions 35kB (10% from source code). I make loading information from text files

    import {mapGetters} from "vuex";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import btn_xSvg from "@/assets/svg/btn_x_black.svg";
    import terms_pTxt from "@/assets/rules/terms_p.txt";        //72 lines minimum
    import terms_liTxt from "@/assets/rules/terms_li.txt";      //44 lines
    import terms_h2Txt from "@/assets/rules/terms_h2.txt";
    import terms_h3Txt from "@/assets/rules/terms_h3.txt";      //12 lines
    import Utils from "@/scripts/Utils";
    import privacy_h3Txt from "@/assets/rules/privacy_h3.txt";

    export default {
        name: "Terms",
        components: {ButtonSvg, },
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight", "orient", "terms","getLeft"]),
            xMult(){
                if (this.$store.state.orient==='H')
                    return 1.55;
                return 1;
            },
            getReady(){         //terms:{p:null,li:null,h2:null,h3:null},           //Terms and Conditions
                const state=this.$store.state;
                if (state.terms.p && state.terms.li && state.terms.h2 && state.terms.h3)
                    return true;
                return false;
            }
        },
        data: function () {
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),
            }
        },
        created() {
            const state=this.$store.state;
            fetch(Utils.getGlobalLink(terms_pTxt))
                .then((res) => res.text())
                .then((text) => {
                    state.terms.p=text.split(/\r\n|\n/);
                    state.terms.p.unshift('');          //Add empty 0 line for easy check lines in text file with intellijIdea
                })
                .catch((e) => console.error(e));

            fetch(Utils.getGlobalLink(terms_liTxt))
                .then((res) => res.text())
                .then((text) => {
                    state.terms.li=text.split(/\r\n|\n/);
                    state.terms.li.unshift('');
                })
                .catch((e) => console.error(e));

            fetch(Utils.getGlobalLink(terms_h2Txt))
                .then((res) => res.text())
                .then((text) => {
                    state.terms.h2=text.split(/\r\n|\n/);
                    state.terms.h2.unshift('');
                })
                .catch((e) => console.error(e));

            fetch(Utils.getGlobalLink(terms_h3Txt))
                .then((res) => res.text())
                .then((text) => {
                    state.terms.h3=text.split(/\r\n|\n/);
                    state.terms.h3.unshift('');
                })
                .catch((e) => console.error(e));
        },

        methods:{
            btn_xClick(){
                this.$store.state.popupVisible.terms=false;
            },
        },

    };

</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-pop_terms_bg {
        overflow: hidden;

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: white;

    }

    .c-pop_terms_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-size: 16px;
        color: black;
        font-weight: 400
    }

    .c-pop_terms_scroll {
        overflow-x: hidden;
        overflow-y: auto;

        font-family: 'Rubik';
        position: relative;
        font-size: 16px;
        background-color: white;
        color: black;
        font-weight: 400;
    }

    h2{
        margin: 10px;
        font-family: 'Rubik';
        color: black;
    }
    h3{
        margin: 0px;
        font-family: 'Rubik';
        font-weight: bold;
        color: black;
    }


    p {

    }

    ul {
        justify-content: center;
        align-items: center;
        text-align: -webkit-center;
        padding-inline-start: 0;
    }

</style>

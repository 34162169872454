<template>
    <div class="c-login_btns"
         :style="{'gap': 40*ss+'px',}"
    >
        <ButtonSvgOval :svg-normal="null"
                       :bg-width="btnRad*3"
                       :bg-height="btnRad*0.85"
                       :svg-width="btnRad/2"
                       :svg-height="btnRad/2"
                       :btn-text="'Login'"
                       :font-size="btnRad/3"
                       :click-scale="0.95"
                       :callback="btnLoginClick" :callback-wait="true"
                       :borderVal="'linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%)'"
        />

        <!--
        :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(95.19deg,#0cff7c -16.14%,#00af90 137.96%) var(--x,0)/200%'"
        -->
        <div class="c-login_btns_or_text"
             :style="{'width': 'auto','font-size': btnRad/3+'px','line-height': btnRad/2+'px'}">
            {{messages.m007}}
        </div>
        <ButtonSvgOval :svg-normal="Utils.getGlobalLink(chipsSvg)"
                       :bg-width="800*ss"
                       :max-width="760*ss-btnRad/2"
                       :bg-height="btnRad*0.85"
                       :font-size="btnRad/3"
                       :svg-width="btnRad/2"
                       :svg-height="btnRad/2"
                       :btn-text="messages.m003"
                       :callback="btnSignUpClick" :callback-wait="true"
                       :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                       :borderVal="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                       :click-scale="0.97"
        />
        <div class="c-login_btns_or_text"
             :style="{'width': 'auto','font-size': btnRad/3+'px','line-height': btnRad/2+'px'}">
            {{messages.m002}}
        </div>


        <ButtonSvgOval :svg-normal="Utils.getGlobalLink(chipsSvg)"
                       :bg-width="800*ss"
                       :max-width="760*ss-btnRad/2"
                       :bg-height="btnRad*0.85"
                       :font-size="btnRad/3"
                       :svg-width="btnRad/2"
                       :svg-height="btnRad/2"
                       :btn-text="messages.m005"
                       :callback="btnGuestClick" :callback-wait="true"
                       :backgroundVal="'linear-gradient(180deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.00) 58.54%), linear-gradient(92deg, #FFC83C -9.33%, #DC8400 92.67%)'"
                       :borderVal="'linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%)'"
                       :clickScale="0.95"
        />
        <div class="c-login_btns_lose_text"
             :style="{'width': 'auto','height': 'auto','font-size': btnRad/3+'px','line-height': btnRad/2+'px',
                'max-width':(800*ss-btnRad/2)+'px'}"
        >
            {{messages.m006}}
        </div>
    </div>


    <!---
        :style="{'width': svgWidth*ss*scale+'px','height': svgHeight*ss*scale+'px'}"
                --->
</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import Utils from "@/scripts/Utils";
    import chipsSvg from '@/assets/svg/chips.svg';

    export default defineComponent({
        name: 'LoginButtons',
        computed: {
            ...mapGetters(["ss","messages"]),
            btnRad(){
                return 135*this.$store.state.ss
            },
        },
        components: {ButtonSvgOval},
        data(){
            return {
                chipsSvg:chipsSvg,Utils:Utils,
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btnGuestClick(){
                Utils.loginGuest();
            },
            btnLoginClick(){
                this.$store.state.popupVisible.login=true;
            },
            btnSignUpClick(){
                this.$store.state.popupVisible.signup=true;
            },
        },


    });
</script>

<style lang="css" scoped>
    .c-login_btns{
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0;
        margin:0;
        /*background: rgba(255, 0, 0, 0.2);/**/

    }

    .c-login_btns_or_text{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF90;

        font-family: Rubik;
        white-space: nowrap;
        font-style: normal;
        font-weight: 600;
    }

    .c-login_btns_lose_text{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF90;

        font-family: Rubik;
        white-space: normal;
        font-style: normal;
        font-weight: 600;
    }

</style>

<template>
    <div class="c-components_body unselectable" style="background: radial-gradient(52.63% 87.8% at 50.03% 4.04%, #0D4455 0%, #030F20 100%), linear-gradient(94deg, #00C6FB 0.27%, #005BEA 105.74%);"
         :style="{'justify-content':orient==='V'?'flex-start':'center'}">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">

            <!--   Vertical orientation   -->
            <div v-if="orient==='V'" style="overflow: hidden;">
                <!--   User name   -->
                <div class="c-user_account_name"
                     :style="{'width': 1080*ss*xMult+'px','height': (150*ss+btnRad)*xMult+'px','gap': 30*ss*xMult+'px','margin-top': 40*ss*xMult+'px',}"
                >
                    <div class="c-user_account_name_1"
                         :style="{'width': 1080*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 10*ss*xMult+'px','margin-top': 0*ss+'px',}"
                    >
                        <div class="c-user_account_img1"
                             :style="{'height': 120*ss*xMult+'px','width': 180*ss*xMult+'px',
                                'margin-left': 0*ss*xMult+'px',}"
                        >
                            <img class="c-user_account_img1" alt=""
                                 :src="accountSvg"
                                 :style="{'width': 'auto','height': 'auto','max-height': 120*ss*xMult+'px','max-width': 180*ss*xMult+'px',}"
                            />
                        </div>


                        <!--   User name + Id + copy btn     -->
                        <div class="c-user_account_name_2"
                             :style="{'width': 750*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <div class="c-user_account_text"
                                 :style="{'width': 750*ss*xMult+'px','height': 50*ss*xMult+'px','margin-left': 0*ss*xMult+'px',
                                    'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                            >
                                {{userName}}
                            </div>
                            <div class="c-user_account_text"
                                 :style="{'width': 750*ss*xMult+'px','height': 50*ss*xMult+'px','margin-left': 0*ss*xMult+'px',
                                    'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                            >
                                {{messages.m017+' '+userId}}
                            </div>

                            <!--   ID copy button     -->
                            <div style="position: absolute;"
                                 :style="{'right': 10*ss*xMult+'px','bottom': 0*ss*xMult+'px', 'width': 80*ss*xMult+'px','height': 80*ss*xMult+'px',}"
                            >
                                <ButtonSvgOval :callback="clickCopy" :callback-wait="true"
                                               :svg-normal="img_copy"
                                               :bg-width="80*ss*xMult" :bg-height="80*ss*xMult"
                                               :max-width="100*ss*xMult"
                                               :font-size="45*ss*xMult"
                                               :svg-width="60*ss*xMult" :svg-height="60*ss*xMult"
                                               :backgroundVal="'none'"
                                               :borderVal="'none'"
                                               :click-scale="0.9"
                                />
                            </div>
                            <!--   ID copy popup     -->
                            <div v-if="popupQuickHelp.userAccountId===true" class="c-user_account_info_popup"
                                 :style="{'right': 70*ss*xMult+'px',}"
                            >
                                <PopupInfo     :bg-width="300*ss*xMult" :bg-height="90*ss*xMult"
                                               :max-width="50*ss*xMult"
                                               :border-radius="30*ss*xMult"
                                               :font-size="45*ss*xMult"
                                               :btn-text="messages.m040_10"
                                               :background-val="'rgba(0, 0, 0, 0.4)'"
                                               :border-val="'1px solid rgba(255, 255, 255, 0.5)'"
                                               :callback="popupInfoClick" :callback-wait="true"

                                />
                            </div>
                        </div>
                    </div>

                    <!--   Referal + Notifications buttons  -->
                    <div class="c-user_account_name_3"
                         :style="{'width': 1080*ss*xMult+'px','height': btnRad*xMult+'px','gap': 30*ss+'px',}"
                    >
                        <!--   Referal   -->
                        <ButtonSvgOval :svg-normal="iconReferralsSvg"
                                       :btn-text="messages.m018"
                                       :bg-width="378*ss*xMult" :bg-height="btnRad*0.8*xMult"
                                       :max-width="(500*ss-btnRad/2-70*ss)*xMult"
                                       :svg-width="btnRad/2*xMult" :svg-height="btnRad/2*xMult"
                                       :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                                       :borderVal="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                                       :callback="btnReferalClick" :callback-wait="true"
                                       :click-scale="0.95"
                        />

                        <!--   Notifications   -->
                        <div class="c-user_account_notif_btn"
                             :style="{'width': 500*ss*xMult+'px','height': btnRad*0.8*xMult+'px','max-width': 500*ss*xMult+'px'}">
                            <ButtonSvgOval :svg-normal="iconNotificationsSvg"
                                           :btn-text="messages.m019"
                                           :bg-width="500*ss*xMult" :bg-height="btnRad*0.8*xMult"
                                           :max-width="500*ss*xMult"
                                           :svg-width="btnRad/2*xMult" :svg-height="btnRad/2*xMult"
                                           :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#0cffc7 .27%,#00af90 105.74%) var(--x,0)/200%'"
                                           :borderVal="'linear-gradient(102.76deg,#06a071 3.6%,#bcffde 53.89%,#06a071 99.19%)'"
                                           :callback="btnNotificationsClick" :callback-wait="true"
                                           :click-scale="0.95"
                            />
                            <NotificationsNumber v-if="notificationsCountUnread>0"
                                                 :bg-left="-10*ss*xMult+'px'"
                                                 :bg-top="-10*ss*xMult+'px'"
                                                 :bg-min-width="25*ss*xMult+'px'"
                                                 :font-size="40*ss*xMult"
                                                 :line-height="40*ss*xMult"
                                                 :bg-padding="10*ss*xMult+'px '+20*ss*xMult+'px'"
                                                 :notifications-count="notificationsCountUnread"
                            />
                        </div>
                    </div>
                </div>

                <!--   Other information in scroll   -->
                <div class="c-user_account_name_4"
                     :style="{'width': 1080*ss*xMult+'px','height': realHeight-(230*ss+btnRad)*xMult+'px','margin-top': 40*ss*xMult+'px',}"
                >
                    <div class="c-user_account_name_5"
                         :style="{'width': 1080*ss*xMult+'px','height': 'auto','gap': 30*ss+'px',}"
                    >
                        <!--   Monthly card present   -->
                        <div v-if="getUserMonthlyCard" class="c-user_account_rss"
                             :style="{'width': 1000*ss*xMult+'px','height': 240*ss*xMult+'px','gap': 30*ss+'px',}"
                        >
                            <img class="c-user_account_img1" alt=""
                                 :src="getVipImg"
                                 @click="clickVip"
                                 :style="{'width': 400*ss*xMult+'px','height': 233*ss*xMult+'px',}"
                            />
                            <div class="c-user_account_name_4"
                                 :style="{'width': 570*ss*xMult+'px','height': 233*ss*xMult+'px','gap': 30*ss+'px',}"
                            >
                                <div class="c-user_account_text"
                                     :style="{'width': 570*ss*xMult+'px','height': 50*ss*xMult+'px','margin-left': 0*ss*xMult+'px',
                                        'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                                >
                                    {{getVipText}}
                                </div>
                                <div class="c-user_account_text"
                                     :style="{'width': 570*ss*xMult+'px','height': 50*ss*xMult+'px','margin-left': 0*ss*xMult+'px',
                                        'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                                >
                                    {{getVipExpiry}}
                                </div>
                            </div>
                        </div>

                        <DiscordInfo v-if="!tgMode"/>
                        <TelegramInfo v-else/>

                        <div class="c-user_account_name_3"
                             :style="{'width': 1000*ss*xMult+'px','height': 400*ss*xMult+'px','gap': 30*ss+'px',}"
                        >
                            <Chips/>
                            <Gems/>
                        </div>
                        <Raffle/>
                        <Boosters/>
                        <Achievements />
                    </div>

                </div>

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>

            <!--   Horizontal region   -->
            <div v-if="orient==='H'" class="c-user_account_hor"
                 :style="{'width': realWidth+'px','height': realHeight+'px'}"
            >
                <!--   Horizontal Left   -->
                <div class="c-user_account_name" style="align-items: flex-end"
                     :style="{'width': 1030*ss*xMult+'px','height': realHeight+'px','gap': 20*ss*xMult+'px',}"
                >
                    <!--   User name   -->
                    <div class="c-user_account_name"
                         :style="{'width': 1000*ss*xMult+'px','height': (120*ss+btnRad)*xMult+'px','gap': 30*ss*xMult+'px','margin-top': 20*ss*xMult+'px',}"
                    >
                        <div class="c-user_account_name_1"
                             :style="{'width': 1080*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 10*ss*xMult+'px','margin-top': 0*ss+'px',}"
                        >
                            <div class="c-user_account_img1"
                                 :style="{'height': 120*ss*xMult+'px','width': 180*ss*xMult+'px',
                                'margin-left': 0*ss*xMult+'px',}"
                            >
                                <img class="c-user_account_img1" alt=""
                                     :src="accountSvg"
                                     :style="{'width': 'auto','height': 'auto','max-height': 120*ss*xMult+'px','max-width': 180*ss*xMult+'px',}"
                                />
                            </div>



                            <div class="c-user_account_name_2"
                                 :style="{'width': 800*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                            >
                                <div class="c-user_account_text"
                                     :style="{'width': 800*ss*xMult+'px','height': 50*ss*xMult+'px','margin-left': 0*ss*xMult+'px',
                                    'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                                >
                                    {{userName}}
                                </div>
                                <div class="c-user_account_text"
                                     :style="{'width': 800*ss*xMult+'px','height': 50*ss*xMult+'px','margin-left': 0*ss*xMult+'px',
                                    'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                                >
                                    {{messages.m017+' '+userId}}
                                </div>

                                <!--   ID copy button     -->
                                <div style="position: absolute;"
                                     :style="{'right': 10*ss*xMult+'px','bottom': 0*ss*xMult+'px', 'width': 80*ss*xMult+'px','height': 80*ss*xMult+'px',}"
                                >
                                    <ButtonSvgOval :callback="clickCopy" :callback-wait="true"
                                                   :svg-normal="img_copy"
                                                   :bg-width="80*ss*xMult" :bg-height="80*ss*xMult"
                                                   :max-width="100*ss*xMult"
                                                   :font-size="45*ss*xMult"
                                                   :svg-width="60*ss*xMult" :svg-height="60*ss*xMult"
                                                   :backgroundVal="'none'"
                                                   :borderVal="'none'"
                                                   :click-scale="0.9"
                                    />
                                </div>
                                <!--   ID copy popup     -->
                                <div v-if="popupQuickHelp.userAccountId===true" class="c-user_account_info_popup"
                                     :style="{'right': 70*ss*xMult+'px',}"
                                >
                                    <PopupInfo     :bg-width="300*ss*xMult" :bg-height="90*ss*xMult"
                                                   :max-width="50*ss*xMult"
                                                   :border-radius="30*ss*xMult"
                                                   :font-size="45*ss*xMult"
                                                   :btn-text="messages.m040_10"
                                                   :background-val="'rgba(0, 0, 0, 0.4)'"
                                                   :border-val="'1px solid rgba(255, 255, 255, 0.5)'"
                                                   :callback="popupInfoClick" :callback-wait="true"

                                    />
                                </div>
                            </div>
                        </div>

                        <div class="c-user_account_name_3"
                             :style="{'width': 1000*ss*xMult+'px','height': btnRad*xMult+'px','gap': 30*ss+'px',}"
                        >
                            <!--   Referal   -->
                            <ButtonSvgOval :svg-normal="iconReferralsSvg"
                                           :btn-text="messages.m018"
                                           :bg-width="378*ss*xMult" :bg-height="btnRad*0.8*xMult"
                                           :max-width="(500*ss-btnRad/2-70*ss)*xMult"
                                           :svg-width="btnRad/2*xMult" :svg-height="btnRad/2*xMult"
                                           :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                                           :borderVal="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                                           :callback="btnReferalClick" :callback-wait="true"
                                           :click-scale="0.95"
                            />

                            <!--   Notifications   -->
                            <ButtonSvgOval :svg-normal="iconNotificationsSvg"
                                           :btn-text="messages.m019"
                                           :bg-width="500*ss*xMult" :bg-height="btnRad*0.8*xMult"
                                           :max-width="(500*ss-btnRad/2-70*ss)*xMult"
                                           :svg-width="btnRad/2*xMult" :svg-height="btnRad/2*xMult"
                                           :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#0cffc7 .27%,#00af90 105.74%) var(--x,0)/200%'"
                                           :borderVal="'linear-gradient(102.76deg,#06a071 3.6%,#bcffde 53.89%,#06a071 99.19%)'"
                                           :callback="btnNotificationsClick" :callback-wait="true"
                                           :click-scale="0.95"
                            />
                        </div>
                    </div>

                    <DiscordInfo v-if="!tgMode"/>
                    <TelegramInfo v-else/>

                    <div class="c-user_account_name_3"
                         :style="{'width': 1000*ss*xMult+'px','height': 400*ss*xMult+'px','gap': 30*ss+'px',}"
                    >
                        <Chips/>
                        <Gems/>
                    </div>
                </div>

                <!--    Horizontal right   Other information in scroll   -->
                <div class="c-user_account_name_4"
                     :style="{'width': 1080*ss*xMult+'px','height': realHeight-60*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
                >
                    <div class="c-user_account_name_5"
                         :style="{'width': 1080*ss*xMult+'px','height': 'auto','gap': 30*ss+'px',}"
                    >
                        <!--   Monthly card present   -->
                        <div v-if="getUserMonthlyCard" class="c-user_account_rss"
                             :style="{'width': 1000*ss*xMult+'px','height': 240*ss*xMult+'px','gap': 30*ss+'px',}"
                        >
                            <img class="c-user_account_img1" alt=""
                                 :src="getVipImg"
                                 @click="clickVip"
                                 :style="{'width': 400*ss*xMult+'px','height': 233*ss*xMult+'px',}"
                            />
                            <div class="c-user_account_name_4"
                                 :style="{'width': 570*ss*xMult+'px','height': 233*ss*xMult+'px','gap': 30*ss+'px',}"
                            >
                                <div class="c-user_account_text"
                                     :style="{'width': 570*ss*xMult+'px','height': 50*ss*xMult+'px','margin-left': 0*ss*xMult+'px',
                                        'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                                >
                                    {{getVipText}}
                                </div>
                                <div class="c-user_account_text"
                                     :style="{'width': 570*ss*xMult+'px','height': 50*ss*xMult+'px','margin-left': 0*ss*xMult+'px',
                                        'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                                >
                                    {{getVipExpiry}}
                                </div>
                            </div>
                        </div>

                        <Raffle/>
                        <Boosters/>
                        <Achievements />
                    </div>

                </div>

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 20*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>

            <PopupVip v-if="popupVip.visible===true"/>
        </div>
    </div>


</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import * as Constants from "@/classes/Constants";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import Achievements from "@/vue/account/Achievements";
    import Boosters from "@/vue/account/Boosters";
    import Chips from "@/vue/account/Chips";
    import DiscordInfo from "@/vue/account/DiscordInfo";
    import TelegramInfo from "@/vue/account/TelegramInfo";
    import Gems from "@/vue/account/Gems";
    import Raffle from "@/vue/account/Raffle";
    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import googleSvg from "@/assets/svg/google_white.svg";
    import discordSvg from "@/assets/svg/icon_discord.svg";
    import facebookSvg from "@/assets/svg/icon_facebook.svg";
    import iconReferralsSvg from "@/assets/svg/icon_referals.svg";
    import iconNotificationsSvg from "@/assets/svg/icon_notifications.svg";
    import NotificationsNumber from "@/vue/elements/NotificationsNumber";
    import img_copy from "@/assets/svg/copy_white";
    import PopupInfo from "@/vue/elements/PopupInfo";
    import PopupVip from "@/vue/elements/PopupVip";
    import imgTg from '@/assets/images/icon_telegram.webp';

    export default defineComponent({
        name: 'UserAccount',
        components: {ButtonSvg,ButtonSvgOval,DiscordInfo,Raffle,Chips,Gems,Boosters,Achievements,NotificationsNumber,PopupInfo,PopupVip,TelegramInfo},
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","userId","userName","messages","btnRad","orient",
                "notificationsCountUnread","popupVisible","popupQuickHelp","getUserMonthlyCard","popupVip","tgMode"]),
            accountSvg(){
                const state=this.$store.state;
                if (state.tgMode)
                    return Utils.getGlobalLink(imgTg);
                if (state.loginData && state.loginData.loginMethod==='discord')
                    return Utils.getGlobalLink(discordSvg);
                if (state.loginData && state.loginData.loginMethod==='facebook')
                    return Utils.getGlobalLink(facebookSvg);

                return Utils.getGlobalLink(googleSvg)
            },
            getVipImg(){
                let img='';
                try{
                    const userMc=this.$store.getters.getUserMonthlyCard;
                    const mc=Constants.monthlyCards[userMc.monthlyCardType];
                    img=Utils.getGlobalLink(mc.img);
                } catch (e) {}
                return img;
            },
            getVipText(){
                let txt='';
                try{
                    const userMc=this.$store.getters.getUserMonthlyCard;
                    const mc=Constants.monthlyCards[userMc.monthlyCardType];
                    txt=mc.name2;
                } catch (e) {}
                return txt;
            },
            getVipExpiry(){
                let txt='';
                try{
                    const userMc=this.$store.getters.getUserMonthlyCard;
                    txt=this.$store.state.messages.m017_2+' '+Utils.getDate(userMc.expiry);
                } catch (e) {}
                return txt;
            },
        },

        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),iconReferralsSvg:Utils.getGlobalLink(iconReferralsSvg),iconNotificationsSvg:Utils.getGlobalLink(iconNotificationsSvg),
                img_copy:Utils.getGlobalLink(img_copy),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btnNotificationsClick(){
                this.$store.state.popupVisible.notifications=true;

            },
            btnReferalClick(){
                this.$store.state.popupVisible.referralInfo=true;
            },
            btn_xClick(){
                this.$store.state.popupVisible.userAccount=false;
            },
            guestClick(){
                this.$store.state.popupVisible.userAccount=false;
                this.$store.state.popupVisible.signup=true;
            },

            clickCopy(){
                const state=this.$store.state;
                const msg=this.$store.state.userId;
                let clipboardData = window.clipboardData || navigator.clipboard;
                clipboardData.writeText(msg);

                state.popupQuickHelp.userAccountId=true;
                setTimeout(() => {
                    state.popupQuickHelp.userAccountId=false;
                }, 1000);
            },
            popupInfoClick(){
                const state=this.$store.state;
                state.popupQuickHelp.userAccountId=false;
            },

            clickVip(){
                try {
                    const state=this.$store.state;
                    state.popupVip.visible=true;
                    const userMc=this.$store.getters.getUserMonthlyCard;
                    state.popupVip.vipType=userMc.monthlyCardType;
                }catch (e) {}
            },

        },

    });
</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-user_account_hor{
        display: flex;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        /*background: rgba(255, 255, 255, 0.2);/**/
    }

    .c-user_account_name{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: hidden;
    }

    .c-user_account_name_1{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: hidden;
    }

    .c-user_account_name_2{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
    }

    .c-user_account_name_3{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }
    .c-user_account_rss{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-user_account_name_4{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        /*background: rgba(0, 0, 0, 0.3);/**/
    }
    .c-user_account_name_5{
        display: grid;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: visible;
    }

    .c-user_account_img1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-user_account_img2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-user_account_text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;


        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        color: #FFFFFF;

        /*word-wrap: break-word;/**/
        white-space: nowrap;        /*Нет переноса не переносит*/
    }

    .c-user_account_guest{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-user_account_notif_btn{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.4);/**/
    }

    .c-user_account_info_popup{
        position: absolute;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }
</style>

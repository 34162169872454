<template>
    <!--     Prize card with nft image in Raffle prizes      -->
    <div class="c-prize_card unselectable"
         :style="{'width': 460*ss*xMult+'px','height': 730*ss*xMult+'px',}"
    >
        <div class="c-prize_card_bg"
             :style="{'width': 460*ss*xMult+'px','height': 670*ss*xMult+'px','margin-top': 0*ss*xMult+'px',
            'border': 6*ss*xMult+'px solid #233C64','border-radius': 60*ss*xMult+'px','padding-top': 7*ss*xMult+'px','padding-bottom': 10*ss*xMult+'px',}"
        >
            <div class="c-prize_card_img_nft"
                 :style="{'width': 460*ss*xMult+'px','height': 450*ss*xMult+'px'}">
                <img v-if="prizeItem.img" :src="prizeItem.img" class="c-prize_card_img_nft"
                     :style="{'width': 'auto','height': 450*ss*xMult+'px','top': 0*ss*xMult+'px',
                     'border-top-left-radius': 50*ss*xMult+'px','border-top-right-radius': 50*ss*xMult+'px'}"
                />
                <video v-if="prizeItem.video" class="c-prize_card_img_nft"
                       :style="{'width': 'auto','height': 450*ss*xMult+'px','top': 0*ss*xMult+'px',}"
                       autoplay
                       muted
                >
                    <source v-if="/\.ogg$/.test(prizeItem.video)" :src="prize.video" type="video/ogg" />
                    <source v-else-if="/\.webm$/.test(prizeItem.video)" :src="prize.video" type="video/webm" />
                    <source v-else :src="prizeItem.video" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <!--     Name       -->
            <div class="c-prize_card_text" style="justify-content: center;"
                 :style="{'width': 460*ss*xMult+'px','height': 150*ss*xMult+'px','margin-top': 7*ss*xMult+'px'}">
                <div class="c-prize_card_text" style="color: #FFFFFF;"
                     :style="{'width': 460*ss*xMult+'px','height': 'auto','max-height': 150*ss*xMult+'px','font-size': 45*ss*xMult+'px','line-height': 50*ss*xMult+'px',}">
                    {{prizeItem.name?prizeItem.name:''}}
                </div>
            </div>

        </div>

        <!-- Prize card collection -->
        <div class="c-prize_card_row"
             :style="{'width': 430*ss*xMult+'px','max-height': 100*ss*xMult+'px','min-height': 100*ss*xMult+'px',
                    'border-radius': 20*ss*xMult+'px','bottom':0+'px'}">
            <div class="c-prize_card_col"
                 :style="{'width': 40*ss*xMult+'px','height': 100*ss*xMult+'px',}">
                <img :src="starSvg" alt=""
                     :style="{'width': 30*ss*xMult+'px','height': 30*ss*xMult+'px',}"
                />
            </div>
            <div class="c-prize_card_text" style="color: #000000; "
                 :style="{'width': 350*ss*xMult+'px','max-height': 100*ss*xMult+'px','height': 'auto','font-size': 40*ss*xMult+'px','line-height': 45*ss*xMult+'px',}">
                {{prizeItem.collection?prizeItem.collection:''}}
            </div>
            <div class="c-prize_card_col"
                 :style="{'width': 40*ss*xMult+'px','height': 100*ss*xMult+'px',}">
                <img :src="starSvg" alt=""
                     :style="{'width': 30*ss*xMult+'px','height': 30*ss*xMult+'px',}"
                />
            </div>
        </div>

        <!--     Chain image (wax, polygon, etc)       -->
        <div v-if="getImage" class="c-prize_card_img"
                :style="{'width': 90*ss*xMult+'px','height': 90*ss*xMult+'px','top': 12*ss*xMult+'px','right': 5*ss*xMult+'px','border-radius': 100*ss*xMult+'px',}"
        >
            <img :src="getImage" alt=""
                 :style="{'width': 70*ss*xMult+'px','height': 70*ss*xMult+'px',}"
            />
        </div>

        <!-- Click -->
        <div style="position: absolute; top:0; left: 0;"
             @click="openTxLink(prizeItem)"
             :style="{'width': '100%','height': '100%',}"
        />
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import starSvg from "@/assets/svg/tickets/star.svg";
    import Utils from "@/scripts/Utils";
    import iconBnb from "@/assets/svg/chains/icon_bnb.svg";
    import iconPolygon from "@/assets/svg/chains/icon_polygon.svg";
    import iconSol from "@/assets/svg/chains/icon_sol.svg";
    import iconWax from "@/assets/svg/chains/icon_wax.svg";


    export default defineComponent({
        name: 'PrizeCard',
        components: {ButtonSvgOval,},
        computed: {
            ...mapGetters(["ss","xMult","blockspinState"]),
            getImage(){
                let img='';
                if (this.prizeItem.chain){
                    let chain=this.prizeItem.chain.toLowerCase();
                    img=chain==='wax'?Utils.getGlobalLink(iconWax):img;
                    img=chain==='sol'?Utils.getGlobalLink(iconSol):img;
                    img=chain==='polygon'?Utils.getGlobalLink(iconPolygon):img;
                    img=chain==='pol'?Utils.getGlobalLink(iconPolygon):img;
                    img=chain==='bnb'?Utils.getGlobalLink(iconBnb):img;
                }
                return img;
            },

        },
        data(){
            return {
                starSvg:Utils.getGlobalLink(starSvg)
            };
        },
        created() {

            //console.log(this.clickScale)
        },
        mounted() {
        },
        methods: {
            openTxLink(item){
                this.$store.dispatch('openTxLink',item);
            },
        },

        props:{
            prizeItem:{
                type: Object,
                default:{},
            }
        }
    });

</script>

<style lang="css" scoped>

    .c-prize_card{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-prize_card_bg{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: visible;

        background: radial-gradient(52.63% 87.8% at 50.03% 4.04%, #0D4455 0%, #030F20 100%);
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-prize_card_col{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-prize_card_row{
        display: flex;
        position: absolute;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: visible;

        background: linear-gradient(95.19deg, #0CFF7C -16.14%, #00AF90 137.96%);

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-prize_card_img{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
    }

    .c-prize_card_img_nft{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .c-prize_card_text{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        text-align: -webkit-center;

        /*background: rgba(255, 255, 255, 0.3);/**/
    }

</style>

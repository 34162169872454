<template>

    <div class="c-game_type_bg"
         :style="{'width': bgWidth+'px','height': bgHeight+'px',}"
    >
        <div class="c-game_type" @click="onClick"
             :style="{'width': bgWidth+'px','height': bgHeight+'px','gap':10*ss+'px',}"
        >
            <div v-if="svgNormal" class="c-game_type_cont"
                 :style="{'width': svgWidth+'px','height': svgHeight+'px'}"
            >
                <img style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;" alt=""
                     :src="svgNormal"

                />
            </div>
            <div v-if="btnText" class="c-game_type_text_bg"
                 :style="{'width': bgWidth+'px','min-height': bgHeight/3+'px','font-size': fontSize+'px',
                    'background': gameType===iconName?'linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%)':'none',
                    'border-radius': gameType===iconName?30*ss+'px':'0px',}"

            >
                <div v-if="btnText" class="c-game_type_text"
                     :style="{'width': 'auto','min-height': bgHeight/3+'px','font-size': fontSize+'px',
                    'margin-left': 10*ss+'px', 'margin-right': 10*ss+'px',
                    'color': gameType===iconName?'#FFFFFF':'#000000',}"
                >
                    {{btnText}}

                    <div v-if="gameType!==iconName" class="c-game_type_new_bg"
                         :style="{'right': 0*ss+'px','bottom': -15*ss+'px','width': 'auto','height': 33*ss+'px',}"
                    >

                        <div v-if="gameTypesSoon.includes(iconName)" class="c-game_type_soon"
                             :style="{'right': 0*ss+'px','font-size': 33*ss*multOur+'px',}"
                        >
                            Soon
                        </div>
                        <div v-if="gameTypesNew.includes(iconName)" class="c-game_type_new"
                             :style="{'right': 0*ss+'px','font-size': 33*ss*multOur+'px',}"
                        >
                            New
                        </div>
                    </div>
                </div>


            </div>



        </div>

    </div>
    <!---

        'max-width':maxWidth+'px',
                --->
</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'GameTypeIcon',
        components: {},
        computed: {
            ...mapGetters(["ss","gameType","gameTypesSoon","gameTypesNew","multOur"]),

        },
        data(){
            return {
                clicked:false,

            };
        },
        created() {
            //console.log()
        },
        mounted() {
        },
        methods: {
            onClick() {
                let gt=this.iconName;
                let it=this.$store.state.gameType;
                if (!this.callback) return;
                this.callback(this.iconName);
            },
        },

        props:{
            iconName: {
                type: String,
                default: ""
            },
            svgNormal: {
                type: String,
                default: ""
            },
            btnText: {
                type: String,
                default: null
            },
            bgWidth: {
                type: Number,
                default: 0         // if 0 than 'auto'
            },
            bgHeight: {
                type: Number,
                default: 0          // if 0 than 'auto'
            },
            maxWidth: {                 //Max text field width
                type: Number,
                default: 100         //
            },
            svgWidth: {
                type: Number,
                default: 44
            },
            svgHeight: {
                type: Number,
                default: 44
            },
            fontSize: {
                type: Number,
                default: 44
            },
            callback: {
                type: Function,
                default: () => {}
            },
            callbackWait: {                 //If true, then call callback after delay
                type: Boolean,
                default: false
            },
            opacityVal: {
                type: Number,
                default: 1
            },
        }
    });

</script>

<style lang="css" scoped>
    .c-game_type_bg{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0px 0px 0px;
        overflow: visible;
    }

    .c-game_type{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 0px 0px;
    }

    .c-game_type_cont{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 0px 0px;
    }

    .c-game_type_text_bg{
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

    }

    .c-game_type_text{
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        overflow: visible;

        text-align: -webkit-center;
        /*word-wrap: break-word;/**/
        white-space: nowrap;        /*Нет переноса не переносит*/

    }

    .c-game_type_soon{
        display: flex;
        position: absolute;
        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        overflow: hidden;

        background: linear-gradient(93.92deg, #fa397c .27%, #610305 105.74%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .c-game_type_new{
        display: flex;
        position: absolute;
        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        overflow: hidden;

        background: linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .c-game_type_new_bg{
        display: flex;
        position: absolute;
        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        overflow: visible;

        justify-content: center;
        align-items: center;
        text-align: -webkit-left;
        color: #ff2e30;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }


</style>

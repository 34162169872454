<template>
    <div class="c-recent_bet_player"
         :style="{'width': '99%','height': 130*ss*xMult+'px',}"
    >
        <div class="c-recent_bet_player_bg"
                :style="{'width': '100%','height': 110*ss*xMult+'px','border-radius': 30*ss*xMult+'px'}"
        >

            <div class="c-recent_bet_player_left"
                 :style="{'width': '45%','height': '100%',}"
            >
                <img v-if="userIcon"
                     style="flex: none; flex-grow: 0; margin: 0 0; overflow: hidden;" alt="" :src="userIcon"
                     :style="{'width': 90*ss*xMult+'px','height': 90*ss*xMult+'px','border-radius': 60*ss*xMult+'px','margin-left': 20*ss*xMult+'px',}"
                />

                <span class="c-recent_bet_player_left_user"
                      :style="{'width': 'auto','height': '100%','margin-left': 20*ss*xMult+'px',
                        'font-size': 35*ss*xMult+'px','line-height': '100%','color':userNameColor}"
                >{{item.username}}</span>
            </div>

            <div class="c-recent_bet_player_points" style="flex-direction: row;justify-content: flex-start;"
                 :style="{'width': '35%','height': '100%','gap': 20*ss*xMult+'px',}">
                <!-- Game Icon-->
                <img v-if="GameList[gameType][item.game]"
                     style="flex: none; flex-grow: 0; margin: 0 0; overflow: hidden;" alt="" :src="getThumb"
                     :style="{'width': 90*ss*xMult+'px','height': 90*ss*xMult+'px','border-radius': 0*ss*xMult+'px','margin-left': 20*ss*xMult+'px',}"
                />
                <!-- Game title (name)-->
                <div
                        class="c-recent_bet_player_text"
                      :style="{'width': 'auto','height': '100%','margin-right': '2%',
                            'font-size': 35*ss*xMult+'px','line-height': '100%',}"
                >
                    {{GameList[gameType][item.game]?GameList[gameType][item.game].title:''}}
                </div>
            </div>

            <div class="c-recent_bet_player_points" :style="{'width': '20%','height': '100%',}">
                <div v-if="item.jackpotWon===true" class="c-recent_bet_player_text" style="color: rgb(249, 250, 1);text-align: -webkit-center;"
                      :style="{'width': '96%','height': 50*ss*xMult+'px','margin-right': '4%',
                            'font-size': 35*ss*xMult+'px','line-height': '100%',}"
                >
                    {{messages.m044_09}}
                </div>
                <div v-else-if="item.isFreeSpin===true" class="c-recent_bet_player_text" style="color: rgb(249, 250, 1);text-align: -webkit-center;"
                     :style="{'width': '96%','height': 50*ss*xMult+'px','margin-right': '4%',
                            'font-size': 35*ss*xMult+'px','line-height': '100%',}"
                >
                    {{messages.m044_11}}
                </div>
                <div v-else-if="item.isBonus===true" class="c-recent_bet_player_text" style="color: rgb(249, 250, 1);text-align: -webkit-center;"
                     :style="{'width': '96%','height': 50*ss*xMult+'px','margin-right': '4%',
                            'font-size': 35*ss*xMult+'px','line-height': '100%',}"
                >
                    {{messages.m044_10}}
                </div>
                <div class="c-recent_bet_player_text" style="text-align: -webkit-center;"
                      :style="{'width': '96%','height': 50*ss*xMult+'px','margin-right': '4%',
                            'font-size': 35*ss*xMult+'px','line-height': '100%',}"
                >
                    {{item.chipsWon?item.chipsWon.toLocaleString():0}}
                </div>
            </div>


        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import GameList from "@/scripts/GameList";
    import Utils from "@/scripts/Utils";

    export default {
        name: 'RecentBetPlayer',
        components: {},
          //One player element of left players panel
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","xMult","messages"]),
            userIcon() {
                //debugger

                return this.item.avatar?this.$store.state.formatIcon(this.item.avatar):null;
            },
            getShowPoints(){        //If prizePoolShowToken true and TokenAvailable in current prizePool then don't show points
                return true;
            },
            getThumb(){
                const item=this.item;
                if (this.gameType==='scratchCards'){
                    return GameList[this.gameType][item.game].imageLink;
                }
                return Utils.getGlobalLink(GameList[this.gameType][item.game].thumb);
            },

        },
        data(){
            return {
                GameList,Utils,
            };
        },
        methods: {

        },
        props: {
            item: {             //{}
                type: Object
            },

            gameType:{        //
                type: String,
                default: 'slot'
            },
            prizePoolObj:{        //item number in list
                type: Object,
                default: {}
            },
            userNameColor:{        //default background
                type: String,
                default: 'white'
            },
        },
    }
</script>

<style lang="css">
    .c-recent_bet_player{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .c-recent_bet_player_bg{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        background-color: hsla(0,0%,100%,.05);
    }
    .c-recent_bet_player_left{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        flex: none;

        flex-grow: 0;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.4);/**/
    }
    .c-recent_bet_player_left_numb{
        font-family: 'Rubik';
        display: flex;
        font-style: normal;
        align-items: center;
        justify-content: flex-end;
        font-weight: 500;
        letter-spacing: -0.03em;
        color: #FFFFFF;
        flex: none;

        flex-grow: 0;
    }

    .c-recent_bet_player_points{
        font-family: 'Rubik';
        display: flex;
        flex-direction: column;
        position: relative;
        font-style: normal;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        letter-spacing: -0.03em;
        color: #FFFFFF;
        flex: none;
        overflow: hidden;

    }

    .c-recent_bet_player_left_user{
        font-family: 'Rubik';
        align-items: center;
        display: flex;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.03em;
        color: #FFFFFF;
        flex: none;

        flex-grow: 1;

        /*background: rgba(255, 255, 255, 0.1);/**/
    }
    .c-recent_bet_player_text{
        font-family: 'Rubik';
        display: flex;
        font-style: normal;
        font-weight: 500;
        align-items: center;
        justify-content: center;
        text-align: -webkit-right;
        letter-spacing: -0.03em;
        color: #FFFFFF;
        flex: none;
        flex-grow: 0;

        /*background: rgba(0, 0, 0, 0.4);/**/
    }
</style>

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.c-frame_bg[data-v-266dece1]{
        display: flex;
        overflow: hidden;
        position: absolute;
        /*transform: translate(50%, -50%);/**/
        transform: translate(0%, 0%);
        padding:0;
        margin:0;

        /*background: rgba(255, 255, 255, 0.1);/**/
        border: 4px solid rgba(0, 255, 0, 0.5);
}

`, "",{"version":3,"sources":["webpack://./vue/BgFrame.vue"],"names":[],"mappings":";AAuCI;QACI,aAAa;QACb,gBAAgB;QAChB,kBAAkB;QAClB,qCAAqC;QACrC,4BAA4B;QAC5B,SAAS;QACT,QAAQ;;QAER,0CAA0C;QAC1C,sCAAsC;AAC1C","sourcesContent":["<template>\r\n    <div class=\"c-frame_bg\"\r\n         :style=\"{'top': 0*ss+'px','left':getLeft+'px',\r\n                'width': realWidth-8+'px','height': realHeight-8+'px',}\">\r\n        <!--  (-8) No *ss because border has fixed size 4px in style-->\r\n\r\n    </div>\r\n</template>\r\n\r\n<script>\r\n    import { defineComponent } from 'vue';\r\n    import { mapGetters } from \"vuex\";\r\n    import Utils from \"../scripts/Utils\";\r\n\r\n\r\n    //export default defineComponent({              //Not working debugger in chrome\r\n    export default {\r\n        name: \"BgFrame\",\r\n        computed: {\r\n            ...mapGetters([\"ss\",\"realWidth\",\"realHeight\",\"getLeft\"]),\r\n        },\r\n        created() {\r\n\r\n\r\n        },\r\n        data: function () {\r\n            return {\r\n\r\n            }\r\n        },\r\n        methods:{\r\n\r\n        },\r\n    }\r\n    //);\r\n\r\n</script>\r\n\r\n<style lang=\"css\" scoped>\r\n    .c-frame_bg{\r\n        display: flex;\r\n        overflow: hidden;\r\n        position: absolute;\r\n        /*transform: translate(50%, -50%);/**/\r\n        transform: translate(0%, 0%);\r\n        padding:0;\r\n        margin:0;\r\n\r\n        /*background: rgba(255, 255, 255, 0.1);/**/\r\n        border: 4px solid rgba(0, 255, 0, 0.5);\r\n    }\r\n\r\n</style>\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

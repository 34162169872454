<template>
    <div class="c-unlock_confirm"
         :style="{'top': 0*ss+'px','left':0*ss+'px',}"
    >
        <div class="c-unlock_confirm_2"
             :style="{'width': 800*ss*xMult+'px','height': 700*ss*xMult+'px',}"
        >
            <!--     discount     -->
            <div v-if="getGameDiscount>0" class="c-unlock_confirm_discount"
                 :style="{'width': 320*ss*xMult+'px','height': 320*ss*xMult+'px','border-radius': 320*ss*xMult+'px',
                    'top': -240*ss*xMult+'px','left': -120*ss*xMult+'px',}"
            >
                <div class="c-unlock_confirm_discount_bg"
                     :style="{'width': 280*ss*xMult+'px','height': 280*ss*xMult+'px','border-radius': 280*ss*xMult+'px',}"
                >
                    <div class="c-unlock_confirm_text_discount c-unlock_confirm_column"
                         :style="{'width': 260*ss*xMult+'px','height': 55*ss*xMult+'px','margin-top': 90*ss*xMult+'px',
                            'font-size': 47*ss*xMult+'px','line-height': 55*ss*xMult+'px',}"
                    >
                        {{messages.m041_03}}
                    </div>
                    <div class="c-unlock_confirm_text_discount c-unlock_confirm_column"
                         :style="{'width': 260*ss*xMult+'px','height': 55*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 55*ss*xMult+'px',}"
                    >
                        {{getGameDiscount+'%'}}
                    </div>
                </div>
                <img class="c-unlock_confirm_img_1" alt=""
                     :style="{'width': 'auto','height': 100*ss*xMult+'px','top': -30*ss*xMult+'px','left': 40*ss*xMult+'px',}"
                     :src="img_gem_1"
                />
            </div>
            <!--   Unlock game bg    -->
            <div class="c-unlock_confirm_2"
                 :style="{'width': 800*ss*xMult+'px','height': 700*ss*xMult+'px','gap': 30*ss*xMult+'px','border-radius':60*ss*xMult+'px',
                    'border':6*ss*xMult+'px solid #CC181A',
                    'background':getBg,'box-shadow':getShadow}"
            >

                <!--     decoration images     -->
                <div class="c-unlock_confirm_bg_abs"
                     :style="{'width': '100%','height': '100%',}"
                >
                    <img class="c-unlock_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 700*ss*xMult+'px','bottom': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
                         :src="confetti_2"
                    />
                    <img class="c-unlock_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 700*ss*xMult+'px','bottom': 0*ss*xMult+'px','right': 0*ss*xMult+'px',}"
                         :src="confetti_3"
                    />

                    <img class="c-unlock_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 150*ss*xMult+'px','top': 280*ss*xMult+'px','left': 0*ss*xMult+'px',}"
                         :src="coin_1"
                    />
                    <img class="c-unlock_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 150*ss*xMult+'px','bottom': 0*ss*xMult+'px','right': -10*ss*xMult+'px',}"
                         :src="coin_2"
                    />
                    <img class="c-unlock_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 150*ss*xMult+'px','top': 300*ss*xMult+'px','right': 20*ss*xMult+'px',}"
                         :src="coin_3"
                    />
                </div>


                <!--     Confirm information       -->
                <div class="c-unlock_confirm_column" style="overflow: hidden;"
                 :style="{'width': 800*ss*xMult+'px','height': 700*ss*xMult+'px','gap': 20*ss*xMult+'px',}"
                >
                    <!--     Unlock + game name       -->
                    <div class="c-unlock_confirm_text c-unlock_confirm_column"
                         :style="{'width': 800*ss*xMult+'px','height': 200*ss*xMult+'px',
                            'font-size': 70*ss*xMult+'px','line-height': 100*ss*xMult+'px',}"
                    >
                        <div class="c-unlock_confirm_text c-unlock_confirm_column"
                             :style="{'width': 800*ss*xMult+'px','height': 100*ss*xMult+'px',
                            'font-size': 70*ss*xMult+'px','line-height': 100*ss*xMult+'px',}"
                        >
                            {{messages.m041_01}}
                        </div>
                        <div class="c-unlock_confirm_text c-unlock_confirm_column"
                             :style="{'width': 800*ss*xMult+'px','height': 100*ss*xMult+'px',
                            'font-size': 70*ss*xMult+'px','line-height': 100*ss*xMult+'px',}"
                        >
                            {{popupUnlockConfirm.gameObj.title}}
                        </div>
                    </div>

                    <!--     for text       -->
                    <div class="c-unlock_confirm_text c-unlock_confirm_column"
                         :style="{'width': 800*ss*xMult+'px','height': 50*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                    >
                        {{messages.m041_02}}
                    </div>

                    <!--     Prices       -->
                    <div class="c-unlock_confirm_text c-unlock_confirm_column" style="justify-content: flex-start"
                         :style="{'width': 800*ss*xMult+'px','height': 200*ss*xMult+'px',
                            'font-size': 70*ss*xMult+'px','line-height': 100*ss*xMult+'px',}"
                    >
                        <!--     Base price visible if not equal final price       -->
                        <div v-if="getGameBasePrice!==getGameFinalPrice" class="c-unlock_confirm_text c-unlock_confirm_column" style="text-decoration: line-through;"
                             :style="{'width': 800*ss*xMult+'px','height': 100*ss*xMult+'px',
                            'font-size': 60*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                        >
                            {{getGameBasePrice.toLocaleString()+' '+messages.m022_5}}
                        </div>
                        <!--     Final price with discount       -->
                        <div class="c-unlock_confirm_text c-unlock_confirm_column"
                             :style="{'width': 800*ss*xMult+'px','height': 100*ss*xMult+'px',
                            'font-size': 70*ss*xMult+'px','line-height': 100*ss*xMult+'px',}"
                        >
                            {{getGameFinalPrice.toLocaleString()+' '+messages.m022_5}}
                        </div>
                    </div>

                    <!--     Confirm button       -->
                    <ButtonSvgOval :svg-normal="checkSvg"
                                   :callback="clickConfirm" :callback-wait="true"
                                   :btn-text="messages.m028_2"
                                   :bg-width="420*ss*xMult" :bg-height="100*ss*xMult"
                                   :max-width="420*ss*xMult"
                                   :font-size="45*ss*xMult"
                                   :svg-width="50*ss*xMult" :svg-height="50*ss*xMult"
                                   :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                                   :borderVal="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                                   :click-scale="0.95"
                    />
                </div>


                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import checkSvg from "@/assets/svg/icon_check.svg";
    import confetti_2 from "@/assets/svg/tickets/confetti_2.svg";
    import confetti_3 from "@/assets/svg/tickets/confetti_3.svg";
    import confetti_4 from "@/assets/images/confetti_4.webp";
    import img_gem_1 from "@/assets/svg/icon_gem_discount.svg";
    import coin_1 from "@/assets/svg/icon_gem3.svg";
    import coin_2 from "@/assets/svg/icon_gem4.svg";
    import coin_3 from "@/assets/svg/icon_gem5.svg";



    export default defineComponent({
        name: 'UnlockConfirm',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","messages","popupUnlockConfirm"]),
            getBg(){
                let txt='linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%)';
                return txt;
            },
            getShadow(){
                let txt='0 0 100px rgba(253,134,81,.6),inset 0 0 100px hsla(0,0%,100%,.5)';
                return txt;
            },
            getGameFinalPrice(){       //price with discount
                return this.getGameFinalPriceThis();
            },
            getGameBasePrice(){       //base game unlock price
                const state=this.$store.state;
                let price=0;
                try{
                    if (state.blockspinState) {
                        let name=state.popupUnlockConfirm.gameObj.name;
                        const gamesGemUnlockPrice = state.blockspinState.gamesGemUnlockPrice;
                        if (gamesGemUnlockPrice[name]>0)
                            price=gamesGemUnlockPrice[name];
                    }
                } catch (e) {}
                return price;
            },
            getGameDiscount(){       //discount in percent
                const state=this.$store.state;
                let discPerc=0;
                try{
                    if (state.blockspinState) {
                        let name=state.popupUnlockConfirm.gameObj.name;
                        const gamesGemUnlockDiscount = state.blockspinState.gamesGemUnlockDiscount;
                        if (gamesGemUnlockDiscount[name]>0)
                            discPerc=gamesGemUnlockDiscount[name];
                    }
                } catch (e) {}
                return discPerc;
            },
        },
        components: {ButtonSvg,ButtonSvgOval,},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),
                confetti_2:Utils.getGlobalLink(confetti_2),
                checkSvg:Utils.getGlobalLink(checkSvg),confetti_3:Utils.getGlobalLink(confetti_3),coin_1:Utils.getGlobalLink(coin_1),
                coin_2:Utils.getGlobalLink(coin_2),coin_3:Utils.getGlobalLink(coin_3),confetti_4:Utils.getGlobalLink(confetti_4),
                img_gem_1:Utils.getGlobalLink(img_gem_1),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btn_xClick(){
                this.$store.state.popupUnlockConfirm.visible=false;
            },
            async clickConfirm(){
                const state=this.$store.state;
                state.popupUnlockConfirm.visible=false;
                let name=state.popupUnlockConfirm.gameObj.name;
                let price=this.getGameFinalPriceThis();
                if (state.userData.gems>=price){
                    const unlock = await this.$store.dispatch("unlockGame", { game: name, price: price });
                    if(unlock.success===true) {
                        this.$store.dispatch("openGame",  name);
                    }
                    else{
                        state.showMessagePopup(state.messages.m041_05,state.messages.m029_5,true);
                    }
                }
                else{   //show error
                    state.showMessagePopup(state.messages.m041_04,'',true);
                }

            },
            getGameFinalPriceThis(){       //price with discount
                const state=this.$store.state;
                let price=0;
                let discPerc=0;
                try {
                    if (state.blockspinState) {
                        let name = state.popupUnlockConfirm.gameObj.name;
                        const gamesGemUnlockPrice = state.blockspinState.gamesGemUnlockPrice;
                        const gamesGemUnlockDiscount = state.blockspinState.gamesGemUnlockDiscount;
                        if (gamesGemUnlockPrice[name] > 0)
                            price = gamesGemUnlockPrice[name];
                        if (gamesGemUnlockDiscount[name] > 0)
                            discPerc = gamesGemUnlockDiscount[name];
                    }
                    price = Math.round(price * (100 - discPerc)/100);
                } catch (e) {}
                return price;
            },
        },
    });
</script>

<style lang="css" scoped>

    .c-unlock_confirm{
        display: flex;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0;
        margin:0;
        width: 100%;
        height: 100%;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;
        backdrop-filter: blur(5px);
        background: linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8));

    }

    .c-unlock_confirm_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-unlock_confirm_bg_abs{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-unlock_confirm_column{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-unlock_confirm_img_1{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-unlock_confirm_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;

        /*word-wrap: break-word;/**/
        white-space: nowrap;        /*Нет переноса не переносит*/
    }

    .c-unlock_confirm_discount{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;
        background: #ffe500;
        transform: rotate(-20deg);
    }


    .c-unlock_confirm_discount_bg{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: visible;
        border: 3px solid #fff;
    }

    .c-unlock_confirm_text_discount{
        font-family: Rubik;
        font-style: normal;
        font-weight: 900;
        overflow: hidden;

        /*word-wrap: break-word;/**/
        white-space: nowrap;        /*Нет переноса не переносит*/

        background: linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(94.81deg,#dc06ff -6.49%,#8606b3 108.32%);
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
    }
</style>

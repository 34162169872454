<template>
    <div class="c-pop_log unselectable"
         :style="{'top': 0*ss+'px','left':0*ss+'px',}">
        <div class="c-pop_log_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <div class="c-pop_log_bg" style=" "
                 :style="{'width': 1020*ss+'px','height': 1250*ss+'px',
                    'border-radius': 60*ss+'px','gap': 40*ss+'px',}">

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss+'px','right': 40*ss+'px', 'width': 72*ss+'px','height': 72*ss+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss"
                               :bg-height="72*ss"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>

                <!--   title text   -->
                <div class="c-pop_log_title"
                     :style="{'width': 720*ss+'px','max-width': 720*ss+'px','height': 'auto',}"
                >
                    <div class="c-pop_log_title_text"
                         :style="{'font-size': 60*ss+'px','line-height': 80*ss+'px',}"
                    >
                        {{messages.m008}}
                    </div>
                </div>

                <!--   Discord   -->
                <div class="c-pop_log_discord"
                     :style="{'width': 650*ss+'px','height': 120*ss+'px','border-radius': 10*ss+'px','gap': 30*ss+'px','margin-top': 50*ss+'px',}"
                     @click="btnDiscordClick"
                >
                    <img class="c-pop_log_img" alt=""
                         :src="discordSvg"
                         :style="{'width': 70*ss+'px','height': 70*ss+'px','margin-left': 40*ss+'px',}"
                    />

                    <div class="c-pop_log_text"
                         :style="{'font-size': 44*ss+'px','line-height': 50*ss+'px',}"
                    >
                        {{messages.m011}}
                    </div>
                </div>

                <!--   Google   -->
                <div class="c-pop_log_google"
                     :style="{'width': 650*ss+'px','height': 120*ss+'px','border-radius': 10*ss+'px','gap': 10*ss+'px',}"
                     @click="btnGoogleClick"
                >
                    <img class="c-pop_log_img" alt=""
                         :src="googleSvg"
                         :style="{'width': 90*ss+'px','height': 60*ss+'px','margin-left': 40*ss+'px',}"
                    />

                    <div class="c-pop_log_google_text"
                         :style="{'font-size': 44*ss+'px','line-height': 50*ss+'px',}"
                    >
                        {{messages.m010}}
                    </div>
                </div>

                <!--   Facebook   -->
                <div class="c-pop_log_facebook"
                     :style="{'width': 650*ss+'px','height': 120*ss+'px','border-radius': 10*ss+'px','gap': 30*ss+'px',}"
                     @click="btnFaceClick"
                >
                    <img class="c-pop_log_img" alt=""
                         :src="facebookSvg"
                         :style="{'width': 70*ss+'px','height': 70*ss+'px','margin-left': 40*ss+'px',}"
                    />

                    <div class="c-pop_log_text"
                         :style="{'font-size': 44*ss+'px','line-height': 50*ss+'px',}"
                    >
                        {{messages.m009}}
                    </div>
                </div>

                <div class="c-pop_log_text"
                     :style="{'width': 'auto','height': 'auto','font-size': 40*ss+'px','line-height': 40*ss+'px',}"
                >
                    {{messages.m002}}
                </div>

                <!--   Continue as guest btn   -->
                <ButtonSvgOval :svg-normal="chipsSvg"
                               :bg-width="650*ss"
                               :max-width="760*ss-btnRad/2"
                               :bg-height="btnRad*0.85"
                               :font-size="btnRad/3"
                               :svg-width="btnRad/2"
                               :svg-height="btnRad/2"
                               :btn-text="messages.m005"
                               :callback="btnGuestClick" :callback-wait="true"
                               :borderVal="'linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%)'"
                               :clickScale="0.97"
                />

                <div class="c-pop_log_title" :style="{'font-size': btnRad/3+'px','line-height': btnRad/2+'px','gap': 0*ss+'px','margin-top': 20*ss+'px'}">
                    <div class="c-pop_log_no_account" :style="{'gap': 20*ss+'px',}"
                         @click="btnSignupClick"
                    >
                        <div class="c-pop_log_text">
                            {{messages.m007}}
                        </div>
                        <div class="c-pop_log_text" style="color: yellow; font-weight: 500;">
                            {{messages.m007_1}}
                        </div>
                    </div>
                    <div class="c-pop_log_text" :style="{'width': 900*ss+'px','font-size': 40*ss+'px',}">
                        {{messages.m006_1}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import chipsSvg from '@/assets/svg/chips.svg';
    import discordSvg from '@/assets/svg/discord.svg';
    import facebookSvg from "@/assets/svg/facebk.svg";
    import googleSvg from "@/assets/svg/google.svg";
    import Utils from "@/scripts/Utils";

    export default {
        name: "PopupLogin",
        components: {ButtonSvg,ButtonSvgOval, },
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","messages","getLeft"]),
            btnRad(){
                return 135*this.$store.state.ss
            },
        },
        created() {


        },
        data: function () {
            return {
                btn_xSvg:btn_xSvg, facebookSvg:facebookSvg, googleSvg:googleSvg, chipsSvg:chipsSvg, discordSvg:discordSvg,
            }
        },
        methods:{
            btnLoginClick(){

            },
            btn_xClick(){
                this.$store.state.popupVisible.login=false;
            },
            btnDiscordClick(){
                Utils.loginDiscord(this.$route);
            },
            btnFaceClick(){
                Utils.loginFacebook(this.$route);
            },
            btnGoogleClick(){
                Utils.loginGoogle(this.$route);
            },
            btnGuestClick(){
                Utils.loginGuest();
                this.$store.state.popupVisible.login=false;
            },
            btnSignupClick(){
                this.$store.state.popupVisible.login=false;
                this.$store.state.popupVisible.signup=true;
            },
        },
    }

</script>

<style lang="css" scoped>
    .c-pop_log{
        display: flex;
        overflow: hidden;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0;
        margin:0;
        width: 100vw;
        height: 100vh;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;

        background: rgba(0, 0, 0, 0.4);/**/
    }
    .c-pop_log_abs{
        display: flex;
        overflow: hidden;
        position: absolute;
        transform: translate(0%, 0%);
        padding:0;
        margin:0;

        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-pop_log_bg{
        display: flex;
        position: relative;
        overflow: hidden;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /*background: radial-gradient(144.91% 76.86% at 50.03% 4.04%, #0D4455 0%, #030F20 100%);/**/
        /*background: radial-gradient(144.91% 76.86% at 50.03% 4.04%, #00C6FB 0%, #005BEA 100%);/**/
        background: linear-gradient(94deg, #00C6FB 0.27%, #005BEA 105.74%);/**/
    }

    .c-pop_log_title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-pop_log_title_text{
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        overflow: visible;
        color: #FFFFFF;

        text-align: -webkit-center;
        /*word-wrap: break-word;/**/
        /*white-space: nowrap;        /*Нет переноса не переносит*/
    }

    .c-pop_log_img{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .c-pop_log_facebook{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        background: linear-gradient(93.92deg,#3849aa .27%, #4356c2 105.74%);
    }

    .c-pop_log_no_account{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .c-pop_log_text{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 400;
        overflow: visible;
        color: #FFFFFF;
        text-align: -webkit-center;
    }

    .c-pop_log_discord{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        background: linear-gradient(93.92deg,#031223 .27%, #535FE1 105.74%);
    }

    .c-pop_log_google{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        background: white;
    }

    .c-pop_log_google_text{
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 400;
        overflow: visible;
        color: #000000;
        opacity: 0.6;
        text-align: -webkit-center;
    }

</style>

<template>
    <div class="c-prize_player_mob"
            :style="{'width': '100%','height': 110*ss*xMult+'px','background':bg,'border-radius': 10*ss*xMult+'px'}"
    >


        <div class="c-prize_player_mob_left"
             :style="{'width': '60%','height': '100%',}"
        >
            <span class="c-prize_player_mob_left_numb"
                  :style="{'width': 40*ss*xMult+'px','height': '100%','line-height': '100%',
                    'font-size': 40*ss*xMult+'px','margin-left': 10*ss*xMult+'px',}"
            >{{numb}}</span>

            <img v-if="userIcon!=null"
                 style="flex: none; flex-grow: 0; margin: 0 0; overflow: hidden;" alt="" :src="userIcon"
                 :style="{'width': 90*ss*xMult+'px','height': 90*ss*xMult+'px','border-radius': 60*ss*xMult+'px','margin-left': 20*ss*xMult+'px',}"
            />
            <div v-else
                    :style="{'width': 90*ss*xMult+'px','height': 90*ss*xMult+'px','margin-left': 30*ss*xMult+'px',}"
            />

            <span class="c-prize_player_mob_left_user"
                  :style="{'width': 430*ss*xMult+'px','height': '100%','margin-left': 20*ss*xMult+'px',
                    'font-size': 40*ss*xMult+'px','line-height': '100%','color':userNameColor}"
            >{{item.username}}</span>
        </div>

        <div v-if="getShowPoints" class="c-prize_player_points" :style="{'width': '20%','height': '100%',}">
            <div
                    class="c-prize_player_mob_text"
                  :style="{'width': '96%','height': '100%','margin-right': '2%',
                        'font-size': 40*ss*xMult+'px','line-height': '100%',}"
            >{{item.points?item.points.toLocaleString():0}}</div>
        </div>

        <div class="c-prize_player_points" :style="{'width': getShowPoints?'20%':'40%','height': '100%',}">
            <span class="c-prize_player_mob_text"
                  :style="{'width': '96%','height': '100%','margin-right': '4%',
                        'font-size': 40*ss*xMult+'px','line-height': '100%',}"
            >{{getPrize}}</span>
        </div>




    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'PrizePoolPlayer',
        components: {},
          //One player element of left players panel
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","xMult"]),
            userIcon() {
                //debugger
                return this.$store.state.formatIcon(this.item.avatar);
            },
            getShowPoints(){        //If prizePoolShowToken true and TokenAvailable in current prizePool then don't show points
                let prizePool = this.prizePoolObj[this.$store.state.prizePoolBets.prizePoolActive];
                if (prizePool.tokenPrizePool && prizePool.tokenPrizePool.amount>0 && this.$store.state.prizePoolShowToken){
                    return false;
                }
                else{
                    return true;
                }
            },

            getPrize(){
                //item.prize
                let pr=0;
                let s='0'
                try {
                    let prizePool = this.prizePoolObj[this.$store.state.prizePoolBets.prizePoolActive];

                    if (prizePool.tokenPrizePool && prizePool.tokenPrizePool.amount>0 && this.$store.state.prizePoolShowToken){
                        pr = this.item.tokenPrize?this.item.tokenPrize.amount:0;
                        s = pr ? pr.toString() + ' ' + prizePool.tokenPrizePool.tokenSymbol : 0 + ' ' + prizePool.tokenPrizePool.tokenSymbol;
                    }
                    else {
                        //pr = Number(prizePool.prizePool);
                        pr = Number(this.item.prize);
                        s = pr ? pr.toLocaleString(): 0;
                    }
                }catch (e) {

                }
                return s;
            },//*/
        },
        data(){
            return {

            };
        },
        methods: {

        },
        props: {
            item: {             //{}
                type: Object
            },
            bg:{        //default background
                type: String,
                default: 'none'
            },
            numb:{        //item number in list
                type: String,
                default: 'none'
            },
            prizePoolObj:{        //item number in list
                type: Object,
                default: {}
            },
            userNameColor:{        //default background
                type: String,
                default: 'white'
            },
        },
    }
</script>

<style lang="css">
    .c-prize_player_mob{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: start;
        align-items: center;

        border-bottom: 2px solid rgba(25,201,243,.4);
    }
    .c-prize_player_mob_left{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        flex: none;

        flex-grow: 0;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.4);/**/
    }
    .c-prize_player_mob_left_numb{
        font-family: 'Rubik';
        display: flex;
        font-style: normal;
        align-items: center;
        justify-content: flex-end;
        font-weight: 500;
        letter-spacing: -0.03em;
        color: #FFFFFF;
        flex: none;

        flex-grow: 0;
    }

    .c-prize_player_points{
        font-family: 'Rubik';
        display: flex;
        position: relative;
        font-style: normal;
        align-items: center;
        justify-content: flex-end;
        font-weight: 500;
        letter-spacing: -0.03em;
        color: #FFFFFF;
        flex: none;
        overflow: hidden;

    }

    .c-prize_player_mob_left_user{
        font-family: 'Rubik';
        align-items: center;
        display: flex;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.03em;
        color: #FFFFFF;
        flex: none;

        flex-grow: 0;

        /*background: rgba(255, 255, 255, 0.1);/**/
    }
    .c-prize_player_mob_text{
        font-family: 'Rubik';
        display: flex;
        font-style: normal;
        font-weight: 500;
        align-items: center;
        justify-content: flex-end;
        text-align: -webkit-right;
        letter-spacing: -0.03em;
        color: #FFFFFF;
        flex: none;
        flex-grow: 0;

        /*background: rgba(0, 0, 0, 0.4);/**/
    }
</style>

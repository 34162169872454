<template>
    <!--   Claim prizes screen   -->
    <div class="c-components_body unselectable" style="background: radial-gradient(52.63% 87.8% at 50.03% 4.04%, #0D4455 0%, #030F20 100%), linear-gradient(94deg, #00C6FB 0.27%, #005BEA 105.74%);">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <!--   VERTICAL   -->
            <div v-if="orient==='V'" class="c-claim_col"
                 :style="{'width': realWidth-80*ss*xMult+'px','height': realHeight-80*ss*xMult+'px','gap': 20*ss*xMult+'px',}"
            >
                <!--   Prizes  'height': 120 -->
                <div class="c-claim_row_3" style="justify-content: flex-start;"
                     :style="{'width': 930*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 30*ss*xMult+'px','margin-top': 20*ss*xMult+'px',}"
                >
                    <div class="c-user_info_title_text"
                         :style="{'width': 'auto','height': 80*ss*xMult+'px','margin-left': 20*ss*xMult+'px',
                            'font-size': 65*ss*xMult+'px','line-height': 80*ss*xMult+'px',
                            'text-shadow': '0 0 '+15*ss*xMult+'px rgba(57,231,156,.6)',}"
                    >
                        {{messages.m030_15}}
                    </div>

                    <!--   Prizes claimed/unclaimed switch  -->
                    <div class="c-claim_row_3" style="background: rgba(255, 255, 255, 0.10);"
                         :style="{'width': 600*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 0*ss*xMult+'px',
                            'border-radius': 50*ss*xMult+'px',}"
                    >
                        <!--   Unclaimed   -->
                        <div class="c-claim_col_switch"  style="justify-content: center"
                             @click="claimTypeClick('unclaimed')"
                             :style="{'width': 'auto','height': 100*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 120*ss*xMult+'px',
                                    'box-shadow': prizes.claimType==='unclaimed'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'background':prizes.claimType==='unclaimed'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'font-size': 45*ss*xMult+'px','line-height': 44*ss*xMult+'px',
                                    'padding': 20*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m030_19}}
                            </div>
                        </div>

                        <!--   Claimed   -->
                        <div class="c-claim_col_switch" style="justify-content: center"
                             @click="claimTypeClick('claimed')"
                             :style="{'width': 'auto','height': 100*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 100*ss*xMult+'px',
                                    'box-shadow': prizes.claimType==='claimed'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'background': prizes.claimType==='claimed'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'font-size': 45*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m030_18}}
                            </div>

                        </div>
                    </div>
                </div>

                <!--   NFT chain switch   'height': 460 -->
                <div class="c-claim_col_2" style="background: rgba(255, 255, 255, 0.10);"
                     :style="{'width': 1000*ss*xMult+'px','height': 460*ss*xMult+'px','gap': 20*ss*xMult+'px',
                            'border-radius': 20*ss*xMult+'px',}"
                >
                    <div class="c-claim_row_3"
                         :style="{'width': 1000*ss*xMult+'px','height': 200*ss*xMult+'px','gap': 20*ss*xMult+'px',}"
                    >
                        <!--   All   -->
                        <div class="c-claim_col_switch"
                             @click="chainClick('')"
                             :style="{'width': 'auto','height': 200*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 100*ss*xMult+'px',
                                        'box-shadow': prizes.blockchain===''?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                        'border-radius': 50*ss*xMult+'px',
                                        'background':prizes.blockchain===''?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'font-size': 50*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                        'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m031_01}}
                            </div>

                        </div>

                        <!--   WAX   -->
                        <div class="c-claim_col_switch"
                             @click="chainClick('wax')"
                             :style="{'width': 'auto','height': 200*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <img class="c-claim_img"
                                 :src="waxSvg" alt=""
                                 :style="{'width': 150*ss*xMult+'px','height': 150*ss*xMult+'px','top': -25*ss*xMult+'px',}"
                            />
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 120*ss*xMult+'px',
                                        'box-shadow': prizes.blockchain==='wax'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                        'border-radius': 50*ss*xMult+'px',
                                        'background':prizes.blockchain==='wax'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'font-size': 50*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                        'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m031_02}}
                            </div>
                        </div>

                        <!--   Ronin   -->
                        <div class="c-claim_col_switch"
                             @click="chainClick('ronin')"
                             :style="{'width': 'auto','height': 200*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <img class="c-claim_img"
                                 :src="ronSvg" alt=""
                                 :style="{'width': 100*ss*xMult+'px','height': 100*ss*xMult+'px','top': 0*ss*xMult+'px',}"
                            />
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 120*ss*xMult+'px',
                                        'box-shadow': prizes.blockchain==='ronin'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                        'border-radius': 50*ss*xMult+'px',
                                        'background':prizes.blockchain==='ronin'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'font-size': 50*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                        'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m031_04}}
                            </div>
                        </div>

                        <!--   Solana   -->
                        <div class="c-claim_col_switch"
                             @click="chainClick('sol')"
                             :style="{'width': 'auto','height': 200*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <img class="c-claim_img"
                                 :src="solSvg" alt=""
                                 :style="{'width': 100*ss*xMult+'px','height': 100*ss*xMult+'px','top': 0*ss*xMult+'px',}"
                            />
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 120*ss*xMult+'px',
                                        'box-shadow': prizes.blockchain==='sol'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                        'border-radius': 50*ss*xMult+'px',
                                        'background':prizes.blockchain==='sol'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'font-size': 50*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                        'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m031_03}}
                            </div>
                        </div>

                    </div>
                    <div class="c-claim_row_3"
                         :style="{'width': 900*ss*xMult+'px','height': 200*ss*xMult+'px','gap': 20*ss*xMult+'px',}"
                    >

                        <!--   BNB   -->
                        <div class="c-claim_col_switch"
                             @click="chainClick('bnb')"
                             :style="{'width': 'auto','height': 200*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <img class="c-claim_img"
                                 :src="bnbSvg" alt=""
                                 :style="{'width': 100*ss*xMult+'px','height': 100*ss*xMult+'px','top': 0*ss*xMult+'px',}"
                            />
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 120*ss*xMult+'px',
                                        'box-shadow': prizes.blockchain==='bnb'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                        'border-radius': 50*ss*xMult+'px',
                                        'background':prizes.blockchain==='bnb'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'font-size': 50*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                        'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m031_05}}
                            </div>
                        </div>

                        <!--   Poligon   -->
                        <div class="c-claim_col_switch"
                             @click="chainClick('pol')"
                             :style="{'width': 'auto','height': 200*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <img class="c-claim_img"
                                 :src="polSvg" alt=""
                                 :style="{'width': 100*ss*xMult+'px','height': 100*ss*xMult+'px','top': 0*ss*xMult+'px',}"
                            />
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 120*ss*xMult+'px',
                                        'box-shadow': prizes.blockchain==='pol'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                        'border-radius': 50*ss*xMult+'px',
                                        'background':prizes.blockchain==='pol'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'font-size': 50*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                        'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m031_06}}
                            </div>
                        </div>

                        <!--   Digital   -->
                        <div class="c-claim_col_switch"
                             @click="chainClick('digital')"
                             :style="{'width': 'auto','height': 200*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <img class="c-claim_img"
                                 :src="blockImg" alt=""
                                 :style="{'width': 150*ss*xMult+'px','height': 150*ss*xMult+'px','top': -25*ss*xMult+'px',}"
                            />
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 120*ss*xMult+'px',
                                        'box-shadow': prizes.blockchain==='digital'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                        'border-radius': 50*ss*xMult+'px',
                                        'background':prizes.blockchain==='digital'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'font-size': 44*ss*xMult+'px','line-height': 44*ss*xMult+'px',
                                        'padding': 20*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m031_07}}
                            </div>
                        </div>
                    </div>

                </div>

                <!--   vertical scroll static   -->
                <div class="c-claim_scroll_static"
                     :style="{'width': realWidth-80*ss*xMult+'px','gap': 30*ss*xMult+'px',
                        'height': orient==='V'?realHeight-850*ss*xMult+'px':realHeight-320*ss*xMult+'px',}"
                >
                    <!--   scroll content dynamic height PRIZES   -->
                    <div class="c-claim_scroll"
                         :style="{'width': realWidth-80*ss*xMult+'px','height': 'auto','gap': 30*ss*xMult+'px',
                         'padding-top': 10*ss*xMult+'px','padding-bottom': 10*ss*xMult+'px','margin-top': 20*ss*xMult+'px',}"
                    >
                        <PrizeCard
                                v-for="(prizeItem, index) in getClaimPrizes"
                                :key="'claim_List'+index"
                                :prize-item="prizeItem"
                        />
                    </div>
                </div>

                <!--   Withdraw prizes  -->
                <div class="c-claim_row_2"
                     :style="{'width': 1000*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 30*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
                >
                    <div v-if="!prizes.blockchain" class="c-claim_row_2 c-claim_text"
                         :style="{'width': 1000*ss*xMult+'px','height': 120*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                    >
                        {{messages.m031_08}}
                    </div>
                    <div v-if="prizes.blockchain==='digital'" class="c-claim_row_2 c-claim_text"
                         :style="{'width': 1000*ss*xMult+'px','height': 120*ss*xMult+'px',}"
                    >
                        <ButtonSvgOval
                                :callback="claimClick" :callback-wait="true"
                                :btn-text="messages.m030_14"
                                :bg-width="250*ss*xMult" :bg-height="90*ss*xMult"
                                :font-size="45*ss*xMult"
                                :svg-width="60*ss*xMult" :svg-height="60*ss*xMult"
                                :click-scale="0.95"
                        />
                    </div>
                    <div v-if="prizes.blockchain && prizes.blockchain!=='digital' && prizes.claimType!=='claimed'" class="c-claim_row_2"
                         :style="{'width': 1000*ss*xMult+'px','height': 120*ss*xMult+'px',}"
                    >

                        <input class="c-claim_input" v-model="sendingWallet" :placeholder="messages.m038_06"
                             :style="{'width': 660*ss*xMult+'px','height': 85*ss*xMult+'px','margin-right': -50*ss*xMult+'px',
                                'padding-left': 80*ss*xMult+'px','font-size': 50*ss*xMult+'px',
                                'border-top-left-radius': 60*ss*xMult+'px','border-bottom-left-radius': 60*ss*xMult+'px',}"
                        />

                        <ButtonSvgOval
                                :callback="claimClick" :callback-wait="true"
                                :btn-text="messages.m030_14"
                                :bg-width="250*ss*xMult" :bg-height="90*ss*xMult"
                                :font-size="45*ss*xMult"
                                :svg-width="60*ss*xMult" :svg-height="60*ss*xMult"
                                :click-scale="0.95"
                        />
                        <img v-if="!sendingWallet" class="c-claim_popup_img_1" alt="" @click="clickPaste"
                             :style="{'width': 'auto','height': 65*ss*xMult+'px','left': 40*ss*xMult+'px',}"
                             :src="img_paste"
                        />
                        <img v-else class="c-claim_popup_img_1" alt="" @click="clickClear"
                             :style="{'width': 'auto','height': 50*ss*xMult+'px','left': 48*ss*xMult+'px',}"
                             :src="img_btn_x_yellow"
                        />
                        <div v-if="popupQuickHelp.prizesClaimQuick===true" class="c-claim_popup_quick"
                             :style="{'left': 50*ss*xMult+'px','top': -90*ss*xMult+'px',}"
                        >
                            <PopupInfo     :bg-width="250*ss*xMult" :bg-height="90*ss*xMult"
                                           :max-width="50*ss*xMult"
                                           :border-radius="30*ss*xMult"
                                           :font-size="45*ss*xMult"
                                           :btn-text="messages.m047_05"
                                           :background-val="'rgba(0, 0, 0, 0.4)'"
                                           :border-val="'1px solid rgba(255, 255, 255, 0.5)'"
                                           :callback="popupInfoClick" :callback-wait="true"

                            />
                        </div>
                    </div>
                </div>

            </div>

            <!--   HORIZONTAL   -->
            <div v-if="orient==='H'" class="c-claim_col"
                 :style="{'width': realWidth-80*ss*xMult+'px','height': realHeight-40*ss*xMult+'px','gap': 0*ss*xMult+'px',}"
            >
                <!--   Prizes  'height': 120 -->
                <div class="c-claim_row_3" style="justify-content: flex-start;"
                     :style="{'width': 930*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 30*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
                >
                    <div class="c-user_info_title_text"
                         :style="{'width': 'auto','height': 80*ss*xMult+'px','margin-left': 20*ss*xMult+'px',
                            'font-size': 65*ss*xMult+'px','line-height': 80*ss*xMult+'px',
                            'text-shadow': '0 0 '+15*ss*xMult+'px rgba(57,231,156,.6)',}"
                    >
                        {{messages.m030_15}}
                    </div>

                    <!--   Prizes claimed/unclaimed switch  -->
                    <div class="c-claim_row_3" style="background: rgba(255, 255, 255, 0.10);"
                         :style="{'width': 600*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 0*ss*xMult+'px',
                            'border-radius': 50*ss*xMult+'px',}"
                    >
                        <!--   Unclaimed   -->
                        <div class="c-claim_col_switch"  style="justify-content: center"
                             @click="claimTypeClick('unclaimed')"
                             :style="{'width': 'auto','height': 100*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 120*ss*xMult+'px',
                                    'box-shadow': prizes.claimType==='unclaimed'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'background':prizes.claimType==='unclaimed'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'font-size': 45*ss*xMult+'px','line-height': 44*ss*xMult+'px',
                                    'padding': 20*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m030_19}}
                            </div>
                        </div>

                        <!--   Claimed   -->
                        <div class="c-claim_col_switch" style="justify-content: center"
                             @click="claimTypeClick('claimed')"
                             :style="{'width': 'auto','height': 100*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 100*ss*xMult+'px',
                                    'box-shadow': prizes.claimType==='claimed'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                    'border-radius': 50*ss*xMult+'px',
                                    'background': prizes.claimType==='claimed'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                    'font-size': 45*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                    'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m030_18}}
                            </div>

                        </div>
                    </div>
                </div>

                <!--   NFT chain switch   'height': 460 -->
                <div class="c-claim_col_2" style="background: rgba(255, 255, 255, 0.10);"
                     :style="{'width': 1900*ss*xMult+'px','height': 230*ss*xMult+'px','border-radius': 30*ss*xMult+'px','margin-top': 15*ss*xMult+'px',}"
                >
                    <div class="c-claim_row_3"
                         :style="{'width': 1840*ss*xMult+'px','height': 200*ss*xMult+'px','gap': 20*ss*xMult+'px',}"
                    >
                        <!--   All   -->
                        <div class="c-claim_col_switch"
                             @click="chainClick('')"
                             :style="{'width': 'auto','height': 200*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 100*ss*xMult+'px',
                                        'box-shadow': prizes.blockchain===''?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                        'border-radius': 50*ss*xMult+'px',
                                        'background':prizes.blockchain===''?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'font-size': 50*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                        'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m031_01}}
                            </div>

                        </div>

                        <!--   WAX   -->
                        <div class="c-claim_col_switch"
                             @click="chainClick('wax')"
                             :style="{'width': 'auto','height': 200*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <img class="c-claim_img"
                                 :src="waxSvg" alt=""
                                 :style="{'width': 150*ss*xMult+'px','height': 150*ss*xMult+'px','top': -25*ss*xMult+'px',}"
                            />
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 120*ss*xMult+'px',
                                        'box-shadow': prizes.blockchain==='wax'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                        'border-radius': 50*ss*xMult+'px',
                                        'background':prizes.blockchain==='wax'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'font-size': 50*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                        'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m031_02}}
                            </div>
                        </div>

                        <!--   Ronin   -->
                        <div class="c-claim_col_switch"
                             @click="chainClick('ronin')"
                             :style="{'width': 'auto','height': 200*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <img class="c-claim_img"
                                 :src="ronSvg" alt=""
                                 :style="{'width': 100*ss*xMult+'px','height': 100*ss*xMult+'px','top': 0*ss*xMult+'px',}"
                            />
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 120*ss*xMult+'px',
                                        'box-shadow': prizes.blockchain==='ronin'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                        'border-radius': 50*ss*xMult+'px',
                                        'background':prizes.blockchain==='ronin'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'font-size': 50*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                        'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m031_04}}
                            </div>
                        </div>

                        <!--   Solana   -->
                        <div class="c-claim_col_switch"
                             @click="chainClick('sol')"
                             :style="{'width': 'auto','height': 200*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <img class="c-claim_img"
                                 :src="solSvg" alt=""
                                 :style="{'width': 100*ss*xMult+'px','height': 100*ss*xMult+'px','top': 0*ss*xMult+'px',}"
                            />
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 120*ss*xMult+'px',
                                        'box-shadow': prizes.blockchain==='sol'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                        'border-radius': 50*ss*xMult+'px',
                                        'background':prizes.blockchain==='sol'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'font-size': 50*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                        'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m031_03}}
                            </div>
                        </div>

                        <!--   BNB   -->
                        <div class="c-claim_col_switch"
                             @click="chainClick('bnb')"
                             :style="{'width': 'auto','height': 200*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <img class="c-claim_img"
                                 :src="bnbSvg" alt=""
                                 :style="{'width': 100*ss*xMult+'px','height': 100*ss*xMult+'px','top': 0*ss*xMult+'px',}"
                            />
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 120*ss*xMult+'px',
                                        'box-shadow': prizes.blockchain==='bnb'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                        'border-radius': 50*ss*xMult+'px',
                                        'background':prizes.blockchain==='bnb'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'font-size': 50*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                        'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m031_05}}
                            </div>
                        </div>

                        <!--   Poligon   -->
                        <div class="c-claim_col_switch"
                             @click="chainClick('pol')"
                             :style="{'width': 'auto','height': 200*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <img class="c-claim_img"
                                 :src="polSvg" alt=""
                                 :style="{'width': 100*ss*xMult+'px','height': 100*ss*xMult+'px','top': 0*ss*xMult+'px',}"
                            />
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 120*ss*xMult+'px',
                                        'box-shadow': prizes.blockchain==='pol'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                        'border-radius': 50*ss*xMult+'px',
                                        'background':prizes.blockchain==='pol'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'font-size': 50*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                        'padding': 15*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m031_06}}
                            </div>
                        </div>

                        <!--   Digital   -->
                        <div class="c-claim_col_switch"
                             @click="chainClick('digital')"
                             :style="{'width': 'auto','height': 200*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                        >
                            <img class="c-claim_img"
                                 :src="blockImg" alt=""
                                 :style="{'width': 150*ss*xMult+'px','height': 150*ss*xMult+'px','top': -25*ss*xMult+'px',}"
                            />
                            <div class="c-claim_ring_info"
                                 :style="{'min-width': 120*ss*xMult+'px',
                                        'box-shadow': prizes.blockchain==='digital'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'0px 5px 6px 0px rgba(255, 255, 255, 0.30) inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.07)',
                                        'border-radius': 50*ss*xMult+'px',
                                        'background':prizes.blockchain==='digital'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':'none',
                                        'font-size': 44*ss*xMult+'px','line-height': 44*ss*xMult+'px',
                                        'padding': 20*ss*xMult+'px '+40*ss*xMult+'px',}"
                            >
                                {{messages.m031_07}}
                            </div>
                        </div>
                    </div>
                </div>

                <!--   horizontal scroll static   -->
                <div class="c-claim_scroll_static"
                     :style="{'width': realWidth-80*ss*xMult+'px','gap': 30*ss*xMult+'px',
                        'height': realHeight-540*ss*xMult+'px','margin-top': 15*ss*xMult+'px',}"
                >
                    <!--   scroll content dynamic height PRIZES    -->
                    <div class="c-claim_scroll"
                         :style="{'width': realWidth-80*ss*xMult+'px','height': 'auto','gap': 30*ss*xMult+'px',
                         'padding-top': 10*ss*xMult+'px','padding-bottom': 10*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
                    >
                        <PrizeCard
                                v-for="(prizeItem, index) in getClaimPrizes"
                                :key="'claim_List'+index"
                                :prize-item="prizeItem"
                        />
                    </div>
                </div>

                <!--   Withdraw prizes  -->
                <div class="c-claim_row_2"
                     :style="{'width': 1000*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 30*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
                >
                    <div v-if="!prizes.blockchain" class="c-claim_row_2 c-claim_text"
                         :style="{'width': 1000*ss*xMult+'px','height': 120*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                    >
                        {{messages.m031_08}}
                    </div>
                    <div v-if="prizes.blockchain==='digital'" class="c-claim_row_2 c-claim_text"
                         :style="{'width': 1000*ss*xMult+'px','height': 120*ss*xMult+'px',}"
                    >
                        <ButtonSvgOval
                                :callback="claimClick" :callback-wait="true"
                                :btn-text="messages.m030_14"
                                :bg-width="250*ss*xMult" :bg-height="90*ss*xMult"
                                :font-size="45*ss*xMult"
                                :svg-width="60*ss*xMult" :svg-height="60*ss*xMult"
                                :click-scale="0.95"
                        />
                    </div>
                    <div v-if="prizes.blockchain && prizes.blockchain!=='digital' && prizes.claimType!=='claimed'" class="c-claim_row_2"
                         :style="{'width': 1000*ss*xMult+'px','height': 120*ss*xMult+'px',}"
                    >

                        <input class="c-claim_input" v-model="sendingWallet" :placeholder="messages.m038_06"
                               :style="{'width': 660*ss*xMult+'px','height': 85*ss*xMult+'px','margin-right': -50*ss*xMult+'px',
                                'padding-left': 80*ss*xMult+'px','font-size': 50*ss*xMult+'px',
                                'border-top-left-radius': 60*ss*xMult+'px','border-bottom-left-radius': 60*ss*xMult+'px',}"
                        />

                        <ButtonSvgOval
                                :callback="claimClick" :callback-wait="true"
                                :btn-text="messages.m030_14"
                                :bg-width="250*ss*xMult" :bg-height="90*ss*xMult"
                                :font-size="45*ss*xMult"
                                :svg-width="60*ss*xMult" :svg-height="60*ss*xMult"
                                :click-scale="0.95"
                        />
                        <img v-if="!sendingWallet" class="c-claim_popup_img_1" alt="" @click="clickPaste"
                             :style="{'width': 'auto','height': 65*ss*xMult+'px','left': 40*ss*xMult+'px',}"
                             :src="img_paste"
                        />
                        <img v-else class="c-claim_popup_img_1" alt="" @click="clickClear"
                             :style="{'width': 'auto','height': 50*ss*xMult+'px','left': 48*ss*xMult+'px',}"
                             :src="img_btn_x_yellow"
                        />
                        <div v-if="popupQuickHelp.prizesClaimQuick===true" class="c-claim_popup_quick"
                             :style="{'left': 50*ss*xMult+'px','top': -90*ss*xMult+'px',}"
                        >
                            <PopupInfo     :bg-width="250*ss*xMult" :bg-height="90*ss*xMult"
                                           :max-width="50*ss*xMult"
                                           :border-radius="30*ss*xMult"
                                           :font-size="45*ss*xMult"
                                           :btn-text="messages.m047_05"
                                           :background-val="'rgba(0, 0, 0, 0.4)'"
                                           :border-val="'1px solid rgba(255, 255, 255, 0.5)'"
                                           :callback="popupInfoClick" :callback-wait="true"

                            />
                        </div>
                    </div>
                </div>
            </div>

            <!--   X button   -->
            <div style="position: absolute"
                 :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
            >
                <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                           :bg-width="72*ss*xMult"
                           :bg-height="72*ss*xMult"
                           :callback="btn_xClick" :callback-wait="true"
                />
            </div>
        </div>
    </div>


</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import PopupInfo from "@/vue/elements/PopupInfo";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import blockImg from "@/assets/images/blockspin.webp";
    import bnbSvg from "@/assets/svg/chains/icon_bnb.svg";
    import polSvg from "@/assets/svg/chains/icon_polygon.svg";
    import ronSvg from "@/assets/svg/chains/icon_ron.svg";
    import solSvg from "@/assets/svg/chains/icon_sol.svg";
    import waxSvg from "@/assets/svg/chains/icon_wax_white.svg";
    import PrizeCard from "@/vue/tickets/PrizeCard";
    import img_paste from "@/assets/svg/paste";
    import img_btn_x_yellow from "@/assets/svg/btn_x_yellow";

    export default defineComponent({
        name: 'PrizesClaim',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","messages","orient","prizes","getClaimPrizes","popupQuickHelp"]),

        },
        components: {ButtonSvg,ButtonSvgOval,PrizeCard,PopupInfo},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),waxSvg:Utils.getGlobalLink(waxSvg),solSvg:Utils.getGlobalLink(solSvg),
                bnbSvg:Utils.getGlobalLink(bnbSvg),ronSvg:Utils.getGlobalLink(ronSvg),polSvg:Utils.getGlobalLink(polSvg),
                blockImg:Utils.getGlobalLink(blockImg),
                img_paste:Utils.getGlobalLink(img_paste), img_btn_x_yellow:Utils.getGlobalLink(img_btn_x_yellow),
                sendingWallet: "",
                startClaim:false,
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        async beforeMount() {
            await this.$store.dispatch("getState");
        },
        methods: {
            btn_xClick(){
                this.$store.state.popupVisible.prizesClaim=false;
            },
            chainClick(chain){
                this.$store.state.prizes.blockchain=chain;
            },
            claimTypeClick(claimType){
                this.$store.state.prizes.claimType=claimType;
            },
            async claimClick(){
                if (this.startClaim)
                    return;
                this.startClaim=true;
                try {
                    const state = this.$store.state;
                    if (state.prizes.blockchain === 'digital') {
                        const cp = this.$store.getters.getClaimPrizes;
                        if (cp.length > 0) {
                            this.$store.dispatch('claimDigitalPrize', this.$store.getters.getClaimPrizes);
                        } else {
                            state.showMessagePopup(state.messages.m029_12, state.messages.m029_5, true);
                        }
                    }
                    if (state.prizes.blockchain !== 'nft') {
                        if (this.sendingWallet) {
                            let claimNftsData = {address: this.sendingWallet};
                            switch (state.prizes.blockchain) {
                                case "wax": {
                                    claimNftsData.chain = "WAX";
                                    break;
                                }
                                case "sol": {
                                    claimNftsData.chain = "SOL";
                                    break;
                                }
                                case "bnb": {
                                    claimNftsData.chain = "BNB";
                                    break;
                                }
                                case "pol": {
                                    claimNftsData.chain = "POL";
                                    break;
                                }
                                case "ronin": {
                                    claimNftsData.chain = "RON";
                                    break;
                                }
                            }
                            const claim = await this.$store.dispatch("claimNfts", claimNftsData);
                            if (claim.error) {
                                state.showMessagePopup(claim.error, state.messages.m029_5, true);
                            } else {
                                state.showMessagePopup(state.messages.m038_07, state.messages.m035_03, false);
                            }
                        }
                    }
                }
                catch (e) {

                }
                this.startClaim=false;
            },
            clickClear(){
                this.sendingWallet='';
            },

            async clickPaste(){
                const state=this.$store.state;
                state.popupQuickHelp.prizesClaimQuick=true;
                let clipboardData = window.clipboardData || navigator.clipboard;
                const text = await clipboardData.readText();
                this.sendingWallet=text;
                setTimeout(() => {
                    state.popupQuickHelp.prizesClaimQuick=false;
                }, 1000);
            },
            popupInfoClick(){
                const state=this.$store.state;
                state.popupQuickHelp.prizesClaimQuick=false;

            },
        },

    });
</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-claim_col{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;

        /*background: rgba(255, 255, 255, 0.20);/**/
    }

    .c-claim_col_2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(255, 255, 255, 0.20);/**/
    }

    .c-claim_row_2{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(255, 255, 255, 0.20);/**/
    }

    .c-claim_input{
        font-style: normal;
        font-weight: 400;
        font-family: 'Rubik', sans-serif;

        letter-spacing: -0.03em;

        /*background: rgba(255, 255, 255, 0.20);/**/
    }

    .c-claim_row_3{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        overflow: visible;

        /*background: rgba(255, 255, 255, 0.20);/**/
    }

    .c-claim_col_switch{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-claim_img{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-claim_ring_info{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        border: 1px solid rgba(255, 255, 255, 0.10);
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.03em;
        color: #FFFFFF;
    }

    .c-claim_scroll_static {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;

        /*background: rgba(255, 255, 255, 0.2);/**/
    }

    .c-claim_scroll{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-claim_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        color: #FFFFFF;
        text-align: -webkit-center;
    }

    .c-claim_text_prizes{
        background: linear-gradient(95.19deg,#0cff7c -16.14%,#00af90 137.96%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 0px 0px 15px rgba(57, 231, 156, 0.60);
    }

    .c-claim_popup_quick{
        position: absolute;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }

    .c-claim_popup_img_1{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

</style>

<template>
    <div class="c-components_body unselectable" style="backdrop-filter: blur(5px);background: linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8));">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <!--   Popup bg  "Reffer a friend" popup  -->
            <div class="c-referral_info_2"
                 :style="{'width': 1000*ss*xMult+'px','height': 800*ss*xMult+'px','gap': 30*ss*xMult+'px','border-radius':60*ss*xMult+'px',
                    'border':6*ss*xMult+'px solid #76069A',
                    'background':getBg,'box-shadow':getShadow}"
            >

                <!--     decoration images -->
                <div class="c-referral_info_bg_abs"
                     :style="{'width': '100%','height': '100%','border-radius':60*ss*xMult+'px',}"
                >
                    <img class="c-referral_info_img_1" alt=""
                         :style="{'width': 'auto','height': 450*ss*xMult+'px','bottom': 0*ss*xMult+'px','right': 0*ss*xMult+'px',}"
                         :src="img_coins"
                    />
                    <img class="c-referral_info_img_1" alt=""
                         :style="{'width': 'auto','height': 400*ss*xMult+'px','bottom': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
                         :src="img_speaker"
                    />
                </div>

                <!--     Info region       -->
                <div class="c-referral_info_column" style="justify-content: space-evenly;"
                     :style="{'width': 1000*ss*xMult+'px','height': 800*ss*xMult+'px',}"
                >
                    <!--     Refer a friend       -->
                    <div class="c-referral_info_text c-referral_info_column"
                         :style="{'width': 1000*ss*xMult+'px','height': 100*ss*xMult+'px',
                            'font-size': 70*ss*xMult+'px','line-height': 100*ss*xMult+'px',}"
                    >
                        {{messages.m040_01}}
                    </div>
                    <!--     DAILY CHIPS AWARD!       -->
                    <div class="c-referral_info_text c-referral_info_column"
                         :style="{'width': 920*ss*xMult+'px','height': 180*ss*xMult+'px',}"
                    >
                        <div class="c-referral_info_text c-referral_info_column"
                             :style="{'width': 920*ss*xMult+'px','height': 60*ss*xMult+'px',
                            'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                        >
                            {{messages.m040_02}}
                        </div>
                        <div class="c-referral_info_text c-referral_info_column"
                             :style="{'width': 920*ss*xMult+'px','height': 120*ss*xMult+'px',
                            'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                        >
                            {{messages.m040_03}}
                            <span style="color: rgb(255, 229, 0);">{{messages.m040_04}}</span>
                        </div>

                    </div>

                    <!--     Share your referral link  -->
                    <div class="c-referral_info_row" style="background: white;"
                         :style="{'width': 800*ss*xMult+'px','height': 130*ss*xMult+'px',
                            'border-radius': 100*ss*xMult+'px',}"
                    >
                        <div class="c-referral_info_text c-referral_info_row" style="color: black; overflow-wrap: anywhere;"
                             :style="{'width': 650*ss*xMult+'px','height': 100*ss*xMult+'px','font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                'gap': 10*ss*xMult+'px',}">
                            {{getReferralLink}}
                        </div>
                        <img class="c-referral_info_img_1" alt="" @click="clickCopy"
                             :style="{'width': 'auto','height': 65*ss*xMult+'px','right': 25*ss*xMult+'px',}"
                             :src="img_copy"
                        />
                        <div v-if="popupQuickHelp.referralCopy===true" class="c-referral_info_popup"
                             :style="{'right': 50*ss*xMult+'px',}"
                        >
                            <PopupInfo     :bg-width="250*ss*xMult" :bg-height="90*ss*xMult"
                                           :max-width="50*ss*xMult"
                                           :border-radius="30*ss*xMult"
                                           :font-size="45*ss*xMult"
                                           :btn-text="messages.m040_09"
                                           :background-val="'rgba(0, 0, 0, 0.4)'"
                                           :border-val="'1px solid rgba(255, 255, 255, 0.5)'"
                                           :callback="popupInfoClick" :callback-wait="true"

                            />
                        </div>
                    </div>

                    <!--     You have invited  -->
                    <div class="c-referral_info_time_bg"
                         :style="{'width': 700*ss*xMult+'px','height': 130*ss*xMult+'px',
                            'border-radius': 100*ss*xMult+'px',}"
                    >
                        <div class="c-referral_info_text c-referral_info_row"
                             :style="{'width': 620*ss*xMult+'px','height': 100*ss*xMult+'px','font-size': 45*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                                'gap': 10*ss*xMult+'px',}">
                            {{messages.m040_07}}<span style="color: rgb(255, 229, 0);">{{userData.numReferred}}</span>{{messages.m040_08}}
                        </div>
                    </div>


                </div>

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import PopupInfo from "@/vue/elements/PopupInfo";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import img_copy from "@/assets/svg/copy.svg";
    import img_speaker from "@/assets/images/account/speaker.webp";
    import img_coins from "@/assets/images/account/coins.webp";
    import img_twitter from "@/assets/svg/tickets/prizes.svg";
    import img_discord from "@/assets/svg/tickets/prizes.svg";

    export default defineComponent({
        name: 'ReferralInfo',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","getLeft","xMult","messages","popupQuickHelp","userData","getReferralLink"]),


            getBg(){
                let txt='radial-gradient(100.84% 132.88% at -11.6% -9.79%,#ca1d91 0,#6d14ce 100%)';
                return txt;
            },
            getShadow(){
                let txt='0 0 100px rgba(181,6,220,.5),inset 0 0 100px hsla(0,0%,100%,.5)';
                return txt;
            },
        },
        components: {ButtonSvg,ButtonSvgOval,PopupInfo},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),img_speaker:Utils.getGlobalLink(img_speaker),
                img_coins:Utils.getGlobalLink(img_coins), img_copy:Utils.getGlobalLink(img_copy),
                img_twitter:Utils.getGlobalLink(img_twitter),img_discord:Utils.getGlobalLink(img_discord),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btn_xClick(){
                this.$store.state.popupVisible.referralInfo=false;
            },
            clickCopy(){
                const getters=this.$store.getters;
                const state=this.$store.state;
                let clipboardData = window.clipboardData || navigator.clipboard;
                clipboardData.writeText(getters.getReferralLink);

                state.popupQuickHelp.referralCopy=true;
                setTimeout(() => {
                    state.popupQuickHelp.referralCopy=false;
                }, 1000);
            },
            popupInfoClick(){
                const state=this.$store.state;
                state.popupQuickHelp.referralCopy=false;
            },
        },
    });
</script>

<style lang="css" scoped>
    .c-referral_info_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-referral_info_bg_abs{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-referral_info_column{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-referral_info_img_1{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-referral_info_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;

        /*word-wrap: break-word;/**/
        /*white-space: nowrap;        /*Нет переноса не переносит*/
    }


    .c-referral_info_time_bg{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        box-shadow: 0 7px 15px rgb(0 0 0 / 59%), inset 0 5px 6px hsl(0deg 0% 100% / 42%);
        background: hsl(0deg 0% 4.3% / 26%);
    }

    .c-referral_info_row{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .c-referral_info_popup{
        position: absolute;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }

</style>

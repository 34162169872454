<template>
    <!--   Popup with wallet subtype for wax:wax/anchor, after login continue buy action with buyObj   -->
    <div class="c-components_body unselectable" style="backdrop-filter: blur(5px);background: linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8));">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <!--   Popup bg static   -->
            <div class="c-wallet_type_2"
                 :style="{'width': 1000*ss*xMult+'px','height': 750*ss*xMult+'px','gap': 30*ss*xMult+'px','border-radius':60*ss*xMult+'px',
                    'border':6*ss*xMult+'px solid #00c6fb',
                    'background':getBg,'box-shadow':getShadow}"
            >

                <!--     Information       -->
                <div class="c-wallet_type_column"
                     :style="{'width': 1000*ss*xMult+'px','height': 650*ss*xMult+'px',}"
                >
                    <!--     Title       -->
                    <div class="c-wallet_type_text c-wallet_type_2" style="color: #ffffff"
                         :style="{'width': 800*ss*xMult+'px','height': 100*ss*xMult+'px',
                            'font-size': 70*ss*xMult+'px','line-height': 100*ss*xMult+'px',}"
                    >
                        {{messages.m033_01}}
                    </div>
                    <!--     Row with WAX/Phantom       -->
                    <div class="c-wallet_type_row"
                         :style="{'width': 900*ss*xMult+'px','height': 120*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                            'border-radius':100*ss*xMult+'px',}"
                    >
                        <img :src="getImg1" alt=""
                             :style="{'width': 'auto','height': 110*ss*xMult+'px','margin-left': 20*ss*xMult+'px',}"
                        />
                        <div class="c-wallet_type_text c-wallet_type_2" style="color: #000000"
                             :style="{'width': 'auto','height': 100*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                        >
                            {{getText1}}
                        </div>
                        <!--     Confirm button       -->
                        <div :style="{'width': 230*ss*xMult+'px','height': 100*ss*xMult+'px','margin-right': 10*ss*xMult+'px',}">
                            <ButtonSvgOval :callback="clickLogin1" :callback-wait="true"
                                           :btn-text="messages.m004"
                                           :bg-width="230*ss*xMult" :bg-height="100*ss*xMult"
                                           :max-width="230*ss*xMult"
                                           :font-size="45*ss*xMult"
                                           :svg-width="50*ss*xMult" :svg-height="50*ss*xMult"
                                           :click-scale="0.95"
                            />
                        </div>
                    </div>

                    <!--     Row with Anchor/Solflare       -->
                    <div class="c-wallet_type_row"
                         :style="{'width': 900*ss*xMult+'px','height': 120*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                            'border-radius':100*ss*xMult+'px',}"
                    >
                        <img :src="getImg2" alt=""
                             :style="{'width': 'auto','height': 110*ss*xMult+'px','margin-left': 20*ss*xMult+'px',}"
                        />
                        <div class="c-wallet_type_text c-wallet_type_2" style="color: #000000"
                             :style="{'width': 'auto','height': 100*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                        >
                            {{getText2}}
                        </div>
                        <!--     Confirm button       -->
                        <div :style="{'width': 230*ss*xMult+'px','height': 100*ss*xMult+'px','margin-right': 10*ss*xMult+'px',}">
                            <ButtonSvgOval :callback="clickLogin2" :callback-wait="true"
                                           :btn-text="messages.m004"
                                           :bg-width="230*ss*xMult" :bg-height="100*ss*xMult"
                                           :max-width="230*ss*xMult"
                                           :font-size="45*ss*xMult"
                                           :svg-width="50*ss*xMult" :svg-height="50*ss*xMult"
                                           :click-scale="0.95"
                            />
                        </div>
                    </div>

                    <!--     Row with Create account text       -->
                    <div class="c-wallet_type_2"
                         :style="{'width': 900*ss*xMult+'px','height': 'auto',}"
                    >
                        <div class="c-wallet_type_text_2"
                             :style="{'font-size': 42*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                        >
                            {{messages.m033_11}}
                        </div>
                        <div class="c-wallet_type_text_2"
                             :style="{'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-top': 20*ss*xMult+'px',}"
                        >
                            {{'* '+getTextLink1}}
                            <a @click="click1" style="color:#FFE500;">{{messages.m033_08+','}}</a>
                            {{' '+getTextLink2}}
                            <a @click="click2" style="color:#FFE500;">{{messages.m033_08}}</a>
                        </div>

                    </div>

                </div>

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import waxSvg from "@/assets/svg/chains/icon_wax.svg";
    import anchorImg from "@/assets/images/logo_anchor.webp";
    import phantomImg from "@/assets/images/logo_phantom.webp";
    import solflareImg from "@/assets/images/logo_solflare.webp";
    import Config from "@/scripts/Config";

    export default defineComponent({
        name: 'WalletType',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","messages","popupWalletType"]),
            getImg1(){
                let txt;
                const param=this.$store.state.popupWalletType.buyObj;
                if (param.currency==='wax')
                    txt=Utils.getGlobalLink(waxSvg);
                if (param.currency==='sol')
                    txt=Utils.getGlobalLink(phantomImg);
                txt=txt?txt:'';
                return txt;
            },
            getImg2(){
                let txt;
                const param=this.$store.state.popupWalletType.buyObj;
                if (param.currency==='wax')
                    txt=Utils.getGlobalLink(anchorImg);
                if (param.currency==='sol')
                    txt=Utils.getGlobalLink(solflareImg);
                txt=txt?txt:'';
                return txt;
            },
            getText1(){
                let txt;
                const state=this.$store.state
                const param=this.$store.state.popupWalletType.buyObj;
                if (param.currency==='wax')
                    txt=state.messages.m033_02;         //Wax Cloud Wallet
                if (param.currency==='sol')
                    txt=state.messages.m033_04;         //Phantom
                txt=txt?txt:'';
                return txt;
            },
            getText2(){
                let txt;
                const state=this.$store.state
                const param=this.$store.state.popupWalletType.buyObj;
                if (param.currency==='wax')
                    txt=state.messages.m033_03;         //Anchor
                if (param.currency==='sol')
                    txt=state.messages.m033_05;         //Solflare
                txt=txt?txt:'';
                return txt;
            },
            getTextLink1(){
                let txt;
                const state=this.$store.state
                const param=this.$store.state.popupWalletType.buyObj;
                if (param.currency==='wax')
                    txt=state.messages.m033_06;         //Wax Cloud Wallet
                if (param.currency==='sol')
                    txt=state.messages.m033_09;         //Phantom
                txt=txt?txt:'';
                return txt;
            },
            getTextLink2(){
                let txt;
                const state=this.$store.state
                const param=this.$store.state.popupWalletType.buyObj;
                if (param.currency==='wax')
                    txt=state.messages.m033_07;         //Anchor Cloud Wallet
                if (param.currency==='sol')
                    txt=state.messages.m033_10;         //Solflare
                txt=txt?txt:'';
                return txt;
            },
            getBg(){
                let txt='linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%)';
                return txt;
            },
            getShadow(){
                let txt='0 0 100px rgba(25,201,243,.6),inset 0 0 100px hsla(0,0%,100%,.5)';
                return txt;
            },

        },
        components: {ButtonSvg,ButtonSvgOval,},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btn_xClick(){
                const param=this.$store.state.popupWalletType;
                param.visible=false;
                param.buyObj=null;

            },
            async clickLogin1(){
                const param=this.$store.state.popupWalletType;
                if (param.buyObj.currency==='wax')
                    param.buyObj.walletType='wax';
                if (param.buyObj.currency==='sol')
                    param.buyObj.walletType='phantom';
                let obj=JSON.parse(JSON.stringify(param.buyObj));
                this.$store.dispatch('clickBuy',obj)
                param.visible=false;
                param.buyObj=null;
            },
            async clickLogin2(){
                const param=this.$store.state.popupWalletType;
                if (param.buyObj.currency==='wax')
                    param.buyObj.walletType='anchor';
                if (param.buyObj.currency==='sol')
                    param.buyObj.walletType='solflare';
                let obj=JSON.parse(JSON.stringify(param.buyObj));
                this.$store.dispatch('clickBuy',obj)
                param.visible=false;
                param.buyObj=null;
            },
            click1(){
                let txt;
                const state=this.$store.state
                const param=this.$store.state.popupWalletType.buyObj;
                if (param.currency==='wax')
                    txt=Config.walletLink.wax;         //Wax Cloud Wallet
                if (param.currency==='sol')
                    txt=Config.walletLink.phantom;         //Phantom
                if (txt)
                    window.open(txt, '_blank').focus();
            },
            click2(){
                let txt;
                const state=this.$store.state
                const param=this.$store.state.popupWalletType.buyObj;
                if (param.currency==='wax')
                    txt=Config.walletLink.anchor;         //anchor Wallet
                if (param.currency==='sol')
                    txt=Config.walletLink.solflare;         //solflare
                if (txt)
                    window.open(txt, '_blank').focus();
            },
        },
    });
</script>

<style lang="css" scoped>

    .c-wallet_type_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-wallet_type_column{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-wallet_type_row{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        background: rgba(255, 255, 255, 1);
    }

    .c-wallet_type_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        text-align: -webkit-center;

        /*word-wrap: break-word;/**/
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-wallet_type_text_2{
        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        color: #FFFFFF;

        text-align: -webkit-center;
        /*word-wrap: break-word;/**/
        white-space: break-spaces;
    }


</style>

<template>
    <div class="c-iframe"
         :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
        <iframe
                v-if="spincity[gameName]"
                id="mainframe"
                class="c-iframe_bg"
                :style="{'width': realWidth+'px','height': realHeight+'px',}"
                :src="gameUri"
        ></iframe>

    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import GameList from "../../scripts/GameList";
    import Backend from '../../classes/backend.js';
    import Config from "../../scripts/Config";
    import Utils from "../../scripts/Utils";

    export default {
        name: "PageSpincity",
        components: {},
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","getLeft",]),
            gameUri(){
                const gameUri = (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? 'https://test.blockspingaming.com/' : 'https://blockspingaming.com/';
                let uri='';
                uri=gameUri+GameList.spincity[this.gameName].uri;
                return uri;
            },
            gameName() {
                return this.$route.params.gameId.split("-").join("");
            },
        },
        created() {
            MyLog('game page created');
            window.addEventListener("message", this.receiveMessage, false);

            let path='/spincity/'+this.gameName;
            localStorage.setItem("path",path);
        },
        beforeUnmount() {
            window.removeEventListener("message", this.receiveMessage);
        },

        data: function () {
            return {
                spincity: GameList.spincity,
                nextUser: {},
                nextChips: [],
            }
        },
        methods:{
            async receiveMessage(message) {
                if (typeof message.data !== "string") {
                    return;
                }
                const data = JSON.parse(message.data);
                switch (data.action) {
                    case "buyChips": {
                        this.$store.state.isBuyChipsPopupOpen=true;
                        break;
                    }
                    case "home": {
                        this.$router.push({path:Config.defaultPath,query:this.$route.query});
                        break;
                    }
                    case "soundOn": {
                        this.$store.commit("setSound", true);
                        break;
                    }
                    case "soundOff": {
                        this.$store.commit("setSound", false);
                        break;
                    }
                    case "init":{
                        let initData = {
                            loginData: this.$store.state.loginData,
                            env: Utils.getEnv(),
                            sound: this.$store.state.sound,
                        }
                        initData.action="init"
                        console.log(initData);
                        message.source.postMessage(JSON.stringify(initData), "*");
                        break;
                    }
                    case "openshop":{
                        console.log("getting state");
                        let state = await Backend.loginSpincity(this.$store.state.loginData);
                        console.log(state);
                        message.source.postMessage(JSON.stringify(state.buildings), "*");
                        break;
                    }
                }
                if(data === 'betComplete' || (data && data.action && data.action === 'betComplete')) {
                    this.$store.state.userData.chips+=this.nextChips.pop();
                }

            },
        },
    }

</script>

<style lang="css" scoped>

</style>

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background: #FFFFFF;
    border: none;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: none;
}


`, "",{"version":3,"sources":["webpack://./style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,UAAU;IACV,SAAS;IACT,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;;IAEZ,mCAAmC;IACnC,kCAAkC;IAClC,8BAA8B;AAClC","sourcesContent":["html, body {\r\n    width: 100%;\r\n    height: 100%;\r\n    padding: 0;\r\n    margin: 0;\r\n    overflow: hidden;\r\n    background: #FFFFFF;\r\n    border: none;\r\n\r\n    -webkit-font-smoothing: antialiased;\r\n    text-rendering: optimizeLegibility;\r\n    -webkit-text-size-adjust: none;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

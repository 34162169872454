<template>
    <div class="c-ticket_elem unselectable"
         :style="{'width': 600*ss*xMult+'px','height': 800*ss*xMult+'px',}"
    >
        <div class="c-ticket_elem_bg"
             :style="{'width': 600*ss*xMult+'px','height': 700*ss*xMult+'px','margin-top': 0*ss*xMult+'px',
            'border': 6*ss*xMult+'px solid #233C64','border-radius': 60*ss*xMult+'px',}"
        >
            <div class="c-ticket_elem_text"
                 :style="{'width': 600*ss*xMult+'px','height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}">
                {{getTitle}}
            </div>
            <div class="c-ticket_elem_text"
                 :style="{'width': 600*ss*xMult+'px','height': 60*ss*xMult+'px','font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-bottom': 30*ss*xMult+'px',}">
                {{getTitle2}}
            </div>

            <div class="c-ticket_elem_row"
                 :style="{'width': 420*ss*xMult+'px','height': 120*ss*xMult+'px','margin-bottom': 40*ss*xMult+'px','border-radius': 200*ss*xMult+'px',}">
                <!--     Minus       -->
                <ButtonSvgOval
                               :callback="clickMinus" :callback-wait="false"
                               :btn-text="'-'"
                               :bg-width="110*ss*xMult" :bg-height="100*ss*xMult"
                               :font-size="60*ss*xMult"
                               :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                               :borderVal="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                               :border-radius="200*ss*xMult"
                               :click-scale="0.9"
                />


                <div class="c-ticket_elem_text"
                     :style="{'width': 150*ss*xMult+'px','height': 120*ss*xMult+'px','font-size': 60*ss*xMult+'px','line-height': 120*ss*xMult+'px',}">
                    {{buyCount}}
                </div>
                <!--     Plus       -->
                <ButtonSvgOval
                        :callback="clickPlus" :callback-wait="false"
                        :btn-text="'+'"
                        :bg-width="110*ss*xMult" :bg-height="100*ss*xMult"
                        :font-size="60*ss*xMult"
                        :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                        :borderVal="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                        :border-radius="200*ss*xMult"
                        :click-scale="0.9"
                />
            </div>

            <!--     Gems       -->
            <div class="c-ticket_elem_col"
                 :style="{'width': 600*ss*xMult+'px','height': 108*ss*xMult+'px','margin-bottom': 40*ss*xMult+'px',}">
                <ButtonSvgOval :svg-normal="getBuyImage"
                               :callback="clickBuy" :callback-wait="true"
                               :btn-text="getText"
                               :bg-width="420*ss*xMult" :bg-height="100*ss*xMult"
                               :max-width="420*ss*xMult"
                               :font-size="45*ss*xMult"
                               :svg-width="60*ss*xMult" :svg-height="60*ss*xMult"
                               :backgroundVal="getBg"
                               :borderVal="getBorder"
                               :click-scale="0.95"
                />
            </div>
        </div>
        <img :src="getImage" class="c-ticket_elem_img" alt=""
             :style="{'width': 'auto','height': 300*ss*xMult+'px','top': 0*ss*xMult+'px',}"
        />
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ticketBronze from "@/assets/images/tickets_bronze.webp";
    import ticketSilver from "@/assets/images/tickets_silver.webp";
    import ticketGold from "@/assets/images/tickets_gold.webp";
    import lottoBronze from "@/assets/svg/lotto_bronze.svg";
    import lottoSilver from "@/assets/svg/lotto_silver.svg";
    import lottoGold from "@/assets/svg/lotto_gold.svg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import iconGemsSvg from "@/assets/svg/icon_gems";
    import iconBurnSvg from "@/assets/svg/burn_white.svg";

    export default defineComponent({
        name: 'TicketElement',
        components: {ButtonSvgOval,},
        computed: {
            ...mapGetters(["ss","xMult","blockspinState"]),
            getImage(){
                let img='';
                img=this.ticketType==='bronze'?Utils.getGlobalLink(ticketBronze):img;
                img=this.ticketType==='silver'?Utils.getGlobalLink(ticketSilver):img;
                img=this.ticketType==='gold'?Utils.getGlobalLink(ticketGold):img;
                img=this.ticketType==='lottoBronze'?Utils.getGlobalLink(lottoBronze):img;
                img=this.ticketType==='lottoSilver'?Utils.getGlobalLink(lottoSilver):img;
                img=this.ticketType==='lottoGold'?Utils.getGlobalLink(lottoGold):img;
                return img;
            },
            getBuyImage(){
                let img='';
                img=this.ticketType==='bronze'?Utils.getGlobalLink(iconGemsSvg):img;
                img=this.ticketType==='silver'?Utils.getGlobalLink(iconGemsSvg):img;
                img=this.ticketType==='gold'?Utils.getGlobalLink(iconGemsSvg):img;
                img=this.ticketType==='lottoBronze'?Utils.getGlobalLink(iconBurnSvg):img;
                img=this.ticketType==='lottoSilver'?Utils.getGlobalLink(iconBurnSvg):img;
                img=this.ticketType==='lottoGold'?Utils.getGlobalLink(iconBurnSvg):img;
                return img;
            },
            getText(){
                const state=this.$store.state;
                let txt;
                if (state.blockspinState){
                    txt=this.ticketType==='bronze'?state.blockspinState.bronzeRaffleGemPrice:txt;
                    txt=this.ticketType==='silver'?state.blockspinState.silverRaffleGemPrice:txt;
                    txt=this.ticketType==='gold'?state.blockspinState.goldRaffleGemPrice:txt;
                }
                txt=txt?txt.toLocaleString():'0';
                if (state.blockspinState){
                    if (this.ticketType==='lottoBronze' || this.ticketType==='lottoSilver' || this.ticketType==='lottoGold')
                        txt=state.messages.m027_3;
                }
                return txt;
            },
            getTitle(){
                const state=this.$store.state;
                let txt;
                txt=this.ticketType==='bronze'?state.messages.m027_4:txt;
                txt=this.ticketType==='silver'?state.messages.m027_5:txt;
                txt=this.ticketType==='gold'?state.messages.m027_6:txt;
                txt=this.ticketType==='lottoBronze'?state.messages.m027_7:txt;
                txt=this.ticketType==='lottoSilver'?state.messages.m027_8:txt;
                txt=this.ticketType==='lottoGold'?state.messages.m027_9:txt;
                return txt;
            },
            getTitle2(){
                const state=this.$store.state;
                let txt;
                txt=this.ticketType==='bronze'?state.messages.m027_41:txt;
                txt=this.ticketType==='silver'?state.messages.m027_51:txt;
                txt=this.ticketType==='gold'?state.messages.m027_61:txt;
                txt=this.ticketType==='lottoBronze'?state.messages.m027_10:txt;
                txt=this.ticketType==='lottoSilver'?state.messages.m027_10:txt;
                txt=this.ticketType==='lottoGold'?state.messages.m027_10:txt;
                return txt;
            },
            getBg(){
                const state=this.$store.state;
                let txt='linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(95.19deg,#0cff7c -16.14%,#00af90 137.96%) var(--x,0)/200%';
                if (state.blockspinState){
                    if (this.ticketType==='lottoBronze' || this.ticketType==='lottoSilver' || this.ticketType==='lottoGold')
                        txt='linear-gradient(180deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.00) 58.54%), linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)';
                }
                return txt;
            },
            getBorder(){
                const state=this.$store.state;
                let txt='linear-gradient(102.76deg,#06a071 3.6%,#bcffde 53.89%,#06a071 99.19%)';
                if (state.blockspinState){
                    if (this.ticketType==='lottoBronze' || this.ticketType==='lottoSilver' || this.ticketType==='lottoGold')
                        txt='linear-gradient(102.76deg,#cc181a 3.6%,#fdee64 53.89%,#cc181a 99.19%)';
                }
                return txt;
            },
            buyCount(){
                let val=1;
                const tb=this.$store.state.ticketsBuyCount;
                val=this.ticketType==='bronze'?tb.bronze:val;
                val=this.ticketType==='silver'?tb.silver:val;
                val=this.ticketType==='gold'?tb.gold:val;
                val=this.ticketType==='lottoBronze'?tb.lottoBronze:val;
                val=this.ticketType==='lottoSilver'?tb.lottoSilver:val;
                val=this.ticketType==='lottoGold'?tb.lottoGold:val;
                return val;
            },
        },
        data(){
            return {

            };
        },
        created() {

            //console.log(this.clickScale)
        },
        mounted() {
        },
        methods: {
            clickMinus() {
                const tb=this.$store.state.ticketsBuyCount;
                if (this.ticketType==='bronze'){
                    tb.bronze=tb.bronze>1?tb.bronze-1:0;
                }
                if (this.ticketType==='silver'){
                    tb.silver=tb.silver>1?tb.silver-1:0;
                }
                if (this.ticketType==='gold'){
                    tb.gold=tb.gold>1?tb.gold-1:0;
                }

                if (this.ticketType==='lottoBronze'){
                    tb.lottoBronze=tb.lottoBronze>1?tb.lottoBronze-1:0;
                }
                if (this.ticketType==='lottoSilver'){
                    tb.lottoSilver=tb.lottoSilver>1?tb.lottoSilver-1:0;
                }
                if (this.ticketType==='lottoGold'){
                    tb.lottoGold=tb.lottoGold>1?tb.lottoGold-1:0;
                }
            },
            clickPlus() {
                const tb=this.$store.state.ticketsBuyCount;
                const state=this.$store.state;
                const getters=this.$store.getters;
                let maxCount=0;
                let prise=0;
                let ticketTypeText='';
                if (this.ticketType==='bronze'){
                    if (state.blockspinState.bronzeRaffleGemPrice){
                        prise=state.blockspinState.bronzeRaffleGemPrice;
                        maxCount=Math.trunc(getters.getGems/state.blockspinState.bronzeRaffleGemPrice);
                    }
                    maxCount=maxCount<99?maxCount:99;
                    tb.bronze+=tb.bronze<maxCount?1:0;
                }
                if (this.ticketType==='silver'){
                    if (state.blockspinState.silverRaffleGemPrice){
                        prise=state.blockspinState.silverRaffleGemPrice;
                        maxCount=Math.trunc(getters.getGems/state.blockspinState.silverRaffleGemPrice);
                    }
                    maxCount=maxCount<99?maxCount:99;
                    tb.silver+=tb.silver<maxCount?1:0;
                }
                if (this.ticketType==='gold'){
                    if (state.blockspinState.goldRaffleGemPrice){
                        prise=state.blockspinState.goldRaffleGemPrice;
                        maxCount=Math.trunc(getters.getGems/state.blockspinState.goldRaffleGemPrice);
                    }
                    maxCount=maxCount<99?maxCount:99;
                    tb.gold+=tb.gold<maxCount?1:0;
                }

                if (this.ticketType==='lottoBronze'){
                    if (state.userData.bronzeTickets)
                        maxCount=state.userData.bronzeTickets;
                    tb.lottoBronze=tb.lottoBronze<maxCount?tb.lottoBronze+1:maxCount;
                    ticketTypeText=' Bronze ';
                }
                if (this.ticketType==='lottoSilver'){
                    if (state.userData.silverTickets)
                        maxCount=state.userData.silverTickets;
                    tb.lottoSilver=tb.lottoSilver<maxCount?tb.lottoSilver+1:maxCount;
                    ticketTypeText=' Silver ';
                }
                if (this.ticketType==='lottoGold'){
                    if (state.userData.goldTickets)
                        maxCount=state.userData.goldTickets;
                    tb.lottoGold=tb.lottoGold<maxCount?tb.lottoGold+1:maxCount;
                    ticketTypeText=' Gold ';
                }

                if (maxCount===0){      //If plus action and maxCount==0 then show error message
                    const messages=this.$store.state.messages;
                    const param=this.$store.state.popupMessage;
                    let msg=''
                    if (this.ticketType==='gold' || this.ticketType==='silver' || this.ticketType==='bronze'){
                        msg=messages.m029_8+' '+prise.toLocaleString()+' '+messages.m029_9;
                    }
                    if (this.ticketType==='lottoBronze' || this.ticketType==='lottoSilver' || this.ticketType==='lottoGold'){
                        msg=messages.m029_10+' '+ticketTypeText+' '+messages.m029_11;
                    }
                    param.msg=msg;
                    param.title=messages.m029_5;
                    param.error=true;
                    param.visible=true;
                }
            },
            clickBuy() {
                const tb=this.$store.state.ticketsBuyCount;
                const state=this.$store.state;
                const getters=this.$store.getters;
                tb.ticketType=this.ticketType;
                if (this.ticketType==='bronze' && tb.bronze>0){
                    tb.buyVisible=true;
                    tb.buyCount=tb.bronze;
                    tb.buyPrise=state.blockspinState.bronzeRaffleGemPrice;
                }
                if (this.ticketType==='silver' && tb.silver>0){
                    tb.buyVisible=true;
                    tb.buyCount=tb.silver;
                    tb.buyPrise=state.blockspinState.silverRaffleGemPrice;
                }
                if (this.ticketType==='gold' && tb.gold>0){
                    tb.buyVisible=true;
                    tb.buyCount=tb.gold;
                    tb.buyPrise=state.blockspinState.goldRaffleGemPrice;
                }

                if (this.ticketType==='lottoBronze' && tb.lottoBronze>0){
                    tb.buyVisible=true;
                    tb.buyCount=tb.lottoBronze;
                    tb.buyPrise=0;
                }
                if (this.ticketType==='lottoSilver' && tb.lottoSilver>0){
                    tb.buyVisible=true;
                    tb.buyCount=tb.lottoSilver;
                    tb.buyPrise=0;
                }
                if (this.ticketType==='lottoGold' && tb.lottoGold>0){
                    tb.buyVisible=true;
                    tb.buyCount=tb.lottoGold;
                    tb.buyPrise=0;
                }
            },
        },

        props:{
            ticketType: {       //bronze, silver, gold, lottoBronze, lottoSilver, lottoGold
                type: String,
                default: null
            },
        }
    });
</script>

<style lang="css" scoped>

    .c-ticket_elem{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-ticket_elem_bg{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: visible;

        background: radial-gradient(52.63% 87.8% at 50.03% 4.04%, #0D4455 0%, #030F20 100%);
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-ticket_elem_col{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-ticket_elem_row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;

        background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-ticket_elem_img{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-ticket_elem_text{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;

        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        text-align: -webkit-center;
    }

</style>

<template>
    <div class="c-entries unselectable"
         :style="{'width': 900*ss*xMult+'px','height': 250*ss*xMult+'px',}"
    >
        <div class="c-entries_bg"
             :style="{'width': 900*ss*xMult+'px','height': 250*ss*xMult+'px','margin-top': 0*ss*xMult+'px',
            'border': 6*ss*xMult+'px solid #233C64','border-radius': 60*ss*xMult+'px','gap': 20*ss*xMult+'px',}"
        >
            <div class="c-entries_text c-entries_title"
                 :style="{'width': 700*ss*xMult+'px','height': 55*ss*xMult+'px','font-size': 55*ss*xMult+'px','line-height': 55*ss*xMult+'px',}">
                {{messages.m030_10}}
            </div>

            <div class="c-entries_row"
                 :style="{'width': 700*ss*xMult+'px','height': 130*ss*xMult+'px','gap': 10*ss*xMult+'px',}">
            >
                <div class="c-entries_col"
                     :style="{'width': 160*ss*xMult+'px','height': '100%','gap': 10*ss*xMult+'px',}">
                            <!--       You         -->

                    <div class="c-entries_ring_text c-entries_you"
                         :style="{'min-width': 30*ss*xMult+'px','max-width': 160*ss*xMult+'px',
                            'box-shadow': '0 0 '+30*ss*xMult+'px rgba(255, 229, 0, 0.60)',
                            'border-radius': 50*ss*xMult+'px','font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                            'padding': 10*ss*xMult+'px '+30*ss*xMult+'px',}"
                    >
                        {{getYou}}
                    </div>
                    <div class="c-entries_text" style="opacity: 0.6;"
                         :style="{'width': '100%','height': 30*ss*xMult+'px','font-size': 30*ss*xMult+'px','line-height': 30*ss*xMult+'px',}">
                        {{messages.m030_11}}
                    </div>
                </div>

                            <!--       Total         -->
                <div class="c-entries_col"
                     :style="{'width': 200*ss*xMult+'px','height': '100%','gap': 10*ss*xMult+'px',}">
                    <div class="c-entries_ring_text c-entries_total"
                         :style="{'min-width': 30*ss*xMult+'px','max-width': 160*ss*xMult+'px','border-radius': 50*ss*xMult+'px',
                            'box-shadow': '0 0 '+30*ss*xMult+'px rgba(25, 201, 243, 0.60)',
                            'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                            'padding': 10*ss*xMult+'px '+30*ss*xMult+'px',}"
                    >
                        {{getTotal}}
                    </div>
                    <div class="c-entries_text" style="opacity: 0.6;"
                         :style="{'width': '100%','height': 30*ss*xMult+'px','font-size': 30*ss*xMult+'px','line-height': 30*ss*xMult+'px',}">
                        {{messages.m030_12}}
                    </div>
                </div>

                        <!--       Odds         -->
                <div class="c-entries_col"
                     :style="{'width': 310*ss*xMult+'px','height': '100%','gap': 10*ss*xMult+'px',}">
                    <div class="c-entries_ring_text c-entries_odds"
                         :style="{'min-width': 30*ss*xMult+'px','max-width': 160*ss*xMult+'px','border-radius': 50*ss*xMult+'px',
                            'box-shadow': '0 0 '+30*ss*xMult+'px rgba(57, 231, 156, 0.60)',
                            'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                            'padding': 10*ss*xMult+'px '+30*ss*xMult+'px',}"
                    >
                        {{getOdds}}
                    </div>
                    <div class="c-entries_text" style="opacity: 0.6;"
                         :style="{'width': '100%','height': 30*ss*xMult+'px','font-size': 30*ss*xMult+'px','line-height': 30*ss*xMult+'px',}">
                        {{messages.m030_13}}
                    </div>
                </div>
            </div>


            <img :src="getImage" class="c-entries_img"
                 :style="{'width': 'auto','height': 210*ss*xMult+'px','bottom': 5*ss*xMult+'px','right': 5*ss*xMult+'px',}"
            />
        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import lottoImg from "@/assets/images/lotto.webp";

    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";


    export default defineComponent({
        name: 'Entries',
        components: {ButtonSvgOval,},
        computed: {
            ...mapGetters(["ss","xMult","blockspinState","prizes","messages"]),
            getImage(){
                let img='';
                img=Utils.getGlobalLink(lottoImg);
                return img;
            },

            getYou() {
                const state=this.$store.state;
                let txt='0';
                try{
                    txt=state.userData.numEntries[state.prizes.period];
                }
                catch (e) {}
                return txt;
            },

            getTotal() {
                const state=this.$store.state;
                let txt='0';
                try{
                    txt=state.blockspinState.totalNumEntries[state.prizes.period];
                }
                catch (e) {}
                return txt;
            },

            getOdds(){
                const state=this.$store.state;
                let txt='0%';
                try{
                    let tot=state.blockspinState.totalNumEntries[state.prizes.period];
                    let you=state.userData.numEntries[state.prizes.period];
                    txt=tot>0?(you / tot * 100).toFixed(2) + "%" : "0%";
                    if (you===tot && tot>0) txt='100%'
                }
                catch (e) {}
                return txt;
            },

        },
        data(){
            return {

            };
        },
        created() {

            //console.log(this.clickScale)
        },
        mounted() {
        },
        methods: {

        },

        props:{

        }
    });
</script>

<style lang="css" scoped>

    .c-entries{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-entries_bg{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;

        background: radial-gradient(52.63% 87.8% at 50.03% 4.04%, #0D4455 0%, #030F20 100%);
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-entries_col{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden;

        /*background: rgba(255, 255, 255, 0.3);/**/
    }

    .c-entries_row{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-entries_img{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .c-entries_text{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;

        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        overflow: visible;
        text-align: -webkit-center;
        white-space: nowrap;

    }

    .c-entries_title{
        background: linear-gradient(95.19deg,#0cff7c -16.14%,#00af90 137.96%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 0px 0px 15px rgba(57, 231, 156, 0.60);
    }

    .c-entries_you{
        background: linear-gradient(95deg, #FFC83C -9.98%, #DC8400 118.99%);
    }
    .c-entries_total{
        background: linear-gradient(94deg, #00C6FB 0.27%, #005BEA 105.74%);
    }
    .c-entries_odds{
        background: linear-gradient(95deg, #0CFF7C -16.14%, #00AF90 137.96%);
    }
    .c-entries_ring_text{
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.03em;
        color: #FFFFFF;

        display: flex;
        justify-content: center;
        align-items: center;
    }

</style>

<template>
    <div class="c-components_body unselectable" style="backdrop-filter: blur(5px);background: linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.6));">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <!--   Vertical   -->
            <div v-if="orient==='V'" class="c_notif_main_col"
                 :style="{'width': realWidth-60*ss*xMult+'px','height': realHeight-60*ss*xMult+'px','gap': 0*ss*xMult+'px','border-radius': 40*ss*xMult+'px',}"
            >
                <!--   Notifications text   -->
                <div class="c_notif_col c-notif_text" style="font-weight: 700;"
                     :style="{'width': '100%','height': 80*ss*xMult+'px',
                             'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px','margin-top': 40*ss*xMult+'px',}"
                >
                    {{messages.m039_01}}
                </div>

                <!--   Filter   -->
                <div class="c_notif_row" style="justify-content: space-between"
                     :style="{'width': '100%','height': 80*ss*xMult+'px',
                             'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px','margin-top': 40*ss*xMult+'px',}"
                >
                    <div class="c_notif_row" style="justify-content: flex-start"
                         :style="{'width': 'auto','height': 80*ss*xMult+'px',
                             'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px','gap': 30*ss*xMult+'px',}"
                    >
                        <!--   Filter text   -->
                        <div class="c_notif_col c-notif_text"
                             :style="{'width': 'auto','height': 80*ss*xMult+'px',
                                 'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-left': 40*ss*xMult+'px',}"
                        >
                            {{messages.m039_02}}
                        </div>
                        <!--   Filter dropbox   -->
                        <select class="c_notif_filter_select c-notif_text" :onchange="selectNotificationsType"
                             v-model="notificationsFilterThis"
                             :style="{'width': 'auto','height': 80*ss*xMult+'px','border-radius': 30*ss*xMult+'px',
                                'padding-left': 20*ss*xMult+'px','padding-right': 20*ss*xMult+'px',
                                'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                        >
                            <option class="c-notif_option_text">{{'All'}}</option>
                            <option class="c-notif_option_text">{{'Unread'}}</option>
                            <option class="c-notif_option_text">{{'Unclaimed'}}</option>
                        </select>
                    </div>

                    <!--   Read all text

                    -->
                    <div class="c_notif_col c-notif_text" @click="clickReadAll"
                         :style="{'width': 'auto','height': 80*ss*xMult+'px',
                                 'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-right': 40*ss*xMult+'px',}"
                    >
                        {{messages.m039_03}}
                    </div>
                </div>

                <!--   List of notifications static   -->
                <div class="c_notif_scroll_static"
                     :style="{'width': '100%','height': (realHeight-400*ss*xMult)*2/3+'px',
                             'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px','margin-top': 40*ss*xMult+'px',}"
                >
                    <div class="c_notif_scroll"
                         :style="{'width': '100%','height': 'auto','gap': 30*ss*xMult+'px','padding-bottom': 20*ss*xMult+'px',}"
                    >
                        <!--   Message titles   -->
                        <div v-for="(notification, index) in notificationsList"
                             :key="index"
                             @click="clickNotification(notification)"
                             class="c_notif_row" style="justify-content: space-between;border-bottom: 1px solid #19c9f3;"
                             :style="{'width': getItemWidth+'px','height': 'auto','margin-left': 40*ss*xMult+'px',
                                'padding-top': 40*ss*xMult+'px','padding-bottom': 40*ss*xMult+'px',}"
                        >
                            <div class="c_notif_row c-notif_text" style="justify-content: flex-start;"
                                 :style="{'width': getItemWidth-40*ss*xMult+'px','height': 'auto',
                                 'font-size': 45*ss*xMult+'px','line-height': 55*ss*xMult+'px',}"
                            >
                                {{notification.title}}
                            </div>
                            <div v-if="notification.isRead===false" class="c_notif_row" style="background: red"
                                 :style="{'width': 20*ss*xMult+'px','height': 20*ss*xMult+'px','border-radius': 20*ss*xMult+'px',}"
                            />
                        </div>

                    </div>
                </div>

                <!--   Yellow line   -->
                <div class="c_notif_row" style="justify-content: center;border-bottom: 2px solid #ffe500;"
                     :style="{'width': getItemWidth+10+'px','height': 4*ss*xMult+'px','margin-top': 20*ss*xMult+'px',}"
                />

                <!--   Selected notification expand   -->
                <div v-if="notificationSelected" class="c_notif_scroll_static"
                     :style="{'width': '100%','height': (realHeight-420*ss*xMult)/3+'px','margin-top': 30*ss*xMult+'px',}"
                >
                    <div class="c_notif_scroll"
                         :style="{'width': '100%','height': 'auto','gap': 20*ss*xMult+'px','padding-bottom': 20*ss*xMult+'px',}"
                    >
                        <div class="c_notif_row c-notif_title_text" style="justify-content: center;text-align: -webkit-center"
                             :style="{'width': getItemWidth+'px','height': 'auto','margin-top': 0*ss*xMult+'px','margin-left': 40*ss*xMult+'px',
                                     'font-size': 60*ss*xMult+'px','line-height': 70*ss*xMult+'px',}"
                        >
                            {{notificationSelected.title}}
                        </div>
                        <div class="c_notif_col" style="justify-content: flex-start;font-weight: 500;border-bottom: 1px solid #19c9f3;overflow-x: hidden;overflow-y: hidden;"
                             :style="{'width': getItemWidth+'px','height': 'auto',
                                'margin-left': 40*ss*xMult+'px','margin-top': 0*ss*xMult+'px',
                                'font-size': 45*ss*xMult+'px','line-height': 55*ss*xMult+'px','padding-bottom': 20*ss*xMult+'px'}"
                        >
                            <div class="c-notif_message_text" style="justify-content: flex-start;font-weight: 500;overflow: hidden;display: block;"
                                 :style="{'width': getItemWidth+'px','height': 'auto',
                                     'font-size': 45*ss*xMult+'px','line-height': 55*ss*xMult+'px','margin-top': 10*ss*xMult+'px','margin-bottom': 10*ss*xMult+'px'}"
                            >
                                {{notificationSelected.message}}
                            </div>
                        </div>

                        <!--   Notification selected timestamp with claim button if prize and not claimed   -->
                        <div class="c_notif_row c-notif_text" style="justify-content: space-between;font-weight: 500;"
                             :style="{'width': getItemWidth+'px','height': 'auto','margin-left': 40*ss*xMult+'px','margin-top': 20*ss*xMult+'px',
                                     'padding-bottom': 20*ss*xMult+'px'}"
                        >
                            <div class="c_notif_row c-notif_text" style="justify-content: flex-start;font-weight: 500;"
                                 :style="{'width': 'auto','height': 'auto','font-size': 36*ss*xMult+'px','line-height': 55*ss*xMult+'px',}"
                            >
                                {{getDateWithTime(notificationSelected.timestamp)}}
                            </div>
                            <ButtonSvgOval v-if="notificationSelected.prizeType && !notificationSelected.isClaimed"
                                           :callback="clickClaim" :callback-wait="true"
                                           :btn-text="getClaimText"
                                           :bg-width="520*ss*xMult" :bg-height="100*ss*xMult"
                                           :max-width="480*ss*xMult"
                                           :font-size="36*ss*xMult"
                                           :svg-width="50*ss*xMult" :svg-height="50*ss*xMult"
                                           :click-scale="0.95"
                                           :background-val="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                                           :border-val="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                            />
                        </div>
                    </div>
                </div>

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>

            <!--   Horizontal   -->
            <div v-if="orient==='H'" class="c_notif_main_col"
                 :style="{'width': realWidth-60*ss*xMult+'px','height': realHeight-60*ss*xMult+'px','gap': 0*ss*xMult+'px','border-radius': 40*ss*xMult+'px',}"
            >
                <!--   Filter  Notifications title -->
                <div class="c_notif_row_2"
                     :style="{'width': '100%','height': 80*ss*xMult+'px','margin-top': 40*ss*xMult+'px',}"
                >
                    <!--   Filter   -->
                    <div class="c_notif_row" style="justify-content: space-between"
                         :style="{'width': '47%','height': 80*ss*xMult+'px','left': 0*ss*xMult+'px',
                             'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                    >
                        <div class="c_notif_row" style="justify-content: flex-start"
                             :style="{'width': 'auto','height': 80*ss*xMult+'px',
                             'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px','gap': 30*ss*xMult+'px',}"
                        >
                            <!--   Filter text   -->
                            <div class="c_notif_col c-notif_text"
                                 :style="{'width': 'auto','height': 80*ss*xMult+'px',
                                 'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-left': 40*ss*xMult+'px',}"
                            >
                                {{messages.m039_02}}
                            </div>
                            <!--   Filter dropbox   -->
                            <select class="c_notif_filter_select c-notif_text"
                                    v-model="notificationsFilterThis" :onchange="selectNotificationsType"
                                    :style="{'width': 'auto','height': 80*ss*xMult+'px','border-radius': 30*ss*xMult+'px',
                                'padding-left': 20*ss*xMult+'px','padding-right': 20*ss*xMult+'px',
                                'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                            >
                                <option class="c-notif_option_text">{{'All'}}</option>
                                <option class="c-notif_option_text">{{'Unread'}}</option>
                                <option class="c-notif_option_text">{{'Unclaimed'}}</option>
                            </select>
                        </div>

                        <!--   Read all text

                        -->
                        <div class="c_notif_col c-notif_text" @click="clickReadAll"
                             :style="{'width': 'auto','height': 80*ss*xMult+'px',
                                 'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-right': 40*ss*xMult+'px',}"
                        >
                            {{messages.m039_03}}
                        </div>
                    </div>
                    <!--   Notifications text   -->
                    <div class="c_notif_col c-notif_text"
                         :style="{'width': '50%','height': 80*ss*xMult+'px',
                                 'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                    >
                        {{messages.m039_01}}
                    </div>
                </div>

                <!--   MAIN Horizontal row   -->
                <div class="c_notif_row_2"
                     :style="{'width': '100%','height': realHeight-210*ss*xMult+'px','margin-top': 20*ss*xMult+'px',}"
                >
                    <!--   List of notifications static   -->
                    <div class="c_notif_scroll_static"
                         :style="{'width': '49%','height': realHeight-210*ss*xMult+'px',
                                 'font-size': 70*ss*xMult+'px','line-height': 80*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
                    >
                        <div class="c_notif_scroll"
                             :style="{'width': '100%','height': 'auto','gap': 30*ss*xMult+'px','padding-bottom': 20*ss*xMult+'px',}"
                        >
                            <!--   Message titles   -->
                            <div v-for="(notification, index) in notificationsList"
                                 :key="index"
                                 @click="clickNotification(notification)"
                                 class="c_notif_row" style="justify-content: space-between;border-bottom: 1px solid #19c9f3;"
                                 :style="{'width': getItemWidth+'px','height': 'auto','margin-left': 40*ss*xMult+'px',
                                    'padding-top': 40*ss*xMult+'px','padding-bottom': 40*ss*xMult+'px',}"
                            >
                                <div class="c_notif_row c-notif_text" style="justify-content: flex-start;"
                                     :style="{'width': getItemWidth-40*ss*xMult+'px','height': 'auto',
                                     'font-size': 45*ss*xMult+'px','line-height': 55*ss*xMult+'px',}"
                                >
                                    {{notification.title}}
                                </div>
                                <!--   red ring   -->
                                <div v-if="notification.isRead===false" class="c_notif_row" style="background: red"
                                     :style="{'width': 20*ss*xMult+'px','height': 20*ss*xMult+'px','border-radius': 20*ss*xMult+'px',}"
                                />
                            </div>

                        </div>
                    </div>

                    <!--   Yellow line   -->
                    <div class="c_notif_row" style="justify-content: center; border-left: 2px solid #ffe500;"
                         :style="{'width': 4*ss*xMult+'px','height': realHeight-230*ss*xMult+'px','margin-top': 10*ss*xMult+'px',}"
                    />

                    <!--   Selected notification expand   -->
                    <div class="c_notif_scroll_static"
                         :style="{'width': '49%','height': realHeight-210*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
                    >
                        <div v-if="notificationSelected" class="c_notif_scroll"
                             :style="{'width': '100%','height': 'auto','gap': 20*ss*xMult+'px','padding-top': 30*ss*xMult+'px','padding-bottom': 20*ss*xMult+'px',}"
                        >
                            <div class="c_notif_row c-notif_title_text" style="justify-content: center;text-align: -webkit-center"
                                 :style="{'width': getItemWidth+'px','height': 'auto','margin-top': 0*ss*xMult+'px','margin-left': 40*ss*xMult+'px',
                                     'font-size': 60*ss*xMult+'px','line-height': 70*ss*xMult+'px',}"
                            >
                                {{notificationSelected.title}}
                            </div>
                            <div class="c_notif_row c-notif_text" style="justify-content: flex-start;font-weight: 500;border-bottom: 1px solid #19c9f3;"
                                 :style="{'width': getItemWidth+'px','height': 'auto','margin-left': 40*ss*xMult+'px','margin-top': 30*ss*xMult+'px',
                                     'font-size': 45*ss*xMult+'px','line-height': 55*ss*xMult+'px','padding-bottom': 20*ss*xMult+'px'}"
                            >
                                {{notificationSelected.message}}
                            </div>

                            <!--   Notification selected timestamp with claim button if prize and not claimed   -->
                            <div class="c_notif_row c-notif_text" style="justify-content: space-between;font-weight: 500;"
                                 :style="{'width': getItemWidth+'px','height': 'auto','margin-left': 40*ss*xMult+'px','margin-top': 20*ss*xMult+'px',
                                     'padding-bottom': 20*ss*xMult+'px'}"
                            >
                                <div class="c_notif_row c-notif_text" style="justify-content: flex-start;font-weight: 500;"
                                     :style="{'width': 'auto','height': 'auto','font-size': 40*ss*xMult+'px','line-height': 55*ss*xMult+'px',}"
                                >
                                    {{getDateWithTime(notificationSelected.timestamp)}}
                                </div>
                                <ButtonSvgOval v-if="notificationSelected.prizeType && !notificationSelected.isClaimed"
                                               :callback="clickClaim" :callback-wait="true"
                                               :btn-text="getClaimText"
                                               :bg-width="550*ss*xMult" :bg-height="100*ss*xMult"
                                               :max-width="510*ss*xMult"
                                               :font-size="40*ss*xMult"
                                               :svg-width="50*ss*xMult" :svg-height="50*ss*xMult"
                                               :click-scale="0.95"
                                               :background-val="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                                               :border-val="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";

    import btn_xSvg from "@/assets/svg/btn_x.svg";

    export default defineComponent({
        name: 'Notifications',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","messages","orient","notificationsList","notificationsFilter"]),
            getItemWidth(){
                const state=this.$store.state;
                const getters=this.$store.getters;
                let w=0;
                if (state.orient==='V'){
                    w=state.realWidth-130*state.ss*getters.xMult-10;
                }
                if (state.orient==='H'){
                    w=(state.realWidth-220*state.ss*getters.xMult)/2-10;
                }
                return w;
            },
            getClaimText(){
                const state=this.$store.state;
                const messages=state.messages;
                let msg=messages.m030_14+' '+Utils.getClaimText(this.notificationSelected);
                return msg;
            },
        },
        components: {ButtonSvg,ButtonSvgOval,},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),
                notificationsFilterThis:'Unread',
                notificationSelected:null,
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
            this.notificationsFilterThis=this.$store.state.notificationsFilter;
        },
        methods: {
            btn_xClick(){
                this.$store.state.popupVisible.notifications=false;
                this.$store.dispatch("getUser");
            },
            async clickClaim(){
                const state = this.$store.state;
                try {
                    await this.$store.dispatch("claimNotification", this.notificationSelected);
                }
                catch (e) {
                    state.showMessagePopup('store.buyFinish catch. Message: '+e.message+'\nstack: '+e.stack,state.messages.m032_08,true);
                }
            },
            clickReadAll(){
                this.$store.dispatch("openNotificationAll");
            },
            clickNotification(notification){
                this.notificationSelected=notification;
                this.$store.dispatch("openNotification", notification._id);
                //this.notificationSelected.message="You successfully purchased 1 insurance1Day boost(s)! Your transaction over the XTR network has the following transaction ID: stxzkjJOetEFgWfYpGgbkrfbw4eN2iYd7yX0xaf86UOdD5ei3B_RQx6zQQob6azgBq5R6WAxclWf93U4Ec-c1FANkGOzqdSFIx6fjVQ_ticoySEjIwIQHsKleKZjbjnPqBL";
            },
            getDateWithTime(timestamp){
                return this.$store.state.getDateWithTime(timestamp);
            },
            selectNotificationsType(){
                this.$store.state.notificationsFilter=this.notificationsFilterThis;
            },
        },

    });
</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c_notif_main_col{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;

        background: radial-gradient(100.84% 132.88% at -11.6% -9.79%,#ca1d91 0,#6d14ce 100%)
    }

    .c-notif_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        overflow: hidden;
        color: #FFFFFF;

        /*word-wrap: break-word;/**/
        /*white-space: nowrap;        /*Нет переноса не переносит*/
    }

    .c-notif_title_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        background: linear-gradient(95.19deg,#0cff7c -16.14%,#00af90 137.96%);
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
    }

    .c-notif_message_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        overflow: hidden;
        color: #FFFFFF;

        word-wrap: break-word;/**/
        white-space: pre-wrap;        /*Нет переноса не переносит*/
    }

    .c_notif_col{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;
    }

    .c_notif_scroll_static{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
    }

    .c_notif_scroll{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-x: hidden;
        overflow-y: auto;

    }

    .c_notif_row{
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: visible;
    }

    .c_notif_row_2{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        overflow: visible;
    }

    .c_notif_filter_select {
        background-color: #1e90ff30;
        color: white;
        font-family: "Rubik", sans-serif;
    }

    .c-notif_option_text{
        display: flex;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        color: #000000;
        background: white;

        /*word-wrap: break-word;/**/
        /*white-space: nowrap;        /*Нет переноса не переносит*/
    }

    .c_notif_filter_select:hover {
        background-color: rgba(30, 144, 255, 0.19);
    }


    /*.c_notif_filter_select.decorated option:hover {
        box-shadow: 0 0 10px 100px #ad1972 inset;
    }*/


</style>

<template>
    <!--
            How to win chips info
    -->
    <div class="c-learn_nft unselectable"
         :style="{'top': 0*ss*xMult+'px','left':0*ss*xMult+'px',}">
        <div class="c-learn_nft_bg"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <div class="c-learn_nft_bg_rel"
                 :style="{'width': realWidth-80*ss*xMult+'px','height':'auto','max-height': realHeight-80*ss*xMult+'px','border-radius': 40*ss*xMult+'px',
                    'padding-top': 40*ss*xMult+'px','padding-bottom': 40*ss*xMult+'px','gap': 40*ss*xMult+'px',}">

                <div class="c-learn_nft_ccc c-learn_nft_text"
                     :style="{'width': '100%','height': 80*ss*xMult+'px','margin-left': 40*ss*xMult+'px','margin-top': 0*ss*xMult+'px',
                        'line-height': 80*ss*xMult+'px','font-size': 60*ss*xMult+'px',}">
                    {{messages.m049_01}}
                </div>

                <!--scroll static-->
                <div class="c-learn_nft_ccc"
                     :style="{'width': realWidth-80*ss*xMult+'px','height':'auto','max-height': realHeight-280*ss*xMult+'px',}">
                    <!--scroll dinamic-->
                    <div class="c-learn_nft_scroll"
                         :style="{'width': realWidth-80*ss*xMult+'px','height':'auto','gap': 30*ss*xMult+'px',
                        'padding-top': 10*ss*xMult+'px','padding-bottom': 10*ss*xMult+'px',}">
                        <LearnElement
                                :img1="img_1"
                                :img2="img_12"
                                :text1="messages.m049_021"
                                :text2="messages.m049_022"
                        />
                        <LearnElement
                                :img1="img_2"
                                :img2="img_22"
                                :text1="messages.m049_031"
                                :text2="messages.m049_032"
                                :callback="clickConvert"
                        />
                        <LearnElement
                                :img1="img_3"
                                :img2="img_32"
                                :text1="messages.m049_041"
                                :text2="messages.m049_042"
                                :callback="clickConvert"
                        />
                    </div>
                </div>

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import Utils from "@/scripts/Utils";
    import btn_xSvg from "@/assets/svg/btn_x";
    import LearnElement from "@/vue/pages/components/LearnElement";

    import img_1 from "@/assets/svg/info/gems_green.svg";
    import img_2 from "@/assets/svg/info/convert.svg";
    import img_3 from "@/assets/svg/info/burn.svg";
    import img_12 from "@/assets/images/info/gems.webp";
    import img_22 from "@/assets/images/info/tickets.webp";
    import img_32 from "@/assets/images/info/lotto.webp";

    //export default defineComponent({              //Not working debugger in chrome
    export default {
        name: "LearnNft",
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","getLeft","xMult","orient","messages","popupVisible",]),
            btnRad(){
                return 135*this.$store.state.ss*this.$store.getters.xMult
            },

        },
        components: {ButtonSvg, LearnElement},
        created() {


        },
        data: function () {
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),
                img_1:Utils.getGlobalLink(img_1),img_2:Utils.getGlobalLink(img_2),img_3:Utils.getGlobalLink(img_3),
                img_12:Utils.getGlobalLink(img_12),img_22:Utils.getGlobalLink(img_22),img_32:Utils.getGlobalLink(img_32),
            }
        },
        methods:{
            btn_xClick(){
                this.$store.state.popupVisible.learnNft=false;
            },
            clickConvert(){
                if (this.$store.state.loginData)
                    this.$store.state.popupVisible.tickets=true;
            },

        },
    }
    //);

</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-learn_nft{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: absolute;
        justify-content: center;
        align-items: center;

        /*transform: translate(50%, -50%);/**/
        padding:0;
        margin:0;
        background: rgba(0, 0, 0, 0.67);/**/

        width: 100vw;
        height: 100vh;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;

    }
    .c-learn_nft_bg{
        display: flex;
        overflow: hidden;
        position: absolute;
        transform: translate(0%, 0%);
        padding:0;
        margin:0;

        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-learn_nft_bg_rel{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        background: linear-gradient(94deg, #00C6FB 0.27%, #005BEA 105.74%);
        /*background: radial-gradient(233.23% 93.25% at 92.1% -14.57%, #00C6FB 0%, #005BEA 100%), #FFF;*/
        box-shadow: 0px 0px 60px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 70px 0px rgba(0, 91, 234, 0.70);
    }

    .c-learn_nft_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: visible;
        color: #FFFFFF;
        text-align: -webkit-center;
    }

    .c-learn_nft_ccc{
        display: flex;
        overflow: hidden;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-learn_nft_scroll{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;
    }



</style>

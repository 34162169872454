<template>
    <div class="c-recent_bets_bg unselectable"
         :style="{'width': realWidth-40*ss*xMult+'px','height': getHeight+'px',}">


        <div class="c-recent_bets"
             :style="{'width': realWidth+'px','height': getHeight+'px',
                'gap': 5*ss*xMult+'px','padding-left':30*ss*xMult+'px','padding-right':30*ss*xMult+'px'}"

        >
            <!--  Recent bets  -->
            <div class="c-recent_bets_col" style="align-items: flex-start;position: relative;"
                 :style="{'width': 'auto','height': 100*ss*xMult+'px','margin-top':30*ss*xMult+'px',}"
            >
                <div class="c-recent_bets_col c-recent_bets_head_text"
                     :style="{'width': 'auto','height': 80*ss*xMult+'px', 'line-height': 80*ss*xMult+'px', 'font-size':70*ss*xMult+'px',}"
                >
                    {{messages.m044_01}}
                </div>
            </div>

            <!--  Period All (all) / Biggest Wins (big) / My Bets (mine)   -->
            <div class="c-recent_bets_head"
                 :style="{'width': (realWidth-80*ss*xMult)+'px','height': 100*ss*xMult+'px', 'gap': 10*ss*xMult+'px','margin-top': 10*ss*xMult+'px',}"
            >
                <div class="c-recent_bets_head_btns unselectable"
                     :style="{'width': (1000*ss*xMult).toString()+'px','height': 100*ss*xMult+'px', 'border-radius': 60*ss*xMult+'px',}"
                >
                    <div class="c-recent_bets_head_btn"
                         :style="{'gap': 10*ss*xMult+'px','width': 97*ss*xMult+'px','height': 100*ss*xMult+'px', 'border-radius': 60*ss*xMult+'px',
                            'padding': 0*ss*xMult+'px '+15*ss*xMult+'px',
                            'background': recentBets.betsActive==='all'?btnBg:'none',
                            'box-shadow': recentBets.betsActive==='all'?'0 0 20px rgba(253,134,81,.8)':'none'}"
                         @click="onClickBtn('all')"
                    >
                        <span class="c-recent_bets_head_btn_txt"
                              :style="{'width': 'auto','height': 60*ss*xMult+'px', 'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                        >
                            {{messages.m044_05}}
                        </span>
                    </div>
                    <div class="c-recent_bets_head_btn"
                         :style="{'gap': 10*ss*xMult+'px','width': 97*ss*xMult+'px','height': 100*ss*xMult+'px', 'border-radius': 60*ss*xMult+'px',
                            'padding': 0*ss*xMult+'px '+15*ss*xMult+'px',
                            'background': recentBets.betsActive==='big'?btnBg:'none',
                            'box-shadow': recentBets.betsActive==='big'?'0 0 20px rgba(253,134,81,.8)':'none'}"
                         @click="onClickBtn('big')"
                    >
                        <span class="c-recent_bets_head_btn_txt"
                              :style="{'width': 'auto','height': 60*ss*xMult+'px', 'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                        >
                            {{messages.m044_06}}
                        </span>
                    </div>
                    <div class="c-recent_bets_head_btn"
                         :style="{'gap': 10*ss*xMult+'px','width': 97*ss*xMult+'px','height': 100*ss*xMult+'px', 'border-radius': 60*ss*xMult+'px',
                            'padding': 0*ss*xMult+'px '+15*ss*xMult+'px',
                            'background': recentBets.betsActive==='mine'?btnBg:'none',
                            'box-shadow': recentBets.betsActive==='mine'?'0 0 20px rgba(253,134,81,.8)':'none'}"
                         @click="onClickBtn('mine')"
                    >
                        <span class="c-recent_bets_head_btn_txt"
                              :style="{'width': 'auto','height': 60*ss*xMult+'px', 'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                        >
                            {{messages.m044_07}}
                        </span>
                    </div>
                </div>
            </div>

            <!--  Table with bets list  -->
            <div class="c-recent_bets_table"
                 :style="{'width': (realWidth-80*ss*xMult).toString()+'px','height': realHeight-(orient==='V'?610:470)*ss*xMult+'px',
                  'gap': 5*ss*xMult+'px','margin-top':20*ss*xMult+'px',}"
            >
                <!--   USER/GAMES/CHIPS     -->
                <div class="c-recent_bets_table_head"
                     :style="{'gap': 0*ss*xMult+'px','width': realWidth-80*ss*xMult+'px','height': 80*ss*xMult+'px',}"
                >
                    <span class="c-recent_bets_table_head_text" style="order: 0;"
                          :style="{'width': '43%','height': 80*ss*xMult+'px',
                        'font-size': 45*ss*xMult+'px','line-height': 80*ss*xMult+'px','margin-left': 0*ss*xMult+'px'}"
                    >{{messages.m044_02}}</span>
                    <span class="c-recent_bets_table_head_text" style="order: 1; text-align: -webkit-center;"
                          :style="{'width': '34%','height': 80*ss*xMult+'px',
                        'font-size': 45*ss*xMult+'px','line-height': 80*ss*xMult+'px','margin-left': 0*ss*xMult+'px'}"
                    >{{messages.m044_03}}</span>
                    <span class="c-recent_bets_table_head_text" style="order: 2; text-align: -webkit-center;"
                          :style="{'width': '20%','height': 80*ss*xMult+'px',
                        'font-size': 45*ss*xMult+'px','line-height': 80*ss*xMult+'px','margin-right': 0*ss*xMult+'px'}"
                    >{{messages.m044_04}}</span>
                </div>

                <!-- scroll static with players bets-->
                <div class="c-recent_bets_player"
                     :style="{'gap': 5*ss*xMult+'px','width': realWidth-80*ss*xMult+'px','height': getScrollHeight+'px',
                        'max-height': getScrollHeight+'px',}"
                >

                    <!--   scroll content dynamic height PRIZES
                    item.timestamp
                    betsListCurrent.length-1-index

                         :css="true"
                         @appear="appear"

                         @before-enter="beforeEnter"
                         @enter="enter"
                         @after-enter="afterEnter"

                        .recent_bets_trans-move,
                        .recent_bets_trans-enter-active,
                        .recent_bets_trans-leave-active
                        {
                            /*transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);*/
                            /*transition: transform 1s, left 0s, top 0s, width 0s, height 0s, max-height 0s;*/

                            /*transition: opacity 1s, transform 1s;       РАБОТАЕТ*/
                            transition: transform 1s;/**/
                        }
                     -->
                    <div class="c-recent_bets_scroll"
                                      :style="{'width': realWidth-80*ss*xMult+'px','height': 'auto','gap': 20*ss*xMult+'px',
                         'padding-top': 0*ss*xMult+'px','padding-bottom': 0*ss*xMult+'px',}"
                    >
                        <transition-group name="recent_bets_trans" class="c-recent_bets_scroll" tag="ppp" style="overflow: visible;"
                             :style="{'width': realWidth-80*ss*xMult-10+'px','height': 'auto','gap': 20*ss*xMult+'px',
                             'margin-top': -130*ss*xMult+'px','padding-bottom': 0*ss*xMult+'px',}"
                        >
                            <RecentBetPlayer v-if="recentBets.betsActive==='big' && betsListCurrent.length>0"
                                    :item="betsListCurrent[0]"
                                    :game-type="gameType==='spincity'?'allGames':gameType"
                                    :prize-pool-obj="betObj"
                                    :user-name-color="'#19c9f3'"
                            />
                            <div v-for="(item,index) in betsListCurrent"
                                 :key="JSON.stringify(item)"
                                 class="recent_bets_trans-item"
                            >
                                <RecentBetPlayer
                                        :item="item"
                                        :game-type="gameType==='spincity'?'allGames':gameType"
                                        :prize-pool-obj="betObj"
                                        :user-name-color="'#19c9f3'"
                                />
                            </div>

                        </transition-group>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { defineComponent } from 'vue';
    import RecentBetPlayer from "@/vue/pages/components/RecentBetPlayer";
    import Utils from "@/scripts/Utils";
    import Config from "@/scripts/Config";

    export default defineComponent({
        //export default {
        name: "RecentBets",
        components: {RecentBetPlayer},
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight", "messages", "orient", "xMult", "recentBets","betsListCurrent","gameType","betsAddDeltaTime"]),
            betObj() {
                return this.betObjThis();
            },
            btnBg() {
                return 'linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%)';
            },
            getHeight() {
                const state = this.$store.state;
                let h = state.realHeight - (state.orient === 'V' ? 300 : 180) * state.ss * this.$store.getters.xMult;
                return h;
            },
            getScrollHeight() {
                const state = this.$store.state;
                let h = state.realHeight - (state.orient === 'V' ? 700 : 560) * state.ss * this.$store.getters.xMult;
                return h;
            },


        },
        data: function () {
            return {}
        },
        created() {

        },
        mounted() {

        },
        async beforeMount() {
            await Utils.getBets(this.$store.getters.gameTypeLeaderboard,this.$store.state.recentBets.betsActive);
        },
        beforeUnmount() {
            Utils.stopBets();
        },
        methods: {
            async onClickBtn(btn) {       //'all', 'big', 'mine' recent bets displayed
                const state = this.$store.state;
                if (state.recentBets.betsActive === btn)
                    return;
                Utils.animationStopper();

                state.recentBets.betsActive = btn;
                await Utils.getBets(this.$store.getters.gameTypeLeaderboard,this.$store.state.recentBets.betsActive);
            },


            betObjThis() {
                const state = this.$store.state;
                const s = this.$store.getters.gameTypeLeaderboard;            //slot/scratch/crash/instant
                const obj = state.bets[s][state.recentBets.betsActive];    //current/previous
                return obj;
            },

            beforeEnter(el) {
                //el.style.opacity = 0
                //el.style.height = 0
                /*
                const state = this.$store.state;
                try{
                    if(state.betsListCurrent.length>Config.betsMaxCount) {
                        state.betsListCurrent.splice(Config.betsMaxCount, state.betsListCurrent.length - Config.betsMaxCount);
                    }
                    MyLog('state.betsListCurrent.length=' + state.betsListCurrent.length);
                }
                catch(e){}
                //*/
            },
            afterEnter(el) {
                //el.style.opacity = 0
                //el.style.height = 0
                //el.style.position='';

            },
            enter(el, done) {
                el.style.top='-200px';
                //el.style.position='absolute';
                let obj={top:-200};

                TweenMax.to(obj, {
                    top: 0,
                    delay: 1,
                    //onComplete: done,
                    onUpdate:()=>{
                        el.style.top=obj.top+'px';
                    },
                    onComplete:()=>{
                        done();
                    },
                })//*/

            },

        },
    });

</script>

<style lang="css" scoped>

    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-recent_bets_bg{
        display: flex;
        overflow: -moz-scrollbars-none;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding:0;
        margin:0;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-recent_bets{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: radial-gradient(178.9% 78.61% at 47.02% 0,#0d4455 0,#030f20 100%);
    }

    .c-recent_bets_col{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-recent_bets_row{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .c-recent_bets_head{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }
    .c-recent_bets_head_2{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.4);/**/
    }
    .c-recent_bets_head_text{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.03em;
        color: #FFFFFF;
    }
    .c-recent_bets_head_chips{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: bold;

        letter-spacing: -0.03em;
        text-transform: uppercase;
        /*background: linear-gradient(281.23deg, #00B2FF 37.33%, #80D9FF 68.64%);*/
        background: linear-gradient(281.23deg, rgba(150, 242, 174, 1) 37.33%, rgba(255, 255, 255, 1) 68.64%);

        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        /*background-clip: text;*/
        text-fill-color: transparent;
        flex: none;
        order: 1;
        flex-grow: 0;
    }
    .c-recent_bets_head_btns{
        display: flex;
        flex-direction: row;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);
    }
    .c-recent_bets_head_btn{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex: none;
        order: 0;
        flex-grow: 1;
    }
    .c-recent_bets_head_btn_txt{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 600;
        text-align: center;
        letter-spacing: -0.03em;
        flex: none;
        order: 0;
        flex-grow: 0;
        color: white;
    }

    .c-recent_bets_table_head{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex: none;
        order: 0;
        flex-grow: 0;

        /*background: rgba(0, 0, 0, 0.4);/**/
    }

    .c-recent_bets_table{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0px;
        flex: none;
        order: 1;
        flex-grow: 0;

        /*background: rgba(255, 255, 255, 0.2);/**/
    }

    .c-recent_bets_table_head_text{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        color: rgba(255, 255, 255, 0.7);
        flex: none;
        flex-grow: 0;
    }

    .c-recent_bets_player{               /* Тело  scroll*/
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: none;
        overflow: hidden;
        font-family: 'Rubik';

        /*background: rgba(0, 0, 0, 0.2);/**/
    }


    .c-recent_bets_scroll{
        display: block;
        /*flex-wrap: wrap;/**/
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .recent_bets_trans-item{
        display: flex;
        position: relative;
        width: 99%;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;
    }

    /*.recent_bets_trans-leave-active/**/
    .recent_bets_trans-move,
    .recent_bets_trans-enter-active
    {
        /*transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);*/
        /*transition: transform 1s, left 0s, top 0s, width 0s, height 0s, max-height 0s;*/

        /*transition: opacity 1s, transform 1s;       РАБОТАЕТ*/
        transition: transform 0.8s;/**/
    }

    /* 2. declare enter from and leave to state */
    .recent_bets_trans-enter-from {
        /*opacity: 0;/**/
        /*transform: scaleY(0.01) translate(30px, 0);/**/
        transform: translate(0px, -200px);/**/
    }

    .recent_bets_trans-leave-to {
        /*opacity: 0;*/
        /*transform: scaleY(0.01) translate(30px, 0);/**/
        /*transform: translate(0, 200px);/**/
    }

    /* 3. ensure leaving items are taken out of layout flow so that moving
          animations can be calculated correctly. */
    .recent_bets_trans-leave-active {
        /*position: absolute;/**/
    }
</style>

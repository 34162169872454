<template>
    <!--   telegram mode    -->
    <!--   show Player level INFO   -->
    <div class="c-telegram_info2"
         :style="{'width': 1000*ss*xMult+'px','height': 500*ss*xMult+'px','border-radius': 40*ss*xMult+'px','gap': 45*ss*xMult+'px',
            'box-shadow':'0 0 '+20*ss*xMult+'px rgba(88,101,242,.5)',}"
    >
        <!--      Progressbar      -->
        <div style="position: absolute;display: flex;"
             :style="{'width': 380*ss*xMult+'px','height': 380*ss*xMult+'px','left': 40*ss*xMult+'px','top': 60*ss*xMult+'px',}">
            <div style="position: relative;display: flex;flex-direction: column;justify-content: center;align-items: center"
                 :style="{'width': '100%','height': '100%',}">
                <svg class="c-header-profile-user-progressbar"
                     :style="{'width': 165*ss*xMult+'px','height': 165*ss*xMult+'px','left': 83*ss*xMult+'px','top': 84*ss*xMult+'px',}"
                >
                    <circle class="c-header-profile-user-pfp-bg"
                            :style="{'stroke-width': 5*cSc,'stroke-dasharray': 360*cSc,'stroke-dashoffset': 60*cSc,}"
                            cx="0"
                            cy="0"
                            :r="62*cSc"
                    />
                    <circle class="c-header-profile-user-pfp-progress"
                            :style="{'stroke-width': 5*cSc,'stroke-dasharray': 450*cSc,
                                'stroke-dashoffset': circVal*cSc,'--offset': circVal*cSc,}"
                            cx="0"
                            cy="0"
                            :r="62*cSc"
                            stroke="url(#linear)"
                    />
                    <defs>
                        <linearGradient
                                id="linear"
                                x1="0"
                                y1="0.49"
                                x2="1"
                                y2="0.51"
                        >
                            <stop offset="0%" stop-color="#0cff7c" />
                            <stop offset="100%" stop-color="#00af90" />
                        </linearGradient>
                    </defs>
                </svg>


                <img class="c-telegram_info2_avatar" :src="getAvatar" style="height: 97px" alt=""
                     :style="{'width': 305*ss*xMult+'px','height': 305*ss*xMult+'px',
                        'border-radius': 200*ss*xMult+'px',}"
                />
            </div>
        </div>

        <div class="c-telegram_info2_level"
             :style="{'width': 300*ss*xMult+'px','left': 85*ss*xMult+'px','top': 415*ss*xMult+'px',
                'font-size': 34*ss*xMult+'px','line-height': 40*ss*xMult+'px',}"
        >
            <div class="c-telegram_info2_level_text1"> {{getExperience}} </div>
            <div class="c-telegram_info2_level_text2"> {{'/'+getExperienceNext}} </div>
        </div>

        <!--      User name, level, etc      -->
        <div class="c-telegram_info_3" style="position: absolute; overflow: hidden"
             :style="{'width': 530*ss*xMult+'px','height': 500*ss*xMult+'px','right': 0*ss*xMult+'px','top': 0*ss*xMult+'px','gap': 5*ss*xMult+'px',}"
        >
            <div class="c-telegram_info_2"
                 :style="{'width': 530*ss*xMult+'px','height': 100*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
            >
                <img class="c-user_account_img1" alt=""
                     :src="imgTg"
                     :style="{'width': 70*ss*xMult+'px','height': 70*ss*xMult+'px',}"
                />
                <div class="c-telegram_info2_level_text2"
                     :style="{'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                > {{userData.username}}
                </div>
            </div>

            <div class="c-telegram_info_2"
                 :style="{'width': 530*ss*xMult+'px','height': 100*ss*xMult+'px',}"
            >
                <div class="c-telegram_info2_level_text3"
                     :style="{'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px','text-shadow': '0 0 '+10*ss*xMult+'px rgba(255, 255, 255, 0.6)',}"
                > {{'🤟 '+getTitle+' 🤟'}}
                </div>
            </div>
            <div class="c-telegram_info_2"
                 :style="{'width': 530*ss*xMult+'px','height': 100*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
            >
                <div class="c-header-profile-user-info-line"
                >
                    <div class="c-header-profile-user-info-value"
                         :style="{'min-width': 30*ss*xMult+'px','box-shadow': '0 0 '+5*ss*xMult+'px #39E79C','border-radius': 50*ss*xMult+'px',
                            'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                            'padding': 15*ss*xMult+'px '+30*ss*xMult+'px',}"
                    >
                        {{getLevel}}
                    </div>
                </div>
                <div class="c-telegram_info2_level_text2"
                     :style="{'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                > {{messages.m020}}
                </div>
            </div>

            <div class="c-telegram_info_2"
                 :style="{'width': 530*ss*xMult+'px','height': 100*ss*xMult+'px','gap': 15*ss*xMult+'px',}"
            >
                <div class="c-header-profile-user-info-line"
                >
                    <div class="c-header-profile-user-info-value_2"
                         :style="{'min-width': 30*ss*xMult+'px','box-shadow': '0 0 '+5*ss*xMult+'px #e7e439','border-radius': 50*ss*xMult+'px',
                            'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',
                            'padding': 15*ss*xMult+'px '+30*ss*xMult+'px',}"
                    >
                        {{userData.dailyStreak}}
                    </div>
                </div>
                <div class="c-telegram_info2_level_text2"
                     :style="{'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                > {{messages.m021}}
                </div>

                <ButtonSvgOval :svg-normal="infoSvg"
                               :bg-width="85*ss*xMult" :bg-height="80*ss*xMult"
                               :max-width="50*ss*xMult"
                               :svg-width="40*ss*xMult" :svg-height="40*ss*xMult"
                               :background-val="'hsla(0,0%,100%,0.2)'"
                               :border-val="'none'"
                               :callback="btnInfoClick" :callback-wait="true"
                               :click-scale="0.9"
                />
            </div>
        </div>

        <div v-if="popupQuickHelp.dailyStreak===true" class="c-telegram_info2_popup"
             :style="{'top': 200*ss*xMult+'px','left': 450*ss*xMult+'px',}"
        >
            <PopupInfo     :bg-width="450*ss*xMult" :bg-height="150*ss*xMult"
                           :max-width="50*ss*xMult"
                           :border-radius="30*ss*xMult"
                           :font-size="35*ss*xMult"
                           :btn-text="messages.daily_streak_popup"
                           :background-val="'rgba(255, 255, 255, 0.1)'"
                           :border-val="'1px solid rgba(255, 255, 255, 0.5)'"
                           :callback="popupInfoClick" :callback-wait="true"

            />
        </div>

    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import PopupInfo from "@/vue/elements/PopupInfo";
    import imgTg from '@/assets/images/icon_telegram.webp';
    import discord_text from '@/assets/svg/discord_text.svg';
    import infoSvg from '@/assets/svg/icon_info.svg';
    import Utils from "@/scripts/Utils";
    import imgTgAvatar from '@/assets/images/tg_avatar.webp';

    export default defineComponent({
        name: 'TelegramInfo',
        components: {ButtonSvgOval, PopupInfo,},
        computed: {
            ...mapGetters(["ss","xMult","btnRad","messages","userData","getExperience","getExperienceNext","getTitle","getLevel","userData",
                "popupQuickHelp",]),
            circVal(){
                const expir=this.$store.getters.getExperience;
                let expNext=this.$store.getters.getExperienceNext;
                let val=1;
                try {
                    val=(450-expir/expNext*300);
                }catch (e) {}
                return val;
            },
            cSc(){
                let val=3*this.$store.state.ss*this.$store.getters.xMult;
                return val;
            },
            getAvatar(){
                let avatar=this.$store.getters.getAvatar;
                if (!avatar){
                    avatar=Utils.getGlobalLink(imgTgAvatar);
                }
                return avatar;
            },
        },
        data(){
            return {
                imgTg:Utils.getGlobalLink(imgTg),discord_text:Utils.getGlobalLink(discord_text),
                infoSvg:Utils.getGlobalLink(infoSvg),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btntelegramClick(){
                Utils.discordGetSecret();
            },
            btnInfoClick(){
                const state=this.$store.state;
                state.popupQuickHelp.dailyStreak=true;
                setTimeout(()=>{
                    state.popupQuickHelp.dailyStreak=false;
                },6000)
            },
            popupInfoClick(){
                const state=this.$store.state;
                state.popupQuickHelp.dailyStreak=false;
            },
        },

    });
</script>

<style lang="css" scoped>
    .c-telegram_info{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        align-items: center;

        background: radial-gradient(56.97% 50.51% at 92.9% 100%, rgba(83, 95, 225, 0.60) 0%, rgba(83, 95, 225, 0.00) 100%),
        radial-gradient(109.84% 101.93% at -17.02% -9.49%, #535FE1 0%, rgba(83, 95, 225, 0.00) 100%), #031223;
    }

    .c-telegram_info_text{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: visible;
        color: #FFFFFF;
        text-align: -webkit-center;
    }

    .c-telegram_info_2{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        /*background: rgba(255, 255, 255, 0.3);/**/
    }
    .c-telegram_info_3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-telegram_info_img{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .c-telegram_info2{
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        align-items: center;

        background: linear-gradient(108.47deg,#5865f2 -3.59%,#4a54c1 87.27%);
    }

    .c-header-profile-user-progressbar{
        position: relative;
        overflow: visible;
    }
    .c-header-profile-user-pfp-bg{
        position: absolute;
        fill: none;
        fill-opacity: 0;
        stroke: white;
        stroke-linecap: round;
        stroke-opacity: 0.4;

        /*stroke-width: 5px;
        stroke-dasharray: 360;
        stroke-dashoffset: 60;/**/

        transform: rotate(132deg);/**/
    }

    .c-header-profile-user-pfp-progress{
        position: absolute;
        top: 0;
        right: 0;
        fill: none;
        stroke-width: 5px;
        stroke-linecap: round;

        --offset:150;
        stroke-dasharray: 450;
        stroke-dashoffset: var(--offset);
        /*animation: progress-1 1s ease-out;*/

        transform: rotate(132deg);
        filter: drop-shadow(0px 0 5px #39E79C);

    }

    @keyframes progress-1 {
        from {
            stroke-dashoffset: 450;
        }
        to {
            stroke-dashoffset: var(--offset);
        }
    }

    .c-telegram_info2_avatar{
        position: absolute;
        background: #7983F0;
        overflow: hidden;
    }
    .c-telegram_info2_level{
        position: absolute;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        white-space: nowrap;        /*Нет переноса не переносит*/
    }

    .c-telegram_info2_level_text1{
        background: linear-gradient(95.19deg, #0CFF7C -16.14%, #00AF90 137.96%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: visible;
        color: #FFFFFF;
        text-align: -webkit-center;
    }

    .c-telegram_info2_level_text2{

        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: visible;
        color: #FFFFFF;
        text-align: -webkit-center;
    }

    .c-telegram_info2_level_text3{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: visible;
        text-align: -webkit-center;
        color: #FFE500;

    }

    .c-header-profile-user-info-line{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .c-header-profile-user-info-value{
        background: linear-gradient(95.19deg, #0CFF7C -16.14%, #00AF90 137.96%);
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.03em;
        color: #FFFFFF;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .c-header-profile-user-info-value_2{
        background: linear-gradient(95.11deg, #FFC83C -9.98%, #DC8400 118.99%), linear-gradient(91.95deg, #FCC031 -9.33%, #FF2E2F 92.67%);
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.03em;
        color: #FFFFFF;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .c-telegram_info2_popup{
        position: absolute;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }

</style>

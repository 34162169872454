<template>
    <div class="c-components_body unselectable" style="backdrop-filter: blur(5px);background: linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8));">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <!--   Buy confirm bg static   -->
            <div class="c-buy_confirm_2"
                 :style="{'width': 1000*ss*xMult+'px','height': 800*ss*xMult+'px','gap': 30*ss*xMult+'px','border-radius':60*ss*xMult+'px',
                    'border':isBuy?6*ss*xMult+'px solid #06A071':6*ss*xMult+'px solid #CC181A',
                    'background':getBg,'box-shadow':getShadow}"
            >

                <!--     confetti  for buy confirm     -->
                <div v-if="isBuy" class="c-buy_confirm_bg_abs"
                     :style="{'width': '100%','height': '100%',}"
                >
                    <img class="c-buy_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 800*ss*xMult+'px','bottom': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
                         :src="confetti_2"
                    />
                    <img class="c-buy_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 800*ss*xMult+'px','bottom': 0*ss*xMult+'px','right': 0*ss*xMult+'px',}"
                         :src="confetti_3"
                    />

                    <img class="c-buy_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 150*ss*xMult+'px','top': 80*ss*xMult+'px','right': 150*ss*xMult+'px',}"
                         :src="coin_1"
                    />
                    <img class="c-buy_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 150*ss*xMult+'px','bottom': 80*ss*xMult+'px','right': 50*ss*xMult+'px',}"
                         :src="coin_2"
                    />
                    <img class="c-buy_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 150*ss*xMult+'px','top': 150*ss*xMult+'px','left': 0*ss*xMult+'px',}"
                         :src="coin_3"
                    />
                </div>
                <!--     confetti for burning confirm     -->
                <div v-else class="c-buy_confirm_bg_abs"
                     :style="{'width': '100%','height': '100%',}"
                >
                    <img class="c-buy_confirm_img_1" alt=""
                         :style="{'width': 1000*ss*xMult+'px','height': 800*ss*xMult+'px','top': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
                         :src="confetti_4"
                    />
                    <img class="c-buy_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 400*ss*xMult+'px','bottom': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
                         :src="coin_left"
                    />
                    <img class="c-buy_confirm_img_1" alt=""
                         :style="{'width': 'auto','height': 300*ss*xMult+'px','bottom': 0*ss*xMult+'px','right': 0*ss*xMult+'px',}"
                         :src="coin_right"
                    />

                </div>

                <!--     Confirm information       -->
                <div class="c-buy_confirm_column"
                     :style="{'width': 1000*ss*xMult+'px','height': 500*ss*xMult+'px','margin-bottom': 80*ss*xMult+'px',}"
                >
                    <div class="c-buy_confirm_text"
                         :style="{'width': 1000*ss*xMult+'px','height': 100*ss*xMult+'px',
                            'font-size': 70*ss*xMult+'px','line-height': 100*ss*xMult+'px','margin-bottom': 30*ss*xMult+'px',}"
                    >
                        {{isBuy?messages.m028:messages.m028_1}}
                    </div>
                    <div class="c-buy_confirm_text"
                         :style="{'width': 1000*ss*xMult+'px','height': 60*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                    >
                        {{getText}}
                    </div>
                    <div v-if="isBuy" class="c-buy_confirm_text"
                         :style="{'width': 1000*ss*xMult+'px','height': 60*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                    >
                        =
                    </div>
                    <div class="c-buy_confirm_text"
                         :style="{'width': 1000*ss*xMult+'px','height': 60*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-bottom': 50*ss*xMult+'px',}"
                    >
                        {{getText2}}
                    </div>

                    <!--     Confirm button       -->
                    <ButtonSvgOval :svg-normal="checkSvg"
                                   :callback="clickConfirm" :callback-wait="true"
                                   :btn-text="messages.m028_2"
                                   :bg-width="420*ss*xMult" :bg-height="100*ss*xMult"
                                   :max-width="420*ss*xMult"
                                   :font-size="45*ss*xMult"
                                   :svg-width="50*ss*xMult" :svg-height="50*ss*xMult"
                                   :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                                   :borderVal="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                                   :click-scale="0.95"
                    />
                </div>

                <img class="c-buy_confirm_img_1" :src="getImage"
                     :style="{'top': -175*ss*xMult+'px', 'height': 350*ss*xMult+'px','width': 'auto',}"
                />
                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import checkSvg from "@/assets/svg/icon_check.svg";
    import confetti_2 from "@/assets/svg/tickets/confetti_2.svg";
    import confetti_3 from "@/assets/svg/tickets/confetti_3.svg";
    import confetti_4 from "@/assets/images/confetti_4.webp";
    import coin_1 from "@/assets/svg/tickets/coin_1.svg";
    import coin_2 from "@/assets/svg/tickets/coin_2.svg";
    import coin_3 from "@/assets/svg/tickets/coin_3.svg";
    import coin_left from "@/assets/svg/tickets/coins_left.svg";
    import coin_right from "@/assets/svg/tickets/coins_right.svg";
    import iconGemSvg from "@/assets/svg/icon_gem2.svg";
    import ticketBronze from "@/assets/images/tickets_bronze.webp";
    import ticketSilver from "@/assets/images/tickets_silver.webp";
    import ticketGold from "@/assets/images/tickets_gold.webp";
    import lottoBronze from "@/assets/svg/lotto_bronze";
    import lottoSilver from "@/assets/svg/lotto_silver";
    import lottoGold from "@/assets/svg/lotto_gold";

    export default defineComponent({
        name: 'BuyConfirm',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","messages","ticketsBuyCount"]),
            getImage(){
                let img='';
                const tb=this.$store.state.ticketsBuyCount;
                img=tb.ticketType==='bronze'?Utils.getGlobalLink(ticketBronze):img;
                img=tb.ticketType==='silver'?Utils.getGlobalLink(ticketSilver):img;
                img=tb.ticketType==='gold'?Utils.getGlobalLink(ticketGold):img;
                img=tb.ticketType==='lottoBronze'?Utils.getGlobalLink(lottoBronze):img;
                img=tb.ticketType==='lottoSilver'?Utils.getGlobalLink(lottoSilver):img;
                img=tb.ticketType==='lottoGold'?Utils.getGlobalLink(lottoGold):img;
                return img;
            },
            getText(){
                const state=this.$store.state;
                const tb=this.$store.state.ticketsBuyCount;
                let txt;
                txt=tb.ticketType==='bronze'?tb.buyCount+' x '+state.messages.m027_4+' '+state.messages.m027_41:txt;
                txt=tb.ticketType==='silver'?tb.buyCount+' x '+state.messages.m027_5+' '+state.messages.m027_51:txt;
                txt=tb.ticketType==='gold'?tb.buyCount+' x '+state.messages.m027_6+' '+state.messages.m027_61:txt;
                txt=tb.ticketType==='lottoBronze'?tb.buyCount+' x '+state.messages.m027_4:txt;
                txt=tb.ticketType==='lottoSilver'?tb.buyCount+' x '+state.messages.m027_5:txt;
                txt=tb.ticketType==='lottoGold'?tb.buyCount+' x '+state.messages.m027_6:txt;
                return txt;
            },
            getText2(){
                const state=this.$store.state;
                const tb=this.$store.state.ticketsBuyCount;
                let txt;
                if (tb.ticketType==='bronze' || tb.ticketType==='silver' || tb.ticketType==='gold')
                    txt=(tb.buyCount*tb.buyPrise).toLocaleString()+' '+state.messages.m022_5;
                else {
                    txt=tb.ticketType==='lottoBronze'?state.messages.m027_41:txt;
                    txt=tb.ticketType==='lottoSilver'?state.messages.m027_51:txt;
                    txt=tb.ticketType==='lottoGold'?state.messages.m027_61:txt;
                }
                return txt;
            },
            getBg(){
                const state=this.$store.state;
                const tb=this.$store.state.ticketsBuyCount;
                let txt='linear-gradient(95deg, #0CFF7C -16.14%, #00AF90 137.96%)';
                if (state.blockspinState){
                    if (tb.ticketType==='lottoBronze' || tb.ticketType==='lottoSilver' || tb.ticketType==='lottoGold')
                        txt='linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)';
                }
                return txt;
            },
            getShadow(){
                const state=this.$store.state;
                const tb=this.$store.state.ticketsBuyCount;
                let txt='0px 0px 100px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 100px 0px rgba(57, 231, 156, 0.60)';
                if (state.blockspinState){
                    if (tb.ticketType==='lottoBronze' || tb.ticketType==='lottoSilver' || tb.ticketType==='lottoGold')
                        txt='0px 0px 100px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 100px 0px rgba(253, 134, 81, 0.60)';
                }
                return txt;
            },
            isBuy(){        //true - buy confirm, false - burn tickets confirm
                const tb=this.$store.state.ticketsBuyCount;
                if (tb.ticketType==='lottoBronze' || tb.ticketType==='lottoSilver' || tb.ticketType==='lottoGold')
                    return false;
                return true;
            },
        },
        components: {ButtonSvg,ButtonSvgOval,},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),iconGemSvg:Utils.getGlobalLink(iconGemSvg), ticketBronze:Utils.getGlobalLink(ticketBronze),
                ticketSilver:Utils.getGlobalLink(ticketSilver),ticketGold:Utils.getGlobalLink(ticketGold),confetti_2:Utils.getGlobalLink(confetti_2),
                checkSvg:Utils.getGlobalLink(checkSvg),confetti_3:Utils.getGlobalLink(confetti_3),coin_1:Utils.getGlobalLink(coin_1),
                coin_2:Utils.getGlobalLink(coin_2),coin_3:Utils.getGlobalLink(coin_3),confetti_4:Utils.getGlobalLink(confetti_4),
                coin_left:Utils.getGlobalLink(coin_left),coin_right:Utils.getGlobalLink(coin_right),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btnNotificationsClick(){},
            btnReferalClick(){

            },
            btn_xClick(){
                this.$store.state.ticketsBuyCount.buyVisible=false;
            },
            async clickConfirm(){
                this.$store.state.ticketsBuyCount.buyVisible=false;
                const tb=this.$store.state.ticketsBuyCount;

                if (tb.ticketType==='bronze' || tb.ticketType==='silver' || tb.ticketType==='gold') {
                    await this.buyTicket();
                }
                if (tb.ticketType==='lottoBronze' || tb.ticketType==='lottoSilver' || tb.ticketType==='lottoGold') {
                    await this.enterRaffle();
                }
            },
            async buyTicket(){
                const tb=this.$store.state.ticketsBuyCount;
                const obj={ ticketType:tb.ticketType, quantity:tb.buyCount, totalPrice:tb.buyPrise*tb.buyCount };
                const purchase = await this.$store.dispatch("buyTickets", obj);

                const messages=this.$store.state.messages;
                const param=this.$store.state.popupMessage;
                if (purchase.success) {
                    param.msg=messages.m029_6+" " + obj.quantity + " "+obj.ticketType+" " +messages.m029_7;
                    param.title=messages.m029_4;
                    param.error=false;
                    param.visible=true;
                } else {
                    param.msg=purchase.error;
                    param.title=messages.m029_5;
                    param.error=true;
                    param.visible=true;
                }
            },
            async enterRaffle(){
                const tb=this.$store.state.ticketsBuyCount;
                let burnTicketType='daily';
                burnTicketType=tb.ticketType==='lottoSilver'?'weekly':burnTicketType;
                burnTicketType=tb.ticketType==='lottoGold'?'monthly':burnTicketType;
                const entry = await this.$store.dispatch("enterRaffle", {
                    raffleType: burnTicketType,
                    quantity: tb.buyCount
                });

                const messages=this.$store.state.messages;
                const param=this.$store.state.popupMessage;
                if (entry.success) {
                    param.msg=messages.m029_1+" " + entry.numEntries + " "+messages.m029_2+" " + burnTicketType + " "+messages.m029_3;
                    param.title=messages.m029_4;
                    param.error=false;
                    param.visible=true;
                } else {
                    param.msg=entry.error;
                    param.title=messages.m029_5;
                    param.error=true;
                    param.visible=true;
                }
            },
        },
    });
</script>

<style lang="css" scoped>

    .c-buy_confirm{
        display: flex;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0;
        margin:0;
        width: 100vw;
        height: 100vh;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;
        backdrop-filter: blur(5px);
        background: linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8));

    }

    .c-buy_confirm_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-buy_confirm_bg_abs{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-buy_confirm_column{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-buy_confirm_img_1{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-buy_confirm_text{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;

        /*word-wrap: break-word;/**/
        white-space: nowrap;        /*Нет переноса не переносит*/
    }


</style>

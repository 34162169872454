<template>
    <!-- Display 'Gamers shop', 'How to win nfts', 'Redeem code', 'Free chips', 'Hourly lucky wheel' -->
    <div class="c-info_info_bg"
         :style="{'width': '100%','height': 'auto','gap': 30*ss*xMult+'px',}">

        <!--Gamers shop-->
        <div class="c-info_info_elem"
             :style="{'width': 950*ss*xMult+'px','height': 400*ss*xMult+'px','border-radius': 50*ss*xMult+'px',}">
            <img  style="position: absolute;"
                 :src="img_shop" alt=""
                 :style="{'width': '100%','height': '100%','top': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
            />

            <div class="c-info_info_ccs"
                 :style="{'width': 800*ss*xMult+'px','height': 150*ss*xMult+'px',}">
                <div class="c-info_info_ccs c-info_info_text"
                    :style="{'width': 800*ss*xMult+'px','height': 150*ss*xMult+'px','font-size': 80*ss*xMult+'px','line-height': 150*ss*xMult+'px',}">
                    {{messages.m045_01}}
                </div>
            </div>
            <div class="c-info_info_ccs" :style="{'width': 800*ss*xMult+'px','height': 130*ss*xMult+'px',}">
                <ButtonSvgOval :svg-normal="img_prizes"
                               :btn-text="messages.m045_02"
                               :bg-width="378*ss*xMult" :bg-height="btnRad*0.8"
                               :max-width="594*ss*xMult-btnRad/2-70*ss*xMult"
                               :font-size="45*ss*xMult"
                               :svg-width="btnRad*0.4" :svg-height="btnRad*0.4"
                               :background-val="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(94.81deg,#dc06ff -6.49%,#8606b3 108.32%) var(--x,0)/200%'"
                               :border-val="'linear-gradient(102.76deg,#88028D 3.6%,#FF37DF 53.89%,#88028D 99.19%)'"
                               :callback="clickOpenShop" :callback-wait="true"
                               :click-scale="0.95"
                />
            </div>

        </div>

        <!--How to win nfts-->
        <div class="c-info_info_elem"
             :style="{'width': 460*ss*xMult+'px','height': 400*ss*xMult+'px','border-radius': 50*ss*xMult+'px',}">
            <img  style="position: absolute;"
                 :src="img_nft" alt=""
                 :style="{'width': '100%','height': '100%','top': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
            />

            <div class="c-info_info_ccs"
                 :style="{'width': 400*ss*xMult+'px','height': 'auto',}">
                <div class="c-info_info_ccs c-info_info_text"
                     :style="{'width': 400*ss*xMult+'px','height': 150*ss*xMult+'px','font-size': 55*ss*xMult+'px','line-height': 73*ss*xMult+'px',}">
                    {{messages.m045_03}}
                </div>
            </div>
            <div class="c-info_info_ccc" :style="{'width': 460*ss*xMult+'px','height': 130*ss*xMult+'px',}">
                <ButtonSvgOval :btn-text="messages.m045_04"
                               :bg-width="378*ss*xMult" :bg-height="btnRad*0.8"
                               :max-width="594*ss*xMult-btnRad/2-70*ss*xMult"
                               :font-size="45*ss*xMult"
                               :background-val="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(95.19deg,#0cff7c -16.14%,#00af90 137.96%) var(--x,0)/200%'"
                               :border-val="'linear-gradient(102.76deg,#06a071 3.6%,#bcffde 53.89%,#06a071 99.19%)'"
                               :callback="clickWinNft" :callback-wait="true"
                               :click-scale="0.95"
                />
            </div>

        </div>

        <!--Hourly lucky wheel-->
        <div class="c-info_info_elem"
             :style="{'width': 460*ss*xMult+'px','height': 400*ss*xMult+'px','border-radius': 50*ss*xMult+'px',}">
            <img  style="position: absolute;"
                 :src="img_wheel" alt=""
                 :style="{'width': '100%','height': '100%','top': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
            />

            <div class="c-info_info_ccs"
                 :style="{'width': 400*ss*xMult+'px','height': 'auto',}">
                <div class="c-info_info_ccs c-info_info_text"
                     :style="{'width': 400*ss*xMult+'px','height': 150*ss*xMult+'px','font-size': 55*ss*xMult+'px','line-height': 73*ss*xMult+'px',}">
                    {{messages.m045_08}}
                </div>
            </div>
            <div class="c-info_info_ccc" :style="{'width': 460*ss*xMult+'px','height': 150*ss*xMult+'px',}">
                <div class="c-info_info_ccs c-info_info_text2"
                     :style="{'width': 400*ss*xMult+'px','height': 150*ss*xMult+'px','font-size': 45*ss*xMult+'px','line-height': 50*ss*xMult+'px',}">
                    {{messages.m045_12}}
                </div>
                <!--ButtonSvgOval
                               :btn-text="btnMsg"
                               :bg-width="378*ss*xMult" :bg-height="btnRad*0.8"
                               :max-width="594*ss*xMult-btnRad/2-70*ss*xMult"
                               :font-size="45*ss*xMult"
                               :svg-width="btnRad*0.4" :svg-height="btnRad*0.4"
                               :background-val="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                               :border-val="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                               :callback="clickLuckyWheel" :callback-wait="true"
                               :click-scale="0.95"
                /-->
            </div>

        </div>

        <!--HOW TO GET FREE CHIPS?-->
        <div class="c-info_info_elem"
             :style="{'width': 460*ss*xMult+'px','height': 400*ss*xMult+'px','border-radius': 50*ss*xMult+'px',}">
            <img  style="position: absolute;"
                 :src="img_raffles" alt=""
                 :style="{'width': '100%','height': '100%','top': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
            />

            <div class="c-info_info_ccs"
                 :style="{'width': 400*ss*xMult+'px','height': 'auto',}">
                <div class="c-info_info_ccs c-info_info_text"
                     :style="{'width': 400*ss*xMult+'px','height': 150*ss*xMult+'px','font-size': 55*ss*xMult+'px','line-height': 73*ss*xMult+'px',}">
                    {{messages.m045_07}}
                </div>
            </div>
            <div class="c-info_info_ccc" :style="{'width': 460*ss*xMult+'px','height': 130*ss*xMult+'px',}">
                <ButtonSvgOval
                               :btn-text="messages.m045_04"
                               :bg-width="378*ss*xMult" :bg-height="btnRad*0.8"
                               :max-width="594*ss*xMult-btnRad/2-70*ss*xMult"
                               :font-size="45*ss*xMult"
                               :svg-width="btnRad*0.4" :svg-height="btnRad*0.4"
                               :background-val="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                               :border-val="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                               :callback="clickChips" :callback-wait="true"
                               :click-scale="0.95"
                />
            </div>

        </div>

        <!--REDEEM CODE-->
        <div class="c-info_info_elem"
             :style="{'width': 460*ss*xMult+'px','height': 400*ss*xMult+'px','border-radius': 50*ss*xMult+'px',}">
            <img  style="position: absolute;"
                 :src="img_redeem" alt=""
                 :style="{'width': '100%','height': '100%','top': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
            />

            <div class="c-info_info_ccs"
                 :style="{'width': 400*ss*xMult+'px','height': 'auto',}">
                <div class="c-info_info_ccs c-info_info_text"
                     :style="{'width': 400*ss*xMult+'px','height': 150*ss*xMult+'px','font-size': 55*ss*xMult+'px','line-height': 73*ss*xMult+'px',}">
                    {{messages.m045_05}}
                </div>
            </div>
            <div class="c-info_info_ccc" :style="{'width': 460*ss*xMult+'px','height': 130*ss*xMult+'px',}">
                <ButtonSvgOval
                               :btn-text="messages.m045_06"
                               :bg-width="378*ss*xMult" :bg-height="btnRad*0.8"
                               :max-width="594*ss*xMult-btnRad/2-70*ss*xMult"
                               :font-size="45*ss*xMult"
                               :svg-width="btnRad*0.4" :svg-height="btnRad*0.4"
                               :background-val="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%'"
                               :border-val="'linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%)'"
                               :callback="clickRedeem" :callback-wait="true"
                               :click-scale="0.95"
                />
            </div>

        </div>

        <!--JACKPOT-->
        <div class="c-info_info_elem"
             :style="{'width': 950*ss*xMult+'px','height': 400*ss*xMult+'px','border-radius': 50*ss*xMult+'px',}">
            <img  style="position: absolute;"
                 :src="img_jackpot" alt=""
                 :style="{'width': '100%','height': '100%','top': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
            />

            <div class="c-info_info_ccc"
                 :style="{'width': 800*ss*xMult+'px','height': 100*ss*xMult+'px',}">
                <div class="c-info_info_ccc c-info_info_text"
                     :style="{'width': 800*ss*xMult+'px','height': 100*ss*xMult+'px','font-size': 80*ss*xMult+'px','line-height': 100*ss*xMult+'px',}">
                    {{messages.m045_11}}
                </div>
            </div>
            <div class="c-info_info_ccc" :style="{'width': 910*ss*xMult+'px','height': 130*ss*xMult+'px',}">
                <div class="c-info_info_ccc c-info_info_text" style="color: #ffe500; font-weight: 900; white-space: nowrap;"
                     :style="{'width': 910*ss*xMult+'px','height': 100*ss*xMult+'px','font-size': 85*ss*xMult+'px','line-height': 100*ss*xMult+'px',}">
                    {{jackPotText}}
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";

    import img_prizes from "@/assets/svg/tickets/prizes";
    import img_shop from "@/assets/images/info/shop_banner.webp";
    import img_nft from "@/assets/images/info/winnfts_banner.webp";
    import img_wheel from "@/assets/images/info/luckywheel_banner.webp";
    import img_raffles from "@/assets/images/info/raffles_banner.webp";
    import img_redeem from "@/assets/images/info/redeem_banner.webp";
    import img_jackpot from "@/assets/images/info/jackpot_banner.webp";


    export default defineComponent({
    //export default {
        name: "PageInfoInfo",
        components: {ButtonSvgOval},
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","messages","orient","xMult",]),
            btnRad(){
                return 135*this.$store.state.ss*this.$store.getters.xMult
            },
            jackPotText(){
                let val=0;
                try {
                    const state=this.$store.state;
                    val=state.blockspinState.jackpot;
                    val=val?val.toLocaleString()+' '+state.messages.m037_02:'0 '+state.messages.m037_02;
                }
                catch (e) {
                    console.log('PageInfoInfo.jackPotText catch: ',e.message,'\nstack: ',e.stack)
                }
                return val;
            },
            btnMsg(){
                const messages=this.$store.state.messages;
                let msg=messages.m045_09;
                //if (Utils.getEnv()==='test')
                {
                    msg=messages.m045_10;
                }
                return msg;
            }
        },
        data: function () {
            return {
                img_shop:Utils.getGlobalLink(img_shop),img_prizes:Utils.getGlobalLink(img_prizes),img_nft:Utils.getGlobalLink(img_nft),
                img_wheel:Utils.getGlobalLink(img_wheel),img_raffles:Utils.getGlobalLink(img_raffles),img_redeem:Utils.getGlobalLink(img_redeem),
                img_jackpot:Utils.getGlobalLink(img_jackpot),
            }
        },

        created() {

        },
        mounted() {

        },
        beforeUnmount() {

        },
        methods:{
            clickOpenShop(){
                this.$store.state.shop.visible=true;
            },
            clickWinNft(){
                this.$store.state.popupVisible.learnNft=true;
            },
            clickLuckyWheel(){
                //if (Utils.getEnv()==='test')
                {
                    const state=this.$store.state;
                    if (state.loginData){
                        this.$store.state.popupLuckyWheel.visible=true;
                    }
                    else{
                        state.showMessagePopup(state.messages.m052_09,state.messages.m029_5,true);
                    }
                }
            },
            clickChips(){
                this.$store.state.popupVisible.learnChips=true;
            },
            clickRedeem(){
                this.$store.state.popupVisible.redeemPopup=true;
            },

        },
    });

</script>

<style lang="css" scoped>
    .c-info_info_bg{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        overflow: -moz-scrollbars-none;
        justify-content: center;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-info_info_elem{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-info_info_ccs{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-info_info_ccc{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-info_info_text{
        font-family: Rubik, sans-serif;
        font-style: normal;
        font-weight: 600;
        overflow: hidden;
        color: #FFFFFF;

        word-wrap: break-word;/**/
        /*white-space: nowrap;        /*Нет переноса не переносит*/
    }

    .c-info_info_text2{
        font-family: Rubik, sans-serif;
        font-style: normal;
        font-weight: 600;
        overflow: hidden;
        color: #FFFFFF;

        word-wrap: break-word;/**/
        white-space: pre-wrap;        /*Нет переноса не переносит*/
    }

</style>

<template>
    <!-- Quick help popup
    :style="{'width': bgWidth+'px','height': bgHeight?bgHeight+'px':'auto'}"
    -->
    <div class="c-popup_info_bg unselectable"
         :style="{'width': 'auto','height': 'auto','border-radius':borderRadius+'px',}"
    >
        <div class="c-popup_info_blur"
             :style="{'width': '100%','height': '100%','top': 0*ss+'px','left': 0*ss+'px','border-radius':borderRadius+'px',
             }"
        >
        </div>
        <!-- Button content  -->
        <div class="c-popup_info_fix" @click="onClick"
             :style="{'width': bgWidth+'px','height': bgHeight?bgHeight+'px':'auto','top': 0*ss+'px','left': 0*ss+'px',
                'padding-top':(bgHeight?0:8)*ss*xMult+'px','padding-bottom':(bgHeight?0:8)*ss*xMult+'px',
                'border-radius':borderRadius+'px','border':borderVal,}"
        >
            <div class="c-popup_info"
                 :style="{'width': (bgWidth-6*ss*xMult)+'px','height': bgHeight?(bgHeight-8*xMult*ss)+'px':'auto',}"
            >
                <div v-if="btnText" class="c-popup_info_text"
                     :style="{'width': (bgWidth-borderRadius)+'px','font-size': fontSize?fontSize+'px':bgHeight/3+'px'}"
                >
                    {{btnText}}
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';


    export default defineComponent({
        name: 'PopupInfo',
        components: {},
        computed: {
            ...mapGetters(["ss","xMult"]),

        },
        data(){
            return {
            };
        },
        created() {
            //console.log(this.clickScale)
        },
        mounted() {
        },
        methods: {
            onClick() {
                if (!this.callback) return;
                this.callback();
            },
        },

        props:{
            btnText: {
                type: String,
                default: null
            },
            bgWidth: {
                type: Number,
                default: 0         // if 0 than 'auto'
            },
            bgHeight: {
                type: Number,
                default: 0          // if 0 than 'auto'
            },
            fontSize: {
                type: Number,
                default: 0          // if 0 than bgHeight/3
            },

            callback: {
                type: Function,
                default: () => {}
            },
            opacityVal: {
                type: Number,
                default: 1
            },
            borderRadius: {
                type: Number,
                default: 44
            },
            backgroundVal: {
                type: String,
                default: 'linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8))'
            },
            borderVal: {
                type: String,
                default: '1px solid rgba(255, 255, 255, 0.5)'
            },
        }
    });
</script>

<style lang="css" scoped>

    .c-popup_info_bg{
        display: flex;
        overflow: visible;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 0px 0px;
    }

    .c-popup_info_fix{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .c-popup_info_blur{
        display: flex;
        position: absolute;
        overflow: visible;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(10px);
        background: rgba(0,0,0,0.7);/**/
    }

    .c-popup_info{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 30px rgba(3, 18, 35, 0.3);/**/

    }




    .c-popup_info_text{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;

        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        text-align: -webkit-center;
    }

</style>

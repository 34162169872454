<template>
    <!--   Boosters    'height': 500*ss*xMult+'px' -->
    <div class="c-boost"
         :style="{'width': 1000*ss*xMult+'px','height': (250+boostsCount*boostSize)*ss*xMult+'px',
         'border-radius': 40*ss*xMult+'px','gap': 0*ss*xMult+'px',}"
    >
        <div class="c-user_info_title_text"
             :style="{'width': 485*ss*xMult+'px','height': 80*ss*xMult+'px','border-radius': 40*ss*xMult+'px',
                'font-size': 46*ss*xMult+'px','line-height': 50*ss*xMult+'px','margin-top': 20*ss*xMult+'px',
                'text-shadow': '0 0 '+15*ss*xMult+'px rgba(57,231,156,.6)',}"
        >
            {{messages.m022_4}}
        </div>

        <div v-if="boostsCount" class="c-boost_col"
             :style="{'width': 1000*ss*xMult+'px','height': boostsCount*boostSize*ss*xMult+'px',}"
        >
            <div v-for="(boostKey, boostIndex) in boostsKeys"
                 class="c-boost_row_main" style="border-bottom: 2px solid #19c9f3;"
                 :style="{'width': 980*ss*xMult+'px','height': (boostSize-35)*ss*xMult+'px','margin-top': 30*ss*xMult+'px','gap': 20*ss*xMult+'px',}"
            >
                <img class="c-boost_img" alt=""
                     :src="getImg(boostKey)"
                     :style="{'width': 175*ss*xMult+'px','height': (boostSize-35)*ss*xMult+'px',}"
                />

                <!--      Boost name          -->
                <div class="c-boost_col2"
                     :style="{'width': 480*ss*xMult+'px','height': (boostSize-35)*ss*xMult+'px',}"
                >
                    <div class="c-boost_text_2" style="opacity: 0.6;"
                         :style="{'width': 480*ss*xMult+'px','height': (boostSize-35)*0.4*ss*xMult+'px',
                            'font-size': (boostSize-35)*0.2*ss*xMult+'px','line-height': (boostSize-35)*0.3*ss*xMult+'px',}"
                    >
                        {{messages.m026}}
                    </div>
                    <div class="c-boost_text"
                         :style="{'width': 480*ss*xMult+'px','height': (boostSize-35)*0.6*ss*xMult+'px',
                            'font-size': (boostSize-35)*0.27*ss*xMult+'px','line-height': (boostSize-35)*0.3*ss*xMult+'px',}"
                    >
                        {{getBoostObj(boostKey).name}}
                    </div>
                </div>

                <!--      Show credits for daysStreakInsuranceRemaining          -->
                <div v-if="boostKey==='daysStreakInsuranceRemaining'" class="c-boost_col2"
                     :style="{'width': 280*ss*xMult+'px','height': (boostSize-35)*ss*xMult+'px',}"
                >
                    <div class="c-boost_text_2" style="opacity: 0.6;"
                         :style="{'width': 480*ss*xMult+'px','height': (boostSize-35)*0.4*ss*xMult+'px',
                            'font-size': (boostSize-35)*0.2*ss*xMult+'px','line-height': (boostSize-35)*0.3*ss*xMult+'px',}"
                    >
                        {{messages.m026_4}}
                    </div>
                    <div class="c-boost_text"
                         :style="{'width': 480*ss*xMult+'px','height': (boostSize-35)*0.6*ss*xMult+'px',
                            'font-size': (boostSize-35)*0.27*ss*xMult+'px','line-height': (boostSize-35)*0.3*ss*xMult+'px',}"
                    >
                        {{userData.boosts[boostKey]?userData.boosts[boostKey]:0}}
                    </div>
                </div>
                <!--      else Boost time left          -->
                <div v-else class="c-boost_col2"
                     :style="{'width': 280*ss*xMult+'px','height': (boostSize-35)*ss*xMult+'px',}"
                >
                    <div class="c-boost_text_2" style="opacity: 0.6;"
                         :style="{'width': 480*ss*xMult+'px','height': (boostSize-35)*0.4*ss*xMult+'px',
                            'font-size': (boostSize-35)*0.2*ss*xMult+'px','line-height': (boostSize-35)*0.3*ss*xMult+'px',}"
                    >
                        {{messages.m026_2}}
                    </div>
                    <div class="c-boost_text"
                         :style="{'width': 480*ss*xMult+'px','height': (boostSize-35)*0.6*ss*xMult+'px',
                            'font-size': (boostSize-35)*0.27*ss*xMult+'px','line-height': (boostSize-35)*0.3*ss*xMult+'px',}"
                    >
                        {{getRemainingTime(userData.boosts[boostKey])}}
                    </div>
                </div>

                <!--   I button   -->
                <div style="position: absolute"
                     @click="btnInfoClickParam(boostKey)"
                     :style="{'top': 0*ss*xMult+'px','right': 10*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvgOval :svg-normal="infoSvg"
                                   :bg-width="75*ss*xMult" :bg-height="70*ss*xMult"
                                   :max-width="50*ss*xMult"
                                   :svg-width="37*ss*xMult" :svg-height="37*ss*xMult"
                                   :background-val="'hsla(0,0%,100%,0.2)'"
                                   :border-val="'none'"
                                   :callback="btnInfoClick" :callback-wait="true"
                                   :click-scale="0.9"
                    />
                </div>

                <!--   quick info   -->
                <div v-if="getBoostObj(boostKey).visible===true" class="c-boost_info_popup"
                     :style="{'top': 0*ss*xMult+'px','right': 100*ss*xMult+'px',}"
                     @click="popupInfoClick(boostKey)"
                >
                    <PopupInfo     :bg-width="600*ss*xMult"
                                   :border-radius="30*ss*xMult"
                                   :font-size="35*ss*xMult"
                                   :btn-text="getBoostObj(boostKey).description"
                                   :background-val="'rgba(255, 255, 255, 0.1)'"
                                   :border-val="'1px solid rgba(255, 255, 255, 0.5)'"
                    />
                </div>
            </div>
        </div>

        <!--       Get more         -->
        <div :style="{'margin-top': 35*ss*xMult+'px'}">
            <ButtonSvgOval2 :svg-normal="iconPlusSvg"
                            :btn-text="messages.m023"
                            :bg-width="300*ss*xMult" :bg-height="btnRad*0.6*xMult"
                            :font-size="btnRad*0.6*0.45*xMult"
                            :max-width="(500*ss-btnRad/2-70*ss)*xMult"
                            :svg-width="btnRad*0.6/2*xMult" :svg-height="btnRad*0.6/2*xMult"
                            :background-val="'linear-gradient(95.19deg,rgba(12,255,124,0) -16.14%,#00af90 237.96%) var(--x,0)/300%'"
                            :border-val="'2px solid'"
                            :border-color="'#0cff7c'"
                            :callback="btnGetClick" :callback-wait="true"
                            :click-scale="0.95"
            />
        </div>

    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import ButtonSvgOval2 from "@/vue/elements/ButtonSvgOval2";
    import PopupInfo from "@/vue/elements/PopupInfo";
    import Utils from "@/scripts/Utils";
    import iconPlusSvg from "@/assets/svg/icon_plus_green.svg";
    import infoSvg from "@/assets/svg/icon_info.svg";

    export default defineComponent({
        name: 'Boosters',
        components: {ButtonSvgOval, ButtonSvgOval2, PopupInfo,},
        computed: {
            ...mapGetters(["ss","xMult","messages","btnRad","userData","boostsObj"]),
            boostsCount(){
                const ud=this.$store.state.userData;
                let cnt=0;
                if (ud && ud.boosts)
                    cnt=Object.keys(ud.boosts).length;
                return cnt;
            },
            boostsKeys(){
                const ud=this.$store.state.userData;
                let keys=[];
                if (ud && ud.boosts)
                    keys=Object.keys(ud.boosts);
                return keys;
            },
            boostSize(){
                return 200;
            },
        },
        data(){
            return {
                iconPlusSvg:Utils.getGlobalLink(iconPlusSvg),Utils:Utils,
                infoSvg:Utils.getGlobalLink(infoSvg)

            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btnGetClick(){
                const shop=this.$store.state.shop;
                shop.shopType='boost';
                shop.visible=true;
            },
            getImg(boostKey){
                const boostObj=this.getBoostObj(boostKey);
                let img='';
                try {
                    img=Utils.getGlobalLink(boostObj.img)
                }catch (e) {}
                return img;
            },
            getBoostObj(boostKey){
                let bk=boostKey;
                const boostsObj=this.$store.getters.boostsObj;
                let obj;
                try {
                    bk=bk==='daysStreakInsuranceRemaining'?'insurance1Day':bk;
                    obj=boostsObj[bk];
                }catch (e) {}
                if (!obj)
                    obj={};
                return obj;
            },
            getRemainingTime(timestamp) {
                if (!timestamp || timestamp===true)
                    return this.$store.state.messages.m026_3;
                let remainingMiliseconds = (timestamp - this.$store.state.blockspinState.timestamp)
                let remainingSeconds = Math.floor((remainingMiliseconds/1000) %60)
                let remainingMinutes = Math.floor((remainingMiliseconds/(1000*60)) %60)
                let remainingHours = Math.floor((remainingMiliseconds/(1000*60*60)) %24)
                remainingSeconds=remainingSeconds<0?0:remainingSeconds;
                remainingMinutes=remainingMinutes<0?0:remainingMinutes;
                remainingHours=remainingHours<0?0:remainingHours;
                return remainingHours.toString().padStart(2, "0")+":"+remainingMinutes.toString().padStart(2, "0")+":"+remainingSeconds.toString().padStart(2, "0")
            },
            btnInfoClick(){
            },
            btnInfoClickParam(bk){
                let boostKey=bk==='daysStreakInsuranceRemaining'?'insurance1Day':bk;
                if (this.$store.state.boostsObj[boostKey]) {
                    this.$store.state.boostsObj[boostKey].visible = true;
                    setTimeout(() => {
                        this.$store.state.boostsObj[boostKey].visible = false;
                    }, 5000);
                }
            },
            popupInfoClick(bk){
                let boostKey=bk==='daysStreakInsuranceRemaining'?'insurance1Day':bk;
                if (this.$store.state.boostsObj[boostKey]) {
                    this.$store.state.boostsObj[boostKey].visible = false;
                }
            },
        },

    });
</script>

<style lang="css" scoped>
    .c-boost{
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: flex-start;
        align-items: center;

        background: hsla(0,0%,100%,.1);
    }

    .c-boost_text{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        color: #FFFFFF;
    }

    .c-boost_text_2{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        color: #FFFFFF;
    }

    .c-boost_img{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-boost_col{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-boost_col2{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-boost_row_main{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;


        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-boost_info_popup{
        position: absolute;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }

</style>

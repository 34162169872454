import rubik_normal from "@/assets/fonts/rubik_normal.woff2";

export default class DynamicFonts {
    constructor() {

        //If I open incorrect web link 'https://mobiletest.blockspingaming.com/ho/tt/dd' for example then fonts load from incorrect location
        // and not working

        let url='./assets/fonts/rubik_normal.woff2';
        if (document.location.hostname==='localhost')
            url='http://localhost:8080/'+rubik_normal;
        if (window.location.hostname.includes("mobiletest."))
            url='https://mobiletest.blockspingaming.com/'+rubik_normal;
        if (window.location.hostname.includes("mobile."))
            url='https://mobile.blockspingaming.com/'+rubik_normal;

        let fontStyle=`
@font-face {
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-display: swap;
src: url(${url}) format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-display: swap;
src: url(${url}) format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}            
        
@font-face {
font-family: 'Rubik';
font-style: normal;
font-weight: 600;
font-display: swap;
src: url(${url}) format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
font-family: 'Rubik';
font-style: normal;
font-weight: 700;
font-display: swap;
src: url(${url}) format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
font-family: 'Rubik';
font-style: normal;
font-weight: 900;
font-display: swap;
src: url(${url}) format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
`;
        let style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = fontStyle;
        document.getElementsByTagName('head')[0].appendChild(style);
    }
}

<template>
    <div class="c-frame_bg"
         :style="{'top': 0*ss+'px','left':getLeft+'px',
                'width': realWidth-8+'px','height': realHeight-8+'px',}">
        <!--  (-8) No *ss because border has fixed size 4px in style-->

    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import { mapGetters } from "vuex";
    import Utils from "../scripts/Utils";


    //export default defineComponent({              //Not working debugger in chrome
    export default {
        name: "BgFrame",
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","getLeft"]),
        },
        created() {


        },
        data: function () {
            return {

            }
        },
        methods:{

        },
    }
    //);

</script>

<style lang="css" scoped>
    .c-frame_bg{
        display: flex;
        overflow: hidden;
        position: absolute;
        /*transform: translate(50%, -50%);/**/
        transform: translate(0%, 0%);
        padding:0;
        margin:0;

        /*background: rgba(255, 255, 255, 0.1);/**/
        border: 4px solid rgba(0, 255, 0, 0.5);
    }

</style>

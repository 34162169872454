<template>
            <div class="c-page_game_list_row" v-for="(gameI, indexI) in gameList"
                 :style="{'width': getWidth+'px','height': getImageHeight+80*ss*xMult+'px',
                    }"
            >

                <div class="c-page_game_list_icon" v-for="(gameObj, indexJ) in gameI"
                     :style="{'width': getImageWidth+'px','height': getImageHeight+80*ss*xMult+'px',}"
                     :key="gameObj.name"
                >
                    <img style="display: flex; align-items: center; justify-content: center;overflow: hidden;" alt=""
                         :style="{'width': getImageWidth+'px','height': getImageHeight+'px','border-radius':50*ss*xMult+'px'}"
                         :src="gameObj.imageLink"

                    />
                    <div class="c-page_game_list_text"
                         :style="{'width': getImageWidth+'px','height': 65*ss*xMult+'px','line-height': 65*ss*xMult+'px',
                            'font-size': 45*ss*xMult+'px',}"
                    >
                        {{gameObj.title}}
                    </div>

                    <!--   Lock image   -->
                    <div v-if="gameObj.isUnlock!==true" class="c-page_game_list_lock" style="background: rgba(0, 0, 0, 0.6);"
                         :style="{'width': 120*ss*xMult+'px','height': 120*ss*xMult+'px','border-radius': 40*ss*xMult+'px',
                            'bottom': 80*ss*xMult+'px','right': 2*ss*xMult+'px',}"
                    >
                        <img alt="" style="width: 90%; height: 90%" :src="lockSvg"/>
                    </div>

                    <img v-if="gameObj.isSoon===true" class="c-page_game_list_lock"
                         :src="soonImg" alt=""
                         :style="{'width': 'auto','height': 70*ss*xMult+'px',
                            'top': 30*ss*xMult+'px','left': 30*ss*xMult+'px',}"
                    />

                    <img v-if="gameObj.isNew===true" class="c-page_game_list_lock"
                         :src="newImg" alt=""
                         :style="{'width': 'auto','height': 70*ss*xMult+'px',
                            'top': 30*ss*xMult+'px','left': 30*ss*xMult+'px',}"
                    />

                    <!--   I button   -->
                    <div style="position: absolute"
                         @click="btnInfoClickAction(gameObj)"
                         :style="{'top': 20*ss*xMult+'px','right': 20*ss*xMult+'px', 'width': 100*ss*xMult+'px','height': 100*ss*xMult+'px',}"
                    >
                        <ButtonSvgOval :svg-normal="infoSvg"
                                       :bg-width="100*ss*xMult" :bg-height="100*ss*xMult"
                                       :max-width="50*ss*xMult"
                                       :svg-width="57*ss*xMult" :svg-height="57*ss*xMult"
                                       :background-val="'rgba(0,0,0,0.5)'"
                                       :border-val="'none'"
                                       :callback="btnInfoClick" :callback-wait="true"
                                       :click-scale="0.9"
                        />
                    </div>

                    <!--   game info   -->
                    <div v-if="gameObj.infoVisible===true || gameObj.popupInfoVisible===true" class="c-page_game_list_info"
                         :style="{'width': getImageWidth+'px','height': getImageHeight+'px','border-radius':50*ss*xMult+'px'}"
                    >
                        <div class="c-page_game_list_info_cont"
                             :style="{'width': getImageWidth+'px','height': getImageHeight-60*ss*xMult+'px',}"
                        >
                            <div class="c-app_ccc"
                                 @click="btnRatioClickAction(gameObj)"
                                :style="{'width': getImageWidth+'px','height': 86*ss*xMult+'px',}">
                                <ButtonSvgOval :btn-text="messages.m052_08+' '+this.miningRatio(gameObj.name)+'-10'"
                                               :font-size="38*ss*xMult"
                                               :callback="btnRatioClick" :callback-wait="true"
                                               :bg-width="450*ss*xMult" :bg-height="80*ss*xMult"
                                               :max-width="430*ss*xMult"
                                               :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%'"
                                               :borderVal="'linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%)'"
                                               :clickScale="0.95"
                                />
                            </div>
                            <div class="c-page_game_list_descr_text"
                                 :style="{'width': getImageWidth+'px','height': getImageHeight-240*ss*xMult+'px','line-height': 65*ss*xMult+'px',
                                    'font-size': 45*ss*xMult+'px',}"
                            >
                                {{messages.games_desc[gameObj.description]}}
                            </div>
                            <div class="c-page_game_list_descr_text"
                                 :style="{'width': getImageWidth+'px','height': 65*ss*xMult+'px','line-height': 65*ss*xMult+'px',
                                    'font-size': 45*ss*xMult+'px',}"
                            >
                                {{messages.m052_07}}
                            </div>
                        </div>

                        <!--   quick info   -->
                        <div v-if="gameObj.popupInfoVisible===true" class="c-page_game_list_info_popup"
                             :style="{'top': 150*ss*xMult+'px',}"
                             @click="popupInfoClick"
                        >
                            <PopupInfo     :bg-width="getImageWidth-60*ss*xMult"
                                           :border-radius="30*ss*xMult"
                                           :font-size="35*ss*xMult"
                                           :btn-text="this.getMiningText(gameObj.name)"
                                           :background-val="'rgba(255, 255, 255, 0.1)'"
                                           :border-val="'1px solid rgba(255, 255, 255, 0.5)'"
                            />
                        </div>

                    </div>

                    <!--   click game trap   -->
                    <div style="position: absolute;"
                         @click="openGame(gameObj.name)"
                         :style="{'bottom': 0*ss*xMult+'px','left': 0*ss*xMult+'px',
                            'width': getImageWidth+'px','height': getImageHeight-70*ss*xMult+'px',}"
                    />

                </div>

            </div>

</template>

<script>
    import { defineComponent } from 'vue';
    import { mapGetters } from "vuex";
    import lockSvg from "../../assets/svg/lock_card.svg";
    import soonImg from "@/assets/svg/icon_soon.svg";
    import newImg from "@/assets/svg/icon_new.svg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import Utils from "@/scripts/Utils";
    import infoSvg from "@/assets/svg/icon_info";
    import PopupInfo from "@/vue/elements/PopupInfo";

    //export default defineComponent({              //Not working debugger in chrome
    export default {
        name: "PageGameList",
        components: {ButtonSvgOval,PopupInfo,},
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","orient","gameList","xMult","messages"]),
            getWidth(){
                const state=this.$store.state;
                let w=state.realWidth-40*state.ss;
                return w;
            },
            getImageHeight(){       //Return game image size depends orientation and gameType
                                    //        //full height of icon with down text, etc
                return this.getImageWidth*this.getImageAspect;
            },
            getImageWidth(){        //full width of icon with down text, etc
                const state=this.$store.state;
                const gap=40;
                let w=this.getWidth/2;
                if (state.orient==='V'){
                    w=state.gameType==='allGames'?(this.getWidth-gap*state.ss)/2:w;
                    w=state.gameType==='scratchCards'?(this.getWidth-gap*state.ss)/2:w;
                    w=state.gameType==='instantGames'?(this.getWidth-gap*state.ss)/2:w;
                    w=state.gameType==='crashGames'?this.getWidth:w;
                    w=state.gameType==='spincity'?this.getWidth:w;
                }
                else{
                    w=state.gameType==='allGames'?(this.getWidth-gap*3*state.ss)/4:w;
                    w=state.gameType==='scratchCards'?(this.getWidth-gap*3*state.ss)/4:w;
                    w=state.gameType==='instantGames'?(this.getWidth-gap*3*state.ss)/4:w;
                    w=state.gameType==='crashGames'?(this.getWidth-gap*state.ss)/2:w;
                    w=state.gameType==='spincity'?(this.getWidth-gap*state.ss)/2:w;

                }
                return w;
            },
            getImageAspect() {       //Aspect ratio for game image height=width*aspect
                const state=this.$store.state;
                let asp=1.33;           //default for slots images
                asp=state.gameType==='crashGames'?0.56:asp;
                asp=state.gameType==='scratchCards'?1:asp;
                asp=state.gameType==='instantGames'?1.185:asp;
                asp=state.gameType==='spincity'?0.564:asp;
                return asp;
            },
        },
        created() {

        },
        data: function () {
            return {
                lockSvg:Utils.getGlobalLink(lockSvg), soonImg:Utils.getGlobalLink(soonImg), newImg:Utils.getGlobalLink(newImg),
                infoSvg:Utils.getGlobalLink(infoSvg),
            }
        },
        beforeMount() {
            try{
                const gameList=this.$store.getters.gameList;
                gameList.forEach((gameC)=>{
                    gameC.forEach(gameObj=>{
                        gameObj.popupInfoVisible=false;
                        gameObj.infoVisible=false;
                    })
                })
            }catch (e) {}
        },
        methods:{
            openGame(name){
                this.$store.dispatch("openGame",  name);
            },
            btnInfoClick(){

            },
            btnInfoClickAction(gameObj){
                gameObj.infoVisible=true;
                setTimeout(()=>{gameObj.infoVisible=false},6000)
            },
            btnRatioClick(){

            },
            btnRatioClickAction(gameObj){
                gameObj.popupInfoVisible=true;
                setTimeout(()=>{gameObj.popupInfoVisible=false},5000)
            },
            getMiningText(name){
                const msg=this.$store.state.messages;
                let txt='';
                try{
                    txt=msg.m053_01+' '+this.miningRatio(name)+msg.m053_02+' '+this.miningRatio(name)+' '+msg.m053_03;
                }catch (e) {}
                return txt;
            },
            popupInfoClick(){

            },
            miningRatio(name){
                let r=0;
                try {
                    const bs=this.$store.state.blockspinState;
                    r=bs.gamesGemMiningRate[name];
                    r=r?r:0;
                }catch (e) {}
                return r*10;
            }
        },
    }
    //);


</script>

<style lang="css" scoped>

    .c-page_game_list_row{
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-page_game_list_icon{
        overflow: hidden;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-page_game_list_text{
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        color: #0d4455;

        white-space: nowrap;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-page_game_list_lock{
        position: absolute;
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        padding: 0px 0px 0px;
    }

    .c-page_game_list_info{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background: linear-gradient(144deg, rgba(12, 255, 124, 0.85) 0%, rgba(0, 175, 144, 0.85) 100%);
    }

    .c-page_game_list_info_cont{
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .c-page_game_list_descr_text{
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: -webkit-center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        color: white;

        white-space: pre-wrap;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-page_game_list_info_popup{
        position: absolute;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }

</style>

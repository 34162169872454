<template>
    <!--   Game messages popup for player "successful and error"   -->
    <div class="c-components_body unselectable" style="backdrop-filter: blur(5px);background: linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8));">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <!--   Popup bg static   -->
            <div class="c-popup_msg_2"
                 :style="{'width': 1000*ss*xMult+'px','height': 800*ss*xMult+'px','gap': 30*ss*xMult+'px','border-radius':60*ss*xMult+'px',
                    'border':isError?6*ss*xMult+'px solid #CC181A':6*ss*xMult+'px solid #06A071',
                    'background':getBg,'box-shadow':getShadow}"
            >

                <!--     Confirm information       -->
                <div class="c-popup_msg_column"
                     :style="{'width': 1000*ss*xMult+'px','height': 700*ss*xMult+'px',}"
                >
                    <div class="c-popup_msg_text unselectable"
                         :style="{'width': 800*ss*xMult+'px','height': 100*ss*xMult+'px',
                            'font-size': 70*ss*xMult+'px','line-height': 100*ss*xMult+'px',}"
                    >
                        {{popupMessage.title}}
                    </div>
                    <div class="c-popup_msg_static"
                         :style="{'width': 950*ss*xMult+'px','height': 450*ss*xMult+'px',}"
                    >
                        <div class="c-popup_msg_text_scroll"
                             :style="{'width': 950*ss*xMult+'px','height': 'auto',
                                'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                        >
                            {{popupMessage.msg}}
                        </div>
                    </div>


                    <div class="c-popup_msg_row"
                         :style="{'width': 1000*ss*xMult+'px','height': 110*ss*xMult+'px',}"
                    >
                        <!--     Confirm button       -->
                        <ButtonSvgOval :callback="clickConfirm" :callback-wait="true"
                                       :btn-text="messages.m_ok"
                                       :bg-width="320*ss*xMult" :bg-height="100*ss*xMult"
                                       :max-width="420*ss*xMult"
                                       :font-size="45*ss*xMult"
                                       :svg-width="50*ss*xMult" :svg-height="50*ss*xMult"
                                       :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                                       :borderVal="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                                       :click-scale="0.95"
                        />

                        <!--   Copy button if error  -->
                        <div class="c-popup_msg_row" style="position: absolute;"
                             :style="{'right': 20*ss*xMult+'px', 'width': 150*ss*xMult+'px','height': 110*ss*xMult+'px',}"
                        >
                            <ButtonSvgOval :callback="clickCopy" :callback-wait="true"
                                           :svg-normal="img_copy"
                                           :bg-width="120*ss*xMult" :bg-height="100*ss*xMult"
                                           :max-width="100*ss*xMult"
                                           :font-size="45*ss*xMult"
                                           :svg-width="80*ss*xMult" :svg-height="80*ss*xMult"
                                           :backgroundVal="'none'"
                                           :borderVal="'none'"
                                           :click-scale="0.9"
                            />
                        </div>
                        <div v-if="popupQuickHelp.popupMessage===true" class="c-popup_msg_info_popup"
                             :style="{'right': 50*ss*xMult+'px','top': -100*ss*xMult+'px',}"
                        >
                            <PopupInfo     :bg-width="250*ss*xMult" :bg-height="90*ss*xMult"
                                           :max-width="50*ss*xMult"
                                           :border-radius="30*ss*xMult"
                                           :font-size="45*ss*xMult"
                                           :btn-text="messages.m040_09"
                                           :background-val="'rgba(0, 0, 0, 0.4)'"
                                           :border-val="'1px solid rgba(255, 255, 255, 0.5)'"
                                           :callback="popupInfoClick" :callback-wait="true"

                            />
                        </div>
                    </div>
                </div>

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import PopupInfo from "@/vue/elements/PopupInfo";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import img_copy from "@/assets/svg/copy_white.svg";

    export default defineComponent({
        name: 'PopupMessage',
        components: {ButtonSvg,ButtonSvgOval,PopupInfo},
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","messages","popupMessage","popupQuickHelp"]),

            getBg(){
                const param=this.$store.state.popupMessage;
                let txt='linear-gradient(95deg, #0CFF7C -16.14%, #00AF90 137.96%)';
                if (param.error===true){
                    txt='linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)';
                }
                return txt;
            },
            getShadow(){
                const param=this.$store.state.popupMessage;
                let txt='0px 0px 100px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 100px 0px rgba(57, 231, 156, 0.60)';
                if (param.error===true){
                    txt='0px 0px 100px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 100px 0px rgba(253, 134, 81, 0.60)';
                }
                return txt;
            },
            isError(){        //true - buy confirm, false - burn tickets confirm
                const param=this.$store.state.popupMessage;
                if (param.error===false)
                    return false;
                return true;
            },
        },
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),img_copy:Utils.getGlobalLink(img_copy),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btn_xClick(){
                const param=this.$store.state.popupMessage;
                param.visible=false;
                param.msg='';
                param.title='';
                param.error=false;
            },
            clickConfirm(){
                const param=this.$store.state.popupMessage;
                param.visible=false;
                param.msg='';
                param.title='';
                param.error=false;
            },
            clickCopy(){
                const state=this.$store.state;
                const msg=this.$store.state.popupMessage.msg;
                let clipboardData = window.clipboardData || navigator.clipboard;
                clipboardData.writeText(msg);

                state.popupQuickHelp.popupMessage=true;
                setTimeout(() => {
                    state.popupQuickHelp.popupMessage=false;
                }, 1000);
            },
            popupInfoClick(){
                const state=this.$store.state;
                state.popupQuickHelp.popupMessage=false;
            },
        },
    });

</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-popup_msg_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-popup_msg_static {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .c-popup_msg_column{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-popup_msg_row{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-popup_msg_text{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;
        text-align: -webkit-center;

        /*word-wrap: break-word;/**/
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-popup_msg_text_scroll{
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;
        text-align: -webkit-center;

        overflow-x: hidden;
        overflow-y: auto;

        word-wrap: break-word;/**/
        white-space: pre-wrap;
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-popup_msg_info_popup{
        position: absolute;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }

</style>

import nitro_full from "@/assets/images/digitalPrizes/nitro_full.webp";
import nitro_classic from "@/assets/images/digitalPrizes/nitro_classic.webp";
import giftcard_csdeals from "@/assets/images/digitalPrizes/giftcard_csdeals.webp";
import giftcard_g2a from "@/assets/images/digitalPrizes/giftcard_g2a.webp";
import giftcard_nintendo5 from "@/assets/images/digitalPrizes/giftcard_nintendo5.webp";
import giftcard_nintendo10 from "@/assets/images/digitalPrizes/giftcard_nintendo10.webp";
import giftcard_nintendo20 from "@/assets/images/digitalPrizes/giftcard_nintendo20.webp";
import giftcard_roblox from "@/assets/images/digitalPrizes/giftcard_roblox.webp";
import giftcard_steam from "@/assets/images/digitalPrizes/giftcard_steam.webp";
import vip_card_prize_bronze from "@/assets/images/digitalPrizes/vip_card_prize_br.webp";
import vip_card_prize_silver from "@/assets/images/digitalPrizes/vip_card_prize_silver.webp";
import vip_card_prize_gold from "@/assets/images/digitalPrizes/vip_card_prize_gold.webp";

export default {
    'nitrofull':{
        name: "Full Nitro",
        collection: "Discord",
        codeInstructions: "- Copy and past the link in your internet browser.\n" +
            "- Follow the instructions.",
        img: nitro_full
    },
    'nitroclassic': {
        name: "Classic Nitro",
        collection: "Discord",
        codeInstructions: "- Copy and past the link in your internet browser.\n" +
            "- Follow the instructions.",
        img: nitro_classic
    },
    'csdeals': {
        name: "Giftcard",
        collection: "CS.Deals",
        codeInstructions: "- Log in to CS. Deals.\n" +
            "- Go to cs. deals/wallet.\n" +
            "- Choose the \"Gift card\" deposit option.\n" +
            "- Enter the key.",
        img: giftcard_csdeals
    },
    'g2a': {
        name: "Giftcard",
        collection: "G2A",
        codeInstructions: "- Login to your G2A account.\n" +
            "- From the G2A Wallet menu in the top right select \"Add G2A Gift Card\"\n" +
            "- Enter your code and click \"Add to your balance\"\n" +
            "- A message should be displayed about a successful redemption.",
        img: giftcard_g2a
    },
    'nintendo5': {
        name: " Giftcard",
        collection: "Nintendo",
        codeInstructions: "- Using a browser, visit ec.nintendo.com/redeem/#/\n" +
            "- Select Sign in and enter your Nintendo Account e-mail address and password, and then select Sign in again.\n" +
            "- If you are already signed in, review the user name in the top-right corner to confirm you are signed in with the correct Nintendo Account\n" +
            "- Enter your 16-digit download code and then select, Next\n" +
            "- Re-enter your Nintendo Account password when prompted, then select Redeem to complete the process.",
        img: giftcard_nintendo5
    },
    'nintendo10': {
        name: "Giftcard",
        collection: "Nintendo",
        codeInstructions: "- Using a browser, visit ec.nintendo.com/redeem/#/\n" +
            "- Select Sign in and enter your Nintendo Account e-mail address and password, and then select Sign in again.\n" +
            "- If you are already signed in, review the user name in the top-right corner to confirm you are signed in with the correct Nintendo Account\n" +
            "- Enter your 16-digit download code and then select, Next\n" +
            "- Re-enter your Nintendo Account password when prompted, then select Redeem to complete the process.",
        img: giftcard_nintendo10
    },
    'nintendo20': {
        name: "Giftcard",
        collection: "Nintendo",
        codeInstructions: "- Using a browser, visit ec.nintendo.com/redeem/#/\n" +
            "- Select Sign in and enter your Nintendo Account e-mail address and password, and then select Sign in again.\n" +
            "- If you are already signed in, review the user name in the top-right corner to confirm you are signed in with the correct Nintendo Account\n" +
            "- Enter your 16-digit download code and then select, Next\n" +
            "- Re-enter your Nintendo Account password when prompted, then select Redeem to complete the process.",
        img: giftcard_nintendo20
    },
    'roblox': {
        name: "Giftcard",
        collection: "Roblox",
        codeInstructions: "- Log into your account on a browser.\n" +
            "- Go to the Code Redemption Page.\n" +
            "- Enter your code in the box.\n" +
            "- Click Redeem.",
        img: giftcard_roblox
    },
    'steam': {
        name: "Giftcard",
        collection: "Steam",
        codeInstructions: "- Visit store.steampowered.com/account/redeemwalletcode in your browser and sign in into your account.\n" +
            "- Enter the code into the Steam Wallet Code field.\n" +
            "- Follow the instructions.",
        img: giftcard_steam
    },
    'bronzeMonthlyCard': {
        name: "Bronze VIP Card",
        collection: "Blockspin",
        codeInstructions: "- Click on 'Chips'.\n" +
            "- Click 'Redeem'.\n" +
            "- Enter the code.\n" +
            "- Click 'Send code'.",
        img: vip_card_prize_bronze
    },
    'silverMonthlyCard': {
        name: "Silver VIP Card",
        collection: "Blockspin",
        codeInstructions: "- Click on 'Chips'.\n" +
            "- Click 'Redeem'.\n" +
            "- Enter the code.\n" +
            "- Click 'Send code'.",
        img: vip_card_prize_silver
    },
    'goldMonthlyCard': {
        name: "Gold VIP Card",
        collection: "Blockspin",
        codeInstructions: "- Click on 'Chips'.\n" +
            "- Click 'Redeem'.\n" +
            "- Enter the code.\n" +
            "- Click 'Send code'.",
        img: vip_card_prize_gold
    },
}

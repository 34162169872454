<template>
    <!--   Element for LearnChips and LearnNft    -->
    <div class="c-learn_elem"
         :style="{'width': 900*ss*xMult+'px','height': 'auto','border-radius': 40*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
    >

        <div class="c-learn_elem_ccc"
             :style="{'width': 150*ss*xMult+'px','height': 150*ss*xMult+'px',}"
        >
            <img v-if="img1" alt=""
                 :style="{'width': 100*ss*xMult+'px','height': 100*ss*xMult+'px',}"
                 :src="img1"
            />
        </div>
        <div class="c-learn_elem_ccs"
             :style="{'width': (img2?410:670)*ss*xMult+'px','height': 'auto','padding-left': 20*ss*xMult+'px','padding-right': 20*ss*xMult+'px',
                'padding-top': 30*ss*xMult+'px','padding-bottom': 40*ss*xMult+'px',}"
        >
            <div class="c-learn_elem_rsc c-learn_elem_text" style="overflow: hidden;"
                 :style="{'width': '100%','height': 'auto',
                        'line-height': 80*ss*xMult+'px','font-size': 50*ss*xMult+'px',}">
                {{text1}}
            </div>
            <div class="c-learn_elem_rsc c-learn_elem_text" style="overflow: hidden;opacity: 0.75;"
                 :style="{'width': '100%','height': 'auto',
                        'line-height': 60*ss*xMult+'px','font-size': 45*ss*xMult+'px',}">
                {{text2}}
            </div>
        </div>
        <div v-if="img2" class="c-learn_elem_ccc"
             :style="{'width': 250*ss*xMult+'px','height': 250*ss*xMult+'px',}"
        >
            <img alt=""
                 :style="{'width': 220*ss*xMult+'px','height': 220*ss*xMult+'px',}"
                 :src="img2"
            />
        </div>

        <div class="c-learn_elem_click"
             @click="onClick"
             :style="{'width': '100%','height': '100%','border-radius': 40*ss*xMult+'px','left': 0,'right': 0,}"
        />
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'LearnElement',
        components: {},
        computed: {
            ...mapGetters(["ss","xMult","messages","btnRad"]),

        },
        data(){
            return {

            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            onClick() {
                try {
                    if (!this.callback) return;
                    this.callback();
                }catch (e) {}
            },
        },
        props:{
            callback: {
                type: Function,
                default: () => {}
            },
            img1: {             //Left image
                type: String,
                default: ""
            },
            img2: {             //Right image, if none, then text area exclude to img size
                type: String,
                default: ""
            },
            text1: {            //title
                type: String,
                default: ""
            },
            text2: {            //description
                type: String,
                default: ""
            },
        },
    });
</script>

<style lang="css" scoped>
    .c-learn_elem{
        position: relative;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        justify-content: center;
        align-items: center;

        background: hsla(0,0%,100%,0.10000000149011612);/**/
        /*background: rgba(0, 0, 0, 0.10);/**/
    }
    .c-learn_elem_click{
        position: absolute;
        display: flex;
        background: hsla(0,0%,100%,0.001);
    }

    .c-learn_elem_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: visible;
        color: #FFFFFF;
        text-align: -webkit-left;
    }


    .c-learn_elem_ccs{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }
    .c-learn_elem_ccc{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .c-learn_elem_rsc{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }


</style>

<template>
    <div class="c-page_our_bg"
         :style="{'width': realWidth-40*ss+'px','height': realHeight-(orient==='V'?310:190)*ss*xMult+'px',}">
        <div class="c-page_our_game_bg unselectable"
             :style="{'left':0*ss*xMult+'px','width': getWidth+'px','height': getHeight+'px',
            'margin-top': 0*ss*xMult+'px'}">
            <div v-if="gameType" class="c-page_our_game_content"
                 :style="{'width': getWidth,'height': 'auto',}">
                <!-- 'gap': 40*ss*xMult+'px', Not working for display block -->
                <div class="c-page_our_game_type"
                     :style="{'height':orient==='V'?220*ss+'px':340*ss+'px','width': getWidth-40*ss*xMult+'px',}">
                    <GameTypeIcon :svg-normal="iconSlot" :icon-name="'allGames'"
                                   :btn-text="'Slots'" :font-size="44*ss*multOur"
                                   :bg-width="250*ss*multOur" :bg-height="220*ss*multOur"
                                   :svg-width="166*ss*multOur" :svg-height="100*ss*multOur"
                                   :callback="iconTypeClick"
                    />
                    <GameTypeIcon :svg-normal="iconScratch" :icon-name="'scratchCards'"
                                  :btn-text="'Scratch'" :font-size="44*ss*multOur"
                                  :bg-width="250*ss*multOur" :bg-height="220*ss*multOur"
                                  :svg-width="152*ss*multOur" :svg-height="100*ss*multOur"
                                  :callback="iconTypeClick"
                    />
                    <GameTypeIcon :svg-normal="iconCrash" :icon-name="'crashGames'"
                                  :btn-text="'Crash'" :font-size="44*ss*multOur"
                                  :bg-width="230*ss*multOur" :bg-height="220*ss*multOur"
                                  :svg-width="144*ss*multOur" :svg-height="100*ss*multOur"
                                  :callback="iconTypeClick"
                    />
                    <GameTypeIcon :svg-normal="iconInstant" :icon-name="'instantGames'"
                                  :btn-text="'Instant Games'" :font-size="35*ss*multOur"
                                  :bg-width="270*ss*multOur" :bg-height="220*ss*multOur"
                                  :svg-width="76*ss*multOur" :svg-height="100*ss*multOur"
                                  :callback="iconTypeClick"
                    />

                    <GameTypeIcon v-if="orient==='H'" :svg-normal="iconSpincity" :icon-name="'spincity'"
                                  :btn-text="'Spincity'" :font-size="44*ss*multOur"
                                  :bg-width="320*ss*multOur" :bg-height="220*ss*multOur"
                                  :svg-width="160*ss*multOur" :svg-height="100*ss*multOur"
                                  :callback="iconTypeClick"
                    />
                    <GameTypeIcon v-if="orient==='H'" :svg-normal="iconPoker" :icon-name="'poker'"
                                  :btn-text="'Poker'" :font-size="44*ss*multOur"
                                  :bg-width="250*ss*multOur" :bg-height="220*ss*multOur"
                                  :svg-width="145*ss*multOur" :svg-height="100*ss*multOur"
                                  :callback="iconTypeClick"
                    />
                    <GameTypeIcon v-if="orient==='H'" :svg-normal="iconCasino" :icon-name="'casino'"
                                  :btn-text="'Casino'" :font-size="44*ss*multOur"
                                  :bg-width="320*ss*multOur" :bg-height="220*ss*multOur"
                                  :svg-width="114*ss*multOur" :svg-height="100*ss*multOur"
                                  :callback="iconTypeClick"
                    />

                </div>

                <div v-if="orient==='V'" class="c-page_our_game_type"
                     :style="{'height':10*ss+'px','width': getWidth+'px',}"/>

                <div v-if="orient==='V'" class="c-page_our_game_type"
                     :style="{'height':220*ss+'px','width': getWidth+'px','margin-top':0*ss+'px',}">
                    <GameTypeIcon :svg-normal="iconSpincity" :icon-name="'spincity'"
                                  :btn-text="'Spincity'" :font-size="44*ss"
                                  :bg-width="320*ss" :bg-height="220*ss"
                                  :svg-width="160*ss" :svg-height="100*ss"
                                  :callback="iconTypeClick"
                    />
                    <GameTypeIcon :svg-normal="iconPoker" :icon-name="'poker'"
                                  :btn-text="'Poker'" :font-size="44*ss"
                                  :bg-width="250*ss" :bg-height="220*ss"
                                  :svg-width="145*ss" :svg-height="100*ss"
                                  :callback="iconTypeClick"
                    />
                    <GameTypeIcon :svg-normal="iconCasino" :icon-name="'casino'"
                                  :btn-text="'Casino'" :font-size="44*ss"
                                  :bg-width="320*ss" :bg-height="220*ss"
                                  :svg-width="114*ss" :svg-height="100*ss"
                                  :callback="iconTypeClick"
                    />
                </div>

                <div class="c-page_our_game_type"
                     :style="{'height':(orient==='V'?20:10)*ss+'px','width': getWidth+'px',}"/>

                <PageGameList/>

            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import GameTypeIcon from "./GameTypeIcon";
    import Utils from "../../scripts/Utils";

    import iconSlot from "../../assets/images/icon_slot.webp";
    import iconScratch from "../../assets/images/icon_scratch.webp";
    import iconCrash from "../../assets/images/icon_crash.webp";
    import iconInstant from "../../assets/images/icon_instant.webp";
    import iconSpincity from "../../assets/images/icon_spincity.webp";
    import iconPoker from "../../assets/images/icon_poker.webp";
    import iconCasino from "../../assets/images/icon_casino.webp";
    import PageGameList from "./PageGameList";
    import Config from "../../scripts/Config";
    import { defineComponent } from 'vue';
    import btn_xSvg from "@/assets/svg/btn_x";

    export default defineComponent({
    //export default {
        name: "PageOur",
        components: {PageGameList, GameTypeIcon,},
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","messages","orient","multOur","xMult","gameType"]),
            getHeight(){
                const state=this.$store.state;
                let h=state.realHeight-310*state.ss;
                if (state.orient==='H')
                    h=state.realHeight-80*state.ss-145*state.ss*this.$store.getters.xMult;
                return h;
            },
            getWidth(){
                const state=this.$store.state;
                let w=state.realWidth-40*state.ss*state.xMult;
                return w;
            },
        },
        created() {

        },
        mounted() {
            Utils.setIcons(appVue.$store.state.gameType);
        },
        beforeUnmount() {

        },
        data: function () {
            return {
                iconSlot:Utils.getGlobalLink(iconSlot),iconScratch:Utils.getGlobalLink(iconScratch),
                iconCrash:Utils.getGlobalLink(iconCrash),iconInstant:Utils.getGlobalLink(iconInstant),
                iconPoker:Utils.getGlobalLink(iconPoker),iconCasino:Utils.getGlobalLink(iconCasino),
                iconSpincity:Utils.getGlobalLink(iconSpincity),//iconLajam
            }
        },
        methods:{
            iconTypeClick(iconType){
                if (this.$store.state.gameType!==iconType){
                    if (Config.gameTypesSoon.includes(iconType))        //If game not developed then exit
                        return;

                    this.$store.state.gameType=null;
                    setTimeout(()=>{
                        Utils.setIcons(iconType);
                    },10);
                }
                else{
                    Utils.setIcons(iconType);
                }
            },

        },
    }
    );

</script>

<style lang="css" scoped>
    .c-page_our_bg{
        display: flex;
        overflow: -moz-scrollbars-none;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding:0;
        margin:0;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-page_our_game_type{
        display: flex;
        overflow: hidden;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-page_our_game_bg{
        overflow: hidden;
        display: flex;

        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-page_our_game_content{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        overflow-y: auto;/**/
        /*overscroll-behavior-y: auto;*/
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch !important;

        display: block;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

</style>

import achievementsstats  from "@/assets/images/boost/achievementsstats.webp";
import freeze  from "@/assets/images/boost/freeze.webp";
import insurance1  from "@/assets/images/boost/insurance1.webp";
import insurance7  from "@/assets/images/boost/insurance7.webp";
import recovery  from "@/assets/images/boost/recovery.webp";
import xpboost  from "@/assets/images/boost/xpboost.webp";
import xpmegaboost  from "@/assets/images/boost/xpmegaboost.webp";
import achievementsstats_j  from "@/assets/images/boost/achievementsstats.jpg";
import freeze_j  from "@/assets/images/boost/freeze.jpg";
import insurance1_j  from "@/assets/images/boost/insurance1.jpg";
import insurance7_j  from "@/assets/images/boost/insurance7.jpg";
import recovery_j  from "@/assets/images/boost/recovery.jpg";
import xpboost_j  from "@/assets/images/boost/xpboost.jpg";
import xpmegaboost_j  from "@/assets/images/boost/xpmegaboost.jpg";

import vip_bronze  from "@/assets/images/digitalPrizes/vip_bronze.webp";
import vip_silver  from "@/assets/images/digitalPrizes/vip_silver.webp";
import vip_gold  from "@/assets/images/digitalPrizes/vip_gold.webp";
import vip_bronze_j  from "@/assets/images/digitalPrizes/vip_bronze.jpg";
import vip_silver_j  from "@/assets/images/digitalPrizes/vip_silver.jpg";
import vip_gold_j  from "@/assets/images/digitalPrizes/vip_gold.jpg";
import chips_0  from "@/assets/images/digitalPrizes/chips_0.webp";
import chips_1  from "@/assets/images/digitalPrizes/chips_1.webp";
import chips_2  from "@/assets/images/digitalPrizes/chips_2.webp";
import chips_3  from "@/assets/images/digitalPrizes/chips_3.webp";
import chips_4  from "@/assets/images/digitalPrizes/chips_4.webp";
import chips_0j  from "@/assets/images/digitalPrizes/chips_0.jpg";
import chips_1j  from "@/assets/images/digitalPrizes/chips_1.jpg";
import chips_2j  from "@/assets/images/digitalPrizes/chips_2.jpg";
import chips_3j  from "@/assets/images/digitalPrizes/chips_3.jpg";
import chips_4j  from "@/assets/images/digitalPrizes/chips_4.jpg";

let webAppUriloc = 'https://t.me/BlockSpinBot/game';
if(window.location.hostname.includes("test.") || window.location.hostname.includes("localhost")) {
    webAppUriloc = 'https://t.me/blockspintest_bot/mobiletest_blockspingaming';
}
export const webAppUri = webAppUriloc;

export const titles = {
    0: "Newbie",
    10: "Rookie",
    20: "Original Sloter",
    30: "Total Slotter",
    40: "Amazing Slotter",
    50: "Wow Slotter",
    60: "Pro Slotter",
    69: "Nice Slotter",
    80: "Godlike",
    90: "Tryhard Gamer",
    100: "The GOAT!",
};

export const boost = {
    'insurance1Day':{
        name:"1 Day Insurance",
        priceUsd: 4.99,
        priceWax: 100,
        priceGems: 100000,
        priceSolana: 10,
        price:{                 //I set possible prices after call backend.getSate() in Utils.updatePrice
            usd:0,
            wax:0,
            sol:0,
            ronin:0,
        },
        amount:1,
        description:"Secure your daily streak with a one-day insurance plan that offers protection in case you miss a day.",
        img: insurance1,
        photo: insurance1_j,
    },
    'insurance7Day':{
        name:"7 Days Insurance",
        priceUsd: 9.99,
        priceWax: 100,
        priceGems: 100000,
        priceSolana: 10,
        price:{},
        amount:1,
        description:"The 7-day insurance pack offers ultimate protection that can be triggered either independently or " +
            "consecutively, giving you peace of mind and flexibility.",
        img: insurance7,
        photo: insurance7_j,
    },
    'xpBoost':{
        name:"XP Boost",
        priceUsd: 1,
        priceWax: 100,
        priceGems: 100000,
        priceSolana: 10,
        price:{},
        amount:1,
        description:"Earn x2 times more experience when you play.\n\nDuration: 6 hours.",
        img: xpboost,
        photo: xpboost_j,
    },
    'megaXpBoost':{
        name:"Mega XP Boost",
        priceUsd: 3,
        priceWax: 100,
        priceGems: 100000,
        priceSolana: 10,
        price:{},
        amount:1,
        description:"Earn x5 times more experience when you play.\n\nDuration: 6 hours.",
        img: xpmegaboost,
        photo: xpmegaboost_j,
    },
    'unlockStats':{
        name:"Achievements Stats",
        priceUsd: 1.99,
        priceWax: 100,
        priceGems: 100000,
        priceSolana: 10,
        price:{},
        amount:1,
        description:"Know how many bets you played per slots and see how far you are from achievements. Each slot achievement rewards 10k chips",
        img: achievementsstats,
        photo: achievementsstats_j,
        isTest: true
    },
    'xpFreeze':{
        name:"XP Freeze",
        priceUsd: 19.99,
        priceWax: 100,
        priceGems: 100000,
        priceSolana: 10,
        price:{},
        amount:1,
        description:"Dont lose your XP and daily streak, the freeze save your Streak and XP until you spin again.\n\nDuration: Unlimited.",
        img: freeze,
        photo: freeze_j,
    },
    'xpRecovery':{
        name:"XP Recovery",
        priceUsd: 49.99,
        priceWax: 100,
        priceGems: 100000,
        priceSolana: 10,
        price:{},
        amount:1,
        description:"You recover your lost XP amount",
        img: recovery,
        photo: recovery_j,
    },
};

export const chips={        //not array for the same structure with 'boost' and 'vip'
    0:{
        name:'',                //Set name=count+' Chips' after call backend.getSate() in Utils
        discount:0,
        price:{                 //I set possible prices after call backend.getSate() in Utils.updatePrice
            usd:0,
            wax:0,
            sol:0,
            ronin:0,
        },
        amount:1,
        description:"",
        img: chips_0,
        photo: chips_0j,
    },
    1:{
        name:'',                //Set name=count+' Chips' after call backend.getSate() in Utils
        discount:0,
        price:{},
        amount:1,
        description:"",
        img: chips_1,
        photo: chips_1j,
    },
    2:{
        name:'',                //Set name=count+' Chips' after call backend.getSate() in Utils
        discount:0,
        price:{},
        amount:1,
        description:"",
        img: chips_2,
        photo: chips_2j,
    },
    3:{
        name:'',                //Set name=count+' Chips' after call backend.getSate() in Utils
        discount:0,
        price:{},
        amount:1,
        description:"",
        img: chips_3,
        photo: chips_3j,
    },
    4:{
        name:'',                //Set name=count+' Chips' after call backend.getSate() in Utils
        discount:0,
        price:{},
        amount:1,
        description:"",
        img: chips_4,
        photo: chips_4j,
    },
};

export const monthlyCards = {                    //'memberships' in blockspin frontend but monthlyCards in backend
    'bronze':{
        name:"30 Days Bronze VIP Membership",
        name2:"Bronze VIP Membership",
        priceUsd: 4.99,
        priceWax: 100,
        priceGems: 100000,
        priceSolana: 10,
        price:{                 //I set possible prices after call backend.getSate() in Utils.updatePrice
            usd:0,
            wax:0,
            sol:0,
            ronin:0,
        },
        amount:1,
        description:"Perks",
        img: vip_bronze,
        photo: vip_bronze_j,
    },
    'silver':{
        name:"30 Days Silver VIP Membership",
        name2:"Silver VIP Membership",
        priceUsd: 19.99,
        priceWax: 100,
        priceGems: 100000,
        priceSolana: 10,
        price:{},
        amount:1,
        description:"Perks",
        img: vip_silver,
        photo: vip_silver_j,
    },
    'gold':{
        name:"30 Days Gold VIP Membership",
        name2:"Gold VIP Membership",
        priceUsd: 49.99,
        priceWax: 100,
        priceGems: 100000,
        priceSolana: 10,
        price:{},
        amount:1,
        description:"Perks",
        img: vip_gold,
        photo: vip_gold_j,
    },
};

export const priceFraction={
    wax: 2,
    usd: 2,
    sol: 4,
    ronin: 4,
    xtr: 0,
};

export const currencyNames={
    wax: 'WAX',
    usd: 'USD',
    sol: 'SOL',
    ronin: 'RON',
    xtr: 'XTR',
};

export const xtrUsdPrice=0.02;

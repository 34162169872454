<template>
    <!--
            Create a free account and unlock all the features
    -->
    <div class="c-learn_newacc unselectable"
         :style="{'top': 0*ss*xMult+'px','left':0*ss*xMult+'px',}">
        <div class="c-learn_newacc_bg"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <div class="c-learn_newacc_bg_rel"
                 :style="{'width': realWidth-80*ss*xMult+'px','height':'auto','max-height': realHeight-80*ss*xMult+'px','border-radius': 40*ss*xMult+'px',
                    'padding-top': 40*ss*xMult+'px','padding-bottom': 40*ss*xMult+'px','gap': 40*ss*xMult+'px',}">

                <div class="c-learn_newacc_ccc c-learn_newacc_text"
                     :style="{'width': (orient==='V'?900:1800)*ss*xMult+'px','height': (orient==='V'?160:80)*ss*xMult+'px','margin-left': 40*ss*xMult+'px','margin-top': 0*ss*xMult+'px',
                        'line-height': 80*ss*xMult+'px','font-size': 60*ss*xMult+'px',}">
                    {{messages.m051_01}}
                </div>

                <!--scroll static-->
                <div class="c-learn_newacc_ccc"
                     :style="{'width': realWidth-80*ss*xMult+'px','height':'auto','max-height': realHeight-(orient==='V'?360:280)*ss*xMult+'px',}">
                    <!--scroll dinamic-->
                    <div class="c-learn_newacc_scroll"
                         :style="{'width': realWidth-80*ss*xMult+'px','height':'auto','gap': 30*ss*xMult+'px',
                        'padding-top': 10*ss*xMult+'px','padding-bottom': 10*ss*xMult+'px',}">
                        <LearnElement
                                :img1="img_1"
                                :text1="messages.m051_02"
                                :text2="messages.m051_03"
                        />
                        <LearnElement
                                :img1="img_2"
                                :text1="messages.m051_04"
                                :text2="messages.m051_05"

                        />
                        <LearnElement
                                :img1="img_3"
                                :text1="messages.m051_06"
                                :text2="messages.m051_07"

                        />
                        <LearnElement
                                :img1="img_4"
                                :text1="messages.m051_08"
                                :text2="messages.m051_09"

                        />
                        <LearnElement
                                :img1="img_5"
                                :text1="messages.m051_10"
                                :text2="messages.m051_11"
                        />
                        <LearnElement
                                :img1="img_6"
                                :text1="messages.m051_12"
                                :text2="messages.m051_13"

                        />
                        <ButtonSvgOval :svg-normal="iconPersonSvg" :btn-text="messages.m051_14"
                                       :callback="clickCreate" :callback-wait="true"
                                       :bg-width="702*ss*xMult" :bg-height="btnRad*0.9"
                                       :max-width="702*ss*xMult-btnRad/2-70*ss*xMult"
                                       :svg-width="btnRad/2" :svg-height="btnRad/2"
                                       :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%'"
                                       :borderVal="'linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%)'"
                                       :clickScale="0.97"
                        />
                    </div>
                </div>

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import LearnElement from "@/vue/pages/components/LearnElement";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";
    import Utils from "@/scripts/Utils";
    import btn_xSvg from "@/assets/svg/btn_x";

    import img_1 from "@/assets/svg/info/gems_yellow.svg";
    import img_2 from "@/assets/svg/info/chips_green.svg";
    import img_3 from "@/assets/svg/info/power_pink.svg";
    import img_4 from "@/assets/svg/info/battle_orange.svg";
    import img_5 from "@/assets/svg/info/spin_yellow.svg";
    import img_6 from "@/assets/svg/info/refer_green.svg";
    import iconPersonSvg from "@/assets/svg/icon_person";

    //export default defineComponent({              //Not working debugger in chrome
    export default {
        name: "LearnNewAccount",
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","getLeft","xMult","orient","messages","popupVisible",]),
            btnRad(){
                return 135*this.$store.state.ss*this.$store.getters.xMult
            },

        },
        components: {ButtonSvg, ButtonSvgOval, LearnElement},
        created() {

        },
        data: function () {
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),
                img_1:Utils.getGlobalLink(img_1),img_2:Utils.getGlobalLink(img_2),img_3:Utils.getGlobalLink(img_3),
                img_4:Utils.getGlobalLink(img_4),img_5:Utils.getGlobalLink(img_5),img_6:Utils.getGlobalLink(img_6),
                iconPersonSvg:Utils.getGlobalLink(iconPersonSvg),
            }
        },
        methods:{
            btn_xClick(){
                this.$store.state.popupVisible.learnNewAccount=false;
            },
            clickCreate(){
                this.$store.state.popupVisible.learnNewAccount=false;
                this.$store.state.popupVisible.signup=true;
            },

        },
    }
    //);

</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-learn_newacc{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: absolute;
        justify-content: center;
        align-items: center;
        padding:0;
        margin:0;
        background: rgba(0, 0, 0, 0.67);

        width: 100vw;
        height: 100vh;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;

    }
    .c-learn_newacc_bg{
        display: flex;
        overflow: hidden;
        position: absolute;
        transform: translate(0%, 0%);
        padding:0;
        margin:0;

        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-learn_newacc_bg_rel{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        background: linear-gradient(94deg, #00C6FB 0.27%, #005BEA 105.74%);
        /*background: radial-gradient(233.23% 93.25% at 92.1% -14.57%, #00C6FB 0%, #005BEA 100%), #FFF;/**/
        box-shadow: 0px 0px 60px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 70px 0px rgba(0, 91, 234, 0.70);
    }

    .c-learn_newacc_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: visible;
        color: #FFFFFF;
        text-align: -webkit-center;
    }

    .c-learn_newacc_ccc{
        display: flex;
        overflow: hidden;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-learn_newacc_scroll{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;
    }

</style>

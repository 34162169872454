<template>
    <div class="c-components_body unselectable" style="background: white;"
         :style="{'top': 0*ss+'px','left':0*ss+'px',}">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <div class="c-pop_privacy_bg"
                 :style="{'width': realWidth+'px','height': realHeight+'px',}"
            >
                <div v-if="getReady" class="c-pop_privacy_text"
                     :style="{'width': realWidth-100*ss*xMult+'px','height': 140*ss*xMult+'px','margin-left': 40*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
                >
                    <h2>
                        {{privacy.h2[1]}}
                    </h2>
                </div>

                <div class="c-pop_privacy_bg"
                     :style="{'width': realWidth+'px','height': realHeight-150*ss*xMult+'px',}"
                >
                    <div v-if="getReady" class="c-pop_privacy_scroll"
                         :style="{'width': realWidth-40*ss*xMult+'px','height': 'auto','margin-left': 40*ss*xMult+'px',}"
                    >

                        <p :style="{'margin-top': 0*ss*xMult+'px',}">
                            {{privacy.p[1]}}
                        </p>
                        <p>
                            {{privacy.p[2]}}
                        </p>
                        <ul>
                            <h3>{{privacy.h3[1]}}</h3>
                        </ul>
                        <p>
                            {{privacy.p[3]}}
                        </p>
                        <ul>
                            <h3>
                                {{privacy.h3[2]}}
                            </h3>
                        </ul>

                        <p>
                            {{privacy.p[4]}}
                        </p>
                        <p>
                            {{privacy.p[5]}}
                        </p>
                        <p>
                            {{privacy.p[6]}}
                        </p>
                        <ul>
                            <h3>
                                {{privacy.h3[3]}}
                            </h3>
                        </ul>
                        <p>
                            {{privacy.p[7]}}
                        </p>
                        <p>
                            {{privacy.p[8]}}
                        </p>
                        <p>
                            {{privacy.p[9]}}
                        </p>
                        <p>
                            {{privacy.p[10]}}
                        </p>
                        <p>
                            {{privacy.p[11]}}
                        </p>
                        <p>
                            {{privacy.p[12]}}
                        </p>
                        <ul>
                            <h3>
                                {{privacy.h3[4]}}
                            </h3>
                        </ul>
                        <p>
                            {{privacy.p[13]}}
                        </p>
                        <p>
                            {{privacy.p[14]}}
                        </p>

                            <li>
                                {{privacy.li[1]}}
                            </li>
                            <li>
                                {{privacy.li[2]}}
                            </li>
                            <li>
                                {{privacy.li[3]}}
                            </li>
                            <li>
                                {{privacy.li[4]}}
                            </li>
                            <li>
                                {{privacy.li[5]}}
                            </li>
                            <li>
                                {{privacy.li[6]}}
                            </li>

                        <p>
                            {{privacy.p[15]}}
                        </p>

                        <ul>
                            <h3>
                                {{privacy.h3[5]}}
                            </h3>
                        </ul>
                        <p>
                            {{privacy.p[16]}}
                        </p>

                        <ul>
                            <h3>{{privacy.h3[6]}}</h3>
                        </ul>
                        <p>
                            {{privacy.p[17]}}
                        </p>

                    </div>
                </div>
            </div>

            <!--   X button   -->
            <div style="position: absolute; display: flex"
                 :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
            >
                <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                           :bg-width="72*ss*xMult"
                           :bg-height="72*ss*xMult"
                           :callback="btn_xClick" :callback-wait="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
    // Size of privacy and Conditions 35kB (10% from source code). I make loading information from text files

    import {mapGetters} from "vuex";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import btn_xSvg from "@/assets/svg/btn_x_black.svg";
    import privacy_pTxt from "@/assets/rules/privacy_p.txt";        //72 lines minimum
    import privacy_liTxt from "@/assets/rules/privacy_li.txt";      //44 lines
    import privacy_h2Txt from "@/assets/rules/privacy_h2.txt";
    import privacy_h3Txt from "@/assets/rules/privacy_h3.txt";      //12 lines
    import Utils from "@/scripts/Utils";
    import terms_pTxt from "@/assets/rules/terms_p.txt";

    export default {
        name: "Privacy",
        components: {ButtonSvg, },
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight", "orient", "privacy","getLeft"]),
            xMult(){
                if (this.$store.state.orient==='H')
                    return 1.55;
                return 1;
            },
            getReady(){         //privacy:{p:null,li:null,h2:null,h3:null},           //privacy and Conditions
                const state=this.$store.state;
                if (state.privacy.p && state.privacy.li && state.privacy.h2 && state.privacy.h3)
                    return true;
                return false;
            }
        },
        data: function () {
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),
            }
        },
        created() {
            const state=this.$store.state;
            fetch(Utils.getGlobalLink(privacy_pTxt))
                .then((res) => res.text())
                .then((text) => {
                    state.privacy.p=text.split(/\r\n|\n/);
                    state.privacy.p.unshift('');          //Add empty 0 line for easy check lines in text file with intellijIdea
                })
                .catch((e) => console.error(e));

            fetch(Utils.getGlobalLink(privacy_liTxt))
                .then((res) => res.text())
                .then((text) => {
                    state.privacy.li=text.split(/\r\n|\n/);
                    state.privacy.li.unshift('');
                })
                .catch((e) => console.error(e));

            fetch(Utils.getGlobalLink(privacy_h2Txt))
                .then((res) => res.text())
                .then((text) => {
                    state.privacy.h2=text.split(/\r\n|\n/);
                    state.privacy.h2.unshift('');
                })
                .catch((e) => console.error(e));

            fetch(Utils.getGlobalLink(privacy_h3Txt))
                .then((res) => res.text())
                .then((text) => {
                    state.privacy.h3=text.split(/\r\n|\n/);
                    state.privacy.h3.unshift('');
                })
                .catch((e) => console.error(e));
        },

        methods:{
            btn_xClick(){
                this.$store.state.popupVisible.privacy=false;
            },
        },

    };

</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-pop_privacy_bg {
        overflow: hidden;

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: white;
    }

    .c-pop_privacy_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-size: 16px;
        color: black;
        font-weight: 400
    }

    .c-pop_privacy_scroll {
        overflow-x: hidden;
        overflow-y: auto;

        font-family: 'Rubik';
        position: relative;
        font-size: 16px;
        background-color: white;
        color: black;
        font-weight: 400;
    }

    h2{
        margin: 10px;
        font-family: 'Rubik';
        color: black;
    }
    h3{
        margin: 0px;
        font-family: 'Rubik';
        font-weight: bold;
        color: black;
    }


    p {

    }

    ul {
        justify-content: center;
        align-items: center;
        text-align: -webkit-center;
        padding-inline-start: 0;
    }

</style>

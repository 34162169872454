<template>
    <div v-if="!isCookiesAccepted" class="cookie_popup unselectable"
         :style="{'width': realWidth,'padding': 30*ss*xMult+'px',}"
    >
      <div class="cookie_popup_2"
              :style="{'width': realWidth+'px',}"
      >
        <div class="cookie_popup_text"
             @click="clickPrivacy"
             :style="{'width': realWidth-360*ss*xMult+'px','font-size': (orient==='V'?35:40)*ss*xMult+'px','line-height': 45*ss*xMult+'px','padding': 5*ss*xMult+'px',}"
        >{{messages.m036}}
          <u> {{messages.m036_03}} </u>
        </div>

        <!--     Accept button       -->
        <ButtonSvgOval
                :callback="acceptCookies" :callback-wait="true"
                :btn-text="messages.m036_02"
                :bg-width="260*ss*xMult" :bg-height="100*ss*xMult"
                :max-width="260*ss*xMult"
                :font-size="45*ss*xMult"
                :svg-width="50*ss*xMult" :svg-height="50*ss*xMult"
                :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                :borderVal="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                :click-scale="0.95"
        />
      </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";

export default {
  name: "CookiePopup",
  computed: {
    ...mapGetters(["ss", "realWidth", "realHeight","xMult","messages","orient"]),
  },
  components:{ButtonSvgOval},
  data() {
      return {
          isCookiesAccepted: localStorage.getItem('cookies-accepted')==='true' ? true : false
      }
  },
  methods: {
      acceptCookies() {
          this.isCookiesAccepted = true;
          localStorage.setItem('cookies-accepted', 'true');
          console.log("accepted cookies");
      },
      clickPrivacy() {
        this.$store.state.popupVisible.privacy=true;
      }
  },
};
</script>

<style lang="css" scoped>

.cookie_popup {
  position: absolute;
  text-align: center;
  z-index: 12;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.60);
  backdrop-filter: blur(10px);

}

.cookie_popup_2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.cookie_popup_text {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  overflow: hidden;
  color: #FFFFFF;

}
a{
  color: #ffcc6f;
  border-bottom: 2px solid #ffcc6f;
}
</style>
